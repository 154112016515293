<!--  -->
<template>
  <div>
   <el-row>
                   <el-col :span="4">
                     <div class="grid-content">H1：</div>
                   </el-col>
                   <el-col :span="8">
                     <div class="grid-content">
                       <el-input v-model="param.H1" @change="ChangData" placeholder="请输入参数H1"></el-input>
                     </div>
                   </el-col>
                   <el-col :span="4">
                     <div class="grid-content">W：</div>
                   </el-col>
                   <el-col :span="8">
                     <div class="grid-content">
                       <el-input v-model="param.W" @change="ChangData" placeholder="请输入参数W"></el-input>
                     </div>
                   </el-col>
 </el-row>
   <el-row>
                    <el-col :span="4">
                      <div class="grid-content">H2：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input v-model="param.H2" @change="ChangData" placeholder="请输入参数H2"></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W1：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input v-model="param.W1" @change="ChangData" placeholder="请输入参数W1"></el-input>
                      </div>
                    </el-col>
  </el-row>
   <el-row>
                    <el-col :span="4">
                      <div class="grid-content">W2：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input v-model="param.W2" @change="ChangData" placeholder="请输入参数W2"></el-input>
                      </div>
                    </el-col>
  </el-row>
</div>
</template>

<script>
export default {
    name: "OtherTrapezoidsA",
  props: {},
  data () {
    return {
      param:{
        shapeName:"OtherTrapezoidsA",
      H1:0,
      H2:0,
      W:0,
      W1:0,
      W2:0,
    }
    };
  },
  methods:{
    ChangData(){
      console.log('The message is changed.',this.param);
      this.$emit("sedX",this.param)
    },
  }
}

</script>
<style lang='scss' scoped>
</style>