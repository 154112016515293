//外形：平形四边形B
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
//(param,model,fixedParam,this.zoomM)
function ParallelogramB(inputParam, model,fixedParam,zoom){
    console.log("ParallelogramB*inputParam=",inputParam);
    console.log("ParallelogramB*model=",model);
    console.log("ParallelogramB*fixedParam=",fixedParam);
    console.log("ParallelogramB*zoom=",zoom);
var H=inputParam.H,W=inputParam.W,W1=inputParam.W1,W2=inputParam.W2,myZoom=zoom*1,
plate = model.plate,  markers = model.markers,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   h= H*1*myZoom,
   w= W*1*myZoom,
   w1= W1*1*myZoom,
   w2= W2*1*myZoom,
x0=0,x1=w1,x2=w+w2,x3=w,y0=0,y1=h,y2=h,y3=0;
var glassH=h,glassW=w+w2;

if (h !== 0) {
    var TxtH = H;
} 
if (w !== 0) {
    var TxtW = W;
}
if (w1 !== 0) {
    var TxtW1 = W1;
}
if (w2 !== 0) {
    var TxtW2 = W2;
}
        var ParallelogramB = {  
            paths: {
                "LeftLine": new makerjs.paths.Line([x0, y0], [x1, y1]),
                "TopLine": new makerjs.paths.Line([x1, y1], [x2, y2]),
                "RightLine": new makerjs.paths.Line([x2, y2], [x3, y3]),
                "BottomLine": new makerjs.paths.Line([x3, y3], [x0, y0]),
            }            
            };
            var ParallelogramBMarkH = {
                paths: {
                    "LeftLine": new makerjs.paths.Line([x0 - markShift, y0], [x0 - markShift, y1]),
                    "LeftShortUpline": new makerjs.paths.Line([x1, y1], [x0 - 2 * markShift, y1]),
                    "LeftShortDnline": new makerjs.paths.Line([x0, y0], [x0 - 2 * markShift, y0]),
                }
            };
            var ParallelogramBMarkW = {
                paths: {
                    "BottomLine": new makerjs.paths.Line([x0, y0 - markShift], [x3, y3 - markShift]),
                    "BotmShortLfline": new makerjs.paths.Line([x0, y0], [x0, y0 - 2 * markShift]),
                    "BotmShortRTline": new makerjs.paths.Line([x3, y3], [x3, y3 - 2 * markShift]),
                }
            };
            var ParallelogramBMarkW1 = {
                paths: {
                    "UpW1Line": new makerjs.paths.Line([x0, y1 + markShift], [x1, y1 + markShift]),
                     "UpW1ShortLfline": new makerjs.paths.Line([x0, y1], [x0, y1 + 2 * markShift]),
                    "UpW1ShortRTline": new makerjs.paths.Line([x1, y1], [x1, y1 + 2 * markShift]),
                }
            };
            
            var ParallelogramBMarkW2 = {
                paths: {
                    "UpW2Line": new makerjs.paths.Line([x3, y3 - markShift], [x2, y3- markShift]),
                    "HRightfline": new makerjs.paths.Line([x2, y3], [x2, y2]),
                    "UpW2ShortTpline": new makerjs.paths.Line([x2-markShift, y2], [x2+markShift, y2 ]),
                    "UpW2ShortLfline": new makerjs.paths.Line([x3, y3], [x3, y3 - 2 * markShift]),
                    "UpW2ShortRTline": new makerjs.paths.Line([x2, y3], [x2, y3 - 2 * markShift]),
                }
            };
                    makerjs.model.addCaption(ParallelogramBMarkW, TxtW, [x0, y0 - 2 * markShift], [x3, y3 - 2 * markShift]);//长度文字  
                    makerjs.model.addCaption(ParallelogramBMarkW1, TxtW1, [x0, y1 + 2 * markShift], [x1, y1 + 2 * markShift]);//长度文字  
                    makerjs.model.addCaption(ParallelogramBMarkW2, TxtW2, [x3, y3 - 2 * markShift], [x2, y3 - 2 * markShift]);//长度文字 
                    makerjs.model.addCaption(ParallelogramBMarkH, TxtH, [x0 - 2 * markShift, y0], [x0 - 2 * markShift, y1]);//宽度文字
        
                    plate = {
                        models: {
                            ParallelogramB
                        },
                       };
                       markers={
                        models: {
                            ParallelogramB,
                            ParallelogramBMarkH,
                            ParallelogramBMarkW,
                            ParallelogramBMarkW1,
                            ParallelogramBMarkW2,
                          }
                        };

         
                // plate.models['ParallelogramB']=myParallelogramB;
                // markers.models['ParallelogramB']=myParallelogramB;
/*****************************************************************************/ 
return{plate,markers,glassW,glassH}
};
export default ParallelogramB;