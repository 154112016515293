//矩形框 
import makerjs from "makerjs";
function Rectangle(inputParam, model,fixedParam,zoom){
var plate=model.plate,markers=model.markers,myZoom=zoom*1,
glassW=inputParam.X,glassH=inputParam.Y,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
    w = glassW * 1,
   TxtW= "长="+w.toString(),
 h = glassH * 1,        
 TxtH=  "宽="+h.toString(); 
 
 console.log("**********=glassW",glassW);
if(w==h){
 var Rectangle = new makerjs.models.Square(w);
}else{
 var Rectangle = new makerjs.models.Rectangle(w, h);
}
plate = {
 models: {
  Rectangle:Rectangle
 },
};
var myWidthMark = new makerjs.models.Square(0);
var myHeightMark = new makerjs.models.Square(0);

makerjs.model.addPath(myWidthMark, new makerjs.paths.Line([0, -markShift+5], [0, -markShift-5]));//长度左竖短标线
makerjs.model.addPath(myWidthMark, new makerjs.paths.Line([w, -markShift+5], [w, -markShift-5]));//长度右竖短标线
makerjs.model.addPath(myWidthMark, new makerjs.paths.Line([0, -markShift], [w, -markShift]));//长度标线
makerjs.model.addCaption(myWidthMark, TxtW, [0, -markShift-8], [w, -markShift-8]);//长度文字

makerjs.model.addPath(myHeightMark, new makerjs.paths.Line([-markShift+5,0], [-markShift-5, 0]));//宽度标线
makerjs.model.addPath(myHeightMark, new makerjs.paths.Line([-markShift+5,h], [-markShift-5, h]));//宽度标线
makerjs.model.addPath(myHeightMark, new makerjs.paths.Line([-markShift,0], [-markShift, h]));//宽度标线
makerjs.model.addCaption(myHeightMark, TxtH, [-markShift-8,0], [-markShift-8, h]);//宽度文字
markers={
models: {
myWidthMark: myWidthMark,
myHeightMark: myHeightMark,
Rectangle:Rectangle
  }
};
return{plate, markers}
};
export default Rectangle;