import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/icon/iconfont.js';
import './assets/icon/iconfont.css';
import myutils from './utils/index';
import axios from 'axios';


//引入路由
import router from "./router";
import store from './store';
import Cookies from 'js-cookie';

//在自定义axios配置文件下面通过生产环境以及开发环境进行判断设置baseURL
axios.defaults.baseURL=
process.env.NODE_ENV==="development" ? "" : "https://www.gy429.com";
//////////////
Vue.prototype.axios=axios;
Vue.prototype.baseUrl=axios;
Vue.prototype.$myutils=myutils;
Vue.use(ElementUI);
Vue.config.productionTip = false;

router.beforeEach((to,from,next)=>{
 const token=Cookies.get('token')
  if(!token && to.name!=='login'){
    next({name:'login'})
  }else if(token && to.name==='login'){
    next({name:'home'});
  }else{
    next();
  }
})
new Vue({ 
  store, 
  router,
  render: h => h(App),
}).$mount('#app')
