//8、双固定孔列
import makerjs from "makerjs";
// inputParam,model,this.myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function DoubleFixHolesCol(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      h= H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        if(plate.models===undefined||plate.models===""){
            console.log("1111111111111111");
            plate.models={};
                    }        
                    if(cutPlate.models===undefined||cutPlate.models===""){
                        console.log("22222222222");
                        //cutPlate.models={};
                        cutPlate.models={};
                    }
                    if(markers.models===undefined||markers.models===""){            
                        console.log("333333333333");
                        markers.models={};
                    } 
        /*
        d = D*1,
        w= W*1,
          x0 = X*1,
          y0 = Y*1;
          */
        if(d!==0){
         var TxtD="∮"+D;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画孔 A位置孔
          //标记双固定孔列 A位置孔上
          var myDfhHolesColAUp= new makerjs.models.Holes(d/2, [[xa, ya]]);
          //标记孔
          var myDfhHolesColMarkAUp = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesColMarkAUp.origin = [xa, ya];      
          var myDfhHolesColXMarkAUp = new makerjs.models.Square(0);      
          myDfhHolesColXMarkAUp.origin =[xa, ya];      
          var myDfhHolesColYMarkAUp = new makerjs.models.Square(0);      
          myDfhHolesColYMarkAUp.origin = [xa, ya];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkAUp, new makerjs.paths.Line([0,0], [0, +markShiftTwo]));//从孔圆心出发Y方向竖向标线
    makerjs.model.addPath(myDfhHolesColMarkAUp, new makerjs.paths.Line([0,0], [0, -markShiftTwo]));//从孔圆心出发Y方向竖向标线

    makerjs.model.addPath(myDfhHolesColMarkAUp, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftFour]));//X标注短线
    makerjs.model.addPath(myDfhHolesColMarkAUp, new makerjs.paths.Line([0,y0], [0, y0+markShiftFour]));//X标注短线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkAUp, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesColMarkAUp, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesColXMarkAUp, new makerjs.paths.Line([0,y0+markShiftTwo], [-x0, y0+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myDfhHolesColXMarkAUp, TxtX, [-x0,y0+markShiftThree], [0, y0+markShiftThree]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myDfhHolesColYMarkAUp, new makerjs.paths.Line([0,0], [0, y0]));//Y短标线
    makerjs.model.addCaption(myDfhHolesColYMarkAUp, TxtY, [markShift,0], [markShift, y0]);//Y文字标注
    /*****************************************************************************/ 
     //画孔 A位置孔
          //标记双固定孔 A位置孔下
          var myDfhHolesColADn= new makerjs.models.Holes(d/2, [[xa, ya-h]]);
          //标记孔
          var myDfhHolesColMarkADn = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesColMarkADn.origin = [xa, ya-h];      
          var myDfhHolesColHMarkADn = new makerjs.models.Square(0);      
          myDfhHolesColHMarkADn.origin =[xa, ya-h]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkADn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesColMarkADn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesColHMarkADn, new makerjs.paths.Line([0,0], [0, h]));//X短标线
    makerjs.model.addCaption(myDfhHolesColHMarkADn, TxtH, [markShift,0], [markShift,h ]);//X文字标注
    /*****************************************************************************/  
    plate.models["myDfhHolesColAUp"+myHolesNum]=myDfhHolesColAUp;
    cutPlate.models["myDfhHolesColAUp"+myHolesNum]=myDfhHolesColAUp;
    markers.models["myDfhHolesColAUp"+myHolesNum]=myDfhHolesColAUp;
    markers.models["myDfhHolesColMarkAUp"+myHolesNum]=myDfhHolesColMarkAUp;
    markers.models["myDfhHolesColXMarkAUp"+myHolesNum]=myDfhHolesColXMarkAUp;
    markers.models["myDfhHolesColYMarkAUp"+myHolesNum]=myDfhHolesColYMarkAUp;
    /*****************************************************************************/
    plate.models["myDfhHolesColADn"+myHolesNum]=myDfhHolesColADn;
    cutPlate.models["myDfhHolesColADn"+myHolesNum]=myDfhHolesColADn;
    markers.models["myDfhHolesColADn"+myHolesNum]=myDfhHolesColADn;
    markers.models["myDfhHolesColMarkADn"+myHolesNum]=myDfhHolesColMarkADn;
    markers.models["myDfhHolesColHMarkADn"+myHolesNum]=myDfhHolesColHMarkADn;
    /*****************************************************************************/ 
   
    }else if(area==2){
    //画孔 B位置孔
          //标记双固定孔列B位置孔上
          var myDfhHolesColBUp= new makerjs.models.Holes(d/2, [[xb, yb]]);
          //标记孔
          var myDfhHolesColMarkBUp = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesColMarkBUp.origin = [xb, yb];      
          var myDfhHolesColXMarkBUp = new makerjs.models.Square(0);      
          myDfhHolesColXMarkBUp.origin =[xb, yb];      
          var myDfhHolesColYMarkBUp = new makerjs.models.Square(0);      
          myDfhHolesColYMarkBUp.origin = [xb, yb];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkBUp, new makerjs.paths.Line([0,0], [0, +markShiftTwo]));//从孔圆心出发Y方向竖向标线
    makerjs.model.addPath(myDfhHolesColMarkBUp, new makerjs.paths.Line([0,0], [0, -markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkBUp, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesColMarkBUp, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesColYMarkBUp, new makerjs.paths.Line([0,0], [0, y0]));//Y短标线
    makerjs.model.addCaption(myDfhHolesColYMarkBUp, TxtY, [-markShift,0], [-markShift, y0]);//Y文字标注
    /*****************************************************************************/ 
     //画孔 B位置孔
          //标记双固定孔 B位置孔下
          var myDfhHolesColBDn= new makerjs.models.Holes(d/2, [[xb, yb-h]]);
          //标记孔
          var myDfhHolesColMarkBDn = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesColMarkBDn.origin = [xb, yb-h];      
          var myDfhHolesColHMarkBDn = new makerjs.models.Square(0);      
          myDfhHolesColHMarkBDn.origin =[xb, yb-h]; 
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkBDn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesColMarkBDn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesColHMarkBDn, new makerjs.paths.Line([0,0], [0, h]));//X短标线
    makerjs.model.addCaption(myDfhHolesColHMarkBDn, TxtH, [-markShift,0], [-markShift,h ]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
    plate.models["myDfhHolesColBUp"+myHolesNum]=myDfhHolesColBUp;
    cutPlate.models["myDfhHolesColBUp"+myHolesNum]=myDfhHolesColBUp;
    markers.models["myDfhHolesColBUp"+myHolesNum]=myDfhHolesColBUp;
    markers.models["myDfhHolesColMarkBUp"+myHolesNum]=myDfhHolesColMarkBUp;
    markers.models["myDfhHolesColYMarkBUp"+myHolesNum]=myDfhHolesColYMarkBUp;
    /*****************************************************************************/
    plate.models["myDfhHolesColBDn"+myHolesNum]=myDfhHolesColBDn;
    cutPlate.models["myDfhHolesColBDn"+myHolesNum]=myDfhHolesColBDn;
    markers.models["myDfhHolesColBDn"+myHolesNum]=myDfhHolesColBDn;
    markers.models["myDfhHolesColMarkBDn"+myHolesNum]=myDfhHolesColMarkBDn;
    markers.models["myDfhHolesColHMarkBDn"+myHolesNum]=myDfhHolesColHMarkBDn;
    /*****************************************************************************/ 
    }else if(area==3){
    //画孔 C位置孔
          //标记双固定孔列C位置孔上
          var myDfhHolesColCUp= new makerjs.models.Holes(d/2, [[xc, yc]]);
          //标记孔
          var myDfhHolesColMarkCUp = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesColMarkCUp.origin = [xc, yc];      
          var myDfhHolesColXMarkCUp = new makerjs.models.Square(0);      
          myDfhHolesColXMarkCUp.origin =[xc, yc];      
          var myDfhHolesColYMarkCUp = new makerjs.models.Square(0);      
          myDfhHolesColYMarkCUp.origin = [xc, yc];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkCUp, new makerjs.paths.Line([0,0], [0, +markShiftTwo]));//从孔圆心出发Y方向竖向标线
    makerjs.model.addPath(myDfhHolesColMarkCUp, new makerjs.paths.Line([0,0], [0, -markShiftTwo]));//从孔圆心出发Y方向竖向标线    

    makerjs.model.addPath(myDfhHolesColMarkCUp, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftFour]));//X标注短线
    makerjs.model.addPath(myDfhHolesColMarkCUp, new makerjs.paths.Line([0,y0], [0, y0+markShiftFour]));//X标注短线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkCUp, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesColMarkCUp, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesColXMarkCUp, new makerjs.paths.Line([0,y0+markShiftTwo], [x0, y0+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myDfhHolesColXMarkCUp, TxtX, [0,y0+markShiftThree], [x0, y0+markShiftThree]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myDfhHolesColYMarkCUp, new makerjs.paths.Line([0,0], [0, y0]));//Y短标线
    makerjs.model.addCaption(myDfhHolesColYMarkCUp, TxtY, [-markShift,0], [-markShift, y0]);//Y文字标注
    /*****************************************************************************/ 
     //画孔 C位置孔
          //标记双固定孔 C位置孔下
          var myDfhHolesColCDn= new makerjs.models.Holes(d/2, [[xc, yc-h]]);
          //标记孔
          var myDfhHolesColMarkCDn = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesColMarkCDn.origin = [xc, yc-h];      
          var myDfhHolesColHMarkCDn = new makerjs.models.Square(0);      
          myDfhHolesColHMarkCDn.origin =[xc, yc-h]; 
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkCDn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesColMarkCDn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesColHMarkCDn, new makerjs.paths.Line([0,0], [0, h]));//X短标线
    makerjs.model.addCaption(myDfhHolesColHMarkCDn, TxtH, [-markShift,0], [-markShift,h ]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
    plate.models["myDfhHolesColCUp"+myHolesNum]=myDfhHolesColCUp;
    cutPlate.models["myDfhHolesColCUp"+myHolesNum]=myDfhHolesColCUp;
    markers.models["myDfhHolesColCUp"+myHolesNum]=myDfhHolesColCUp;
    markers.models["myDfhHolesColMarkCUp"+myHolesNum]=myDfhHolesColMarkCUp;
    markers.models["myDfhHolesColXMarkCUp"+myHolesNum]=myDfhHolesColXMarkCUp;
    markers.models["myDfhHolesColYMarkCUp"+myHolesNum]=myDfhHolesColYMarkCUp;
    /*****************************************************************************/
    plate.models["myDfhHolesColCDn"+myHolesNum]=myDfhHolesColCDn;
    cutPlate.models["myDfhHolesColCDn"+myHolesNum]=myDfhHolesColCDn;
    markers.models["myDfhHolesColCDn"+myHolesNum]=myDfhHolesColCDn;
    markers.models["myDfhHolesColMarkCDn"+myHolesNum]=myDfhHolesColMarkCDn;
    markers.models["myDfhHolesColHMarkCDn"+myHolesNum]=myDfhHolesColHMarkCDn;
    /*****************************************************************************/ 
   
    }else if(area==4){
    //画孔 D位置孔
          //标记双固定孔列 D位置孔上
          var myDfhHolesColDUp= new makerjs.models.Holes(d/2, [[xd, yd+h/2]]);
          //标记孔
          var myDfhHolesColMarkDUp = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesColMarkDUp.origin = [xd, yd+h/2];      
          var myDfhHolesColXMarkDUp = new makerjs.models.Square(0);      
          myDfhHolesColXMarkDUp.origin =[xd, yd+h/2];      
          var myDfhHolesColYMarkDUp = new makerjs.models.Square(0);      
          myDfhHolesColYMarkDUp.origin = [xd, yd+h/2];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkDUp, new makerjs.paths.Line([0,0], [0, yd-h/2]));//从孔圆心出发Y方向竖向标线
    makerjs.model.addPath(myDfhHolesColMarkDUp, new makerjs.paths.Line([-x0,yd-h/2], [-x0, yd-h/2+markShiftFour]));//X标注短线
    makerjs.model.addPath(myDfhHolesColMarkDUp, new makerjs.paths.Line([0,yd-h/2], [0, yd-h/2+markShiftFour]));//X标注短线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkDUp, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesColMarkDUp, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesColXMarkDUp, new makerjs.paths.Line([0,yd-h/2+markShiftTwo], [-x0, yd-h/2+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myDfhHolesColXMarkDUp, TxtX, [-x0,yd-h/2+markShiftThree], [0, yd-h/2+markShiftThree]);//X文字标注
    /*****************************************************************************/    
     //画孔 D位置孔
          //标记双固定孔 D位置孔下
          var myDfhHolesColDDn= new makerjs.models.Holes(d/2, [[xd, yd-h/2]]);
          //标记孔
          var myDfhHolesColMarkDDn = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesColMarkDDn.origin = [xd, yd-h/2];      
          var myDfhHolesColHMarkDDn = new makerjs.models.Square(0);      
          myDfhHolesColHMarkDDn.origin =[xd, yd-h/2]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkDDn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesColMarkDDn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesColHMarkDDn, new makerjs.paths.Line([0,0], [0, h]));//X短标线
    makerjs.model.addCaption(myDfhHolesColHMarkDDn, TxtH, [markShift,0], [markShift,h ]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
    plate.models["myDfhHolesColDUp"+myHolesNum]=myDfhHolesColDUp;
    cutPlate.models["myDfhHolesColDUp"+myHolesNum]=myDfhHolesColDUp;
    markers.models["myDfhHolesColDUp"+myHolesNum]=myDfhHolesColDUp;
    markers.models["myDfhHolesColMarkDUp"+myHolesNum]=myDfhHolesColMarkDUp;
    markers.models["myDfhHolesColXMarkDUp"+myHolesNum]=myDfhHolesColXMarkDUp;
    /*****************************************************************************/
    plate.models["myDfhHolesColDDn"+myHolesNum]=myDfhHolesColDDn;
    cutPlate.models["myDfhHolesColDDn"+myHolesNum]=myDfhHolesColDDn;
    markers.models["myDfhHolesColDDn"+myHolesNum]=myDfhHolesColDDn;
    markers.models["myDfhHolesColMarkDDn"+myHolesNum]=myDfhHolesColMarkDDn;
    markers.models["myDfhHolesColHMarkDDn"+myHolesNum]=myDfhHolesColHMarkDDn;
    /*****************************************************************************/ 
   
    }else if(area==5){
    //画孔 E位置孔
          //标记双固定孔列 E位置孔上
          var myDfhHolesColEUp= new makerjs.models.Holes(d/2, [[xe, ye+h/2]]);
          //标记孔
          var myDfhHolesColMarkEUp = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesColMarkEUp.origin = [xe, ye+h/2];      
          var myDfhHolesColXMarkEUp = new makerjs.models.Square(0);      
          myDfhHolesColXMarkEUp.origin =[xe, ye+h/2];      
          var myDfhHolesColYMarkEUp = new makerjs.models.Square(0);      
          myDfhHolesColYMarkEUp.origin = [xe, ye+h/2];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkEUp, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesColMarkEUp, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
     //画孔 E位置孔
          //标记双固定孔 E位置孔下
          var myDfhHolesColEDn= new makerjs.models.Holes(d/2, [[xe, ye-h/2]]);
          //标记孔
          var myDfhHolesColMarkEDn = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesColMarkEDn.origin = [xe, ye-h/2];      
          var myDfhHolesColHMarkEDn = new makerjs.models.Square(0);      
          myDfhHolesColHMarkEDn.origin =[xe, ye-h/2]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkEDn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesColMarkEDn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesColHMarkEDn, new makerjs.paths.Line([0,0], [0, h]));//X短标线
    makerjs.model.addCaption(myDfhHolesColHMarkEDn, TxtH, [markShift,0], [markShift,h ]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
    plate.models["myDfhHolesColEUp"+myHolesNum]=myDfhHolesColEUp;
    cutPlate.models["myDfhHolesColEUp"+myHolesNum]=myDfhHolesColEUp;
    markers.models["myDfhHolesColEUp"+myHolesNum]=myDfhHolesColEUp;
    markers.models["myDfhHolesColMarkEUp"+myHolesNum]=myDfhHolesColMarkEUp;
    /*****************************************************************************/
    plate.models["myDfhHolesColEDn"+myHolesNum]=myDfhHolesColEDn;
    cutPlate.models["myDfhHolesColEDn"+myHolesNum]=myDfhHolesColEDn;
    markers.models["myDfhHolesColEDn"+myHolesNum]=myDfhHolesColEDn;
    markers.models["myDfhHolesColMarkEDn"+myHolesNum]=myDfhHolesColMarkEDn;
    markers.models["myDfhHolesColHMarkEDn"+myHolesNum]=myDfhHolesColHMarkEDn;
    /*****************************************************************************/ 
    
    }else if(area==6){
    //画孔 F位置孔
          //标记双固定孔列 F位置孔上
          var myDfhHolesColFUp= new makerjs.models.Holes(d/2, [[xf, yf+h/2]]);
          //标记孔
          var myDfhHolesColMarkFUp = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesColMarkFUp.origin = [xf, yf+h/2];      
          var myDfhHolesColXMarkFUp = new makerjs.models.Square(0);      
          myDfhHolesColXMarkFUp.origin =[xf, yf+h/2];      
          var myDfhHolesColYMarkFUp = new makerjs.models.Square(0);      
          myDfhHolesColYMarkFUp.origin = [xf, yf+h/2];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkFUp, new makerjs.paths.Line([0,0], [0, yd-h/2]));//从孔圆心出发Y方向竖向标线
    makerjs.model.addPath(myDfhHolesColMarkFUp, new makerjs.paths.Line([x0,yd-h/2], [x0, yd-h/2+markShiftFour]));//X标注短线
    makerjs.model.addPath(myDfhHolesColMarkFUp, new makerjs.paths.Line([0,yd-h/2], [0, yd-h/2+markShiftFour]));//X标注短线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkFUp, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesColMarkFUp, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesColXMarkFUp, new makerjs.paths.Line([x0,yd-h/2+markShiftTwo], [0, yd-h/2+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myDfhHolesColXMarkFUp, TxtX, [0,yd-h/2+markShiftThree], [x0, yd-h/2+markShiftThree]);//X文字标注
    /*****************************************************************************/   
     //画孔 F位置孔
          //标记双固定孔 F位置孔下
          var myDfhHolesColFDn= new makerjs.models.Holes(d/2, [[xf, yf-h/2]]);
          //标记孔
          var myDfhHolesColMarkFDn = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesColMarkFDn.origin = [xf, yf-h/2];      
          var myDfhHolesColHMarkFDn = new makerjs.models.Square(0);      
          myDfhHolesColHMarkFDn.origin =[xf, yf-h/2]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkFDn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesColMarkFDn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesColHMarkFDn, new makerjs.paths.Line([0,0], [0, h]));//X短标线
    makerjs.model.addCaption(myDfhHolesColHMarkFDn, TxtH, [markShift,0], [markShift,h ]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
    plate.models["myDfhHolesColFUp"+myHolesNum]=myDfhHolesColFUp;
    cutPlate.models["myDfhHolesColFUp"+myHolesNum]=myDfhHolesColFUp;
    markers.models["myDfhHolesColFUp"+myHolesNum]=myDfhHolesColFUp;
    markers.models["myDfhHolesColMarkFUp"+myHolesNum]=myDfhHolesColMarkFUp;
    markers.models["myDfhHolesColXMarkFUp"+myHolesNum]=myDfhHolesColXMarkFUp;
    /*****************************************************************************/
    plate.models["myDfhHolesColFDn"+myHolesNum]=myDfhHolesColFDn;
    cutPlate.models["myDfhHolesColFDn"+myHolesNum]=myDfhHolesColFDn;
    markers.models["myDfhHolesColFDn"+myHolesNum]=myDfhHolesColFDn;
    markers.models["myDfhHolesColMarkFDn"+myHolesNum]=myDfhHolesColMarkFDn;
    markers.models["myDfhHolesColHMarkFDn"+myHolesNum]=myDfhHolesColHMarkFDn;
    /*****************************************************************************/ 
   
    }else if(area==7){
    //画孔 G位置孔
          //标记双固定孔列 G位置孔下
          var myDfhHolesColGUp= new makerjs.models.Holes(d/2, [[xg, yg]]);
          //标记孔
          var myDfhHolesColMarkGUp = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesColMarkGUp.origin = [xg, yg];      
          var myDfhHolesColXMarkGUp = new makerjs.models.Square(0);      
          myDfhHolesColXMarkGUp.origin =[xg, yg];      
          var myDfhHolesColYMarkGUp = new makerjs.models.Square(0);      
          myDfhHolesColYMarkGUp.origin = [xg, yg];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkGUp, new makerjs.paths.Line([0,0], [0, +markShiftTwo]));//从孔圆心出发Y方向竖向标线
    makerjs.model.addPath(myDfhHolesColMarkGUp, new makerjs.paths.Line([0,0], [0, -markShiftTwo]));//从孔圆心出发Y方向竖向标线

    makerjs.model.addPath(myDfhHolesColMarkGUp, new makerjs.paths.Line([-x0,-y0], [-x0, -y0-markShiftFour]));//X标注短线
    makerjs.model.addPath(myDfhHolesColMarkGUp, new makerjs.paths.Line([0,-y0], [0, -y0-markShiftFour]));//X标注短线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkGUp, new makerjs.paths.Line([0,0], [d, +d]));//直径标线
    makerjs.model.addCaption(myDfhHolesColMarkGUp, TxtD, [0,0], [d+markHolesOneD, +d+markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesColXMarkGUp, new makerjs.paths.Line([0,-y0-markShiftTwo], [-x0, -y0-markShiftTwo]));//X短标线
    makerjs.model.addCaption(myDfhHolesColXMarkGUp, TxtX, [-x0,-y0-markShiftFour], [0, -y0-markShiftFour]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myDfhHolesColYMarkGUp, new makerjs.paths.Line([0,0], [0, -y0]));//Y短标线
    makerjs.model.addCaption(myDfhHolesColYMarkGUp, TxtY, [markShift,-y0], [markShift, 0]);//Y文字标注
    /*****************************************************************************/ 
     //画孔 G位置孔
          //标记双固定孔 G位置孔下
          var myDfhHolesColGDn= new makerjs.models.Holes(d/2, [[xg, yg+h]]);
          //标记孔
          var myDfhHolesColMarkGDn = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesColMarkGDn.origin = [xg, yg+h];      
          var myDfhHolesColHMarkGDn = new makerjs.models.Square(0);      
          myDfhHolesColHMarkGDn.origin =[xg, yg+h]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkGDn, new makerjs.paths.Line([0,0], [d, +d]));//直径标线
    makerjs.model.addCaption(myDfhHolesColMarkGDn, TxtD, [0,0], [d+markHolesOneD, +d+markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesColHMarkGDn, new makerjs.paths.Line([0,0], [0, -h]));//X短标线
    makerjs.model.addCaption(myDfhHolesColHMarkGDn, TxtH, [markShift,-h], [markShift,0 ]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
    plate.models["myDfhHolesColGUp"+myHolesNum]=myDfhHolesColGUp;
    cutPlate.models["myDfhHolesColGUp"+myHolesNum]=myDfhHolesColGUp;
    markers.models["myDfhHolesColGUp"+myHolesNum]=myDfhHolesColGUp;
    markers.models["myDfhHolesColMarkGUp"+myHolesNum]=myDfhHolesColMarkGUp;
    markers.models["myDfhHolesColXMarkGUp"+myHolesNum]=myDfhHolesColXMarkGUp;
    markers.models["myDfhHolesColYMarkGUp"+myHolesNum]=myDfhHolesColYMarkGUp;
    /*****************************************************************************/
    plate.models["myDfhHolesColGDn"+myHolesNum]=myDfhHolesColGDn;
    cutPlate.models["myDfhHolesColGDn"+myHolesNum]=myDfhHolesColGDn;
    markers.models["myDfhHolesColGDn"+myHolesNum]=myDfhHolesColGDn;
    markers.models["myDfhHolesColMarkGDn"+myHolesNum]=myDfhHolesColMarkGDn;
    markers.models["myDfhHolesColHMarkGDn"+myHolesNum]=myDfhHolesColHMarkGDn;
    /*****************************************************************************/ 
   
    }else if(area==8){
    //画孔 H位置孔
          //标记双固定孔列 H位置孔下
          var myDfhHolesColHUp= new makerjs.models.Holes(d/2, [[xh, yh]]);
          //标记孔
          var myDfhHolesColMarkHUp = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesColMarkHUp.origin = [xh, yh];      
          var myDfhHolesColXMarkHUp = new makerjs.models.Square(0);      
          myDfhHolesColXMarkHUp.origin =[xh, yh];      
          var myDfhHolesColYMarkHUp = new makerjs.models.Square(0);      
          myDfhHolesColYMarkHUp.origin = [xh, yh];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkHUp, new makerjs.paths.Line([0,0], [0, +markShiftTwo]));//从孔圆心出发Y方向竖向标线
    makerjs.model.addPath(myDfhHolesColMarkHUp, new makerjs.paths.Line([0,0], [0, -markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkHUp, new makerjs.paths.Line([0,0], [d, +d]));//直径标线
    makerjs.model.addCaption(myDfhHolesColMarkHUp, TxtD, [0,0], [d+markHolesOneD, +d+markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesColYMarkHUp, new makerjs.paths.Line([0,0], [0, -y0]));//Y短标线
    makerjs.model.addCaption(myDfhHolesColYMarkHUp, TxtY, [markShift,-y0], [markShift, 0]);//Y文字标注
    /*****************************************************************************/ 
     //画孔 H位置孔
          //标记双固定孔 H位置孔下
          var myDfhHolesColHDn= new makerjs.models.Holes(d/2, [[xh, yh+h]]);
          //标记孔
          var myDfhHolesColMarkHDn = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesColMarkHDn.origin = [xh, yh+h];      
          var myDfhHolesColHMarkHDn = new makerjs.models.Square(0);      
          myDfhHolesColHMarkHDn.origin =[xh, yh+h]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkHDn, new makerjs.paths.Line([0,0], [d, +d]));//直径标线
    makerjs.model.addCaption(myDfhHolesColMarkHDn, TxtD, [0,0], [d+markHolesOneD, +d+markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesColHMarkHDn, new makerjs.paths.Line([0,0], [0, -h]));//X短标线
    makerjs.model.addCaption(myDfhHolesColHMarkHDn, TxtH, [markShift,-h], [markShift,0 ]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
    plate.models["myDfhHolesColHUp"+myHolesNum]=myDfhHolesColHUp;
    cutPlate.models["myDfhHolesColHUp"+myHolesNum]=myDfhHolesColHUp;
    markers.models["myDfhHolesColHUp"+myHolesNum]=myDfhHolesColHUp;
    markers.models["myDfhHolesColMarkHUp"+myHolesNum]=myDfhHolesColMarkHUp;
    markers.models["myDfhHolesColXMarkHUp"+myHolesNum]=myDfhHolesColXMarkHUp;
    markers.models["myDfhHolesColYMarkHUp"+myHolesNum]=myDfhHolesColYMarkHUp;
    /*****************************************************************************/
    plate.models["myDfhHolesColHDn"+myHolesNum]=myDfhHolesColHDn;
    cutPlate.models["myDfhHolesColHDn"+myHolesNum]=myDfhHolesColHDn;
    markers.models["myDfhHolesColHDn"+myHolesNum]=myDfhHolesColHDn;
    markers.models["myDfhHolesColMarkHDn"+myHolesNum]=myDfhHolesColMarkHDn;
    markers.models["myDfhHolesColHMarkHDn"+myHolesNum]=myDfhHolesColHMarkHDn;
    /*****************************************************************************/ 
   
    }else if(area==9){
    //画孔 I位置孔
          //标记双固定孔列 I位置孔下
          var myDfhHolesColIUp= new makerjs.models.Holes(d/2, [[xi, yi]]);
          //标记孔
          var myDfhHolesColMarkIUp = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesColMarkIUp.origin = [xi, yi];      
          var myDfhHolesColXMarkIUp = new makerjs.models.Square(0);      
          myDfhHolesColXMarkIUp.origin =[xi, yi];      
          var myDfhHolesColYMarkIUp = new makerjs.models.Square(0);      
          myDfhHolesColYMarkIUp.origin = [xi, yi];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkIUp, new makerjs.paths.Line([0,0], [0, +markShiftTwo]));//从孔圆心出发Y方向竖向标线
    makerjs.model.addPath(myDfhHolesColMarkIUp, new makerjs.paths.Line([0,0], [0, -markShiftTwo]));//从孔圆心出发Y方向竖向标线

    makerjs.model.addPath(myDfhHolesColMarkIUp, new makerjs.paths.Line([x0,-y0], [x0, -y0-markShiftFour]));//X标注短线
    makerjs.model.addPath(myDfhHolesColMarkIUp, new makerjs.paths.Line([0,-y0], [0, -y0-markShiftFour]));//X标注短线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkIUp, new makerjs.paths.Line([0,0], [d, +d]));//直径标线
    makerjs.model.addCaption(myDfhHolesColMarkIUp, TxtD, [0,0], [d+markHolesOneD, +d+markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesColXMarkIUp, new makerjs.paths.Line([0,-y0-markShiftTwo], [x0, -y0-markShiftTwo]));//X短标线
    makerjs.model.addCaption(myDfhHolesColXMarkIUp, TxtX, [0,-y0-markShiftFour], [x0, -y0-markShiftFour]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myDfhHolesColYMarkIUp, new makerjs.paths.Line([0,0], [0, -y0]));//Y短标线
    makerjs.model.addCaption(myDfhHolesColYMarkIUp, TxtY, [markShift,-y0], [markShift, 0]);//Y文字标注
    /*****************************************************************************/ 
     //画孔 I位置孔
          //标记双固定孔 I位置孔下
          var myDfhHolesColIDn= new makerjs.models.Holes(d/2, [[xi, yi+h]]);
          //标记孔
          var myDfhHolesColMarkIDn = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesColMarkIDn.origin = [xi, yi+h];      
          var myDfhHolesColHMarkIDn = new makerjs.models.Square(0);      
          myDfhHolesColHMarkIDn.origin =[xi, yi+h]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesColMarkIDn, new makerjs.paths.Line([0,0], [d, +d]));//直径标线
    makerjs.model.addCaption(myDfhHolesColMarkIDn, TxtD, [0,0], [d+markHolesOneD, +d+markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesColHMarkIDn, new makerjs.paths.Line([0,0], [0, -h]));//X短标线
    makerjs.model.addCaption(myDfhHolesColHMarkIDn, TxtH, [markShift,-h], [markShift,0 ]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
    plate.models["myDfhHolesColIUp"+myHolesNum]=myDfhHolesColIUp;
    cutPlate.models["myDfhHolesColIUp"+myHolesNum]=myDfhHolesColIUp;
    markers.models["myDfhHolesColIUp"+myHolesNum]=myDfhHolesColIUp;
    markers.models["myDfhHolesColMarkIUp"+myHolesNum]=myDfhHolesColMarkIUp;
    markers.models["myDfhHolesColXMarkIUp"+myHolesNum]=myDfhHolesColXMarkIUp;
    markers.models["myDfhHolesColYMarkIUp"+myHolesNum]=myDfhHolesColYMarkIUp;
    /*****************************************************************************/
    plate.models["myDfhHolesColIDn"+myHolesNum]=myDfhHolesColIDn;
    cutPlate.models["myDfhHolesColIDn"+myHolesNum]=myDfhHolesColIDn;
    markers.models["myDfhHolesColIDn"+myHolesNum]=myDfhHolesColIDn;
    markers.models["myDfhHolesColMarkIDn"+myHolesNum]=myDfhHolesColMarkIDn;
    markers.models["myDfhHolesColHMarkIDn"+myHolesNum]=myDfhHolesColHMarkIDn;
    /*****************************************************************************/ 
   
    } 

myHolesNum++;
return{plate,cutPlate,markers, myHolesNum}
};


export default DoubleFixHolesCol;