//外形：等腰梯形
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
//(param,model,fixedParam,this.zoomM)
function IsoscelesTrapezoid(inputParam, model, fixedParam, zoom) {
    var H = inputParam.H, W = inputParam.W, W1 = inputParam.W1, myZoom = zoom * 1,
        plate = model.plate, markers = model.markers,
        markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
        markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,

        h = H * 1 * myZoom,
        w = W * 1 * myZoom,
        w1 = W1 * 1 * myZoom,
        // x0 = X*1*myZoom,
        // y0 = Y*1*myZoom;
        x0 = 0, x1 = (w - w1) / 2, x2 = w1 + (w - w1) / 2, x3 = w, y0 = 0, y1 = h, y2 = h, y3 = 0;
    var glassH = h, glassW = w;
    //在A区域 计算孔的位置
    if (h !== 0) {
        var TxtH = H;
    }
    if (w !== 0) {
        var TxtW = W;
    }
    if (w1 !== 0) {
        var TxtW1 = W1;
    }
    console.log("inputParam=", inputParam);
    console.log("inputParam.H=", inputParam.H);

    var IsoscelesTrapezoid = {
        paths: {
            "LeftLine": new makerjs.paths.Line([x0, y0], [x1, y1]),
            "TopLine": new makerjs.paths.Line([x1, y1], [x2, y2]),
            "RightLine": new makerjs.paths.Line([x2, y2], [x3, y3]),
            "BottomLine": new makerjs.paths.Line([x3, y3], [x0, y0]),
        }
    };
    var IsoscelesTrapezoidMarkH = {
        paths: {
            "LeftLine": new makerjs.paths.Line([x0 - markShift, y0], [x0 - markShift, y1]),
            "LeftShortUpline": new makerjs.paths.Line([x1, y1], [x0 - 2 * markShift, y1]),
            "LeftShortDnline": new makerjs.paths.Line([x0, y0], [x0 - 2 * markShift, y0]),
        }
    };
    var IsoscelesTrapezoidMarkW = {
        paths: {
            "BottomLine": new makerjs.paths.Line([x0, y0 - markShift], [x3, y3 - markShift]),
            "BotmShortLfline": new makerjs.paths.Line([x0, y0], [x0, y0 - 2 * markShift]),
            "BotmShortRTline": new makerjs.paths.Line([x3, y3], [x3, y3 - 2 * markShift]),
        }
    };
    var IsoscelesTrapezoidMarkW1 = {
        paths: {
            "UpLine": new makerjs.paths.Line([x1, y1 + markShift], [x2, y2 + markShift]),
            "UpShortLfline": new makerjs.paths.Line([x1, y1], [x1, y1 + 2 * markShift]),
            "UpShortRTline": new makerjs.paths.Line([x2, y2], [x2, y2 + 2 * markShift]),
        }
    };
    makerjs.model.addCaption(IsoscelesTrapezoidMarkW, TxtW, [x0, y0 - 2 * markShift], [x3, y3 - 2 * markShift]);//长度文字  
    makerjs.model.addCaption(IsoscelesTrapezoidMarkW1, TxtW1, [x1, y1 + 2 * markShift], [x2, y2 + 2 * markShift]);//长度文字
    makerjs.model.addCaption(IsoscelesTrapezoidMarkH, TxtH, [x0 - 2 * markShift, y0], [x0 - 2 * markShift, y1]);//宽度文字
    plate.models['IsoscelesTrapezoid'] = IsoscelesTrapezoid;
    markers.models['IsoscelesTrapezoid'] = IsoscelesTrapezoid;
    markers.models['IsoscelesTrapezoidMarkW'] = IsoscelesTrapezoidMarkW;
    markers.models['IsoscelesTrapezoidMarkW1'] = IsoscelesTrapezoidMarkW1;
    markers.models['IsoscelesTrapezoidMarkH'] = IsoscelesTrapezoidMarkH;
    /*****************************************************************************/
    return { plate, markers, glassW, glassH }
};
export default IsoscelesTrapezoid;