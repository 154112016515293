//1、 单孔
import makerjs from "makerjs";


function CalCoordinates(x, y, θ, r, r0) {
    //画出入刀线 出三（3mm）进五(5mm)  角度θ计算  S  出入刀线弧长 r0(弧度代表的圆坐标)  clockwise 是否顺时针 true为顺时针
    /**
     * 孔坐标:(x,y) 半径 r
     * 1、和孔的交点:交点坐标(x1,y1); x1:孔的圆心X坐标值x-r;y1:孔的圆心X坐标值y;
     * 2、45度  进刀线:s=Math.sqrt(2*r*r)  起点X坐标(x-r+r0)起点Y坐标(y-r0); 终点坐标  X坐标(x-r)Y坐标(y)  
     * 3、45度  出刀线:s=Math.sqrt(2*r*r)  起点X坐标(x-r)起点Y坐标(y); 终点坐标  X坐标(x-r+r0)Y坐标(y+r0)
     * 2、θ度  进刀线:s=Math.sqrt(2*r*r)  起点X坐标(x-r+r0)起点Y坐标(y-r0); 终点坐标  X坐标(x-r)Y坐标(y)  
     * 3、θ度  出刀线:s=Math.sqrt(2*r*r)  起点X坐标(x-r)起点Y坐标(y); 终点坐标  X坐标(x-r+r0)Y坐标(y+r0)
     * 4、new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
     * 5、计算三个点的坐标
     */

        var arr = [
            {
                InX: x - r + r0,
                InY: y + r0,
            },
            {
                CrossX: x - r,
                CrossY: y,
            }, {
                OutX: x - r + r0,
                OutY: y - r0,
            }];
  

    return arr;
};
// inputParam,model,this.myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function SingleHole(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut) {
    console.log("SingleHole|||参数inputParam",inputParam);
    console.log("SingleHole|||参数model",model);
    console.log("SingleHole|||参数typeofmodel",typeof(model));
    console.log("SingleHole|||参数myHolesNum",myHolesNum);
    console.log("SingleHole|||参数outParam",outParam);
    console.log("SingleHole|||参数fixedParam",fixedParam);
    console.log("SingleHole|||zoom",zoom);
    console.log("SingleHole|||θCut",θCut);
    console.log("SingleHole|||参数rCut",rCut);
    var D = inputParam.D, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
        plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
        glassW = outParam.glassW, glassH = outParam.glassH,
        markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
        markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
        
        d = D * 1 * myZoom,
        x0 = X * 1 * myZoom,
        y0 = Y * 1 * myZoom;
        
        console.log("plate==",plate);
        // console.log("plate==",plate);
        console.log("plate.models==",plate.models);
        console.log("cutPlate.models==",cutPlate.models);
        console.log("markers==",markers);
        console.log("markers.models==",markers.models);
        if(plate.models===undefined||plate.models===""){
console.log("1111111111111111");
plate.models={};
        }        
        if(cutPlate.models===undefined||cutPlate.models===""){
            console.log("22222222222");
            //cutPlate.models={};
            cutPlate.models={};
        }
        if(markers.models===undefined||markers.models===""){            
            console.log("333333333333");
            markers.models={};
        }
        console.log("plate.models|||||||1111==",plate.models);           
        console.log("cutPlate.models=2222=",cutPlate.models);        
        console.log("markers.models==",markers.models);
       
    if (d !== 0) {
        var TxtD = "∮" + D;
    }
    if (x0 !== 0) {
        var TxtX = X;
    }
    if (y0 !== 0) {
        var TxtY = Y;
    }
    var benchMark="I";
    //在A区域 计算孔的位置       
    var xa = x0, ya = glassH * 1 - y0,
        //B区域//只有Y标注没有X标注 
        xb = glassW * 1 / 2, yb = glassH * 1 - y0,
        //C区域
        xc = glassW * 1 - x0, yc = glassH * 1 - y0,
        //D区域
        //只有X标注没有Y标注 
        xd = x0, yd = glassH * 1 / 2,
        //E区域
        //只有X标注没有Y标注 
        xe = glassW * 1 / 2, ye = glassH * 1 / 2,
        //F区域
        //只有X标注没有Y标注 
        xf = glassW * 1 - x0, yf = glassH * 1 / 2,
        //在G区域 计算孔的位置
        xg = x0, yg = y0,
        //在H区域 计算孔的位置
        //只标注Y
        xh = glassW * 1 / 2, yh = y0,
        //在I区域 计算孔的位置
        xi = glassW * 1 - x0, yi = y0;
    if (area == 1) {
        var arr = CalCoordinates(xa, ya, myθCut, d / 2, myrCut,true);
        console.log("arr===", arr);
        console.log("arr===", arr[0].InX);
        console.log("arr===", arr[0].InY);
        console.log("arr===", arr[2].OutX);
        console.log("arr===", arr[2].OutY);
        console.log("arr===", arr[1].CrossX);
        console.log("arr===", arr[1].CrossY);
        /*
        var myCutLineA = {
            paths: {
                //new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
                "InArc": new makerjs.paths.Arc([arr[0].InX, arr[0].InY], [arr[1].CrossX, arr[1].CrossY], r, false, false),
                "OutArc": new makerjs.paths.Line([arr[2].OutX, arr[2].OutY], [arr[1].CrossX, arr[1].CrossY], r, false, false)
                "RightHoles": new makerjs.paths.Circle([xa+w1, ya-h1], r),
            }
        };
        */
       /*
        var myCutHolesA={
            paths: {
            //new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
            "InArc": new makerjs.paths.Arc([arr[0].InX, arr[0].InY], [arr[1].CrossX, arr[1].CrossY], myrCut, false, true),
            "holesA": new makerjs.paths.Arc([xa, ya], d/2, 180,180),
            "OutArc": new makerjs.paths.Arc([arr[1].CrossX, arr[1].CrossY], [arr[2].OutX, arr[2].OutY], myrCut, false, true)
        }};
        */
       if(benchMark=="G"){
        //基准点坐标在G位置时加入刀线图
        var myCutHolesAreaA={
            paths: {
            //new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
            // "InArc": new makerjs.paths.Arc([xa-(d/2-myrCut), ya], myrCut, 90, 180),
            "holesAUp": new makerjs.paths.Arc([xa, ya], d/2, 180,0),
            "holesADn": new makerjs.paths.Arc([xa, ya], d/2, 0,180),
            // "OutArc": new makerjs.paths.Arc([xa-(d/2-myrCut), ya], myrCut, 180, 270)
        }};
       }else if(benchMark=="I"){
        var myCutHolesAreaA={
            paths: {
            //new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
            // "InArc": new makerjs.paths.Arc([xa+(d/2-myrCut), ya], myrCut, 270, 360),
            "holesAUp": new makerjs.paths.Arc([xa, ya], d/2, 180,0),
            "holesADn": new makerjs.paths.Arc([xa, ya], d/2, 0,180),
            // "OutArc": new makerjs.paths.Arc([xa+(d/2-myrCut), ya], myrCut, 0, 90)
        }};
       }
        
        var myHolesA = new makerjs.models.Holes(d / 2, [[xa, ya]]);
        //标记孔
        var myHolesMarkA = new makerjs.models.Square(0); //孔的原点       
        myHolesMarkA.origin = [xa, ya];
        var myXMarkA = new makerjs.models.Square(0);
        myXMarkA.origin = [xa, ya];
        var myYMarkA = new makerjs.models.Square(0);
        myYMarkA.origin = [xa, ya];

        /*****************************************************************************/
        makerjs.model.addPath(myHolesMarkA, new makerjs.paths.Line([-x0, y0], [-x0, y0 + markShiftTwo]));//Y轴加长标线
        makerjs.model.addPath(myHolesMarkA, new makerjs.paths.Line([0, 0], [-x0 - markShiftThree, 0]));//从孔圆心出发X方向横向标线
        makerjs.model.addPath(myHolesMarkA, new makerjs.paths.Line([-x0, y0], [-x0 - markShiftThree, y0]));//X轴加长标线
        makerjs.model.addPath(myHolesMarkA, new makerjs.paths.Line([0, 0], [0, y0 + markShiftTwo]));//从孔圆心出发Y方向竖向标线

        /*****************************************************************************/
        makerjs.model.addPath(myHolesMarkA, new makerjs.paths.Line([0, 0], [d, -d]));//直径标线
        makerjs.model.addCaption(myHolesMarkA, TxtD, [0, 0], [d + markHolesOneD, -d - markHolesOneD]);//直径文字标注
        /*****************************************************************************/
        makerjs.model.addPath(myXMarkA, new makerjs.paths.Line([0, y0 + markShift], [-x0, y0 + markShift]));//X短标线
        makerjs.model.addCaption(myXMarkA, TxtX, [-x0, y0 + markShiftTwo], [0, y0 + markShiftTwo]);//X文字标注
        /*****************************************************************************/
        makerjs.model.addPath(myYMarkA, new makerjs.paths.Line([-x0 - markShiftTwo, 0], [-x0 - markShiftTwo, y0]));//Y短标线
        makerjs.model.addCaption(myYMarkA, TxtY, [-x0 - markShiftThree, 0], [-x0 - markShiftThree, y0]);//Y文字标注
        /*****************************************************************************/
        plate.models["myHolesA" + myHolesNum] = myHolesA;
        cutPlate.models["myCutHolesAreaA" + myHolesNum] = myCutHolesAreaA;
        markers.models["myHolesA" + myHolesNum] = myHolesA;
        markers.models["myHolesMarkA" + myHolesNum] = myHolesMarkA;
        markers.models["myXMarkA" + myHolesNum] = myXMarkA;
        markers.models["myYMarkA" + myHolesNum] = myYMarkA;

    }
    else if (area == 2) {
        //B区域
        //只有Y标注没有X标注 
        //画孔
        if(benchMark=="G"){
            //基准点坐标在G位置时加入刀线图
            var myCutHolesAreaB={
                paths: {
                //new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
                // "InArc": new makerjs.paths.Arc([xb-(d/2-myrCut), yb], myrCut, 90, 180),
                "holesBUp": new makerjs.paths.Arc([xb, yb], d/2, 180,0),
                "holesBDn": new makerjs.paths.Arc([xb, yb], d/2, 0,180),
                // "OutArc": new makerjs.paths.Arc([xb-(d/2-myrCut), yb], myrCut, 180, 270)
            }};
           }else if(benchMark=="I"){
            var myCutHolesAreaB={
                paths: {
                //new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
                // "InArc": new makerjs.paths.Arc([xb+(d/2-myrCut), yb], myrCut, 270, 360),
                "holesAUp": new makerjs.paths.Arc([xb, yb], d/2, 180,0),
                "holesADn": new makerjs.paths.Arc([xb, yb], d/2, 0,180),
                // "OutArc": new makerjs.paths.Arc([xb+(d/2-myrCut), yb], myrCut, 0, 90)
            }};
           }
        

        var myHolesB = new makerjs.models.Holes(d / 2, [[xb, yb]]);
        //标记孔
        var myHolesMarkB = new makerjs.models.Square(0); //孔的原点       
        myHolesMarkB.origin = [xb, yb];
        var myYMarkB = new makerjs.models.Square(0);
        myYMarkB.origin = [xb, yb];
        /*****************************************************************************/
        makerjs.model.addPath(myHolesMarkB, new makerjs.paths.Line([-markShift / 2, 0], [markShift / 2, 0]));//短线标注
        /*****************************************************************************/
        makerjs.model.addPath(myHolesMarkB, new makerjs.paths.Line([0, 0], [d, -d]));//直径标线
        makerjs.model.addCaption(myHolesMarkB, TxtD, [0, 0], [d + markHolesOneD, -d - markHolesOneD]);//直径文字标注
        /*****************************************************************************/
        makerjs.model.addPath(myYMarkB, new makerjs.paths.Line([0, 0], [0, y0]));//Y标线
        makerjs.model.addCaption(myYMarkB, TxtY, [0 - markShift / 2, 0], [0 - markShift / 2, y0]);//Y文字标注
        /*****************************************************************************/
        plate.models["myHolesB" + myHolesNum] = myHolesB;
        cutPlate.models["myCutHolesAreaB" + myHolesNum] = myCutHolesAreaB;
        markers.models["myHolesB" + myHolesNum] = myHolesB;
        markers.models["myHolesMark" + myHolesNum] = myHolesMarkB;
        markers.models["myYMarkB" + myHolesNum] = myYMarkB;
    }
    else if (area == 3) {
        //画孔
        if(benchMark=="G"){
            //基准点坐标在G位置时加入刀线图
            var myCutHolesAreaC={
                paths: {
                //new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
                // "InArc": new makerjs.paths.Arc([xc-(d/2-myrCut), yc], myrCut, 90, 180),
                "holesCUp": new makerjs.paths.Arc([xc, yc], d/2, 180,0),
                "holesCDn": new makerjs.paths.Arc([xc, yc], d/2, 0,180),
                // "OutArc": new makerjs.paths.Arc([xc-(d/2-myrCut), yc], myrCut, 180, 270)
            }};
           }else if(benchMark=="I"){
            var myCutHolesAreaC={
                paths: {
                //new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
                // "InArc": new makerjs.paths.Arc([xc+(d/2-myrCut), yc], myrCut, 270, 360),
                "holesAUp": new makerjs.paths.Arc([xc, yc], d/2, 180,0),
                "holesADn": new makerjs.paths.Arc([xc, yc], d/2, 0,180),
                // "OutArc": new makerjs.paths.Arc([xc+(d/2-myrCut), yc], myrCut, 0, 90)
            }};
           }
       

        var myHolesC = new makerjs.models.Holes(d / 2, [[xc, yc]]);
        var myHolesMarkC = new makerjs.models.Square(0); //孔的原点       
        myHolesMarkC.origin = [xc, yc];
        var myXMarkC = new makerjs.models.Square(0);
        myXMarkC.origin = [xc, yc];
        var myYMarkC = new makerjs.models.Square(0);
        myYMarkC.origin = [xc, yc];
        /*****************************************************************************/
        makerjs.model.addPath(myHolesMarkC, new makerjs.paths.Line([x0, y0], [x0 + markShiftTwo, y0]));//Y轴加长标线
        makerjs.model.addPath(myHolesMarkC, new makerjs.paths.Line([x0, y0], [x0, y0 + markShiftTwo]));//X轴加长标线
        makerjs.model.addPath(myYMarkC, new makerjs.paths.Line([0, 0], [0, y0 + markShiftTwo]));////从孔圆心出发Y方向横向标线
        makerjs.model.addPath(myXMarkC, new makerjs.paths.Line([0, 0], [x0 + markShiftTwo, 0]));////从孔圆心出发X方向竖向标线
        /*****************************************************************************/
        makerjs.model.addPath(myHolesMarkC, new makerjs.paths.Line([0, 0], [-d, -d]));//直径标线
        makerjs.model.addCaption(myHolesMarkC, TxtD, [-d - markHolesOneD, -d - markHolesOneD], [0, 0]);//直径文字标注
        /*****************************************************************************/
        makerjs.model.addPath(myXMarkC, new makerjs.paths.Line([0, y0 + markShift], [x0, y0 + markShift]));//X短标线
        makerjs.model.addCaption(myXMarkC, TxtX, [0, y0 + markShiftThree], [x0, y0 + markShiftThree]);//X文字标注
        /*****************************************************************************/
        makerjs.model.addPath(myYMarkC, new makerjs.paths.Line([x0 + markShift, 0], [x0 + markShift, y0]));//Y短标线
        makerjs.model.addCaption(myYMarkC, TxtY, [x0 + markShiftThree, 0], [x0 + markShiftThree, y0]);//Y文字标注
        /*****************************************************************************/
        console.log("plate.models**********CCCCC",plate.models);
        plate.models["myHolesC" + myHolesNum] = myHolesC;
        cutPlate.models["myCutHolesAreaC" + myHolesNum] = myCutHolesAreaC;
        markers.models["myHolesC" + myHolesNum] = myHolesC;
        markers.models["myHolesMark" + myHolesNum] = myHolesMarkC;
        markers.models["myXMarkC" + myHolesNum] = myXMarkC;
        markers.models["myYMarkC" + myHolesNum] = myYMarkC;



        // plate = {
        //     models: {
        //         ["myHolesC" + myHolesNum]:myHolesC
        //     },
        //    };
        //    cutPlate = {
        //        models: {
        //         ["myCutHolesAreaC" + myHolesNum]:myCutHolesAreaC
        //        },
        //       };
        //       markers = {
        //           models: {
        //             ["myHolesC" + myHolesNum]:myHolesC,
        //             ["myHolesMark" + myHolesNum]:myHolesMarkC,
        //             ["myXMarkC" + myHolesNum]:myXMarkC,
        //             ["myYMarkC" + myHolesNum]:myYMarkC,
        //           },
        //          };



    }
    else if (area == 4) {
        //画孔 D位置
        if(benchMark=="G"){
            //基准点坐标在G位置时加入刀线图
            var myCutHolesAreaD={
                paths: {
                //new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
                // "InArc": new makerjs.paths.Arc([xd-(d/2-myrCut), yd], myrCut, 90, 180),
                "holesDUp": new makerjs.paths.Arc([xd, yd], d/2, 180,0),
                "holesDDn": new makerjs.paths.Arc([xd, yd], d/2, 0,180),
                // "OutArc": new makerjs.paths.Arc([xd-(d/2-myrCut), yd], myrCut, 180, 270)
            }};
           }else if(benchMark=="I"){
            var myCutHolesAreaD={
                paths: {
                //new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
                // "InArc": new makerjs.paths.Arc([xd+(d/2-myrCut), yd], myrCut, 270, 360),
                "holesAUp": new makerjs.paths.Arc([xd, yd], d/2, 180,0),
                "holesADn": new makerjs.paths.Arc([xd, yd], d/2, 0,180),
                // "OutArc": new makerjs.paths.Arc([xd+(d/2-myrCut), yd], myrCut, 0, 90)
            }};
           }
        

        var myHolesD = new makerjs.models.Holes(d / 2, [[xd, yd]]);
        //标记孔 D位置
        var myHolesMarkD = new makerjs.models.Square(0); //孔的原点       
        myHolesMarkD.origin = [xd, yd];
        var myXMarkD = new makerjs.models.Square(0);
        myXMarkD.origin = [xd, yd];

        /*****************************************************************************/
        makerjs.model.addPath(myHolesMarkD, new makerjs.paths.Line([0, -markShift / 2], [0, markShift / 2]));//短线标注
        /*****************************************************************************/
        makerjs.model.addPath(myHolesMarkD, new makerjs.paths.Line([0, 0], [d, -d]));//直径标线
        makerjs.model.addCaption(myHolesMarkD, TxtD, [0, 0], [d + markHolesOneD, -d - markHolesOneD]);//直径文字标注
        /*****************************************************************************/
        makerjs.model.addPath(myXMarkD, new makerjs.paths.Line([0, 0], [-x0, 0]));//X标线
        makerjs.model.addCaption(myXMarkD, TxtX, [-x0, 0 + markShift], [0, 0 + markShift]);//X文字标注
        /*****************************************************************************/
        plate.models["myHolesD" + myHolesNum] = myHolesD;
        cutPlate.models["myCutHolesAreaD" + myHolesNum] = myCutHolesAreaD;
        markers.models["myHolesD" + myHolesNum] = myHolesD;
        markers.models["myHolesMarkD" + myHolesNum] = myHolesMarkD;
        markers.models["myXMarkD" + myHolesNum] = myXMarkD;
    }
    else if (area == 5) {
        //画孔 E位置
        if(benchMark=="G"){
            //基准点坐标在G位置时加入刀线图
            var myCutHolesAreaE={
                paths: {
                //new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
                // "InArc": new makerjs.paths.Arc([xe-(d/2-myrCut), ye], myrCut, 90, 180),
                "holesEUp": new makerjs.paths.Arc([xe, ye], d/2, 180,0),
                "holesEDn": new makerjs.paths.Arc([xe, ye], d/2, 0,180),
                // "OutArc": new makerjs.paths.Arc([xe-(d/2-myrCut), ye], myrCut, 180, 270)
            }};
           }else if(benchMark=="I"){
            var myCutHolesAreaE={
                paths: {
                //new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
                // "InArc": new makerjs.paths.Arc([xe+(d/2-myrCut), ye], myrCut, 270, 360),
                "holesAUp": new makerjs.paths.Arc([xe, ye], d/2, 180,0),
                "holesADn": new makerjs.paths.Arc([xe, ye], d/2, 0,180),
                // "OutArc": new makerjs.paths.Arc([xe+(d/2-myrCut), ye], myrCut, 0, 90)
            }};
           }
        

        var myHolesE = new makerjs.models.Holes(d / 2, [[xe, ye]]);
        var myHolesMarkE = new makerjs.models.Square(0); //孔的原点       
        myHolesMarkE.origin = [xe, ye];
        var myXMarkE = new makerjs.models.Square(0);
        myXMarkE.origin = [xe, ye];
        /*****************************************************************************/
        makerjs.model.addPath(myHolesMarkE, new makerjs.paths.Line([0, 0], [d + markHolesOneD, 0]));//直径标线
        makerjs.model.addCaption(myHolesMarkE, TxtD, [0, 0], [d + markHolesOneD, 0]);//直径文字标注 
        /*****************************************************************************/
        plate.models["myHolesE" + myHolesNum] = myHolesE;
        cutPlate.models["myCutHolesAreaE" + myHolesNum] = myCutHolesAreaE;
        markers.models["myHolesE" + myHolesNum] = myHolesE;
        markers.models["myHolesMarkE" + myHolesNum] = myHolesMarkE;
        markers.models["myXMarkE" + myHolesNum] = myXMarkE;
    }
    else if (area == 6) {
        //画孔 F位置
        if(benchMark=="G"){
            //基准点坐标在G位置时加入刀线图
            var myCutHolesAreaF={
                paths: {
                //new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
                // "InArc": new makerjs.paths.Arc([xf-(d/2-myrCut), yf], myrCut, 90, 180),
                "holesFUp": new makerjs.paths.Arc([xf, yf], d/2, 180,0),
                "holesFDn": new makerjs.paths.Arc([xf, yf], d/2, 0,180),
                // "OutArc": new makerjs.paths.Arc([xf-(d/2-myrCut), yf], myrCut, 180, 270)
            }};
           }else if(benchMark=="I"){
            var myCutHolesAreaF={
                paths: {
                //new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
                // "InArc": new makerjs.paths.Arc([xf+(d/2-myrCut), yf], myrCut, 270, 360),
                "holesAUp": new makerjs.paths.Arc([xf, yf], d/2, 180,0),
                "holesADn": new makerjs.paths.Arc([xf, yf], d/2, 0,180),
                // "OutArc": new makerjs.paths.Arc([xf+(d/2-myrCut), yf], myrCut, 0, 90)
            }};
           }
       

        var myHolesF = new makerjs.models.Holes(d / 2, [[xf, yf]]);
        var myHolesMarkF = new makerjs.models.Square(0); //孔的原点       
        myHolesMarkF.origin = [xf, yf];
        var myXMarkF = new makerjs.models.Square(0);
        myXMarkF.origin = [xf, yf];
        /*****************************************************************************/
        makerjs.model.addPath(myHolesMarkF, new makerjs.paths.Line([0, -markShift / 2], [0, markShift / 2]));//短线标注
        /*****************************************************************************/
        makerjs.model.addPath(myHolesMarkF, new makerjs.paths.Line([0, 0], [-d, -d]));//直径标线
        makerjs.model.addCaption(myHolesMarkF, TxtD, [-d - markHolesOneD, -d - markHolesOneD], [0, 0]);//直径文字标注
        /*****************************************************************************/
        makerjs.model.addPath(myXMarkF, new makerjs.paths.Line([0, 0], [x0, 0]));//X标线
        makerjs.model.addCaption(myXMarkF, TxtX, [0, 0 + markShift], [x0, 0 + markShift]);//X文字标注
        /*****************************************************************************/
        plate.models["myHolesF" + myHolesNum] = myHolesF;
        cutPlate.models["myCutHolesAreaF" + myHolesNum] = myCutHolesAreaF;
        markers.models["myHolesF" + myHolesNum] = myHolesF;
        markers.models["myHolesMarkF" + myHolesNum] = myHolesMarkF;
        markers.models["myXMarkF" + myHolesNum] = myXMarkF;
    }
    else if (area == 7) {
        //画孔 G
        if(benchMark=="G"){
            //基准点坐标在G位置时加入刀线图
            var myCutHolesAreaG={
                paths: {
                //new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
                // "InArc": new makerjs.paths.Arc([xg-(d/2-myrCut), yg], myrCut, 90, 180),
                "holesGUp": new makerjs.paths.Arc([xg, yg], d/2, 180,0),
                "holesGDn": new makerjs.paths.Arc([xg, yg], d/2, 0,180),
                // "OutArc": new makerjs.paths.Arc([xg-(d/2-myrCut), yg], myrCut, 180, 270)
            }};
           }else if(benchMark=="I"){
            var myCutHolesAreaG={
                paths: {
                //new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
                // "InArc": new makerjs.paths.Arc([xg+(d/2-myrCut), yg], myrCut, 270, 360),
                "holesAUp": new makerjs.paths.Arc([xg, yg], d/2, 180,0),
                "holesADn": new makerjs.paths.Arc([xg, yg], d/2, 0,180),
                // "OutArc": new makerjs.paths.Arc([xg+(d/2-myrCut), yg], myrCut, 0, 90)
            }};
           }

        var myHolesG = new makerjs.models.Holes(d / 2, [[xg, yg]]);
        //标记孔 G
        var myHolesMarkG = new makerjs.models.Square(0); //孔的原点       
        myHolesMarkG.origin = [xg, yg];
        var myXMarkG = new makerjs.models.Square(0);
        myXMarkG.origin = [xg, yg];
        var myYMarkG = new makerjs.models.Square(0);
        myYMarkG.origin = [xg, yg];

        /*****************************************************************************/
        makerjs.model.addPath(myHolesMarkG, new makerjs.paths.Line([-x0, -y0], [-x0, -y0 - markShiftFour]));//Y轴加长标线
        makerjs.model.addPath(myHolesMarkG, new makerjs.paths.Line([0, 0], [-x0 - markShiftFour, 0]));//从孔圆心出发X方向横向标线
        makerjs.model.addPath(myHolesMarkG, new makerjs.paths.Line([-x0, -y0], [-x0 - markShiftFour, -y0]));//X轴加长标线
        makerjs.model.addPath(myHolesMarkG, new makerjs.paths.Line([0, 0], [0, -y0 - markShiftFour]));//从孔圆心出发Y方向竖向标线                   
        /*****************************************************************************/
        makerjs.model.addPath(myHolesMarkG, new makerjs.paths.Line([0, 0], [d, d]));//直径标线
        makerjs.model.addCaption(myHolesMarkG, TxtD, [0, 0], [d + markHolesOneD, d + markHolesOneD]);//直径文字标注
        /*****************************************************************************/
        makerjs.model.addPath(myXMarkG, new makerjs.paths.Line([0, -y0 - markShiftTwo], [-x0, -y0 - markShiftTwo]));//X短标线
        makerjs.model.addCaption(myXMarkG, TxtX, [-x0, -y0 - markShiftFour], [0, -y0 - markShiftFour]);//X文字标注
        /*****************************************************************************/
        makerjs.model.addPath(myYMarkG, new makerjs.paths.Line([-x0 - markShiftTwo, 0], [-x0 - markShiftTwo, -y0]));//Y短标线
        makerjs.model.addCaption(myYMarkG, TxtY, [-x0 - markShiftThree, -y0], [-x0 - markShiftThree, 0]);//Y文字标注
        /*****************************************************************************/
        console.log("plate**********CCCCC",plate);
        console.log("plate.models**********CCCCC",plate.models);
        plate.models["myHolesG" + myHolesNum] = myHolesG;
        cutPlate.models["myCutHolesAreaG" + myHolesNum] = myCutHolesAreaG;
        markers.models["myHolesG" + myHolesNum] = myHolesG;
        markers.models["myHolesMark" + myHolesNum] = myHolesMarkG;
        markers.models["myXMarkG" + myHolesNum] = myXMarkG;
        markers.models["myYMarkG" + myHolesNum] = myYMarkG;
        // plate = {
        //     models: {
        //         ["myHolesG" + myHolesNum]:myHolesG
        //     },
        //    };
        //    cutPlate = {
        //        models: {
        //         ["myCutHolesAreaG" + myHolesNum]:myCutHolesAreaG
        //        },
        //       };
        //       markers = {
        //           models: {
        //             ["myHolesG" + myHolesNum]:myHolesG,
        //             ["myHolesMark" + myHolesNum]:myHolesMarkG,
        //             ["myXMarkG" + myHolesNum]:myXMarkG,
        //             ["myYMarkG" + myHolesNum]:myYMarkG,
        //           },
        //          };

        console.log("singleHole||20241115||",plate)
        console.log("singleHole||20241115||",cutPlate)
        console.log("singleHole||20241115||",markers)
        console.log("singleHole||20241115||",myHolesNum)
        
    }
    else if (area == 8) {
        //画孔 H
        if(benchMark=="G"){
            //基准点坐标在G位置时加入刀线图
            var myCutHolesAreaH={
                paths: {
                //new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
                // "InArc": new makerjs.paths.Arc([xh-(d/2-myrCut), yh], myrCut, 90, 180),
                "holesHUp": new makerjs.paths.Arc([xh, yh], d/2, 180,0),
                "holesHDn": new makerjs.paths.Arc([xh, yh], d/2, 0,180),
                // "OutArc": new makerjs.paths.Arc([xh-(d/2-myrCut), yh], myrCut, 180, 270)
            }};
           }else if(benchMark=="I"){
            var myCutHolesAreaH={
                paths: {
                //new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
                // "InArc": new makerjs.paths.Arc([xh+(d/2-myrCut), yh], myrCut, 270, 360),
                "holesAUp": new makerjs.paths.Arc([xh, yh], d/2, 180,0),
                "holesADn": new makerjs.paths.Arc([xh, yh], d/2, 0,180),
                // "OutArc": new makerjs.paths.Arc([xh+(d/2-myrCut), yh], myrCut, 0, 90)
            }};
           }        

        var myHolesH = new makerjs.models.Holes(d / 2, [[xh, yh]]);
        //标记孔
        var myHolesMarkH = new makerjs.models.Square(0); //孔的原点       
        myHolesMarkH.origin = [xh, yh];
        var myXMarkH = new makerjs.models.Square(0);
        myXMarkH.origin = [xh, yh];
        var myYMarkH = new makerjs.models.Square(0);
        myYMarkH.origin = [xh, yh];

        /*****************************************************************************/
        makerjs.model.addPath(myHolesMarkH, new makerjs.paths.Line([-markShift / 2, 0], [markShift / 2, 0]));//短线标注
        /*****************************************************************************/
        makerjs.model.addPath(myHolesMarkH, new makerjs.paths.Line([0, 0], [d, d]));//直径标线
        makerjs.model.addCaption(myHolesMarkH, TxtD, [0, 0], [d + markHolesOneD, d + markHolesOneD]);//直径文字标注
        /*****************************************************************************/
        makerjs.model.addPath(myYMarkH, new makerjs.paths.Line([0, 0], [0, -y0]));//Y标线
        makerjs.model.addCaption(myYMarkH, TxtY, [0 - markShift / 2, -y0], [0 - markShift / 2, 0]);//Y文字标注
        /*****************************************************************************/
        plate.models["myHolesH" + myHolesNum] = myHolesH;
        cutPlate.models["myCutHolesAreaH" + myHolesNum] = myCutHolesAreaH;
        markers.models["myHolesH" + myHolesNum] = myHolesH;
        markers.models["myHolesMarkH" + myHolesNum] = myHolesMarkH;
        markers.models["myXMarkH" + myHolesNum] = myXMarkH;
        markers.models["myYMarkH" + myHolesNum] = myYMarkH;
    }
    else if (area == 9) {
        //画孔I
        if(benchMark=="G"){
            //基准点坐标在G位置时加入刀线图
            var myCutHolesAreaI={
                paths: {
                //new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
                // "InArc": new makerjs.paths.Arc([xi-(d/2-myrCut), yi], myrCut, 90, 180),
                "holesIUp": new makerjs.paths.Arc([xi, yi], d/2, 180,0),
                "holesIDn": new makerjs.paths.Arc([xi, yi], d/2, 0,180),
                // "OutArc": new makerjs.paths.Arc([xi-(d/2-myrCut), yi], myrCut, 180, 270)
            }};
           }else if(benchMark=="I"){
            var myCutHolesAreaI={
                paths: {
                //new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
                // "InArc": new makerjs.paths.Arc([xi+(d/2-myrCut), yi], myrCut, 270, 360),
                "holesAUp": new makerjs.paths.Arc([xi, yi], d/2, 180,0),
                "holesADn": new makerjs.paths.Arc([xi, yi], d/2, 0,180),
                // "OutArc": new makerjs.paths.Arc([xi+(d/2-myrCut), yi], myrCut, 0, 90)
            }};
           }
        

        var myHolesI = new makerjs.models.Holes(d / 2, [[xi, yi]]);
        //标记孔I
        var myHolesMarkI = new makerjs.models.Square(0); //孔的原点       
        myHolesMarkI.origin = [xi, yi];
        var myXMarkI = new makerjs.models.Square(0);
        myXMarkI.origin = [xi, yi];
        var myYMarkI = new makerjs.models.Square(0);
        myYMarkI.origin = [xi, yi];

        /*****************************************************************************/
        makerjs.model.addPath(myHolesMarkI, new makerjs.paths.Line([x0, -y0], [x0 + markShiftTwo, -y0]));//X轴加长标线
        makerjs.model.addPath(myYMarkI, new makerjs.paths.Line([0, 0], [0, -y0 - markShiftThree]));////从孔圆心出发Y方向横向标线
        makerjs.model.addPath(myXMarkI, new makerjs.paths.Line([0, 0], [x0 + markShiftTwo, 0]));////从孔圆心出发X方向竖向标线
        /*****************************************************************************/
        makerjs.model.addPath(myHolesMarkI, new makerjs.paths.Line([0, 0], [-d, d]));//直径标线
        makerjs.model.addCaption(myHolesMarkI, TxtD, [-d - markHolesOneD, d + markHolesOneD], [0, 0]);//直径文字标注
        /*****************************************************************************/
        makerjs.model.addPath(myYMarkI, new makerjs.paths.Line([x0 + markShift, 0], [x0 + markShift, -y0]));//Y短标线
        makerjs.model.addCaption(myYMarkI, TxtY, [x0 + markShiftTwo, 0], [x0 + markShiftTwo, -y0]);//Y文字标注
        /*****************************************************************************/
        makerjs.model.addPath(myXMarkI, new makerjs.paths.Line([0, -y0 - markShiftTwo], [x0, -y0 - markShiftTwo]));//X短标线
        makerjs.model.addCaption(myXMarkI, TxtX, [0, -y0 - markShiftThree], [x0, -y0 - markShiftThree]);//X文字标注
        /*****************************************************************************/
        /*****************************************************************************/
       /*
        plate = {
            models: {
                ["myHolesI" + myHolesNum]:myHolesI,
            },
           };
           cutPlate = {
            models: {
                ["myCutHolesAreaI" + myHolesNum]:myCutHolesAreaI,
            },
           };
           markers = {
            models: {
                ["myHolesI" + myHolesNum]: myHolesI,
                ["myHolesMarkI" + myHolesNum]: myHolesMarkI,
                ["myXMarkI" + myHolesNum]: myXMarkI,
                ["myYMarkI" + myHolesNum]: myYMarkI,
            },
           };
           */


        plate.models["myHolesI" + myHolesNum] = myHolesI;
        cutPlate.models["myCutHolesAreaI" + myHolesNum] = myCutHolesAreaI;
        markers.models["myHolesI" + myHolesNum] = myHolesI;
        markers.models["myHolesMarkI" + myHolesNum] = myHolesMarkI;
        markers.models["myXMarkI" + myHolesNum] = myXMarkI;
        markers.models["myYMarkI" + myHolesNum] = myYMarkI;
       
    }
   
    myHolesNum++;
    return { plate,cutPlate,markers, myHolesNum }
};


export default SingleHole;

