import Vue from 'vue'
import VueRouter from 'vue-router'
/*
import Home from '../views/Home.vue'
import User from '../views/User.vue'
import Mall from '../views/Mall.vue'
import PageOne from '../views/PageOne.vue'
import PageTwo from '../views/PageTwo.vue'
*/

import Home from '../views/Home.vue'
import LogView from '../views/LogView.vue'
Vue.use(VueRouter)
const routes=[ 
  {
    path:'/',
   //redirect:'/',
    name:"home",
    component:Home, 
    meta: {
      title: '贵友玻璃软件画图首页'
    }   
},{
  path:'/login',
 //redirect:'/login',
  name:"login",
  component:LogView,
  meta: {
    title: '贵友玻璃软件画图登录'
  }   
  
},
]
const router = new VueRouter({
  mode:'history',
  routes // (缩写) 相当于 routes: routes
  })

export default router