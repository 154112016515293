//外形框调用
import makerjs from "makerjs";
function RectangleMark(outParam, model, fixedParam, zoom, frame) {
    console.log("是否调用了框标记？？？？？？？RectangleMark")
    console.log("是否调用了框标记？？？？？？？frame", frame)
    console.log("outParam=====", outParam);
    var H = outParam.H, H1 = outParam.H1, H2 = outParam.H2, W = outParam.W, W1 = outParam.W1, W2 = outParam.W2,
        markers = model.markers, myZoom = zoom * 1,
        h = H * 1 * myZoom,
        h1 = H1 * 1 * myZoom,
        h2 = H2 * 1 * myZoom,
        w = W * 1 * myZoom,
        w1 = W1 * 1 * myZoom,
        w2 = W2 * 1 * myZoom,
        x0 = 0, y0 = 0, x3 = w, y3 = 0,
        markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
        markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD;
    if (h !== 0) {
        var TxtH = H;
    } if (h1 !== 0) {
        var TxtH1 = H1;
    } if (h2 !== 0) {
        var TxtH2 = H2;
    }
    if (w !== 0) {
        var TxtW = W;
    }
    if (w1 !== 0) {
        var TxtW1 = W1;
    }
    if (w2 !== 0) {
        var TxtW2 = W2;
    }
    if (frame == "Rectangle") {
        var glassW = outParam.glassW, glassH = outParam.glassH,
            w = glassW * 1,
            TxtW = "长=" + w.toString(),
            h = glassH * 1,
            TxtH = "宽=" + h.toString();
        var RectMarkH = {
            paths: {
                "LeftLine": new makerjs.paths.Line([0 - markShift, 0], [0 - markShift, h]),
                "LeftShortUpline": new makerjs.paths.Line([0, h], [0 - 2 * markShift, h]),
                "LeftShortDnline": new makerjs.paths.Line([0, 0], [0 - 2 * markShift, 0]),
            }
        };
        var RectMarkWLine = {
            paths: {
                "BottomLine": new makerjs.paths.Line([0, -markShift], [w, -markShift]),
                "BotmShortLfline": new makerjs.paths.Line([0, 0], [0, 0 - 2 * markShift]),
                "BotmShortRTline": new makerjs.paths.Line([w, 0], [w, 0 - 2 * markShift]),
            }
        };

        makerjs.model.addCaption(RectMarkWLine, TxtW, [0, -2 * markShift], [w, -2 * markShift]);//长度文字
        makerjs.model.addCaption(RectMarkH, TxtH, [-2 * markShift, 0], [-2 * markShift, h]);//宽度文字
        markers = {
            models: {
                RectMarkH,
                RectMarkWLine,
            }
        };
    } else if (frame == "IsoscelesTrapezoid") {
        var x1 = (w - w1) / 2, x2 = w1 + (w - w1) / 2, y1 = h, y2 = h;
        //在A区域 计算孔的位置
        var IsoscelesTrapezoidMarkH = {
            paths: {
                "LeftLine": new makerjs.paths.Line([x0 - markShift, y0], [x0 - markShift, y1]),
                "LeftShortUpline": new makerjs.paths.Line([x1, y1], [x0 - 2 * markShift, y1]),
                "LeftShortDnline": new makerjs.paths.Line([x0, y0], [x0 - 2 * markShift, y0]),
            }
        };
        var IsoscelesTrapezoidMarkW = {
            paths: {
                "BottomLine": new makerjs.paths.Line([x0, y0 - markShift], [x3, y3 - markShift]),
                "BotmShortLfline": new makerjs.paths.Line([x0, y0], [x0, y0 - 2 * markShift]),
                "BotmShortRTline": new makerjs.paths.Line([x3, y3], [x3, y3 - 2 * markShift]),
            }
        };
        var IsoscelesTrapezoidMarkW1 = {
            paths: {
                "UpLine": new makerjs.paths.Line([x1, y1 + markShift], [x2, y2 + markShift]),
                "UpShortLfline": new makerjs.paths.Line([x1, y1], [x1, y1 + 2 * markShift]),
                "UpShortRTline": new makerjs.paths.Line([x2, y2], [x2, y2 + 2 * markShift]),
            }
        };
        makerjs.model.addCaption(IsoscelesTrapezoidMarkW, TxtW, [x0, y0 - 2 * markShift], [x3, y3 - 2 * markShift]);//长度文字  
        makerjs.model.addCaption(IsoscelesTrapezoidMarkW1, TxtW1, [x1, y1 + 2 * markShift], [x2, y2 + 2 * markShift]);//长度文字
        makerjs.model.addCaption(IsoscelesTrapezoidMarkH, TxtH, [x0 - 2 * markShift, y0], [x0 - 2 * markShift, y1]);//宽度文字
        markers = {
            models: {
                IsoscelesTrapezoidMarkH,
                IsoscelesTrapezoidMarkW,
                IsoscelesTrapezoidMarkW1
            }
        }

    } else if (frame == "RightAngledTrapezoid") {
        var x1 = 0, x2 = w1, y1 = h1, y2 = h;
        var RightAngledTrapezoidMarkH = {
            paths: {
                "RightLine": new makerjs.paths.Line([x3 + markShift, y3], [x3 + markShift, y2]),
                "RightShortUpline": new makerjs.paths.Line([x2, y2], [x3 + 2 * markShift, y2]),
                "RightShortDnline": new makerjs.paths.Line([x3, y3], [x3 + 2 * markShift, y3]),
            }
        };
        var RightAngledTrapezoidMarkH1 = {
            paths: {
                "LeftLine": new makerjs.paths.Line([x0 - markShift, y0], [x0 - markShift, y1]),
                "LeftShortUpline": new makerjs.paths.Line([x1, y1], [x0 - 2 * markShift, y1]),
                "LeftShortDnline": new makerjs.paths.Line([x0, y0], [x0 - 2 * markShift, y0]),
            }
        };
        var RightAngledTrapezoidMarkW = {
            paths: {
                "BottomLine": new makerjs.paths.Line([x0, y0 - markShift], [x3, y3 - markShift]),
                "BotmShortLfline": new makerjs.paths.Line([x0, y0], [x0, y0 - 2 * markShift]),
                "BotmShortRTline": new makerjs.paths.Line([x3, y3], [x3, y3 - 2 * markShift]),
            }
        };
        var RightAngledTrapezoidMarkW1 = {
            paths: {
                "UpLine": new makerjs.paths.Line([x1, y2 + markShift], [x2, y2 + markShift]),
                "UpShortLfline": new makerjs.paths.Line([x1, y1], [x1, y2 + 2 * markShift]),
                "UpShortRTline": new makerjs.paths.Line([x2, y2], [x2, y2 + 2 * markShift]),
            }
        };
        makerjs.model.addCaption(RightAngledTrapezoidMarkW, TxtW, [x0, y0 - 2 * markShift], [x3, y3 - 2 * markShift]);//长度文字  
        makerjs.model.addCaption(RightAngledTrapezoidMarkW1, TxtW1, [x1, y2 + 2 * markShift], [x2, y2 + 2 * markShift]);//长度文字
        makerjs.model.addCaption(RightAngledTrapezoidMarkH, TxtH, [x3 + 2 * markShift, y2], [x3 + 2 * markShift, y3]);//宽度文字
        makerjs.model.addCaption(RightAngledTrapezoidMarkH1, TxtH1, [x0 - 2 * markShift, y0], [x0 - 2 * markShift, y1]);//宽度文字
        markers = {
            models: {
                RightAngledTrapezoidMarkH1,
                RightAngledTrapezoidMarkH,
                RightAngledTrapezoidMarkW,
                RightAngledTrapezoidMarkW1
            }
        }
    } else if (frame == "OtherTrapezoidsA") {
        var x1 = w1, x2 = w - w2, y1 = h1, y2 = h2;
        var OtherTrapezoidsAMarkH1 = {
            paths: {
                "LeftLine": new makerjs.paths.Line([x0 - markShift, y0], [x0 - markShift, y1]),
                "LeftShortUpline": new makerjs.paths.Line([x1, y1], [x0 - 2 * markShift, y1]),
                "LeftShortDnline": new makerjs.paths.Line([x0, y0], [x0 - 2 * markShift, y0]),
            }
        };
        var OtherTrapezoidsAMarkH2 = {
            paths: {
                "RightLine": new makerjs.paths.Line([x3 + markShift, y3], [x3 + markShift, y2]),
                "RightShortUpline": new makerjs.paths.Line([x2, y2], [x3 + 2 * markShift, y2]),
                "RightShortDnline": new makerjs.paths.Line([x3, y3], [x3 + 2 * markShift, y3]),
            }
        };
        var OtherTrapezoidsAMarkW = {
            paths: {
                "BottomLine": new makerjs.paths.Line([x0, y0 - markShift], [x3, y3 - markShift]),
                "BotmShortLfline": new makerjs.paths.Line([x0, y0], [x0, y0 - 2 * markShift]),
                "BotmShortRTline": new makerjs.paths.Line([x3, y3], [x3, y3 - 2 * markShift]),
            }
        };
        var OtherTrapezoidsAMarkW1 = {
            paths: {
                "UpW1Line": new makerjs.paths.Line([x0, y1 + markShift], [x1, y1 + markShift]),
                "UpW1ShortLfline": new makerjs.paths.Line([x0, y1], [x0, y1 + 2 * markShift]),
                "UpW1ShortRTline": new makerjs.paths.Line([x1, y1], [x1, y1 + 2 * markShift]),
            }
        };
        var OtherTrapezoidsAMarkW2 = {
            paths: {
                "UpW2Line": new makerjs.paths.Line([x2, y2 + markShift], [x3, y2 + markShift]),
                "UpW2ShortLfline": new makerjs.paths.Line([x2, y2], [x2, y2 + 2 * markShift]),
                "UpW2ShortRTline": new makerjs.paths.Line([x3, y2], [x3, y2 + 2 * markShift]),
            }
        };
        makerjs.model.addCaption(OtherTrapezoidsAMarkW, TxtW, [x0, y0 - 2 * markShift], [x3, y3 - 2 * markShift]);//长度文字  
        makerjs.model.addCaption(OtherTrapezoidsAMarkW1, TxtW1, [x0, y1 + 2 * markShift], [x1, y1 + 2 * markShift]);//长度文字  
        makerjs.model.addCaption(OtherTrapezoidsAMarkW2, TxtW2, [x2, y2 + 2 * markShift], [x3, y2 + 2 * markShift]);//长度文字
        makerjs.model.addCaption(OtherTrapezoidsAMarkH1, TxtH1, [x0 - 2 * markShift, y0], [x0 - 2 * markShift, y1]);//宽度文字
        makerjs.model.addCaption(OtherTrapezoidsAMarkH2, TxtH2, [x3 + 2 * markShift, y3], [x3 + 2 * markShift, y2]);//宽度文字
        markers = {
            models: {
                OtherTrapezoidsAMarkW,
                OtherTrapezoidsAMarkW1,
                OtherTrapezoidsAMarkW2,
                OtherTrapezoidsAMarkH1,
                OtherTrapezoidsAMarkH2,
            }
        };

    } else if (frame == "OtherTrapezoidsB") {
        var x1 = w1, x2 = w - w2, y1 = h, y2 = h;
        var OtherTrapezoidsBMarkH = {
            paths: {
                "LeftLine": new makerjs.paths.Line([x0 - markShift, y0], [x0 - markShift, y1]),
                "LeftShortUpline": new makerjs.paths.Line([x1, y1], [x0 - 2 * markShift, y1]),
                "LeftShortDnline": new makerjs.paths.Line([x0, y0], [x0 - 2 * markShift, y0]),
            }
        };
        var OtherTrapezoidsBMarkW = {
            paths: {
                "BottomLine": new makerjs.paths.Line([x0, y0 - markShift], [x3, y3 - markShift]),
                "BotmShortLfline": new makerjs.paths.Line([x0, y0], [x0, y0 - 2 * markShift]),
                "BotmShortRTline": new makerjs.paths.Line([x3, y3], [x3, y3 - 2 * markShift]),
            }
        };
        var OtherTrapezoidsBMarkW1 = {
            paths: {
                "UpW1Line": new makerjs.paths.Line([x0, y1 + markShift], [x1, y1 + markShift]),
                "UpW1ShortLfline": new makerjs.paths.Line([x0, y1], [x0, y1 + 2 * markShift]),
                "UpW1ShortRTline": new makerjs.paths.Line([x1, y1], [x1, y1 + 2 * markShift]),
            }
        };
        var OtherTrapezoidsBMarkW2 = {
            paths: {
                "UpW2Line": new makerjs.paths.Line([x2, y2 + markShift], [x3, y2 + markShift]),
                "UpW2ShortLfline": new makerjs.paths.Line([x2, y2], [x2, y2 + 2 * markShift]),
                "UpW2ShortRTline": new makerjs.paths.Line([x3, y2], [x3, y2 + 2 * markShift]),
                "UpW2RightLine": new makerjs.paths.Line([x3, y3], [x3, y2]),
                "UpW2RightShortUpLine": new makerjs.paths.Line([x3-markShift, y2], [x3+2*markShift, y2]),
            }
        };
        makerjs.model.addCaption(OtherTrapezoidsBMarkW, TxtW, [x0, y0 - 2 * markShift], [x3, y3 - 2 * markShift]);//长度文字  
        makerjs.model.addCaption(OtherTrapezoidsBMarkW1, TxtW1, [x0, y1 + 2 * markShift], [x1, y1 + 2 * markShift]);//长度文字  
        makerjs.model.addCaption(OtherTrapezoidsBMarkW2, TxtW2, [x2, y2 + 2 * markShift], [x3, y2 + 2 * markShift]);//长度文字
        makerjs.model.addCaption(OtherTrapezoidsBMarkH, TxtH, [x0 - 2 * markShift, y0], [x0 - 2 * markShift, y1]);//宽度文字
        markers = {
            models: {
                OtherTrapezoidsBMarkW,
                OtherTrapezoidsBMarkW1,
                OtherTrapezoidsBMarkW2,
                OtherTrapezoidsBMarkH,
            }
        };

    } else if (frame == "ParallelogramA") {
        var x1 = w1, x2 = w + w1, y1 = h, y2 = h;
        var ParallelogramAMarkH = {
            paths: {
                "LeftLine": new makerjs.paths.Line([x0 - markShift, y0], [x0 - markShift, y1]),
                "LeftShortUpline": new makerjs.paths.Line([x1, y1], [x0 - 2 * markShift, y1]),
                "LeftShortDnline": new makerjs.paths.Line([x0, y0], [x0 - 2 * markShift, y0]),
            }
        };
        var ParallelogramAMarkW = {
            paths: {
                "BottomLine": new makerjs.paths.Line([x0, y0 - markShift], [x3, y3 - markShift]),
                "BotmShortLfline": new makerjs.paths.Line([x0, y0], [x0, y0 - 2 * markShift]),
                "BotmShortRTline": new makerjs.paths.Line([x3, y3], [x3, y3 - 2 * markShift]),
            }
        };
        var ParallelogramAMarkW1 = {
            paths: {
                "UpW1Line": new makerjs.paths.Line([x0, y1 + markShift], [x1, y1 + markShift]),
                "UpW1ShortLfline": new makerjs.paths.Line([x0, y1], [x0, y1 + 2 * markShift]),
                "UpW1ShortRTline": new makerjs.paths.Line([x1, y1], [x1, y1 + 2 * markShift]),
            }
        };
        makerjs.model.addCaption(ParallelogramAMarkW, TxtW, [x0, y0 - 2 * markShift], [x3, y3 - 2 * markShift]);//长度文字  
        makerjs.model.addCaption(ParallelogramAMarkW1, TxtW1, [x0, y1 + 2 * markShift], [x1, y1 + 2 * markShift]);//长度文字 
        makerjs.model.addCaption(ParallelogramAMarkH, TxtH, [x0 - 2 * markShift, y0], [x0 - 2 * markShift, y1]);//宽度文字

        markers = {
            models: {
                ParallelogramAMarkH,
                ParallelogramAMarkW,
                ParallelogramAMarkW1,
            }
        };
    } else if (frame == "ParallelogramB") {
        var x1 = w1, x2 = w + w2, y1 = h, y2 = h;
        var ParallelogramBMarkH = {
            paths: {
                "LeftLine": new makerjs.paths.Line([x0 - markShift, y0], [x0 - markShift, y1]),
                "LeftShortUpline": new makerjs.paths.Line([x1, y1], [x0 - 2 * markShift, y1]),
                "LeftShortDnline": new makerjs.paths.Line([x0, y0], [x0 - 2 * markShift, y0]),
            }
        };
        var ParallelogramBMarkW = {
            paths: {
                "BottomLine": new makerjs.paths.Line([x0, y0 - markShift], [x3, y3 - markShift]),
                "BotmShortLfline": new makerjs.paths.Line([x0, y0], [x0, y0 - 2 * markShift]),
                "BotmShortRTline": new makerjs.paths.Line([x3, y3], [x3, y3 - 2 * markShift]),
            }
        };
        var ParallelogramBMarkW1 = {
            paths: {
                "UpW1Line": new makerjs.paths.Line([x0, y1 + markShift], [x1, y1 + markShift]),
                "UpW1ShortLfline": new makerjs.paths.Line([x0, y1], [x0, y1 + 2 * markShift]),
                "UpW1ShortRTline": new makerjs.paths.Line([x1, y1], [x1, y1 + 2 * markShift]),
            }
        };

        var ParallelogramBMarkW2 = {
            paths: {
                "UpW2Line": new makerjs.paths.Line([x3, y3 - markShift], [x2, y3 - markShift]),
                "HRightfline": new makerjs.paths.Line([x2, y3], [x2, y2]),
                "UpW2ShortTpline": new makerjs.paths.Line([x2, y2], [x2 + markShift, y2]),
                "UpW2ShortLfline": new makerjs.paths.Line([x3, y3], [x3, y3 - 2 * markShift]),
                "UpW2ShortRTline": new makerjs.paths.Line([x2, y3], [x2, y3 - 2 * markShift]),
            }
        };
        makerjs.model.addCaption(ParallelogramBMarkW, TxtW, [x0, y0 - 2 * markShift], [x3, y3 - 2 * markShift]);//长度文字  
        makerjs.model.addCaption(ParallelogramBMarkW1, TxtW1, [x0, y1 + 2 * markShift], [x1, y1 + 2 * markShift]);//长度文字  
        makerjs.model.addCaption(ParallelogramBMarkW2, TxtW2, [x3, y3 - 2 * markShift], [x2, y3 - 2 * markShift]);//长度文字 
        makerjs.model.addCaption(ParallelogramBMarkH, TxtH, [x0 - 2 * markShift, y0], [x0 - 2 * markShift, y1]);//宽度文字

        markers = {
            models: {
                ParallelogramBMarkH,
                ParallelogramBMarkW,
                ParallelogramBMarkW1,
                ParallelogramBMarkW2,
            }
        };
    }

    return { markers }
};
export default RectangleMark;