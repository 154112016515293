import Cookies from "js-cookie"
export default {
    namespaced:true,//namespaced为true的作用是告诉vuex，该模块所有的state 、mutations、actions
                    //里面的东西调用时都需要加上命名空间，这个命名空间就是该模块被improt时命名的名字。
    state: {
        token: '',
        userInfo:JSON.parse(sessionStorage.getItem("userInfo"))
        //sessionStorage中得userInfo是序列化得字符串，这里要将他反序列化成对象。
    },
    mutations: {
        setToken(state, val) {
            state.token = val
            Cookies.set('token', JSON.stringify(val))
            localStorage.setItem('token', JSON.stringify(val))
        },
        setUserInfo(state,userInfo){
            state.userInfo = userInfo
            //sessionStorage中不可以存入对象，所以要将他序列化
            sessionStorage.setItem("userInfo", JSON.stringify(userInfo))
      
        },
        clearToken(state) {
            state.token = ''
            Cookies.remove('token')
            localStorage.setItem("token", '')
      sessionStorage.setItem("userInfo", JSON.stringify(''))
      state.userInfo = {}

        },
        getToken(state) {
            state.token = state.token || Cookies.get('token')
        },
    },
    getters: {
        getUser: state => {
          return state.userInfo
        }
      },
      actions: {},
      modules: {}
    
}