//缺1.3、蝴蝶
import makerjs from "makerjs";
// 矩形等去角 连线函数
function creatmyButterflyGap3(area, param) {
  console.log("是否调用此函数creatOneCorner（）");
  console.log("是否调用此函数creatOneCorner（）param=", param);
  var xa = param.xa, ya = param.ya, xb = param.xb, yb = param.yb, xc = param.xc, yc = param.yc, xg = param.xg, yg = param.yg, xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,
        x = param.x0, r1 = param.r1, r2 = param.r2, w = param.w,h = param.h, h1 = param.h1, h2 = param.h2, hd1 = param.hd1, hd2 = param.hd2, dd = param.dd, wd = param.wd;
      var lx=Math.sqrt(r2*r2-hd2*hd2);//确定两个圆弧坐标
      var angleA = Math.asin(lx /r2)*180/Math.PI; //对边比斜边 
          
  
  if (area == "A") {
    // var myButterflyGap3 = {
    //       paths: {
    //             "Left": new makerjs.paths.Line([xa, ya], [xa, ya-h]),
    //             "BottomLeft": new makerjs.paths.Arc([xa, ya-h2], r2, 90, 0+angleA),
    //             'Bottom': new makerjs.paths.Line([xa+lx, ya-h1], [xa+w-lx, ya-h1]),
    //             "BottomRight": new makerjs.paths.Arc([xa+w, ya-h2], r2, 180-angleA, 90),
    //             "Right": new makerjs.paths.Line([xa+w, ya-h], [xa+w, ya]),
    //       }
    // };
    var myButterflyGap3 = {
          paths: {
                Left: new makerjs.paths.Line([xa, ya], [xa, ya-h]),
                BottomLeft: new makerjs.paths.Arc([xa, ya-h],[xa+lx, ya-h1], r2, true,false),
                Bottom: new makerjs.paths.Line([xa+lx, ya-h1], [xa+w-lx, ya-h1]),
                BottomRight: new makerjs.paths.Arc([xa+w-lx, ya-h1],[xa+w, ya-h], r2, true,false),
                Right: new makerjs.paths.Line([xa+w, ya-h], [xa+w, ya]),
                // fillet:"",
          }
    };
    var fillet1 = makerjs.path.fillet(myButterflyGap3.paths.Left, myButterflyGap3.paths.BottomLeft, r1);
    var fillet2 = makerjs.path.fillet(myButterflyGap3.paths.BottomLeft, myButterflyGap3.paths.Bottom, r1);
    var fillet3 = makerjs.path.fillet(myButterflyGap3.paths.Bottom, myButterflyGap3.paths.BottomRight, r1);
    var fillet4 = makerjs.path.fillet(myButterflyGap3.paths.BottomRight, myButterflyGap3.paths.Right, r1);
    // var arc2 = makerjs.path.fillet(myButterflyGap3.paths.arc, myButterflyGap3.paths.line, 5);
//add the fillet to the model
myButterflyGap3.paths.fillet1 = fillet1;
myButterflyGap3.paths.fillet2 = fillet2;
myButterflyGap3.paths.fillet3 = fillet3;
myButterflyGap3.paths.fillet4 = fillet4;
console.log("myButterflyGap3==",myButterflyGap3);
  } else if (area == "B") {
    var myButterflyGap3 = {
      paths: {
            Left: new makerjs.paths.Line([xb, yb], [xb, yb-h]),
            BottomLeft: new makerjs.paths.Arc([xb, yb-h],[xb+lx, yb-h1], r2, true,false),
            Bottom: new makerjs.paths.Line([xb+lx, yb-h1], [xb+w-lx, yb-h1]),
            BottomRight: new makerjs.paths.Arc([xb+w-lx, yb-h1],[xb+w, yb-h], r2, true,false),
            Right: new makerjs.paths.Line([xb+w, yb-h], [xb+w, yb]),
            // fillet:"",
      }
};
var fillet1 = makerjs.path.fillet(myButterflyGap3.paths.Left, myButterflyGap3.paths.BottomLeft, r1);
var fillet2 = makerjs.path.fillet(myButterflyGap3.paths.BottomLeft, myButterflyGap3.paths.Bottom, r1);
var fillet3 = makerjs.path.fillet(myButterflyGap3.paths.Bottom, myButterflyGap3.paths.BottomRight, r1);
var fillet4 = makerjs.path.fillet(myButterflyGap3.paths.BottomRight, myButterflyGap3.paths.Right, r1);
myButterflyGap3.paths.fillet1 = fillet1;
myButterflyGap3.paths.fillet2 = fillet2;
myButterflyGap3.paths.fillet3 = fillet3;
myButterflyGap3.paths.fillet4 = fillet4;
console.log("myButterflyGap3==",myButterflyGap3);
  } else if (area == "C") {
    var myButterflyGap3 = {
      paths: {
            Left: new makerjs.paths.Line([xc-w, yc], [xc-w, yc-h]),
            BottomLeft: new makerjs.paths.Arc([xc-w, yc-h],[xc-w+lx, yc-h1], r2, true,false),
            Bottom: new makerjs.paths.Line([xc-w+lx, yc-h1], [xc-lx, yc-h1]),
            BottomRight: new makerjs.paths.Arc([xc-lx, yc-h1],[xc, yc-h], r2, true,false),
            Right: new makerjs.paths.Line([xc, yc-h], [xc, yc]),
            // fillet:"",
      }
};
var fillet1 = makerjs.path.fillet(myButterflyGap3.paths.Left, myButterflyGap3.paths.BottomLeft, r1);
var fillet2 = makerjs.path.fillet(myButterflyGap3.paths.BottomLeft, myButterflyGap3.paths.Bottom, r1);
var fillet3 = makerjs.path.fillet(myButterflyGap3.paths.Bottom, myButterflyGap3.paths.BottomRight, r1);
var fillet4 = makerjs.path.fillet(myButterflyGap3.paths.BottomRight, myButterflyGap3.paths.Right, r1);
myButterflyGap3.paths.fillet1 = fillet1;
myButterflyGap3.paths.fillet2 = fillet2;
myButterflyGap3.paths.fillet3 = fillet3;
myButterflyGap3.paths.fillet4 = fillet4;
console.log("myButterflyGap3==",myButterflyGap3);
  } else if (area == "G") {
    var myButterflyGap3 = {
      paths: {
            Left: new makerjs.paths.Line([xg, yg], [xg, yg+h]),
            BottomLeft: new makerjs.paths.Arc([xg, yg+h],[xg+lx, yg+h1], r2, true,true),
            Bottom: new makerjs.paths.Line([xg+lx, yg+h1], [xg+w-lx, yg+h1]),
            BottomRight: new makerjs.paths.Arc([xg+w-lx, yg+h1],[xg+w, yg+h], r2, true,true),
            Right: new makerjs.paths.Line([xg+w, yg+h], [xg+w, yg]),
            // fillet:"",
      }
};
var fillet1 = makerjs.path.fillet(myButterflyGap3.paths.Left, myButterflyGap3.paths.BottomLeft, r1);
var fillet2 = makerjs.path.fillet(myButterflyGap3.paths.BottomLeft, myButterflyGap3.paths.Bottom, r1);
var fillet3 = makerjs.path.fillet(myButterflyGap3.paths.Bottom, myButterflyGap3.paths.BottomRight, r1);
var fillet4 = makerjs.path.fillet(myButterflyGap3.paths.BottomRight, myButterflyGap3.paths.Right, r1);
// var arc2 = makerjs.path.fillet(myButterflyGap3.paths.arc, myButterflyGap3.paths.line, 5);
//add the fillet to the model
myButterflyGap3.paths.fillet1 = fillet1;
myButterflyGap3.paths.fillet2 = fillet2;
myButterflyGap3.paths.fillet3 = fillet3;
myButterflyGap3.paths.fillet4 = fillet4;
console.log("myButterflyGap3==",myButterflyGap3);
  } else if (area == "H") {
    var myButterflyGap3 = {
      paths: {
            Left: new makerjs.paths.Line([xh, yh], [xh, yh+h]),
            BottomLeft: new makerjs.paths.Arc([xh, yh+h],[xh+lx, yh+h1], r2, true,true),
            Bottom: new makerjs.paths.Line([xh+lx, yh+h1], [xh+w-lx, yh+h1]),
            BottomRight: new makerjs.paths.Arc([xh+w-lx, yh+h1],[xh+w, yh+h], r2, true,true),
            Right: new makerjs.paths.Line([xh+w, yh+h], [xh+w, yh]),
      }
};
var fillet1 = makerjs.path.fillet(myButterflyGap3.paths.Left, myButterflyGap3.paths.BottomLeft, r1);
var fillet2 = makerjs.path.fillet(myButterflyGap3.paths.BottomLeft, myButterflyGap3.paths.Bottom, r1);
var fillet3 = makerjs.path.fillet(myButterflyGap3.paths.Bottom, myButterflyGap3.paths.BottomRight, r1);
var fillet4 = makerjs.path.fillet(myButterflyGap3.paths.BottomRight, myButterflyGap3.paths.Right, r1);
myButterflyGap3.paths.fillet1 = fillet1;
myButterflyGap3.paths.fillet2 = fillet2;
myButterflyGap3.paths.fillet3 = fillet3;
myButterflyGap3.paths.fillet4 = fillet4;
console.log("myButterflyGap3==",myButterflyGap3);
  } else if (area == "I") {
    var myButterflyGap3 = {
      paths: {
            Left: new makerjs.paths.Line([xi-w, yi], [xi-w, yi+h]),
            BottomLeft: new makerjs.paths.Arc([xi-w, yi+h],[xi-w+lx, yi+h1], r2, true,true),
            Bottom: new makerjs.paths.Line([xi-w+lx, yi+h1], [xi-lx, yi+h1]),
            BottomRight: new makerjs.paths.Arc([xi-lx, yi+h1],[xi, yi+h], r2, true,true),
            Right: new makerjs.paths.Line([xi, yi+h], [xi, yi]),
            // fillet:"",
      }
};
var fillet1 = makerjs.path.fillet(myButterflyGap3.paths.Left, myButterflyGap3.paths.BottomLeft, r1);
var fillet2 = makerjs.path.fillet(myButterflyGap3.paths.BottomLeft, myButterflyGap3.paths.Bottom, r1);
var fillet3 = makerjs.path.fillet(myButterflyGap3.paths.Bottom, myButterflyGap3.paths.BottomRight, r1);
var fillet4 = makerjs.path.fillet(myButterflyGap3.paths.BottomRight, myButterflyGap3.paths.Right, r1);
myButterflyGap3.paths.fillet1 = fillet1;
myButterflyGap3.paths.fillet2 = fillet2;
myButterflyGap3.paths.fillet3 = fillet3;
myButterflyGap3.paths.fillet4 = fillet4;
console.log("myButterflyGap3==",myButterflyGap3);
  }
  return myButterflyGap3;
};
// 矩形等去角标记 连线函数      
function creatmyButterflyGap3Mark(outParam, area, param, inputParam, fixedParam, OffsetParam, zoom) {
  console.log("是否调用此函数creatCornerMark()");
  console.log("是否调用此函数param", param);
  console.log("是否调用此函数outParam", outParam);
  console.log("是否调用此函数inputParam", inputParam);
  console.log("是否调用此函数OffsetParam", OffsetParam);
  var R1=inputParam.R1,R2=inputParam.R2,W=inputParam.W,H=inputParam.H,H1=inputParam.H1,H2=inputParam.H2,X=inputParam.X,Y=inputParam.Y, myZoom = zoom * 1,
        glassW = outParam.glassW, glassH = outParam.glassH,
        xa = param.xa, ya = param.ya, xb = param.xb, yb = param.yb, xc = param.xc, yc = param.yc, xg = param.xg, yg = param.yg, xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,
        x = param.x0, r1 = param.r1, r2 = param.r2, w = param.w,h = param.h, h1 = param.h1, h2 = param.h2, hd1 = param.hd1, hd2 = param.hd2, dd = param.dd, wd = param.wd,
      lx=Math.sqrt(r2*r2-hd2*hd2),//确定两个圆弧坐标
      angleA = Math.asin(lx /r2)*180/Math.PI, //对边比斜边 
        markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
        markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
        r1 = R1*1*myZoom,
              r2= R2*1*myZoom,
              h= H*1*myZoom,
              h1= H1*1*myZoom,
              h2= H2*1*myZoom,
              w = W*1*myZoom,
              hd1=h1-h,
              hd2=h2-h1,
              wd=(w-w)/2,
              dd=Math.sqrt(r2*r2-hd2*hd2),
              // wd=(w-w1)/2,
                x0 = X*1*myZoom,
                y0 = Y*1*myZoom;
                if(r1!==0){
                 var TxtR1="R1="+R1;
                }
                if(r2!==0){
                 var TxtR2="R2="+R2;
                }
                if(h!==0){
                 var TxtH=H;
                }
                if(h1!==0){
                 var TxtH1=H1;
                }
                if(h2!==0){
                 var TxtH2=H2;
                }
                if(w!==0){
                 var TxtW=W;
                }
                if(y0!==0){          
                 var TxtY=Y;
                }
                if(x0!==0){          
                 var TxtX=X;
                }

  if (area == "A") {
     var myBfg3GapAMakerW = {
           paths: {
                 "WALine0": new makerjs.paths.Line([xa,ya], [xa,ya+markShiftFour]),
                 "WALine1": new makerjs.paths.Line([xa+w,ya], [xa+w,ya+markShiftFour]),
                 "WALine2": new makerjs.paths.Line([xa,ya+markShiftTwo], [xa+w, ya+markShiftTwo]),
           }
     };
     var myBfg3GapAMakerH = {
           paths: {
                 "HALine0": new makerjs.paths.Line([xa - markShift, ya], [xa - markShift, ya - h]),
                 "HALine2": new makerjs.paths.Line([xa - markShiftFour, ya - h], [xa, ya - h]),
           }
     };
     var myBfg3GapAMakerH1 = {
           paths: {
                 "H1ALine0": new makerjs.paths.Line([xa+w-markShiftTwo, ya - h1], [xa+w-markShiftTwo, ya]),
           }
     };
     var myBfg3GapAMakerH2 = {
           paths: {
                 "H1ALine0": new makerjs.paths.Line([xa+w+markShiftFour, ya - h2], [xa+w+markShiftFour, ya]),
                 "H1ALine2": new makerjs.paths.Line([xa+w, ya - h2], [xa+w+ markShiftFour*2, ya - h2]),
           }
     };
        var myBfg3GapAMakerX = {
              paths: {
                    "XALine0": new makerjs.paths.Line([xa - x0, ya + markShift], [xa, ya + markShift]),
                    "XALine1": new makerjs.paths.Line([xa - x0, ya], [xa - x0, ya + markShiftFour]),
                    "XALine2": new makerjs.paths.Line([xa, ya], [xa, ya + markShiftFour]),
              }
        };
        var myBfg3GapAMakerR1 = {
              paths: {
                    "R1ALine0": new makerjs.paths.Line([xa, ya - h], [xa - dd - markHolesOneD, ya - h - dd - markHolesOneD]),
              }
        };
        var myBfg3GapAMakerR2 = {
              paths: {
                    "R2ALine0": new makerjs.paths.Line([xa + w, ya - h2], [xa + w + dd + markHolesOneD, ya - h2 - dd - markHolesOneD]),
              }
        };
        makerjs.model.addCaption(myBfg3GapAMakerW, TxtW,[xa,ya+markShiftFour], [xa+w, ya+markShiftFour]);//W文字标注
        makerjs.model.addCaption(myBfg3GapAMakerH, TxtH, [xa - markShiftThree, ya - h], [xa - markShiftThree, ya]);//H文字标注
        makerjs.model.addCaption(myBfg3GapAMakerH1, TxtH1, [xa+w-markShiftFour, ya - h1], [xa+w-markShiftFour, ya]);//H1文字标注
        makerjs.model.addCaption(myBfg3GapAMakerH2, TxtH2, [xa+w+markShiftFour*2, ya - h2], [xa+w+markShiftFour*2, ya]);//H2文字标注
        makerjs.model.addCaption(myBfg3GapAMakerX, TxtX, [xa - x0, ya + markShiftTwo], [xa, ya + markShiftTwo]);//X文字标注
        makerjs.model.addCaption(myBfg3GapAMakerR1, TxtR1, [xa - dd - markHolesOneD, ya - h - dd - markHolesOneD], [xa - dd - markHolesOneD+markShiftTwo*2, ya - h - dd - markHolesOneD]);//R1文字标注
        makerjs.model.addCaption(myBfg3GapAMakerR2, TxtR2, [xa + w + dd + markHolesOneD, ya - h2 - dd - markHolesOneD], [xa + w + dd + markHolesOneD+markShiftTwo*2, ya - h2 - dd - markHolesOneD]);//R2文字标注  
        var markers = [myBfg3GapAMakerW,myBfg3GapAMakerH,myBfg3GapAMakerH1,myBfg3GapAMakerH2,myBfg3GapAMakerX, myBfg3GapAMakerR1, myBfg3GapAMakerR2]
  } else if (area == "B") {
    var myBfg3GapBMakerW = {
      paths: {
            "WBLine0": new makerjs.paths.Line([xb,yb], [xb,yb+markShiftFour]),
            "WBLine1": new makerjs.paths.Line([xb+w,yb], [xb+w,yb+markShiftFour]),
            "WBLine2": new makerjs.paths.Line([xb,yb+markShiftTwo], [xb+w, yb+markShiftTwo]),
      }
};
var myBfg3GapBMakerH = {
      paths: {
            "HBLine0": new makerjs.paths.Line([xb - markShift, yb], [xb - markShift, yb - h]),
            "HBLine2": new makerjs.paths.Line([xb - markShiftFour, yb - h], [xb, yb - h]),
      }
};
var myBfg3GapBMakerH1 = {
      paths: {
            "H1BLine0": new makerjs.paths.Line([xb+w-markShiftTwo, yb - h1], [xb+w-markShiftTwo, yb]),
      }
};
var myBfg3GapBMakerH2 = {
      paths: {
            "H1BLine0": new makerjs.paths.Line([xb+w+markShiftFour, yb - h2], [xb+w+markShiftFour, yb]),
            "H1BLine2": new makerjs.paths.Line([xb+w, yb - h2], [xb+w+ markShiftFour*2, yb - h2]),
      }
};
   var myBfg3GapBMakerX = {
         paths: {
               "XBLine0": new makerjs.paths.Line([xb - x0, yb + markShift], [xb, yb + markShift]),
               "XBLine1": new makerjs.paths.Line([xb - x0, yb], [xb - x0, yb + markShiftFour]),
               "XBLine2": new makerjs.paths.Line([xb, yb], [xb, yb + markShiftFour]),
         }
   };
   var myBfg3GapBMakerR1 = {
         paths: {
               "R1BLine0": new makerjs.paths.Line([xb, yb - h], [xb - dd - markHolesOneD, yb - h - dd - markHolesOneD]),
         }
   };
   var myBfg3GapBMakerR2 = {
         paths: {
               "R2BLine0": new makerjs.paths.Line([xb + w, yb - h2], [xb + w + dd + markHolesOneD, yb - h2 - dd - markHolesOneD]),
         }
   };
   makerjs.model.addCaption(myBfg3GapBMakerW, TxtW,[xb,yb+markShiftFour], [xb+w, yb+markShiftFour]);//W文字标注
   makerjs.model.addCaption(myBfg3GapBMakerH, TxtH, [xb - markShiftThree, yb - h], [xb - markShiftThree, yb]);//H文字标注
   makerjs.model.addCaption(myBfg3GapBMakerH1, TxtH1, [xb+w-markShiftFour, yb - h1], [xb+w-markShiftFour, yb]);//H1文字标注
   makerjs.model.addCaption(myBfg3GapBMakerH2, TxtH2, [xb+w+markShiftFour*2, yb - h2], [xb+w+markShiftFour*2, yb]);//H2文字标注
   makerjs.model.addCaption(myBfg3GapBMakerX, TxtX, [xb - x0, yb + markShiftTwo], [xb, yb + markShiftTwo]);//X文字标注
   makerjs.model.addCaption(myBfg3GapBMakerR1, TxtR1, [xb - dd - markHolesOneD, yb - h - dd - markHolesOneD], [xb - dd - markHolesOneD+markShiftTwo*2, yb - h - dd - markHolesOneD]);//R1文字标注
   makerjs.model.addCaption(myBfg3GapBMakerR2, TxtR2, [xb + w + dd + markHolesOneD, yb - h2 - dd - markHolesOneD], [xb + w + dd + markHolesOneD+markShiftTwo*2, yb - h2 - dd - markHolesOneD]);//R2文字标注  
   var markers = [myBfg3GapBMakerW,myBfg3GapBMakerH,myBfg3GapBMakerH1,myBfg3GapBMakerH2,myBfg3GapBMakerX, myBfg3GapBMakerR1, myBfg3GapBMakerR2]
  } else if (area == "C") {
    var myBfg3GapCMakerW = {
      paths: {
            "WCLine0": new makerjs.paths.Line([xc,yc], [xc,yc+markShiftFour]),
            "WCLine1": new makerjs.paths.Line([xc-w,yc], [xc-w,yc+markShiftFour]),
            "WCLine2": new makerjs.paths.Line([xc-w,yc+markShiftTwo], [xc, yc+markShiftTwo]),
      }
};
var myBfg3GapCMakerH = {
      paths: {
            "HCLine0": new makerjs.paths.Line([xc + markShift, yc], [xc + markShift, yc - h]),
            "HCLine2": new makerjs.paths.Line([xc + markShiftFour, yc - h], [xc, yc - h]),
      }
};
var myBfg3GapCMakerH1 = {
      paths: {
            "H1CLine0": new makerjs.paths.Line([xc-w+markShiftTwo, yc - h1], [xc-w+markShiftTwo, yc]),
      }
};
var myBfg3GapCMakerH2 = {
      paths: {
            "H1CLine0": new makerjs.paths.Line([xc-w-markShiftFour, yc - h2], [xc-w-markShiftFour, yc]),
            "H1CLine2": new makerjs.paths.Line([xc-w, yc - h2], [xc-w- markShiftFour*2, yc - h2]),
      }
};
   var myBfg3GapCMakerX = {
         paths: {
               "XCLine0": new makerjs.paths.Line([xc+ x0, yc + markShift], [xc, yc + markShift]),
               "XCLine1": new makerjs.paths.Line([xc+ x0, yc], [xc+ x0, yc + markShiftFour]),
               "XCLine2": new makerjs.paths.Line([xc, yc], [xc, yc + markShiftFour]),
         }
   };
   var myBfg3GapCMakerR1 = {
         paths: {
               "R1CLine0": new makerjs.paths.Line([xc, yc - h], [xc + dd + markHolesOneD, yc - h - dd - markHolesOneD]),
         }
   };
   var myBfg3GapCMakerR2 = {
         paths: {
               "R2CLine0": new makerjs.paths.Line([xc - w, yc - h2], [xc - w - dd - markHolesOneD, yc - h2 - dd - markHolesOneD]),
         }
   };
   makerjs.model.addCaption(myBfg3GapCMakerW, TxtW,[xc-w,yc+markShiftThree], [xc, yc+markShiftThree]);//W文字标注
   makerjs.model.addCaption(myBfg3GapCMakerH, TxtH, [xc + markShiftThree, yc - h], [xc + markShiftThree, yc]);//H文字标注
   makerjs.model.addCaption(myBfg3GapCMakerH1, TxtH1, [xc-w+markShiftFour, yc - h1], [xc-w+markShiftFour, yc]);//H1文字标注
   makerjs.model.addCaption(myBfg3GapCMakerH2, TxtH2, [xc-w-markShiftFour*2, yc - h2], [xc-w-markShiftFour*2, yc]);//H2文字标注
   makerjs.model.addCaption(myBfg3GapCMakerX, TxtX, [xc, yc + markShiftThree], [xc+ x0, yc + markShiftThree]);//X文字标注
   makerjs.model.addCaption(myBfg3GapCMakerR1, TxtR1, [xc + dd + markHolesOneD, yc - h - dd - markHolesOneD], [xc + dd +markHolesOneD+markShiftTwo*2, yc - h - dd - markHolesOneD]);//R1文字标注
   makerjs.model.addCaption(myBfg3GapCMakerR2, TxtR2, [xc - w - dd - markHolesOneD, yc - h2 - dd - markHolesOneD], [xc - w - dd - markHolesOneD+markShiftTwo*2, yc - h2 - dd - markHolesOneD]);//R2文字标注  
   var markers = [myBfg3GapCMakerW,myBfg3GapCMakerH,myBfg3GapCMakerH1,myBfg3GapCMakerH2,myBfg3GapCMakerX, myBfg3GapCMakerR1, myBfg3GapCMakerR2]
  } else if (area == "G") {
    var myBfg3GapGMakerW = {
      paths: {
            "WGLine0": new makerjs.paths.Line([xg,yg], [xg,yg-markShiftFour]),
            "WGLine1": new makerjs.paths.Line([xg+w,yg], [xg+w,yg-markShiftFour]),
            "WGLine2": new makerjs.paths.Line([xg,yg-markShiftTwo], [xg+w, yg-markShiftTwo]),
      }
};
var myBfg3GapGMakerH = {
      paths: {
            "HGLine0": new makerjs.paths.Line([xg - markShift, yg], [xg - markShift, yg + h]),
            "HGLine2": new makerjs.paths.Line([xg - markShiftFour, yg + h], [xg, yg + h]),
      }
};
var myBfg3GapGMakerH1 = {
      paths: {
            "H1GLine0": new makerjs.paths.Line([xg+w-markShiftTwo, yg + h1], [xg+w-markShiftTwo, yg]),
      }
};
var myBfg3GapGMakerH2 = {
      paths: {
            "H1GLine0": new makerjs.paths.Line([xg+w+markShiftFour, yg + h2], [xg+w+markShiftFour, yg]),
            "H1GLine2": new makerjs.paths.Line([xg+w, yg + h2], [xg+w+ markShiftFour*2, yg + h2]),
      }
};
   var myBfg3GapGMakerX = {
         paths: {
               "XGLine0": new makerjs.paths.Line([xg - x0, yg - markShiftTwo], [xg, yg - markShiftTwo]),
               "XGLine1": new makerjs.paths.Line([xg - x0, yg], [xg - x0, yg - markShiftFour]),
               "XGLine2": new makerjs.paths.Line([xg, yg], [xg, yg - markShiftFour]),
         }
   };
   var myBfg3GapGMakerR1 = {
         paths: {
               "R1GLine0": new makerjs.paths.Line([xg, yg + h], [xg - dd - markHolesOneD, yg + h + dd + markHolesOneD]),
         }
   };
   var myBfg3GapGMakerR2 = {
         paths: {
               "R2GLine0": new makerjs.paths.Line([xg + w, yg + h2], [xg + w + dd + markHolesOneD, yg + h2 + dd + markHolesOneD]),
         }
   };
   makerjs.model.addCaption(myBfg3GapGMakerW, TxtW,[xg,yg-markShiftFour], [xg+w, yg-markShiftFour]);//W文字标注
   makerjs.model.addCaption(myBfg3GapGMakerH, TxtH, [xg - markShiftThree, yg + h], [xg - markShiftThree, yg]);//H文字标注
   makerjs.model.addCaption(myBfg3GapGMakerH1, TxtH1, [xg+w-markShiftFour, yg + h1], [xg+w-markShiftFour, yg]);//H1文字标注
   makerjs.model.addCaption(myBfg3GapGMakerH2, TxtH2, [xg+w+markShiftFour*2, yg + h2], [xg+w+markShiftFour*2, yg]);//H2文字标注
   makerjs.model.addCaption(myBfg3GapGMakerX, TxtX, [xg - x0, yg - markShiftFour], [xg, yg - markShiftFour]);//X文字标注
   makerjs.model.addCaption(myBfg3GapGMakerR1, TxtR1, [xg - dd - markHolesOneD, yg + h + dd + markHolesOneD], [xg - dd - markHolesOneD+markShiftTwo*2, yg + h + dd + markHolesOneD]);//R1文字标注
   makerjs.model.addCaption(myBfg3GapGMakerR2, TxtR2, [xg + w + dd + markHolesOneD, yg + h2 + dd + markHolesOneD], [xg + w + dd + markHolesOneD+markShiftTwo*2, yg + h2 + dd + markHolesOneD]);//R2文字标注  
   var markers = [myBfg3GapGMakerW,myBfg3GapGMakerH,myBfg3GapGMakerH1,myBfg3GapGMakerH2,myBfg3GapGMakerX, myBfg3GapGMakerR1, myBfg3GapGMakerR2]
  } else if (area == "H") {
    var myBfg3GapHMakerW = {
      paths: {
            "WHLine0": new makerjs.paths.Line([xh,yh], [xh,yh-markShiftFour*2]),
            "WHLine1": new makerjs.paths.Line([xh+w,yh], [xh+w,yh-markShiftFour*2]),
            "WHLine2": new makerjs.paths.Line([xh,yh-markShiftTwo*2], [xh+w, yh-markShiftTwo*2]),
      }
};
var myBfg3GapHMakerH = {
      paths: {
            "HHLine0": new makerjs.paths.Line([xh - markShift, yh], [xh - markShift, yh + h]),
            "HHLine2": new makerjs.paths.Line([xh - markShiftFour, yh + h], [xh, yh + h]),
      }
};
var myBfg3GapHMakerH1 = {
      paths: {
            "H1HLine0": new makerjs.paths.Line([xh+w-markShiftTwo, yh + h1], [xh+w-markShiftTwo, yh]),
      }
};
var myBfg3GapHMakerH2 = {
      paths: {
            "H1HLine0": new makerjs.paths.Line([xh+w+markShiftFour, yh + h2], [xh+w+markShiftFour, yh]),
            "H1HLine2": new makerjs.paths.Line([xh+w, yh + h2], [xh+w+ markShiftFour*2, yh + h2]),
      }
};
   var myBfg3GapHMakerX = {
         paths: {
              //  "XHLine0": new makerjs.paths.Line([xh - x0, yh - markShiftTwo], [xh, yh - markShiftTwo]),
              //  "XHLine1": new makerjs.paths.Line([xh - x0, yh], [xh - x0, yh - markShiftFour]),
              //  "XHLine2": new makerjs.paths.Line([xh, yh], [xh, yh - markShiftFour]),
         }
   };
   var myBfg3GapHMakerR1 = {
         paths: {
               "R1HLine0": new makerjs.paths.Line([xh, yh + h], [xh - dd - markHolesOneD, yh + h + dd + markHolesOneD]),
         }
   };
   var myBfg3GapHMakerR2 = {
         paths: {
               "R2HLine0": new makerjs.paths.Line([xh + w, yh + h2], [xh + w + dd + markHolesOneD, yh + h2 + dd + markHolesOneD]),
         }
   };
   makerjs.model.addCaption(myBfg3GapHMakerW, TxtW,[xh,yh-markShiftFour*2], [xh+w, yh-markShiftFour*2]);//W文字标注
   makerjs.model.addCaption(myBfg3GapHMakerH, TxtH, [xh - markShiftThree, yh + h], [xh - markShiftThree, yh]);//H文字标注
   makerjs.model.addCaption(myBfg3GapHMakerH1, TxtH1, [xh+w-markShiftFour, yh + h1], [xh+w-markShiftFour, yh]);//H1文字标注
   makerjs.model.addCaption(myBfg3GapHMakerH2, TxtH2, [xh+w+markShiftFour*2, yh + h2], [xh+w+markShiftFour*2, yh]);//H2文字标注
   makerjs.model.addCaption(myBfg3GapHMakerX, TxtX, [xh - x0, yh + markShiftTwo], [xh, yh + markShiftTwo]);//X文字标注
   makerjs.model.addCaption(myBfg3GapHMakerR1, TxtR1, [xh - dd - markHolesOneD, yh + h + dd + markHolesOneD], [xh - dd - markHolesOneD+markShiftTwo*2, yh+ h + dd + markHolesOneD]);//R1文字标注
   makerjs.model.addCaption(myBfg3GapHMakerR2, TxtR2, [xh + w + dd + markHolesOneD, yh + h2 + dd + markHolesOneD], [xh + w + dd + markHolesOneD+markShiftTwo*2, yh + h2 + dd +markHolesOneD]);//R2文字标注  
   var markers = [myBfg3GapHMakerW,myBfg3GapHMakerH,myBfg3GapHMakerH1,myBfg3GapHMakerH2,myBfg3GapHMakerX, myBfg3GapHMakerR1, myBfg3GapHMakerR2]
  } else if (area == "I") {
    var myBfg3GapIMakerW = {
      paths: {
            "WILine0": new makerjs.paths.Line([xi,yi], [xi,yi-markShiftFour]),
            "WILine1": new makerjs.paths.Line([xi-w,yi], [xi-w,yi-markShiftFour]),
            "WILine2": new makerjs.paths.Line([xi-w,yi-markShiftTwo], [xi, yi-markShiftTwo]),
      }
};
var myBfg3GapIMakerH = {
      paths: {
            "HILine0": new makerjs.paths.Line([xi + markShift, yi], [xi + markShift, yi+ h]),
            "HILine2": new makerjs.paths.Line([xi + markShiftFour, yi + h], [xi, yi + h]),
      }
};
var myBfg3GapIMakerH1 = {
      paths: {
            "H1ILine0": new makerjs.paths.Line([xi-w+markShiftTwo, yi + h1], [xi-w+markShiftTwo, yi]),
      }
};
var myBfg3GapIMakerH2 = {
      paths: {
            "H1ILine0": new makerjs.paths.Line([xi-w-markShiftFour, yi + h2], [xi-w-markShiftFour, yi]),
            "H1ILine2": new makerjs.paths.Line([xi-w, yi + h2], [xi-w- markShiftFour*2, yi+ h2]),
      }
};
   var myBfg3GapIMakerX = {
         paths: {
               "XILine0": new makerjs.paths.Line([xi+ x0, yi - markShiftTwo], [xi, yi - markShiftTwo]),
               "XILine1": new makerjs.paths.Line([xi+ x0, yi], [xi+ x0, yi - markShiftFour]),
               "XILine2": new makerjs.paths.Line([xi, yi], [xi, yi - markShiftFour]),
         }
   };
   var myBfg3GapIMakerR1 = {
         paths: {
               "R1ILine0": new makerjs.paths.Line([xi, yi + h], [xi + dd + markHolesOneD, yi + h + dd + markHolesOneD]),
         }
   };
   var myBfg3GapIMakerR2 = {
         paths: {
               "R2ILine0": new makerjs.paths.Line([xi - w, yi+ h2], [xi - w - dd - markHolesOneD, yi + h2 + dd + markHolesOneD]),
         }
   };
   makerjs.model.addCaption(myBfg3GapIMakerW, TxtW,[xi-w,yi-markShiftThree], [xi, yi-markShiftThree]);//W文字标注
   makerjs.model.addCaption(myBfg3GapIMakerH, TxtH, [xi + markShiftThree, yi + h], [xi + markShiftThree, yi]);//H文字标注
   makerjs.model.addCaption(myBfg3GapIMakerH1, TxtH1, [xi-w+markShiftFour, yi + h1], [xi-w+markShiftFour, yi]);//H1文字标注
   makerjs.model.addCaption(myBfg3GapIMakerH2, TxtH2, [xi-w-markShiftFour*2, yi + h2], [xi-w-markShiftFour*2, yi]);//H2文字标注
   makerjs.model.addCaption(myBfg3GapIMakerX, TxtX, [xi, yi- markShiftThree], [xi+ x0, yi - markShiftThree]);//X文字标注
   makerjs.model.addCaption(myBfg3GapIMakerR1, TxtR1, [xi + dd + markHolesOneD, yi + h + dd + markHolesOneD], [xi + dd +markHolesOneD+markShiftTwo*2, yi + h + dd + markHolesOneD]);//R1文字标注
   makerjs.model.addCaption(myBfg3GapIMakerR2, TxtR2, [xi - w - dd - markHolesOneD, yi + h2 + dd + markHolesOneD], [xi - w - dd - markHolesOneD+markShiftTwo*2, yi + h2+ dd + markHolesOneD]);//R2文字标注  
   var markers = [myBfg3GapIMakerW,myBfg3GapIMakerH,myBfg3GapIMakerH1,myBfg3GapIMakerH2,myBfg3GapIMakerX, myBfg3GapIMakerR1, myBfg3GapIMakerR2]
  }
  console.log("markers||||||||||||||||||=", markers)
  return markers;
};
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ButterflyGap3(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
  var frame = outParam.shapeName;
  console.log("outParam==", outParam)
  console.log("model==", model)
  console.log("inputParam==", inputParam)
  console.log("outParam.shapeName==////////", outParam.shapeName)
  if (typeof (frame) == "undefined") {
        var R1=inputParam.R1,R2=inputParam.R2,W=inputParam.W,H=inputParam.H,H1=inputParam.H1,H2=inputParam.H2,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
        plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = "Rectangle",
              glassW = outParam.glassW, glassH = outParam.glassH,
              markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
              markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
              r1 = R1*1*myZoom,
              r2= R2*1*myZoom,
              h= H*1*myZoom,
              h1= H1*1*myZoom,
              h2= H2*1*myZoom,
              w = W*1*myZoom,
              hd1=h1-h,
              hd2=h2-h1,
              wd=(w-w)/2,
              dd=Math.sqrt(r2*r2-hd2*hd2),
              // wd=(w-w1)/2,
                x0 = X*1*myZoom,
                y0 = Y*1*myZoom;
                if(plate.models===undefined||plate.models===""){
                  console.log("1111111111111111");
                  plate.models={};
                          }        
                          if(cutPlate.models===undefined||cutPlate.models===""){
                              console.log("22222222222");
                              //cutPlate.models={};
                              cutPlate.models={};
                          }
                          if(markers.models===undefined||markers.models===""){            
                              console.log("333333333333");
                              markers.models={};
                          }
                if(r1!==0){
                 var TxtR1="R1="+R1;
                }
                if(r2!==0){
                 var TxtR2="R2="+R2;
                }
                if(h!==0){
                 var TxtH=H;
                }
                if(h1!==0){
                 var TxtH1=H1;
                }
                if(h2!==0){
                 var TxtH2=H2;
                }
                if(w!==0){
                 var TxtW=W;
                }
                if(y0!==0){          
                 var TxtY=Y;
                }
                if(x0!==0){          
                 var TxtX=X;
                }
  } else {
        var R1=inputParam.R1,R2=inputParam.R2,W=inputParam.W,H=inputParam.H,H1=inputParam.H1,H2=inputParam.H2,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
        plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = outParam.shapeName,
              glassW = outParam.glassW, glassH = outParam.glassH,
              markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
              markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
              r1 = R1*1*myZoom,
              r2= R2*1*myZoom,
              h= H*1*myZoom,
              h1= H1*1*myZoom,
              h2= H2*1*myZoom,
              w = W*1*myZoom,
              hd1=h1-h,
              hd2=h2-h1,
              wd=(w-w)/2,
              dd=Math.sqrt(r2*r2-hd2*hd2),
                x0 = X*1*myZoom,
                y0 = Y*1*myZoom;
                if(plate.models===undefined||plate.models===""){
                  console.log("1111111111111111");
                  plate.models={};
                          }        
                          if(cutPlate.models===undefined||cutPlate.models===""){
                              console.log("22222222222");
                              //cutPlate.models={};
                              cutPlate.models={};
                          }
                          if(markers.models===undefined||markers.models===""){            
                              console.log("333333333333");
                              markers.models={};
                          }
                if(r1!==0){
                 var TxtR1="R1="+R1;
                }
                if(r2!==0){
                 var TxtR2="R2="+R2;
                }
                if(h!==0){
                 var TxtH=H;
                }
                if(h1!==0){
                 var TxtH1=H1;
                }
                if(h2!==0){
                 var TxtH2=H2;
                }
                if(w!==0){
                 var TxtW=W;
                }
                if(y0!==0){          
                 var TxtY=Y;
                }
                if(x0!==0){          
                 var TxtX=X;
                }
  }
  if (frame == "Rectangle") {
        console.log("frame==undefined||||*****")
        //在A区域 计算孔的位置       
        var xa = x0, ya = glassH * 1 - y0,
              //B区域//只有Y标注没有X标注 
              xb = glassW * 1 / 2, yb = glassH * 1 - y0,
              //C区域
              xc = glassW * 1 - x0, yc = glassH * 1 - y0,
              //在G区域 计算孔的位置
              xg = x0, yg = y0,
              //在H区域 计算孔的位置
              //只标注Y
              xh = glassW * 1 / 2, yh = y0,
              //在I区域 计算孔的位置
              xi = glassW * 1 - x0, yi = y0;
        var OffsetParam = {};
  } else if (frame == "IsoscelesTrapezoid") {

  } else if (frame == "RightAngledTrapezoid") {

  } else if (frame == "OtherTrapezoidsA") {

  } else if (frame == "OtherTrapezoidsB") {

  } else if (frame == "ParallelogramA") {


  } else if (frame == "ParallelogramB") {

  }
  // 坐标参数
  var paramCoordinate = { x0,hd1,hd2,dd,wd,r1,r2,h,h1,h2, w, xa, ya, xb, yb, xc, yc, xg, yg, xh, yh, xi, yi };
  const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(model.plate.models)));
  var areaPoint = "";
  console.log("//角7、地弹簧3 ------", allKeys)
  if (allKeys[0] == frame) {
        allKeys.shift();//去掉数组第一个元素
  }
  console.log("//角7、地弹簧3 位置去掉数组第一个元素------", allKeys)
  switch (area) {
        case "1": areaPoint = "A"; break;
        case "2": areaPoint = "B"; break;
        case "3": areaPoint = "C"; break;
        case "7": areaPoint = "G"; break;
        case "8": areaPoint = "H"; break;
        case "9": areaPoint = "I"; break;
  }
  /**
  * 画图
  * 
  */
  var ObjName = "myButterflyGap3" + areaPoint;
  var ObjNameMmyButterflyGap = "myButterflyGap3" + areaPoint + "Mark";//角标记
  var ObjNameMmyButterflyGap = "myButterflyGap3" + areaPoint + "Mark";//角标记
  ObjName = creatmyButterflyGap3(areaPoint, paramCoordinate);//图形
  console.log("ObjName===|||||||||", ObjName);
  ObjNameMmyButterflyGap = creatmyButterflyGap3Mark(outParam, areaPoint, paramCoordinate, inputParam, fixedParam, OffsetParam, zoom);//图形
  console.log("ObjNameMmyButterflyGap===|||||||||角标注", ObjNameMmyButterflyGap);
  console.log("cutPlate.models====************", cutPlate.models),
        console.log("plate.models====**************", plate.models),
        console.log("markers.models====*************", markers.models),
     //    myBfg3GapAMakerW,myBfg3GapAMakerH,myBfg3GapAMakerH1,myBfg3GapAMakerH2,myBfg3GapAMakerX, myBfg3GapAMakerR1, myBfg3GapAMakerR2
        cutPlate.models["myButterflyGap3" + areaPoint + myHolesNum] = ObjName;
  plate.models["myButterflyGap3" + areaPoint + myHolesNum] = ObjName;
  markers.models["myButterflyGap3" + areaPoint + myHolesNum] = ObjName;
  markers.models["myButterflyGap3W" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyButterflyGap[0];
  markers.models["myButterflyGap3H" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyButterflyGap[1];
  markers.models["myButterflyGap3H1" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyButterflyGap[2];
  markers.models["myButterflyGap3H2" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyButterflyGap[3];
  markers.models["myButterflyGap3X" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyButterflyGap[4];
  markers.models["myButterflyGap3R1" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyButterflyGap[5];
  markers.models["myButterflyGap3R2" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyButterflyGap[6];

myHolesNum++;
return{plate,cutPlate,markers, myHolesNum}
};


export default ButterflyGap3;