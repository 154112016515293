//缺3、应力孔
import makerjs from "makerjs";
// 矩形等去角 连线函数
function creatmyStressHoleGap(area, param) {
  console.log("是否调用此函数creatOneCorner（）");
  console.log("是否调用此函数creatOneCorner（）param=", param);
  var xa = param.xa, ya = param.ya, xb = param.xb, yb = param.yb, xc = param.xc, yc = param.yc, xg = param.xg, yg = param.yg, xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,
  x = param.x0, r = param.r, d = param.d,w = param.w, h = param.h,dd = param.dd,h0 = param.h0,w0 = param.w0;
           
  
  if (area == "A") {
    var myStressHoleGap = {
          paths: {
            "LeftLine": new makerjs.paths.Line([xa, ya], [xa, ya-h0]),
            "BottomArc": new makerjs.paths.Arc([xa, ya-h0], [xa+w, ya-h0],r,true,false),
            "RightLine": new makerjs.paths.Line([xa+w, ya-h0], [xa+w, ya])
          }
    };
console.log("myStressHoleGap==",myStressHoleGap);
  } else if (area == "B") {
      var myStressHoleGap = {
            paths: {
              "LeftLine": new makerjs.paths.Line([xb, yb], [xb, yb-h0]),
              "ArcBottom": new makerjs.paths.Arc([xb, yb-h0], [xb+w, yb-h0],r,true,false),
              "RightLine": new makerjs.paths.Line([xb+w, yb-h0], [xb+w, yb])
            }
      };
console.log("myStressHoleGap==",myStressHoleGap);
  } else if (area == "C") {
      var myStressHoleGap = {
            paths: {
                  "LeftLine": new makerjs.paths.Line([xc-w, yc], [xc-w, yc-h0]),
                  "ArcBottom": new makerjs.paths.Arc( [xc-w, yc-h0],[xc, yc-h0],r,true,false),
                  "RightLine": new makerjs.paths.Line([xc, yc-h0],[xc, yc])
            }
      };
console.log("myStressHoleGap==",myStressHoleGap);
  } else if (area == "G") {
      var myStressHoleGap = {
            paths: {
              "LeftLine": new makerjs.paths.Line([xg, yg], [xg, yg+h0]),
              "ArcTop": new makerjs.paths.Arc([xg, yg+h0], [xg+w, yg+h0],r,true,true),
              "RightLine": new makerjs.paths.Line([xg+w, yg+h0], [xg+w, yg])
            }
      };
console.log("myStressHoleGap==",myStressHoleGap);
  } else if (area == "H") {
      var myStressHoleGap = {
            paths: {
              "LeftLine": new makerjs.paths.Line([xh, yh], [xh, yh+h0]),
              "ArcTop": new makerjs.paths.Arc([xh, yh+h0], [xh+w, yh+h0],r,true,true),
              "RightLine": new makerjs.paths.Line([xh+w, yh+h0], [xh+w, yh])
            }
      };
console.log("myStressHoleGap==",myStressHoleGap);
  } else if (area == "I") {
      var myStressHoleGap = {
            paths: {
            "LeftLine": new makerjs.paths.Line([xi-w, yi+h0], [xi-w, yi]),
           "ArcTop": new makerjs.paths.Arc([xi-w, yi+h0],[xi, yi+h0], r,true,true),
           "RightLine": new makerjs.paths.Line([xi, yi], [xi, yi+h0])
            }
      };
console.log("myStressHoleGap==",myStressHoleGap);
  }
  return myStressHoleGap;
};
// 矩形等去角标记 连线函数      
function creatmyStressHoleGapMark(outParam, area, param, inputParam, fixedParam, OffsetParam, zoom) {
  console.log("是否调用此函数creatCornerMark()");
  console.log("是否调用此函数param", param);
  console.log("是否调用此函数outParam", outParam);
  console.log("是否调用此函数inputParam", inputParam);
  console.log("是否调用此函数OffsetParam", OffsetParam);
  var H=inputParam.H,D=inputParam.D,W=inputParam.W,X=inputParam.X,Y=inputParam.Y, myZoom = zoom * 1,
        xa = param.xa, ya = param.ya, xb = param.xb, yb = param.yb, xc = param.xc, yc = param.yc, xg = param.xg, yg = param.yg, xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,
        x = param.x0, r = param.r, d = param.d,w = param.w, h = param.h, 
        glassW = outParam.glassW, glassH = outParam.glassH,
       markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
        markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
        d = D*1*myZoom,
              w= W*1*myZoom,
              h= H*1*myZoom,
              r=d/2,
              dd=Math.sqrt(r*r-w/2*w/2).toFixed(),
              h0=h-dd,
              w0=(d-w)/2,
                x0 = X*1*myZoom,
                y0 = Y*1*myZoom;
     if(d!==0){
      var TxtD="∮"+D;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(w!==0){
      var TxtW=W;
     }
     if(x0!==0){          
     var TxtX=X;
     }
     if(y0!==0){          
      var TxtY=Y;
     }

  if (area == "A") {
      var myShgGapAMakerH = {
            paths: {
                  "HALine0": new makerjs.paths.Line([xa+w+w0+markShiftTwo,ya], [xa+w+w0+markShiftTwo,ya-h]),
                  "HALine1": new makerjs.paths.Line([xa+w+w0,ya-h], [xa+w+w0+markShiftFour,ya-h]),
            }
      };
      var myShgGapAMakerD = {
            paths: {
                   "DALine0": new makerjs.paths.Line([xa-w0,ya-h], [xa-w0,ya-h-markShiftFour*2]),
                   "DALine1": new makerjs.paths.Line([xa-w0+d,ya-h], [xa-w0+d,ya-h-markShiftFour*2]),
                   "DALine2": new makerjs.paths.Line([xa-w0,ya-h-markShiftFour-markShiftTwo], [xa-w0+d, ya-h-markShiftFour-markShiftTwo]),
            }
      };
      var myShgGapAMakerX = {
            paths: {
                  "XALine0": new makerjs.paths.Line([xa - x0, ya + markShift], [xa, ya + markShift]),
                  "XALine1": new makerjs.paths.Line([xa - x0, ya], [xa - x0, ya + markShiftFour]),
                  "XALine2": new makerjs.paths.Line([xa, ya], [xa, ya + markShiftFour]),
            }
      };
      var myShgGapAMakerW = {
            paths: {
                  "WALine0": new makerjs.paths.Line([xa+w, ya], [xa+w, ya + markShiftFour]),
                  "WALine1": new makerjs.paths.Line([xa, ya+ markShift], [xa+w, ya + markShift]),
            }
      };
        makerjs.model.addCaption(myShgGapAMakerH, TxtH, [xa+d+markShiftTwo,ya-h],[xa+d+markShiftTwo,ya]);//H文字标注
        makerjs.model.addCaption(myShgGapAMakerX, TxtX, [xa - x0, ya + markShiftTwo], [xa, ya + markShiftTwo]);//X文字标注
        makerjs.model.addCaption(myShgGapAMakerD, TxtD, [xa-w0,ya-h-markShiftFour*2], [xa-w0+d, ya-h-markShiftFour*2]);//D文字标注  
        makerjs.model.addCaption(myShgGapAMakerW, TxtW, [xa, ya+ markShiftFour], [xa+w, ya + markShiftFour]);//W文字标注  
        var markers = [myShgGapAMakerH,myShgGapAMakerX,myShgGapAMakerD,myShgGapAMakerW]
  } else if (area == "B") {
      var myShgGapBMakerH = {
            paths: {
                  "HBLine0": new makerjs.paths.Line([xb+w+w0+markShiftTwo,yb], [xb+w+w0+markShiftTwo,yb-h]),
                  "HBLine1": new makerjs.paths.Line([xb+w+w0,yb-h], [xb+w+w0+markShiftFour,yb-h]),
            }
      };
      var myShgGapBMakerD = {
            paths: {
                   "DBLine0": new makerjs.paths.Line([xb-w0,yb-h], [xb-w0,yb-h-markShiftFour*2]),
                   "DBLine1": new makerjs.paths.Line([xb-w0+d,yb-h], [xb-w0+d,yb-h-markShiftFour*2]),
                   "DBLine2": new makerjs.paths.Line([xb-w0,yb-h-markShiftFour-markShiftTwo], [xb-w0+d, yb-h-markShiftFour-markShiftTwo]),
            }
      };
      var myShgGapBMakerX = {
            paths: {
                  // "XBLine0": new makerjs.paths.Line([xb - x0, yb + markShift], [xb, yb + markShift]),
                  // "XBLine1": new makerjs.paths.Line([xb - x0, yb], [xb - x0, yb + markShiftFour]),
                  // "XBLine2": new makerjs.paths.Line([xb, yb], [xb, yb + markShiftFour]),
            }
      };
      var myShgGapBMakerW = {
            paths: {
                  "WBLine0": new makerjs.paths.Line([xb, yb], [xb, yb + markShiftFour]),
                  "WBLine1": new makerjs.paths.Line([xb+w, yb], [xb+w, yb + markShiftFour]),
                  "WBLine2": new makerjs.paths.Line([xb, yb+ markShift], [xb+w, yb + markShift]),
            }
      };
        makerjs.model.addCaption(myShgGapBMakerH, TxtH, [xb+d+markShiftTwo,yb-h],[xb+d+markShiftTwo,yb]);//H文字标注
        makerjs.model.addCaption(myShgGapBMakerX, TxtX, [xb - x0, yb + markShiftTwo], [xb, yb + markShiftTwo]);//X文字标注
        makerjs.model.addCaption(myShgGapBMakerD, TxtD, [xb-w0,yb-h-markShiftFour*2], [xb-w0+d, yb-h-markShiftFour*2]);//D文字标注  
        makerjs.model.addCaption(myShgGapBMakerW, TxtW, [xb, yb+ markShiftFour], [xb+w, yb + markShiftFour]);//W文字标注  
        var markers = [myShgGapBMakerH,myShgGapBMakerX,myShgGapBMakerD,myShgGapBMakerW]
  } else if (area == "C") {
      var myShgGapCMakerH = {
            paths: {
                  "HCLine0": new makerjs.paths.Line([xc-w-w0-markShiftTwo,yc], [xc-w-w0-markShiftTwo,yc-h]),
                  "HCLine1": new makerjs.paths.Line([xc-w-w0,yc-h], [xc-w-w0-markShiftFour,yc-h]),
            }
      };
      var myShgGapCMakerD = {
            paths: {
                   "DCLine0": new makerjs.paths.Line([xc+w0,yc-h], [xc+w0,yc-h-markShiftFour*2]),
                   "DCLine1": new makerjs.paths.Line([xc+w0-d,yc-h], [xc+w0-d,yc-h-markShiftFour*2]),
                   "DCLine2": new makerjs.paths.Line([xc+w0,yc-h-markShiftFour-markShiftTwo], [xc+w0-d, yc-h-markShiftFour-markShiftTwo]),
            }
      };
      var myShgGapCMakerX = {
            paths: {
                  "XCLine0": new makerjs.paths.Line([xc+ x0, yc + markShift], [xc, yc + markShift]),
                  "XCLine1": new makerjs.paths.Line([xc + x0, yc], [xc + x0, yc + markShiftFour]),
                  "XCLine2": new makerjs.paths.Line([xc, yc], [xc, yc + markShiftFour]),
            }
      };
      var myShgGapCMakerW = {
            paths: {
                  "WCLine0": new makerjs.paths.Line([xc-w, yc], [xc-w, yc + markShiftFour]),
                  "WCLine1": new makerjs.paths.Line([xc, yc+ markShift], [xc-w, yc + markShift]),
            }
      };
        makerjs.model.addCaption(myShgGapCMakerH, TxtH, [xc-d-markShiftTwo,yc-h],[xc-d-markShiftTwo,yc]);//H文字标注
        makerjs.model.addCaption(myShgGapCMakerX, TxtX, [xc, yc + markShiftFour], [xc + x0, yc + markShiftFour]);//X文字标注
        makerjs.model.addCaption(myShgGapCMakerD, TxtD, [xc+w0-d, yc-h-markShiftFour*2], [xc+w0,yc-h-markShiftFour*2]);//D文字标注  
        makerjs.model.addCaption(myShgGapCMakerW, TxtW, [xc-w, yc + markShiftFour], [xc, yc+ markShiftFour]);//W文字标注  
        var markers = [myShgGapCMakerH,myShgGapCMakerX,myShgGapCMakerD,myShgGapCMakerW]
  } else if (area == "G") {
      var myShgGapGMakerH = {
            paths: {
                  "HGLine0": new makerjs.paths.Line([xg+w+w0+markShiftTwo,yg], [xg+w+w0+markShiftTwo,yg+h]),
                  "HGLine1": new makerjs.paths.Line([xg+w+w0,yg+h], [xg+w+w0+markShiftFour,yg+h]),
            }
      };
      var myShgGapGMakerD = {
            paths: {
                   "DGLine0": new makerjs.paths.Line([xg-w0,yg+h], [xg-w0,yg+h+markShiftFour*2]),
                   "DGLine1": new makerjs.paths.Line([xg-w0+d,yg+h], [xg-w0+d,yg+h+markShiftFour*2]),
                   "DGLine2": new makerjs.paths.Line([xg-w0,yg+h+markShiftFour+markShiftTwo], [xg-w0+d, yg+h+markShiftFour+markShiftTwo]),
            }
      };
      var myShgGapGMakerX = {
            paths: {
                  "XGLine0": new makerjs.paths.Line([xg - x0, yg - markShiftTwo], [xg, yg - markShiftTwo]),
                  "XGLine1": new makerjs.paths.Line([xg - x0, yg], [xg - x0, yg - markShiftFour]),
                  "XGLine2": new makerjs.paths.Line([xg, yg], [xg, yg - markShiftFour]),
            }
      };
      var myShgGapGMakerW = {
            paths: {
                  "WGLine0": new makerjs.paths.Line([xg+w, yg], [xg+w, yg - markShiftFour]),
                  "WGLine1": new makerjs.paths.Line([xg, yg-markShiftTwo], [xg+w, yg -markShiftTwo]),
            }
      };
        makerjs.model.addCaption(myShgGapGMakerH, TxtH,[xg+d+markShiftTwo,yg], [xg+d+markShiftTwo,yg+h]);//H文字标注
        makerjs.model.addCaption(myShgGapGMakerX, TxtX, [xg - x0, yg - markShiftFour], [xg, yg - markShiftFour]);//X文字标注
        makerjs.model.addCaption(myShgGapGMakerD, TxtD, [xg-w0,yg+h+markShiftFour*2], [xg-w0+d, yg+h+markShiftFour*2]);//D文字标注  
        makerjs.model.addCaption(myShgGapGMakerW, TxtW, [xg, yg- markShiftFour], [xg+w, yg - markShiftFour]);//W文字标注  
        var markers = [myShgGapGMakerH,myShgGapGMakerX,myShgGapGMakerD,myShgGapGMakerW]
  } else if (area == "H") {
      var myShgGapHMakerH = {
            paths: {
                  "HHLine0": new makerjs.paths.Line([xh+w+w0+markShiftTwo,yh], [xh+w+w0+markShiftTwo,yh+h]),
                  "HHLine1": new makerjs.paths.Line([xh+w+w0,yh+h], [xh+w+w0+markShiftFour,yh+h]),
            }
      };
      var myShgGapHMakerD = {
            paths: {
                   "DHLine0": new makerjs.paths.Line([xh-w0,yh+h], [xh-w0,yh+h+markShiftFour*2]),
                   "DHLine1": new makerjs.paths.Line([xh-w0+d,yh+h], [xh-w0+d,yh+h+markShiftFour*2]),
                   "DHLine2": new makerjs.paths.Line([xh-w0,yh+h+markShiftFour+markShiftTwo], [xh-w0+d, yh+h+markShiftFour+markShiftTwo]),
            }
      };
      var myShgGapHMakerX = {
            paths: {
                  // "XHLine0": new makerjs.paths.Line([xh - x0, yh - markShiftTwo], [xh, yh - markShiftTwo]),
                  // "XHLine1": new makerjs.paths.Line([xh - x0, yh], [xh - x0, yh - markShiftFour]),
                  // "XHLine2": new makerjs.paths.Line([xh, yh], [xh, yh - markShiftFour]),
            }
      };
      var myShgGapHMakerW = {
            paths: {
                  "WHLine0": new makerjs.paths.Line([xh, yh], [xh, yh - markShiftFour*2]),
                  "WHLine1": new makerjs.paths.Line([xh+w, yh], [xh+w, yh - markShiftFour*2]),
                  "WHLine2": new makerjs.paths.Line([xh, yh-markShiftFour-markShift], [xh+w, yh -markShiftFour-markShift]),
            }
      };
        makerjs.model.addCaption(myShgGapHMakerH, TxtH,[xh+d+markShiftTwo,yh], [xh+d+markShiftTwo,yh+h]);//H文字标注
        makerjs.model.addCaption(myShgGapHMakerX, TxtX, [xh - x0, yh - markShiftFour], [xh, yh - markShiftFour]);//X文字标注
        makerjs.model.addCaption(myShgGapHMakerD, TxtD, [xh-w0,yh+h+markShiftFour*2], [xh-w0+d, yh+h+markShiftFour*2]);//D文字标注  
        makerjs.model.addCaption(myShgGapHMakerW, TxtW, [xh, yh- markShiftFour*2], [xh+w, yh - markShiftFour*2]);//W文字标注  
        var markers = [myShgGapHMakerH,myShgGapHMakerX,myShgGapHMakerD,myShgGapHMakerW]
  } else if (area == "I") {
      var myShgGapIMakerH = {
            paths: {
                  "HILine0": new makerjs.paths.Line([xi-w-w0-markShiftTwo,yi], [xi-w-w0-markShiftTwo,yi+h]),
                  "HILine1": new makerjs.paths.Line([xi-w-w0,yi+h], [xi-w-w0-markShiftFour,yi+h]),
            }
      };
      var myShgGapIMakerD = {
            paths: {
                   "DILine0": new makerjs.paths.Line([xi+w0,yi+h], [xi+w0,yi+h+markShiftFour*2]),
                   "DILine1": new makerjs.paths.Line([xi+w0-d,yi+h], [xi+w0-d,yi+h+markShiftFour*2]),
                   "DILine2": new makerjs.paths.Line([xi+w0,yi+h+markShiftFour+markShiftTwo], [xi+w0-d, yi+h+markShiftFour+markShiftTwo]),
            }
      };
      var myShgGapIMakerX = {
            paths: {
                  "XILine0": new makerjs.paths.Line([xi+ x0, yi - markShiftTwo], [xi, yi - markShiftTwo]),
                  "XILine1": new makerjs.paths.Line([xi + x0, yi], [xi + x0, yi- markShiftFour]),
                  "XILine2": new makerjs.paths.Line([xi, yi], [xi, yi - markShiftFour]),
            }
      };
      var myShgGapIMakerW = {
            paths: {
                  "WILine0": new makerjs.paths.Line([xi-w, yi], [xi-w, yi - markShiftFour]),
                  "WILine1": new makerjs.paths.Line([xi, yi- markShiftTwo], [xi-w, yi -markShiftTwo]),
            }
      };
        makerjs.model.addCaption(myShgGapIMakerH, TxtH,[xi-d-markShiftTwo,yi], [xi-d-markShiftTwo,yi+h]);//H文字标注
        makerjs.model.addCaption(myShgGapIMakerX, TxtX, [xi, yi- markShiftFour], [xi + x0, yi - markShiftFour]);//X文字标注
        makerjs.model.addCaption(myShgGapIMakerD, TxtD, [xi+w0-d, yi+h+markShiftFour*2], [xi+w0,yi+h+markShiftFour*2]);//D文字标注  
        makerjs.model.addCaption(myShgGapIMakerW, TxtW, [xi-w, yi - markShiftFour], [xi, yi- markShiftFour]);//W文字标注  
        var markers = [myShgGapIMakerH,myShgGapIMakerX,myShgGapIMakerD,myShgGapIMakerW]
  }
  console.log("markers||||||||||||||||||=", markers)
  return markers;
};
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function StressHoleGap(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
  var frame = outParam.shapeName;
  console.log("outParam==", outParam)
  console.log("model==", model)
  console.log("inputParam==", inputParam)
  console.log("outParam.shapeName==////////", outParam.shapeName)
  if (typeof (frame) == "undefined") {
        var H=inputParam.H,D=inputParam.D,W=inputParam.W,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
        plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = "Rectangle",
              glassW = outParam.glassW, glassH = outParam.glassH,
              markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
              markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
              d = D*1*myZoom,
              w= W*1*myZoom,
              h= H*1*myZoom,
              r=d/2,
              dd=Math.sqrt(r*r-w/2*w/2).toFixed(),
              h0=h-dd,
              w0=(d-w)/2,
                x0 = X*1*myZoom,
                y0 = Y*1*myZoom;
                if(plate.models===undefined||plate.models===""){
                  console.log("1111111111111111");
                  plate.models={};
                          }        
                          if(cutPlate.models===undefined||cutPlate.models===""){
                              console.log("22222222222");
                              //cutPlate.models={};
                              cutPlate.models={};
                          }
                          if(markers.models===undefined||markers.models===""){            
                              console.log("333333333333");
                              markers.models={};
                          }
     if(d!==0){
      var TxtD="∮"+D;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(w!==0){
      var TxtW=W;
     }
     if(x0!==0){          
     var TxtX=X;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
  } else {
        var H=inputParam.H,D=inputParam.D,W=inputParam.W,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
        plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = outParam.shapeName,
              glassW = outParam.glassW, glassH = outParam.glassH,
              markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
              markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
              d = D*1*myZoom,
              w= W*1*myZoom,
              h= H*1*myZoom,
              r=d/2,
              dd=Math.sqrt(r*r-w/2*w/2).toFixed(),
              h0=h-dd,
              w0=(d-w)/2,
                x0 = X*1*myZoom,
                y0 = Y*1*myZoom;
                if(plate.models===undefined||plate.models===""){
                  console.log("1111111111111111");
                  plate.models={};
                          }        
                          if(cutPlate.models===undefined||cutPlate.models===""){
                              console.log("22222222222");
                              //cutPlate.models={};
                              cutPlate.models={};
                          }
                          if(markers.models===undefined||markers.models===""){            
                              console.log("333333333333");
                              markers.models={};
                          }
                if(d!==0){
                 var TxtD="∮"+D;
                }
                if(h!==0){
                 var TxtH=H;
                }
                if(w!==0){
                 var TxtW=W;
                }
                if(x0!==0){          
                var TxtX=X;
                }
                if(y0!==0){          
                 var TxtY=Y;
                }
  }
  if (frame == "Rectangle") {
        console.log("frame==undefined||||*****")
        //在A区域 计算孔的位置       
        var xa = x0, ya = glassH * 1 - y0,
              //B区域//只有Y标注没有X标注 
              xb = glassW * 1 / 2, yb = glassH * 1 - y0,
              //C区域
              xc = glassW * 1 - x0, yc = glassH * 1 - y0,
              //在G区域 计算孔的位置
              xg = x0, yg = y0,
              //在H区域 计算孔的位置
              //只标注Y
              xh = glassW * 1 / 2, yh = y0,
              //在I区域 计算孔的位置
              xi = glassW * 1 - x0, yi = y0;
        var OffsetParam = {};
  } else if (frame == "IsoscelesTrapezoid") {

  } else if (frame == "RightAngledTrapezoid") {d

  } else if (frame == "OtherTrapezoidsA") {

  } else if (frame == "OtherTrapezoidsB") {

  } else if (frame == "ParallelogramA") {


  } else if (frame == "ParallelogramB") {

  }
  // 坐标参数
  var paramCoordinate = { x0,dd,h0,w0,d,w,h,r,xa, ya, xb, yb, xc, yc, xg, yg, xh, yh, xi, yi };
  const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(model.plate.models)));
  var areaPoint = "";
  console.log("//角7、地弹簧3 ------", allKeys)
  if (allKeys[0] == frame) {
        allKeys.shift();//去掉数组第一个元素
  }
  console.log("//角7、地弹簧3 位置去掉数组第一个元素------", allKeys)
  switch (area) {
        case "1": areaPoint = "A"; break;
        case "2": areaPoint = "B"; break;
        case "3": areaPoint = "C"; break;
        case "7": areaPoint = "G"; break;
        case "8": areaPoint = "H"; break;
        case "9": areaPoint = "I"; break;
  }
  /**
  * 画图
  * 
  */
  var ObjName = "myStressHoleGap" + areaPoint;
  var ObjNameMmyStressHoleGap = "myStressHoleGap" + areaPoint + "Mark";//角标记
  ObjName = creatmyStressHoleGap(areaPoint, paramCoordinate);//图形
  console.log("ObjName===|||||||||", ObjName);
  ObjNameMmyStressHoleGap = creatmyStressHoleGapMark(outParam, areaPoint, paramCoordinate, inputParam, fixedParam, OffsetParam, zoom);//图形
  console.log("ObjNameMmyStressHoleGap===|||||||||角标注", ObjNameMmyStressHoleGap);
  console.log("cutPlate.models====************", cutPlate.models),
        console.log("plate.models====**************", plate.models),
        console.log("markers.models====*************", markers.models),
     // myShgGapAMakerH,myShgGapAMakerX,myShgGapAMakerD,myShgGapAMakerW
        cutPlate.models["myStressHoleGap" + areaPoint + myHolesNum] = ObjName;
  plate.models["myStressHoleGap" + areaPoint + myHolesNum] = ObjName;
  markers.models["myStressHoleGap" + areaPoint + myHolesNum] = ObjName;
  markers.models["myStressHoleGapH" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyStressHoleGap[0];
  markers.models["myStressHoleGapX" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyStressHoleGap[1];
  markers.models["myStressHoleGapD" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyStressHoleGap[2];
  markers.models["myStressHoleGapW" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyStressHoleGap[3];

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default StressHoleGap;