<!--  -->
<template>
    <el-row>
                    <el-col :span="2">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input v-model="param.X" @change="ChangData" placeholder="请输入参数X"></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input v-model="param.Y" @change="ChangData" placeholder="请输入参数Y"></el-input>
                      </div>
                    </el-col>
  </el-row>
</template>
<script>
export default {
    name: "Rectangle",
  props: ["myPost"],
  data () {
    return {
      param:{        
        shapeName:"Rectangle",
          X:0,
          Y:0,
      }
   
    };
  },
  watch: {
    // 监听message数据的变化
    myPost(newValue, oldValue) {
      console.log(`message changed from ${oldValue} to ${newValue}`);
      if(newValue){
        console.log("this.parma.X=",this.param.X);
        console.log("this.parma=",this.param);
        //recieveMsg(shapeName,x,y,h,h1,h2,w,w1,w2)
        this.$emit("sedX",this.param);
      }
      // 在这里可以执行更多的逻辑
    },
  },
  methods:{
    ChangData(){
      console.log('The message is changed.',this.param);
      this.$emit("sedX",this.param)
    },
  }
}
</script>