import { render, staticRenderFns } from "./ParallelogramB.vue?vue&type=template&id=a7c220c6&scoped=true"
import script from "./ParallelogramB.vue?vue&type=script&lang=js"
export * from "./ParallelogramB.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7c220c6",
  null
  
)

export default component.exports