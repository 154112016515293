//外形：其它梯形B
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
//(param,model,fixedParam,this.zoomM)
function OtherTrapezoidsB(inputParam, model, fixedParam, zoom) {

    console.log("RightAngledTrapezoid*inputParam=", inputParam);
    console.log("RightAngledTrapezoid*model=", model);
    console.log("RightAngledTrapezoid*fixedParam=", fixedParam);
    console.log("RightAngledTrapezoid*zoom=", zoom);
    var H = inputParam.H, W = inputParam.W, W1 = inputParam.W1, W2 = inputParam.W2, myZoom = zoom * 1,
        plate = model.plate, markers = model.markers,
        markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
        markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,

        h = H * 1 * myZoom,
        w = W * 1 * myZoom,
        w1 = W1 * 1 * myZoom,
        w2 = W2 * 1 * myZoom,
        // x0 = X*1*myZoom,
        // y0 = Y*1*myZoom;
        x0 = 0, x1 = w1, x2 = w - w2, x3 = w, y0 = 0, y1 = h, y2 = h, y3 = 0;
    var glassH = h, glassW = w;    
            if(h!==0){
             var TxtH=H;
            }
            if(w!==0){
             var TxtW=W;
            }
            if(w1!==0){
             var TxtW1=W1;
            }
            if(w2!==0){
             var TxtW2=W2;
            }
            

    var OtherTrapezoidsB = {
        paths: {
            "LeftLine": new makerjs.paths.Line([x0, y0], [x1, y1]),
            "TopLine": new makerjs.paths.Line([x1, y1], [x2, y2]),
            "RightLine": new makerjs.paths.Line([x2, y2], [x3, y3]),
            "BottomLine": new makerjs.paths.Line([x3, y3], [x0, y0]),
        }
    };


    
    var OtherTrapezoidsBMarkH = {
        paths: {
            "LeftLine": new makerjs.paths.Line([x0 - markShift, y0], [x0 - markShift, y1]),
            "LeftShortUpline": new makerjs.paths.Line([x1, y1], [x0 - 2 * markShift, y1]),
            "LeftShortDnline": new makerjs.paths.Line([x0, y0], [x0 - 2 * markShift, y0]),
        }
    };
    var OtherTrapezoidsBMarkW = {
        paths: {
            "BottomLine": new makerjs.paths.Line([x0, y0 - markShift], [x3, y3 - markShift]),
            "BotmShortLfline": new makerjs.paths.Line([x0, y0], [x0, y0 - 2 * markShift]),
            "BotmShortRTline": new makerjs.paths.Line([x3, y3], [x3, y3 - 2 * markShift]),
        }
    };
    var OtherTrapezoidsBMarkW1 = {
        paths: {
            "UpW1Line": new makerjs.paths.Line([x0, y1 + markShift], [x1, y1 + markShift]),
             "UpW1ShortLfline": new makerjs.paths.Line([x0, y1], [x0, y1 + 2 * markShift]),
            "UpW1ShortRTline": new makerjs.paths.Line([x1, y1], [x1, y1 + 2 * markShift]),
        }
    };
    var OtherTrapezoidsBMarkW2 = {
        paths: {
            "UpW2Line": new makerjs.paths.Line([x2, y2 + markShift], [x3, y2 + markShift]),
            "UpW2ShortLfline": new makerjs.paths.Line([x2, y2], [x2, y2 + 2 * markShift]),
            "UpW2ShortRTline": new makerjs.paths.Line([x3, y2], [x3, y2 + 2 * markShift]),
            "UpW2RightLine": new makerjs.paths.Line([x3, y3], [x3, y2]),
            "UpW2RightShortDnLine": new makerjs.paths.Line([x3, y3], [x3+2*markShift, y3]),
            "UpW2RightShortUpLine": new makerjs.paths.Line([x3-markShift, y2], [x3+2*markShift, y2]),
        }
    };
    makerjs.model.addCaption(OtherTrapezoidsBMarkW, TxtW, [x0, y0 - 2 * markShift], [x3, y3 - 2 * markShift]);//长度文字  
    makerjs.model.addCaption(OtherTrapezoidsBMarkW1, TxtW1, [x0, y1 + 2 * markShift], [x1, y1 + 2 * markShift]);//长度文字  
    makerjs.model.addCaption(OtherTrapezoidsBMarkW2, TxtW2, [x2, y2 + 2 * markShift], [x3, y2 + 2 * markShift]);//长度文字
    makerjs.model.addCaption(OtherTrapezoidsBMarkH, TxtH, [x0 - 2 * markShift, y0], [x0 - 2 * markShift, y1]);//宽度文字

    plate = {
        models: {
            OtherTrapezoidsB
        },
    };
    markers = {
        models: {
            OtherTrapezoidsB,
            OtherTrapezoidsBMarkW,
            OtherTrapezoidsBMarkW1,
            OtherTrapezoidsBMarkW2,
            OtherTrapezoidsBMarkH,
        }
    };
    /*****************************************************************************/
    return { plate, markers, glassW, glassH }
};
export default OtherTrapezoidsB;