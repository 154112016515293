
//缺8、椭圆
import makerjs from "makerjs";
// 矩形等去角 连线函数
function creatmyEllipseGap(area, param) {
  console.log("是否调用此函数creatOneCorner（）");
  console.log("是否调用此函数creatOneCorner（）param=", param);
  var xa = param.xa, ya = param.ya,xb = param.xb, yb = param.yb, xc = param.xc, yc = param.yc, 
  xd = param.xd, yd = param.yd,xe = param.xe, ye = param.ye, xf = param.xf, yf = param.yf,   
  xg = param.xg, yg = param.yg,xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,  
  x = param.x0, y = param.y0, rx = param.rx,ry = param.ry; 
  var myEgGapAEllipse=new makerjs.models.Ellipse(rx, ry);
  if (area == "A") {
   myEgGapAEllipse.origin=[xa,ya];    
  } else if (area == "B") { 
   myEgGapAEllipse.origin=[xb,yb];
} else if (area == "C") {   
   myEgGapAEllipse.origin=[xc,yc];
  } else if (area == "D") {   
   myEgGapAEllipse.origin=[xd,yd];
  } else if (area == "E") {   
   myEgGapAEllipse.origin=[xe,ye];
  } else if (area == "F") {   
   myEgGapAEllipse.origin=[xf,yf];
  } else if (area == "G") {   
   myEgGapAEllipse.origin=[xg,yg];
  } else if (area == "H") {
   myEgGapAEllipse.origin=[xh,yh]; 
  } else if (area == "I") {
   myEgGapAEllipse.origin=[xi,yi];  
  }
  return myEgGapAEllipse;
};
// 矩形等去角标记 连线函数      
function creatmyEllipseGapMark(outParam, area, param, inputParam, fixedParam, OffsetParam, zoom) {
  console.log("是否调用此函数creatCornerMark()");
  console.log("是否调用此函数param", param);
  console.log("是否调用此函数outParam", outParam);
  console.log("是否调用此函数inputParam", inputParam);
  console.log("是否调用此函数OffsetParam", OffsetParam);
  var Rx=inputParam.Rx,Ry=inputParam.Ry,X=inputParam.X,Y=inputParam.Y, myZoom = zoom * 1,
  xa = param.xa, ya = param.ya,xb = param.xb, yb = param.yb, xc = param.xc, yc = param.yc,   
  xd = param.xd, yd = param.yd,xe = param.xe, ye = param.ye, xf = param.xf, yf = param.yf,   
  xg = param.xg, yg = param.yg,xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,  
  x = param.x0, y = param.y0, rx = param.rx,ry = param.ry, 
//   x0,rd,r,w,w1,h,d 
        glassW = outParam.glassW, glassH = outParam.glassH,
       markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
        markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
        rx= Rx*1*myZoom,
      ry= Ry*1*myZoom, 
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        if(rx!==0){
         var TxtRx=Rx;
        }
        if(ry!==0){
         var TxtRy=Ry;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }

  if (area == "A") {
   var myCopperClipAMakerX = {
         paths: {
               "XALine0": new makerjs.paths.Line([xa+0,ya], [xa+0, ya+y0+markShiftFour]),
               "XALine1": new makerjs.paths.Line([xa-x0,ya+y0], [xa-x0, ya+y0+markShiftFour]),
               "XALine2": new makerjs.paths.Line([xa-x0,ya+y0+markShiftTwo], [xa+0, ya+y0+markShiftTwo]),
         }
   };
   var myCopperClipAMakerY = {
         paths: {
               "YALine0": new makerjs.paths.Line([xa-x0-markShiftFour,ya+y0], [xa-x0, ya+y0]),
               "YALine1": new makerjs.paths.Line([xa-x0-markShiftFour,ya], [xa, ya]),
               "YALine2": new makerjs.paths.Line([xa-x0-markShiftTwo,ya+y0], [xa-x0-markShiftTwo, ya]),
         }
   };
      var myCopperClipAMakerRx = {
            paths: {
                  "RxALine0": new makerjs.paths.Line([xa-rx,ya], [xa-rx, ya-ry-markShiftFour]),
                  "RxALine1": new makerjs.paths.Line([xa+rx,ya], [xa+rx, ya-ry-markShiftFour]),
                  "RxALine2": new makerjs.paths.Line([xa-rx,ya-ry-markShiftTwo], [xa+rx, ya-ry-markShiftTwo]),
            }
      };
      var myCopperClipAMakerRy = {
            paths: {
               "RyALine0": new makerjs.paths.Line([xa,ya-ry], [xa+rx+markShiftFour, ya-ry]),
               "RyALine1": new makerjs.paths.Line([xa,ya+ry], [xa+rx+markShiftFour, ya+ry]),
               "RyALine2": new makerjs.paths.Line([xa+rx+markShiftTwo,ya-ry], [xa+rx+markShiftTwo,ya+ry]),
                  }
      };
      makerjs.model.addCaption(myCopperClipAMakerX, TxtX, [xa-x0,ya+y0+markShiftFour], [xa, ya+y0+markShiftFour]);//X文字标注
      makerjs.model.addCaption(myCopperClipAMakerY, TxtY, [xa-x0-markShiftFour, ya],[xa-x0-markShiftFour,ya+y0]);//Y文字标注
      makerjs.model.addCaption(myCopperClipAMakerRx, TxtRx,[xa-rx,ya-ry-markShiftFour], [xa+rx, ya-ry-markShiftFour]);//Rx文字标注  
      makerjs.model.addCaption(myCopperClipAMakerRy, TxtRy,[xa+rx+markShiftFour,ya-ry], [xa+rx+markShiftFour, ya+ry]);//Ry文字标注 
      var markers = [myCopperClipAMakerX,myCopperClipAMakerY,myCopperClipAMakerRx,myCopperClipAMakerRy];
} else if (area == "B") {
   var myCopperClipBMakerX = {
      paths: {
            // "XBLine0": new makerjs.paths.Line([xb+0,yb], [xb+0, yb+y0+markShiftFour]),
            // "XBLine1": new makerjs.paths.Line([xb-x0,yb+y0], [xb-x0, yb+y0+markShiftFour]),
            // "XBLine2": new makerjs.paths.Line([xb-x0,yb+y0+markShiftTwo], [xb+0, yb+y0+markShiftTwo]),
      }
};
var myCopperClipBMakerY = {
      paths: {
            "YBLine0": new makerjs.paths.Line([xb-rx-markShiftFour,yb+y0], [xb-x0, yb+y0]),
            "YBLine1": new makerjs.paths.Line([xb-rx-markShiftFour,yb], [xb, yb]),
            "YBLine2": new makerjs.paths.Line([xb-rx-markShiftTwo,yb+y0], [xb-rx-markShiftTwo, yb]),
      }
};
   var myCopperClipBMakerRx = {
         paths: {
               "RxBLine0": new makerjs.paths.Line([xb-rx,yb], [xb-rx, yb-ry-markShiftFour]),
               "RxBLine1": new makerjs.paths.Line([xb+rx,yb], [xb+rx, yb-ry-markShiftFour]),
               "RxBLine2": new makerjs.paths.Line([xb-rx,yb-ry-markShiftTwo], [xb+rx, yb-ry-markShiftTwo]),
         }
   };
   var myCopperClipBMakerRy = {
         paths: {
            "RyBLine0": new makerjs.paths.Line([xb,yb-ry], [xb+rx+markShiftFour, yb-ry]),
            "RyBLine1": new makerjs.paths.Line([xb,yb+ry], [xb+rx+markShiftFour, yb+ry]),
            "RyBLine2": new makerjs.paths.Line([xb+rx+markShiftTwo,yb-ry], [xb+rx+markShiftTwo,yb+ry]),
               }
   };
   makerjs.model.addCaption(myCopperClipBMakerX, TxtX, [xb-x0,yb+y0+markShiftFour], [xb, yb+y0+markShiftFour]);//X文字标注
   makerjs.model.addCaption(myCopperClipBMakerY, TxtY, [xb-rx-markShiftFour, yb],[xb-rx-markShiftFour,yb+y0]);//Y文字标注
   makerjs.model.addCaption(myCopperClipBMakerRx, TxtRx,[xb-rx,yb-ry-markShiftFour], [xb+rx, yb-ry-markShiftFour]);//Rx文字标注  
   makerjs.model.addCaption(myCopperClipBMakerRy, TxtRy,[xb+rx+markShiftFour,yb-ry], [xb+rx+markShiftFour, yb+ry]);//Ry文字标注 
   var markers = [myCopperClipBMakerX,myCopperClipBMakerY,myCopperClipBMakerRx,myCopperClipBMakerRy];
} else if (area == "C") {
   var myCopperClipCMakerX = {
      paths: {
            "XCLine0": new makerjs.paths.Line([xc,yc], [xc, yc+y0+markShiftFour]),
            "XCLine1": new makerjs.paths.Line([xc+x0,yc+y0], [xc+x0, yc+y0+markShiftFour]),
            "XCLine2": new makerjs.paths.Line([xc+x0,yc+y0+markShiftTwo], [xc, yc+y0+markShiftTwo]),
      }
};
var myCopperClipCMakerY = {
      paths: {
            "YCLine0": new makerjs.paths.Line([xc+x0+markShiftFour,yc+y0], [xc+x0, yc+y0]),
            "YCLine1": new makerjs.paths.Line([xc+x0+markShiftFour,yc], [xc, yc]),
            "YCLine2": new makerjs.paths.Line([xc+x0+markShiftTwo,yc+y0], [xc+x0+markShiftTwo, yc]),
      }
};
   var myCopperClipCMakerRx = {
         paths: {
               "RxCLine0": new makerjs.paths.Line([xc+rx,yc], [xc+rx, yc-ry-markShiftFour]),
               "RxCLine1": new makerjs.paths.Line([xc-rx,yc], [xc-rx, yc-ry-markShiftFour]),
               "RxCLine2": new makerjs.paths.Line([xc+rx,yc-ry-markShiftTwo], [xc-rx, yc-ry-markShiftTwo]),
         }
   };
   var myCopperClipCMakerRy = {
         paths: {
            "RyCLine0": new makerjs.paths.Line([xc,yc-ry], [xc-rx-markShiftFour, yc-ry]),
            "RyCLine1": new makerjs.paths.Line([xc,yc+ry], [xc-rx-markShiftFour, yc+ry]),
            "RyCLine2": new makerjs.paths.Line([xc-rx-markShiftTwo,yc-ry], [xc-rx-markShiftTwo,yc+ry]),
               }
   };
   makerjs.model.addCaption(myCopperClipCMakerX, TxtX, [xc, yc+y0+markShiftFour], [xc+x0,yc+y0+markShiftFour]);//X文字标注
   makerjs.model.addCaption(myCopperClipCMakerY, TxtY, [xc+x0+markShiftFour, yc],[xc+x0+markShiftFour,yc+y0]);//Y文字标注
   makerjs.model.addCaption(myCopperClipCMakerRx, TxtRx, [xc-rx, yc-ry-markShiftFour],[xc+rx,yc-ry-markShiftFour]);//Rx文字标注  
   makerjs.model.addCaption(myCopperClipCMakerRy, TxtRy,[xc-rx-markShiftFour,yc-ry], [xc-rx-markShiftFour, yc+ry]);//Ry文字标注 
   var markers = [myCopperClipCMakerX,myCopperClipCMakerY,myCopperClipCMakerRx,myCopperClipCMakerRy];
} else if (area == "D") {
   var myCopperClipDMakerX = {
      paths: {
            "XDLine0": new makerjs.paths.Line([xd+0,yd], [xd+0, yd+y0+markShiftFour]),
            "XDLine1": new makerjs.paths.Line([xd-x0,yd+y0], [xd-x0, yd+y0+markShiftFour]),
            // "XDLine2": new makerjs.paths.Line([xd-x0,yd+y0+markShiftTwo], [xd+0, yd+y0+markShiftTwo]),
      }
};
var myCopperClipDMakerY = {
      paths: {
            // "YDLine0": new makerjs.paths.Line([xd-x0-markShiftFour,yd+y0], [xd-x0, yd+y0]),
            "YDLine1": new makerjs.paths.Line([xd-x0,yd], [xd, yd]),
            // "YDLine2": new makerjs.paths.Line([xd-x0-markShiftTwo,yd+y0], [xd-x0-markShiftTwo, yd]),
      }
};
   var myCopperClipDMakerRx = {
         paths: {
               "RxDLine0": new makerjs.paths.Line([xd-rx,yd], [xd-rx, yd-ry-markShiftFour]),
               "RxDLine1": new makerjs.paths.Line([xd+rx,yd], [xd+rx, yd-ry-markShiftFour]),
               "RxDLine2": new makerjs.paths.Line([xd-rx,yd-ry-markShiftTwo], [xd+rx, yd-ry-markShiftTwo]),
         }
   };
   var myCopperClipDMakerRy = {
         paths: {
            "RyDLine0": new makerjs.paths.Line([xd,yd-ry], [xd+rx+markShiftFour, yd-ry]),
            "RyDLine1": new makerjs.paths.Line([xd,yd+ry], [xd+rx+markShiftFour, yd+ry]),
            "RyDLine2": new makerjs.paths.Line([xd+rx+markShiftTwo,yd-ry], [xd+rx+markShiftTwo,yd+ry]),
               }
   };
   makerjs.model.addCaption(myCopperClipDMakerX, TxtX, [xd-x0,yd+y0+markShift], [xd, yd+y0+markShift]);//X文字标注
   makerjs.model.addCaption(myCopperClipDMakerY, TxtY, [xd-x0-markShiftFour, yd],[xd-x0-markShiftFour,yd+y0]);//Y文字标注
   makerjs.model.addCaption(myCopperClipDMakerRx, TxtRx,[xd-rx,yd-ry-markShiftFour], [xd+rx, yd-ry-markShiftFour]);//Rx文字标注  
   makerjs.model.addCaption(myCopperClipDMakerRy, TxtRy,[xd+rx+markShiftFour,yd-ry], [xd+rx+markShiftFour, yd+ry]);//Ry文字标注 
   var markers = [myCopperClipDMakerX,myCopperClipDMakerY,myCopperClipDMakerRx,myCopperClipDMakerRy];
} else if (area == "E") {
   var myCopperClipEMakerX = {
      paths: {
            // "XELine0": new makerjs.paths.Line([xe+0,ye], [xe+0, ye+y0+markShiftFour]),
            // "XELine1": new makerjs.paths.Line([xe-x0,ye+y0], [xe-x0, ye+y0+markShiftFour]),
            // "XELine2": new makerjs.paths.Line([xe-x0,ye+y0+markShiftTwo], [xe+0, ye+y0+markShiftTwo]),
      }
};
var myCopperClipEMakerY = {
      paths: {
            // "YELine0": new makerjs.paths.Line([xe-x0-markShiftFour,ye+y0], [xe-x0, ye+y0]),
            // "YELine1": new makerjs.paths.Line([xe-x0-markShiftFour,ye], [xe, ye]),
            // "YELine2": new makerjs.paths.Line([xe-x0-markShiftTwo,ye+y0], [xe-x0-markShiftTwo, ye]),
      }
};
   var myCopperClipEMakerRx = {
         paths: {
               "RxELine0": new makerjs.paths.Line([xe-rx,ye], [xe-rx, ye-ry-markShiftFour]),
               "RxELine1": new makerjs.paths.Line([xe+rx,ye], [xe+rx, ye-ry-markShiftFour]),
               "RxELine2": new makerjs.paths.Line([xe-rx,ye-ry-markShiftTwo], [xe+rx, ye-ry-markShiftTwo]),
         }
   };
   var myCopperClipEMakerRy = {
         paths: {
            "RyELine0": new makerjs.paths.Line([xe,ye-ry], [xe+rx+markShiftFour, ye-ry]),
            "RyELine1": new makerjs.paths.Line([xe,ye+ry], [xe+rx+markShiftFour, ye+ry]),
            "RyELine2": new makerjs.paths.Line([xe+rx+markShiftTwo,ye-ry], [xe+rx+markShiftTwo,ye+ry]),
               }
   };
   makerjs.model.addCaption(myCopperClipEMakerX, TxtX, [xe-x0,ye+y0+markShiftFour], [xe, ye+y0+markShiftFour]);//X文字标注
   makerjs.model.addCaption(myCopperClipEMakerY, TxtY, [xe-x0-markShiftFour, ye],[xe-x0-markShiftFour,ye+y0]);//Y文字标注
   makerjs.model.addCaption(myCopperClipEMakerRx, TxtRx,[xe-rx,ye-ry-markShiftFour], [xe+rx, ye-ry-markShiftFour]);//Rx文字标注  
   makerjs.model.addCaption(myCopperClipEMakerRy, TxtRy,[xe+rx+markShiftFour,ye-ry], [xe+rx+markShiftFour, ye+ry]);//Ry文字标注 
   var markers = [myCopperClipEMakerX,myCopperClipEMakerY,myCopperClipEMakerRx,myCopperClipEMakerRy];
} else if (area == "F") {
   var myCopperClipFMakerX = {
      paths: {
            "XFLine0": new makerjs.paths.Line([xf,yf], [xf, yf+y0+markShiftFour]),
            "XFLine1": new makerjs.paths.Line([xf+x0,yf+y0], [xf+x0, yf+y0+markShiftFour]),
            // "XFLine2": new makerjs.paths.Line([xf+x0,yf+y0+markShiftTwo], [xf, yf+y0+markShiftTwo]),
      }
};
var myCopperClipFMakerY = {
      paths: {
            // "YFLine0": new makerjs.paths.Line([xf-x0-markShiftFour,yf+y0], [xf-x0, yf+y0]),
            "YFLine1": new makerjs.paths.Line([xf,yf], [xf+x0, yf]),
            // "YFLine2": new makerjs.paths.Line([xf-x0-markShiftTwo,yf+y0], [xf-x0-markShiftTwo, yf]),
      }
};
   var myCopperClipFMakerRx = {
         paths: {
               "RxFLine0": new makerjs.paths.Line([xf-rx,yf], [xf-rx, yf-ry-markShiftFour]),
               "RxFLine1": new makerjs.paths.Line([xf+rx,yf], [xf+rx, yf-ry-markShiftFour]),
               "RxFLine2": new makerjs.paths.Line([xf-rx,yf-ry-markShiftTwo], [xf+rx, yf-ry-markShiftTwo]),
         }
   };
   var myCopperClipFMakerRy = {
         paths: {
            "RyFLine0": new makerjs.paths.Line([xf,yf-ry], [xf-rx-markShiftFour, yf-ry]),
            "RyFLine1": new makerjs.paths.Line([xf,yf+ry], [xf-rx-markShiftFour, yf+ry]),
            "RyFLine2": new makerjs.paths.Line([xf-rx-markShiftTwo,yf-ry], [xf-rx-markShiftTwo,yf+ry]),
               }
   };
   makerjs.model.addCaption(myCopperClipFMakerX, TxtX, [xf, yf+y0+markShift], [xf+x0,yf+y0+markShift]);//X文字标注
   makerjs.model.addCaption(myCopperClipFMakerY, TxtY, [xf-x0-markShiftFour, yf],[xf-x0-markShiftFour,yf+y0]);//Y文字标注
   makerjs.model.addCaption(myCopperClipFMakerRx, TxtRx,[xf-rx,yf-ry-markShiftFour], [xf+rx, yf-ry-markShiftFour]);//Rx文字标注  
   makerjs.model.addCaption(myCopperClipFMakerRy, TxtRy,[xf-rx-markShiftFour,yf-ry], [xf-rx-markShiftFour, yf+ry]);//Ry文字标注 
   var markers = [myCopperClipFMakerX,myCopperClipFMakerY,myCopperClipFMakerRx,myCopperClipFMakerRy];
} else if (area == "G") {
   var myCopperClipGMakerX = {
      paths: {
            "XGLine0": new makerjs.paths.Line([xg+0,yg], [xg+0, yg-y0-markShiftFour]),
            "XGLine1": new makerjs.paths.Line([xg-x0,yg-y0], [xg-x0, yg-y0-markShiftFour]),
            "XGLine2": new makerjs.paths.Line([xg-x0,yg-y0-markShiftTwo], [xg+0, yg-y0-markShiftTwo]),
      }
};
var myCopperClipGMakerY = {
      paths: {
            "YGLine0": new makerjs.paths.Line([xg-x0-markShiftFour,yg-y0], [xg-x0, yg-y0]),
            "YGLine1": new makerjs.paths.Line([xg-x0-markShiftFour,yg], [xg, yg]),
            "YGLine2": new makerjs.paths.Line([xg-x0-markShiftTwo,yg-y0], [xg-x0-markShiftTwo, yg]),
      }
};
   var myCopperClipGMakerRx = {
         paths: {
               "RxGLine0": new makerjs.paths.Line([xg-rx,yg], [xg-rx, yg+ry+markShiftFour]),
               "RxGLine1": new makerjs.paths.Line([xg+rx,yg], [xg+rx, yg+ry+markShiftFour]),
               "RxGLine2": new makerjs.paths.Line([xg-rx,yg+ry+markShiftTwo], [xg+rx, yg+ry+markShiftTwo]),
         }
   };
   var myCopperClipGMakerRy = {
         paths: {
            "RyGLine0": new makerjs.paths.Line([xg,yg+ry], [xg+rx+markShiftFour, yg+ry]),
            "RyGLine1": new makerjs.paths.Line([xg,yg-ry], [xg+rx+markShiftFour, yg-ry]),
            "RyGLine2": new makerjs.paths.Line([xg+rx+markShiftTwo,yg+ry], [xg+rx+markShiftTwo,yg-ry]),
               }
   };
   makerjs.model.addCaption(myCopperClipGMakerX, TxtX, [xg-x0,yg-y0-markShiftFour], [xg, yg-y0-markShiftFour]);//X文字标注
   makerjs.model.addCaption(myCopperClipGMakerY, TxtY,[xg-x0-markShiftFour,yg-y0], [xg-x0-markShiftFour, yg]);//Y文字标注
   makerjs.model.addCaption(myCopperClipGMakerRx, TxtRx,[xg-rx,yg+ry+markShiftFour], [xg+rx, yg+ry+markShiftFour]);//Rx文字标注  
   makerjs.model.addCaption(myCopperClipGMakerRy, TxtRy,[xg+rx+markShiftFour, yg-ry],[xg+rx+markShiftFour,yg+ry]);//Ry文字标注 
   var markers = [myCopperClipGMakerX,myCopperClipGMakerY,myCopperClipGMakerRx,myCopperClipGMakerRy];
  } else if (area == "H") {
   var myCopperClipHMakerX = {
      paths: {
            // "XHLine0": new makerjs.paths.Line([xh+0,yh], [xh+0, yh+y0+markShiftFour]),
            // "XHLine1": new makerjs.paths.Line([xh-x0,yh+y0], [xh-x0, yh+y0+markShiftFour]),
            // "XHLine2": new makerjs.paths.Line([xh-x0,yh+y0+markShiftTwo], [xh+0, yh+y0+markShiftTwo]),
      }
};
var myCopperClipHMakerY = {
      paths: {
            "YHLine0": new makerjs.paths.Line([xh-rx-markShiftFour,yh-y0], [xh-x0, yh-y0]),
            "YHLine1": new makerjs.paths.Line([xh-rx-markShiftFour,yh], [xh, yh]),
            "YHLine2": new makerjs.paths.Line([xh-rx-markShiftTwo,yh-y0], [xh-rx-markShiftTwo, yh]),
      }
};
   var myCopperClipHMakerRx = {
         paths: {
               "RxHLine0": new makerjs.paths.Line([xh-rx,yh], [xh-rx, yh+ry+markShiftFour]),
               "RxHLine1": new makerjs.paths.Line([xh+rx,yh], [xh+rx, yh+ry+markShiftFour]),
               "RxHLine2": new makerjs.paths.Line([xh-rx,yh+ry+markShiftTwo], [xh+rx, yh+ry+markShiftTwo]),
         }
   };
   var myCopperClipHMakerRy = {
         paths: {
            "RyHLine0": new makerjs.paths.Line([xh,yh+ry], [xh+rx+markShiftFour, yh+ry]),
            "RyHLine1": new makerjs.paths.Line([xh,yh-ry], [xh+rx+markShiftFour, yh-ry]),
            "RyHLine2": new makerjs.paths.Line([xh+rx+markShiftTwo,yh+ry], [xh+rx+markShiftTwo,yh-ry]),
               }
   };
   makerjs.model.addCaption(myCopperClipHMakerX, TxtX, [xh-x0,yh-y0-markShiftFour], [xh, yh-y0-markShiftFour]);//X文字标注
   makerjs.model.addCaption(myCopperClipHMakerY, TxtY,[xh-rx-markShiftFour,yh-y0], [xh-rx-markShiftFour, yh]);//Y文字标注
   makerjs.model.addCaption(myCopperClipHMakerRx, TxtRx,[xh-rx,yh+ry+markShiftFour], [xh+rx, yh+ry+markShiftFour]);//Rx文字标注  
   makerjs.model.addCaption(myCopperClipHMakerRy, TxtRy, [xh+rx+markShiftFour, yh-ry],[xh+rx+markShiftFour,yh+ry]);//Ry文字标注 
   var markers = [myCopperClipHMakerX,myCopperClipHMakerY,myCopperClipHMakerRx,myCopperClipHMakerRy];
  } else if (area == "I") {
   var myCopperClipIMakerX = {
      paths: {
            "XILine0": new makerjs.paths.Line([xi,yi], [xi, yi-y0-markShiftFour]),
            "XILine1": new makerjs.paths.Line([xi+x0,yi-y0], [xi+x0, yi-y0-markShiftFour]),
            "XILine2": new makerjs.paths.Line([xi+x0,yi-y0-markShiftTwo], [xi, yi-y0-markShiftTwo]),
      }
};
var myCopperClipIMakerY = {
      paths: {
            "YILine0": new makerjs.paths.Line([xi+x0+markShiftFour,yi-y0], [xi+x0, yi-y0]),
            "YILine1": new makerjs.paths.Line([xi+x0+markShiftFour,yi], [xi, yi]),
            "YILine2": new makerjs.paths.Line([xi+x0+markShiftTwo,yi-y0], [xi+x0+markShiftTwo, yi]),
      }
};
   var myCopperClipIMakerRx = {
         paths: {
               "RxILine0": new makerjs.paths.Line([xi+rx,yi], [xi+rx, yi+ry+markShiftFour]),
               "RxILine1": new makerjs.paths.Line([xi-rx,yi], [xi-rx, yi+ry+markShiftFour]),
               "RxILine2": new makerjs.paths.Line([xi+rx,yi+ry+markShiftTwo], [xi-rx, yi+ry+markShiftTwo]),
         }
   };
   var myCopperClipIMakerRy = {
         paths: {
            "RyILine0": new makerjs.paths.Line([xi,yi+ry], [xi-rx-markShiftFour, yi+ry]),
            "RyILine1": new makerjs.paths.Line([xi,yi-ry], [xi-rx-markShiftFour, yi-ry]),
            "RyILine2": new makerjs.paths.Line([xi-rx-markShiftTwo,yi+ry], [xi-rx-markShiftTwo,yi-ry]),
               }
   };
   makerjs.model.addCaption(myCopperClipIMakerX, TxtX, [xi, yi-y0-markShiftFour], [xi+x0,yi-y0-markShiftFour]);//X文字标注
   makerjs.model.addCaption(myCopperClipIMakerY, TxtY,[xi+x0+markShiftFour,yi-y0], [xi+x0+markShiftFour, yi]);//Y文字标注
   makerjs.model.addCaption(myCopperClipIMakerRx, TxtRx, [xi-rx, yi+ry+markShiftFour],[xi+rx,yi+ry+markShiftFour]);//Rx文字标注  
   makerjs.model.addCaption(myCopperClipIMakerRy, TxtRy, [xi-rx-markShiftFour, yi-ry],[xi-rx-markShiftFour,yi+ry]);//Ry文字标注 
   var markers = [myCopperClipIMakerX,myCopperClipIMakerY,myCopperClipIMakerRx,myCopperClipIMakerRy];
  }
  console.log("markers||||||||||||||||||=", markers)
  return markers;
};
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function EllipseGap(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){

   var frame = outParam.shapeName;
      console.log("outParam==", outParam)
      console.log("model==", model)
      console.log("inputParam==", inputParam)
      console.log("outParam.shapeName==////////", outParam.shapeName)
      if (typeof (frame) == "undefined") {
            var Rx=inputParam.Rx,Ry=inputParam.Ry,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
            plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = "Rectangle",
                  glassW = outParam.glassW, glassH = outParam.glassH,
                  markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
                  markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
                  rx= Rx*1*myZoom,
                  ry= Ry*1*myZoom, 
                    x0 = X*1*myZoom,
                    y0 = Y*1*myZoom;
                    if(plate.models===undefined||plate.models===""){
                        console.log("1111111111111111");
                        plate.models={};
                                }        
                                if(cutPlate.models===undefined||cutPlate.models===""){
                                    console.log("22222222222");
                                    //cutPlate.models={};
                                    cutPlate.models={};
                                }
                                if(markers.models===undefined||markers.models===""){            
                                    console.log("333333333333");
                                    markers.models={};
                                }
                    if(rx!==0){
                     var TxtRx=Rx;
                    }
                    if(ry!==0){
                     var TxtRy=Ry;
                    }
                    if(x0!==0){          
                    var TxtX=X;
                    }
                    if(y0!==0){          
                     var TxtY=Y;
                    }
      } else {
            var Rx=inputParam.Rx,Ry=inputParam.Ry,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
            plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = outParam.shapeName,
                  glassW = outParam.glassW, glassH = outParam.glassH,
                  markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
                  markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
                  rx= Rx*1*myZoom,
      ry= Ry*1*myZoom, 
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        if(plate.models===undefined||plate.models===""){
            console.log("1111111111111111");
            plate.models={};
                    }        
                    if(cutPlate.models===undefined||cutPlate.models===""){
                        console.log("22222222222");
                        //cutPlate.models={};
                        cutPlate.models={};
                    }
                    if(markers.models===undefined||markers.models===""){            
                        console.log("333333333333");
                        markers.models={};
                    }
        if(rx!==0){
         var TxtRx=Rx;
        }
        if(ry!==0){
         var TxtRy=Ry;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
      }
      if (frame == "Rectangle") {
            console.log("frame==undefined||||*****")
          //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
            var OffsetParam = {};
      } else if (frame == "IsoscelesTrapezoid") {
    
      } else if (frame == "RightAngledTrapezoid") {d
    
      } else if (frame == "OtherTrapezoidsA") {
    
      } else if (frame == "OtherTrapezoidsB") {
    
      } else if (frame == "ParallelogramA") {
    
    
      } else if (frame == "ParallelogramB") {
    
      }
      // 坐标参数
      var paramCoordinate = { x0,y0,rx,ry,xa, ya, xb, yb, xc, yc, xd, yd, xe, ye, xf, yf, xg, yg, xh, yh, xi, yi };
      const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(model.plate.models)));
      var areaPoint = "";
      console.log("//角7、地弹簧3 ------", allKeys)
      if (allKeys[0] == frame) {
            allKeys.shift();//去掉数组第一个元素
      }
      console.log("//角7、地弹簧3 位置去掉数组第一个元素------", allKeys)
      switch (area) {
            case "1": areaPoint = "A"; break;
            case "2": areaPoint = "B"; break;
            case "3": areaPoint = "C"; break;
            case "4": areaPoint = "D"; break;
            case "5": areaPoint = "E"; break;
            case "6": areaPoint = "F"; break;
            case "7": areaPoint = "G"; break;
            case "8": areaPoint = "H"; break;
            case "9": areaPoint = "I"; break;
      }
      /**
      * 画图
      * 
      */
      var ObjName = "myEllipseGap" + areaPoint;
      var ObjNameMmyEllipseGap = "myEllipseGap" + areaPoint + "Mark";//角标记
      ObjName = creatmyEllipseGap(areaPoint, paramCoordinate);//图形
      console.log("ObjName===|||||||||", ObjName);
      ObjNameMmyEllipseGap = creatmyEllipseGapMark(outParam, areaPoint, paramCoordinate, inputParam, fixedParam, OffsetParam, zoom);//图形
      console.log("ObjNameMmyEllipseGap===|||||||||角标注", ObjNameMmyEllipseGap);
      console.log("cutPlate.models====************", cutPlate.models),
            console.log("plate.models====**************", plate.models),
            console.log("markers.models====*************", markers.models),
         // myCopperClipAMakerX,myCopperClipAMakerY,myCopperClipAMakerRx,myCopperClipAMakerRy
            cutPlate.models["myEllipseGap" + areaPoint + myHolesNum] = ObjName;
      plate.models["myEllipseGap" + areaPoint + myHolesNum] = ObjName;
      markers.models["myEllipseGap" + areaPoint + myHolesNum] = ObjName;
      markers.models["myEllipseGapX" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyEllipseGap[0];
      markers.models["myEllipseGapY" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyEllipseGap[1];
      markers.models["myEllipseGapRx" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyEllipseGap[2];
      markers.models["myEllipseGapRy" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyEllipseGap[3];

myHolesNum++;
return{plate,cutPlate,markers, myHolesNum}
};
export default EllipseGap;