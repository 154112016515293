//角4、圆角2
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
//已知圆上两个点坐标及圆的半径，求圆心坐标函数
import RectangleMark from "./RectangleMark";
//已知圆上两个点坐标及圆的半径，求圆心坐标函数
function Circle_Center(x1, y1, x2, y2, r) {
  var a = 0, b = 0, b2 = 0;
  console.log("Circle_Center计算圆心坐标函数")
  //Math.pow((x1-a),2)+Math.pow((y1-b),2) == Math.pow(r,2)
  //Math.pow((x2-a),2)+Math.pow((y2-b),2) == Math.pow(r,2)
  var c1 = (x2 * x2 - x1 * x1 + y2 * y2 - y1 * y1) / (2 * (x2 - x1));
  var c2 = (y2 - y1) / (x2 - x1);//
  var A = (c2 * c2 + 1);
  var B = (2 * x1 * c2 - 2 * c1 * c2 - 2 * y1);
  var C = x1 * x1 - 2 * x1 * c1 + c1 * c1 + y1 * y1 - r * r;
  b = (-B + Math.sqrt(B * B - 4 * A * C)) / (2 * A);
  b2 = (-B - Math.sqrt(B * B - 4 * A * C)) / (2 * A);
  a = c1 - c2 * b;
  var arr = [a, b, b2];
  console.log("arr==", arr);
  return arr;

};
//获取角的字符串判断是哪个角
function getStrs(area, strs) {
  console.log("是否调用此函数getStrs（）")
  var arrA = [];
  var TA = strs[0].slice(9);
  TA = TA + area;
  arrA = Array.from(TA);
  console.log("是否调用此函数getStrs()arrA==", arrA)
  arrA = arrA.sort();
  arrA = arrA.join("");
  console.log("是否调用此函数getStrs()areaPoint arrA==", arrA)
  return arrA;
};
// 矩形等去角 连线函数
function creatmyFillet2(area, param) {
  console.log("是否调用此函数creatOneCorner（）");
  console.log("是否调用此函数creatOneCorner（）param=", param);
  var xa = param.xa, ya = param.ya, xc = param.xc, yc = param.yc,
    xg = param.xg, yg = param.yg, xi = param.xi, yi = param.yi,
    w = param.w, h = param.h, r = param.r, x0 = param.x0, y0 = param.y0, rd = param.rd;
  if (area == "A") {
    var myFillet2 = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xa, ya - h]),
        "TopLeftArc": new makerjs.paths.Arc([xa, ya - h], [xa + w, ya], r, false, true),
        "TopLine": new makerjs.paths.Line([xa + w, ya], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "BottomLine": new makerjs.paths.Line([xi, yi], [xg, yg]),
      }
    };

    //   var myFillet2 = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xi, yi]]);
  } else if (area == "C") {
    var myFillet2 = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLine": new makerjs.paths.Line([xa, ya], [xc - w, yc]),
        "TopRightArc": new makerjs.paths.Arc([xc - w, yc], [xc, yc - h], r, false, true),
        "RightLine": new makerjs.paths.Line([xc, yc - h], [xi, yi]),
        "BottomLine": new makerjs.paths.Line([xi, yi], [xg, yg]),
      }
    };
    // var myFillet2 = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi]]);
  } else if (area == "G") {
    var myFillet2 = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg + h], [xa, ya]),
        "TopLine": new makerjs.paths.Line([xa, ya], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "BottomLine": new makerjs.paths.Line([xi, yi], [xg + w, yg]),
        "BottomLeftArc": new makerjs.paths.Arc([xg + w, yg], [xg, yg + h], r, false, true),
      }
    };
    // var myFillet2 = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "I") {
    var myFillet2 = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLine": new makerjs.paths.Line([xa, ya], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xi, yi + h]),
        "BottomRightArc": new makerjs.paths.Arc([xi, yi + h], [xi - w, yi], r, false, true),
        "BottomLine": new makerjs.paths.Line([xi - w, yi], [xg, yg]),
      }
    };
  } else if (area == "AC") {
    var myFillet2 = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xa, ya - h]),
        "TopLeftArc": new makerjs.paths.Arc([xa, ya - h], [xa + w, ya], r, false, true),
        "TopLine": new makerjs.paths.Line([xa + w, ya], [xc - w, yc]),
        "TopRightArc": new makerjs.paths.Arc([xc - w, yc], [xc, yc - h], r, false, true),
        "RightLine": new makerjs.paths.Line([xc, yc - h], [xi, yi]),
        "BottomLine": new makerjs.paths.Line([xi, yi], [xg, yg]),
      }
    };
  } else if (area == "AG") {
    var myFillet2 = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg + h], [xa, ya - h]),
        "TopLeftArc": new makerjs.paths.Arc([xa, ya - h], [xa + w, ya], r, false, true),
        "TopLine": new makerjs.paths.Line([xa + w, ya], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "BottomLine": new makerjs.paths.Line([xi, yi], [xg + w, yg]),
        "BottomLeftArc": new makerjs.paths.Arc([xg + w, yg], [xg, yg + h], r, false, true),
      }
    };
  } else if (area == "AI") {
    var myFillet2 = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xa, ya - h]),
        "TopLeftArc": new makerjs.paths.Arc([xa, ya - h], [xa + w, ya], r, false, true),
        "TopLine": new makerjs.paths.Line([xa + w, ya], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xi, yi + h]),
        "BottomRightArc": new makerjs.paths.Arc([xi, yi + h], [xi - w, yi], r, false, true),
        "BottomLine": new makerjs.paths.Line([xi - w, yi], [xg, yg]),
      }
    };
  } else if (area == "CG") {
    var myFillet2 = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg + h], [xa, ya]),
        "TopLine": new makerjs.paths.Line([xa, ya], [xc - w, yc]),
        "TopRightArc": new makerjs.paths.Arc([xc - w, yc], [xc, yc - h], r, false, true),
        "RightLine": new makerjs.paths.Line([xc, yc - h], [xi, yi]),
        "BottomLine": new makerjs.paths.Line([xi, yi], [xg + w, yg]),
        "BottomLeftArc": new makerjs.paths.Arc([xg + w, yg], [xg, yg + h], r, false, true),
      }
    };
  } else if (area == "CI") {
    var myFillet2 = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLine": new makerjs.paths.Line([xa, ya], [xc - w, yc]),
        "TopRightArc": new makerjs.paths.Arc([xc - w, yc], [xc, yc - h], r, false, true),
        "RightLine": new makerjs.paths.Line([xc, yc - h], [xi, yi + h]),
        "BottomRightArc": new makerjs.paths.Arc([xi, yi + h], [xi - w, yi], r, false, true),
        "BottomLine": new makerjs.paths.Line([xi - w, yi], [xg, yg]),
      }
    };
  } else if (area == "GI") {
    var myFillet2 = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg + h], [xa, ya]),
        "TopLine": new makerjs.paths.Line([xa, ya], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xi, yi + h]),
        "BottomRightArc": new makerjs.paths.Arc([xi, yi + h], [xi - w, yi], r, false, true),
        "BottomLine": new makerjs.paths.Line([xi - w, yi], [xg + w, yg]),
        "BottomLeftArc": new makerjs.paths.Arc([xg + w, yg], [xg, yg + h], r, false, true),
      }
    };
  } else if (area == "ACG") {
    var myFillet2 = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg + h], [xa, ya - h]),
        "TopLeftArc": new makerjs.paths.Arc([xa, ya - h], [xa + w, ya], r, false, true),
        "TopLine": new makerjs.paths.Line([xa + w, ya], [xc - w, yc]),
        "TopRightArc": new makerjs.paths.Arc([xc - w, yc], [xc, yc - h], r, false, true),
        "RightLine": new makerjs.paths.Line([xc, yc - h], [xi, yi]),
        "BottomLine": new makerjs.paths.Line([xi, yi], [xg + w, yg]),
        "BottomLeftArc": new makerjs.paths.Arc([xg + w, yg], [xg, yg + h], r, false, true),
      }
    };
  } else if (area == "ACI") {
    var myFillet2 = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xa, ya - h]),
        "TopLeftArc": new makerjs.paths.Arc([xa, ya - h], [xa + w, ya], r, false, true),
        "TopLine": new makerjs.paths.Line([xa + w, ya], [xc - w, yc]),
        "TopRightArc": new makerjs.paths.Arc([xc - w, yc], [xc, yc - h], r, false, true),
        "RightLine": new makerjs.paths.Line([xc, yc - h], [xi, yi + h]),
        "BottomRightArc": new makerjs.paths.Arc([xi, yi + h], [xi - w, yi], r, false, true),
        "BottomLine": new makerjs.paths.Line([xi, yi], [xg, yg]),
      }
    };
  } else if (area == "AGI") {
    var myFillet2 = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg + h], [xa, ya - h]),
        "TopLeftArc": new makerjs.paths.Arc([xa, ya - h], [xa + w, ya], r, false, true),
        "TopLine": new makerjs.paths.Line([xa + w, ya], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xi, yi + h]),
        "BottomRightArc": new makerjs.paths.Arc([xi, yi + h], [xi - w, yi], r, false, true),
        "BottomLine": new makerjs.paths.Line([xi - w, yi], [xg + w, yg]),
        "BottomLeftArc": new makerjs.paths.Arc([xg + w, yg], [xg, yg + h], r, false, true),
      }
    };
  } else if (area == "CGI") {
    var myFillet2 = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg + h], [xa, ya]),
        "TopLine": new makerjs.paths.Line([xa, ya], [xc - w, yc]),
        "TopRightArc": new makerjs.paths.Arc([xc - w, yc], [xc, yc - h], r, false, true),
        "RightLine": new makerjs.paths.Line([xc, yc - h], [xi, yi + h]),
        "BottomRightArc": new makerjs.paths.Arc([xi, yi + h], [xi - w, yi], r, false, true),
        "BottomLine": new makerjs.paths.Line([xi - w, yi], [xg + w, yg]),
        "BottomLeftArc": new makerjs.paths.Arc([xg + w, yg], [xg, yg + h], r, false, true),
      }
    };
  } else if (area == "ACGI") {
    var myFillet2 = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg + h], [xa, ya - h]),
        "TopLeftArc": new makerjs.paths.Arc([xa, ya - h], [xa + w, ya], r, false, true),
        "TopLine": new makerjs.paths.Line([xa + w, ya], [xc - w, yc]),
        "TopRightArc": new makerjs.paths.Arc([xc - w, yc], [xc, yc - h], r, false, true),
        "RightLine": new makerjs.paths.Line([xc, yc - h], [xi, yi + h]),
        "BottomRightArc": new makerjs.paths.Arc([xi, yi + h], [xi - w, yi], r, false, true),
        "BottomLine": new makerjs.paths.Line([xi - w, yi], [xg + w, yg]),
        "BottomLeftArc": new makerjs.paths.Arc([xg + w, yg], [xg, yg + h], r, false, true),
      }
    };
  }
  return myFillet2;
};
// 矩形等去角标记
function creatmyFillet2Mark(outParam, area, param, inputParam, fixedParam, OffsetParam, zoom) {
  console.log("是否调用此函数creatCornerMark()");
  console.log("是否调用此函数param", param);
  console.log("是否调用此函数OffsetParam", OffsetParam);
  var R = inputParam.R, W = inputParam.W, H = inputParam.H, X = inputParam.X, Y = inputParam.Y,
    markShiftTwo = fixedParam.markShiftTwo * 1, myZoom = zoom * 1,
    xa = param.xa * 1, ya = param.ya * 1, xc = param.xc * 1, yc = param.yc * 1, xg = param.xg * 1, yg = param.yg * 1,
    xi = param.xi * 1, yi = param.yi * 1,
    w = param.w, h = param.h, r = param.r, x0 = param.x0, y0 = param.y0, rd = param.rd,
    glassW = outParam.glassW, glassH = outParam.glassH,
    markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
    markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
    w = W * 1 * myZoom,
    h = H * 1 * myZoom,
    r = R * 1 * myZoom,
    rd = r / Math.sqrt(2),
    a = 0,//圆心坐标[a,b]
    b = 0,//圆心坐标[a,b]
    b2 = 0,//圆心坐标[a,b]
    x0 = X * 1 * myZoom,
    y0 = Y * 1 * myZoom;
  if (r !== 0) {
    var TxtR = "R=" + R;
  }
  if (w !== 0) {
    var TxtW = W;
  }
  if (h !== 0) {
    var TxtH = H;
  }
  if (x0 !== 0) {
    var TxtX = X;
  }
  if (y0 !== 0) {
    var TxtY = Y;
  }
  var arrA = [], arrC = [], arrG = [], arrI = [],
    arrA = Circle_Center(0, -h, w, 0, r),
    aA = arrA[0].toFixed() * 1,
    bA = arrA[1].toFixed() * 1,
    b2A = arrA[2].toFixed() * 1,
    arrC = Circle_Center(w, 0, 0, -h, r),
    aC = arrC[0].toFixed() * 1,
    bC = arrC[1].toFixed() * 1,
    b2C = arrC[2].toFixed() * 1,
    arrG = Circle_Center(w, 0, 0, -h, r),
    aG = arrG[0].toFixed() * 1,
    bG = arrG[1].toFixed() * 1,
    b2G = arrG[2].toFixed() * 1,
    arrI = Circle_Center(0, h, -w, 0, r),
    aI = arrI[0].toFixed() * 1,
    bI = arrI[1].toFixed() * 1,
    b2I = arrI[2].toFixed() * 1;
  if (area == "A") {
    var myFillet2AMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xa, ya], [xa, ya + markShiftThree]),
        "WLine1": new makerjs.paths.Line([xa + w, ya], [xa + w, ya + markShiftFour]),
        "WLine2": new makerjs.paths.Line([xa, ya + markShift], [xa + w, ya + markShift]),
      }
    };
    var myFillet2AMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xa, ya], [xa - markShiftThree, ya]),//左上角
        "HLine1": new makerjs.paths.Line([xa, ya - h], [xa - markShiftThree, ya - h]),//左上角
        "HLine2": new makerjs.paths.Line([xa - markShiftTwo, ya - h], [xa - markShiftTwo, ya]),//左上角
      }
    };
    var rLineA = new makerjs.paths.Line([xa + aA + r, ya + b2A], [xa, ya - h]);
    var rAngleInDegreesA = makerjs.angle.ofPointInDegrees([xa, ya - h], [xa + w, ya]);
    var myFillet2AMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineA, -rAngleInDegreesA, [xa + aA + r, ya + b2A]),//左上角
      }
    };
    makerjs.model.addCaption(myFillet2AMakerW, TxtW, [xa, ya + markShiftThree], [xa + w, ya + markShiftThree]);//W文字标注
    makerjs.model.addCaption(myFillet2AMakerH, TxtH, [xa - markShiftFour, ya - h], [xa - markShiftFour, ya]);//H文字标注 
    makerjs.model.addCaption(myFillet2AMakerR, TxtR, [xa + aA + r + markShift, ya + b2A - markShift], [xa + aA + r + markShiftFour, ya + b2A - markShift]);//R文字标注 
    var markers = {
      myFillet2AMakerW,
      myFillet2AMakerH,
      myFillet2AMakerR,
    }

  } else if (area == "C") {

    var myFillet2CMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xc, yc], [xc, yc + markShiftThree]),
        "WLine1": new makerjs.paths.Line([xc - w, yc], [xc - w, yc + markShiftFour]),
        "WLine2": new makerjs.paths.Line([xc, yc + markShift], [xc - w, yc + markShift]),
      }
    };
    var myFillet2CMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftThree, yc]),//左上角
        "HLine1": new makerjs.paths.Line([xc, yc - h], [xc + markShiftThree, yc - h]),//左上角
        "HLine2": new makerjs.paths.Line([xc + markShiftTwo, yc - h], [xc + markShiftTwo, yc]),//左上角
      }
    };
    var rLineC = new makerjs.paths.Line([xc - (r + aC), yc + b2C], [xc - w, yc]);
    var rAngleInDegreesC = makerjs.angle.ofPointInDegrees([xc, yc - h], [xc - w, yc]);//角度
    var myFillet2CMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineC, -90 + rAngleInDegreesC / 2, [xc - (r + aC), yc + b2C]),//左上角
      }
    };
    makerjs.model.addCaption(myFillet2CMakerW, TxtW, [xc - w, yc + markShiftThree], [xc, yc + markShiftThree]);//W文字标注
    makerjs.model.addCaption(myFillet2CMakerH, TxtH, [xc + markShiftFour, yc - h], [xc + markShiftFour, yc]);//H文字标注 
    makerjs.model.addCaption(myFillet2CMakerR, TxtR, [xc + aC - r + markShift, yc + b2C - markShift], [xc + aC - r + markShiftFour, yc + b2C - markShift]);//R文字标注 
    var markers = {
      myFillet2CMakerW,
      myFillet2CMakerH,
      myFillet2CMakerR,
    }
  } else if (area == "G") {

    var myFillet2GMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xg, yg], [xg, yg - markShiftThree]),
        "WLine1": new makerjs.paths.Line([xg + w, yg], [xg + w, yg - markShiftFour]),
        "WLine2": new makerjs.paths.Line([xg, yg - markShiftTwo], [xg + w, yg - markShiftTwo]),
      }
    };
    var myFillet2GMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftThree, yg]),//左上角
        "HLine1": new makerjs.paths.Line([xg, yg + h], [xg - markShiftThree, yg + h]),//左上角
        "HLine2": new makerjs.paths.Line([xg - markShiftTwo, yg + h], [xg - markShiftTwo, yg]),//左上角
      }
    };
    var rLineG = new makerjs.paths.Line([xg + aG + r, yg - b2G], [xg + w, yg]);
    var rAngleInDegreesG = makerjs.angle.ofPointInDegrees([xg + w, yg], [xg, yg + h]);//角度
    var myFillet2GMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineG, -90 + rAngleInDegreesG / 2, [xg + aG + r, yg - b2G]),//左上角
      }
    };
    makerjs.model.addCaption(myFillet2GMakerW, TxtW, [xg, yg - markShiftFour], [xg + w, yg - markShiftFour]);//W文字标注
    makerjs.model.addCaption(myFillet2GMakerH, TxtH, [xg - markShiftFour, yg - h], [xg - markShiftFour, yg]);//H文字标注 
    makerjs.model.addCaption(myFillet2GMakerR, TxtR, [xg + aG + r + markShift, yg - b2G + markShift], [xg + aG + r + markShiftFour, yg - b2G + markShift]);//R文字标注 
    var markers = {
      myFillet2GMakerW,
      myFillet2GMakerH,
      myFillet2GMakerR,
    }
  } else if (area == "I") {

    var myFillet2IMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xi, yi], [xi, yi - markShiftThree]),
        "WLine1": new makerjs.paths.Line([xi - w, yi], [xi - w, yi - markShiftFour]),
        "WLine2": new makerjs.paths.Line([xi, yi - markShiftTwo], [xi - w, yi - markShiftTwo]),
      }
    };
    var myFillet2IMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xi, yi], [xi + markShiftThree, yi]),//左上角
        "HLine1": new makerjs.paths.Line([xi, yi + h], [xi + markShiftThree, yi + h]),//左上角
        "HLine2": new makerjs.paths.Line([xi + markShiftTwo, yi + h], [xi + markShiftTwo, yi]),//左上角
      }
    };
    var rLineI = new makerjs.paths.Line([xi + aI, yi + bI], [xi, yi + h]);
    var rAngleInDegreesI = makerjs.angle.ofPointInDegrees([xi, yi + h], [xi - w, yi]);//角度
    var myFillet2IMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineI, 90 - rAngleInDegreesI / 2, [xi + aI, yi + bI]),//左上角
      }
    };
    makerjs.model.addCaption(myFillet2IMakerW, TxtW, [xi - w, yi - markShiftFour], [xi, yi - markShiftFour]);//W文字标注
    makerjs.model.addCaption(myFillet2IMakerH, TxtH, [xi + markShiftFour, yi + h], [xi + markShiftFour, yi]);//H文字标注 
    makerjs.model.addCaption(myFillet2IMakerR, TxtR, [xi + aI + markShift, yi + bI + markShift], [xi + aI + markShiftFour, yi + bI + markShift]);//R文字标注 
    var markers = {
      myFillet2IMakerW,
      myFillet2IMakerH,
      myFillet2IMakerR,
    }
  } else if (area == "AC") {
    var myFillet2AMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xa, ya], [xa, ya + markShiftThree]),
        "WLine1": new makerjs.paths.Line([xa + w, ya], [xa + w, ya + markShiftFour]),
        "WLine2": new makerjs.paths.Line([xa, ya + markShift], [xa + w, ya + markShift]),
      }
    };
    var myFillet2AMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xa, ya], [xa - markShiftThree, ya]),//左上角
        "HLine1": new makerjs.paths.Line([xa, ya - h], [xa - markShiftThree, ya - h]),//左上角
        "HLine2": new makerjs.paths.Line([xa - markShiftTwo, ya - h], [xa - markShiftTwo, ya]),//左上角
      }
    };
    var rLineA = new makerjs.paths.Line([xa + aA + r, ya + b2A], [xa, ya - h]);
    var rAngleInDegreesA = makerjs.angle.ofPointInDegrees([xa, ya - h], [xa + w, ya]);
    var myFillet2AMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineA, -rAngleInDegreesA, [xa + aA + r, ya + b2A]),//左上角
      }
    };

    var myFillet2CMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xc, yc], [xc, yc + markShiftThree]),
        "WLine1": new makerjs.paths.Line([xc - w, yc], [xc - w, yc + markShiftFour]),
        "WLine2": new makerjs.paths.Line([xc, yc + markShift], [xc - w, yc + markShift]),
      }
    };
    var myFillet2CMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftThree, yc]),//左上角
        "HLine1": new makerjs.paths.Line([xc, yc - h], [xc + markShiftThree, yc - h]),//左上角
        "HLine2": new makerjs.paths.Line([xc + markShiftTwo, yc - h], [xc + markShiftTwo, yc]),//左上角
      }
    };
    var rLineC = new makerjs.paths.Line([xc - (r + aC), yc + b2C], [xc - w, yc]);
    var rAngleInDegreesC = makerjs.angle.ofPointInDegrees([xc, yc - h], [xc - w, yc]);//角度
    var myFillet2CMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineC, -90 + rAngleInDegreesC / 2, [xc - (r + aC), yc + b2C]),//左上角
      }
    };
    makerjs.model.addCaption(myFillet2AMakerW, TxtW, [xa, ya + markShiftThree], [xa + w, ya + markShiftThree]);//W文字标注
    makerjs.model.addCaption(myFillet2AMakerH, TxtH, [xa - markShiftFour, ya - h], [xa - markShiftFour, ya]);//H文字标注 
    makerjs.model.addCaption(myFillet2AMakerR, TxtR, [xa + aA + r + markShift, ya + b2A - markShift], [xa + aA + r + markShiftFour, ya + b2A - markShift]);//R文字标注
    makerjs.model.addCaption(myFillet2CMakerW, TxtW, [xc - w, yc + markShiftThree], [xc, yc + markShiftThree]);//W文字标注
    makerjs.model.addCaption(myFillet2CMakerH, TxtH, [xc + markShiftFour, yc - h], [xc + markShiftFour, yc]);//H文字标注 
    makerjs.model.addCaption(myFillet2CMakerR, TxtR, [xc + aC - r + markShift, yc + b2C - markShift], [xc + aC - r + markShiftFour, yc + b2C - markShift]);//R文字标注
     var markers = {
      myFillet2AMakerW,
      myFillet2AMakerH,
      myFillet2AMakerR,
      myFillet2CMakerW,
      myFillet2CMakerH,
      myFillet2CMakerR,
    }
 } else if (area == "AG") {
    var myFillet2AMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xa, ya], [xa, ya + markShiftThree]),
        "WLine1": new makerjs.paths.Line([xa + w, ya], [xa + w, ya + markShiftFour]),
        "WLine2": new makerjs.paths.Line([xa, ya + markShift], [xa + w, ya + markShift]),
      }
    };
    var myFillet2AMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xa, ya], [xa - markShiftThree, ya]),//左上角
        "HLine1": new makerjs.paths.Line([xa, ya - h], [xa - markShiftThree, ya - h]),//左上角
        "HLine2": new makerjs.paths.Line([xa - markShiftTwo, ya - h], [xa - markShiftTwo, ya]),//左上角
      }
    };
    var rLineA = new makerjs.paths.Line([xa + aA + r, ya + b2A], [xa, ya - h]);
    var rAngleInDegreesA = makerjs.angle.ofPointInDegrees([xa, ya - h], [xa + w, ya]);
    var myFillet2AMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineA, -rAngleInDegreesA, [xa + aA + r, ya + b2A]),//左上角
      }
    };
    var myFillet2GMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xg, yg], [xg, yg - markShiftThree]),
        "WLine1": new makerjs.paths.Line([xg + w, yg], [xg + w, yg - markShiftFour]),
        "WLine2": new makerjs.paths.Line([xg, yg - markShiftTwo], [xg + w, yg - markShiftTwo]),
      }
    };
    var myFillet2GMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftThree, yg]),//左上角
        "HLine1": new makerjs.paths.Line([xg, yg + h], [xg - markShiftThree, yg + h]),//左上角
        "HLine2": new makerjs.paths.Line([xg - markShiftTwo, yg + h], [xg - markShiftTwo, yg]),//左上角
      }
    };
    var rLineG = new makerjs.paths.Line([xg + aG + r, yg - b2G], [xg + w, yg]);
    var rAngleInDegreesG = makerjs.angle.ofPointInDegrees([xg + w, yg], [xg, yg + h]);//角度
    var myFillet2GMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineG, -90 + rAngleInDegreesG / 2, [xg + aG + r, yg - b2G]),//左上角
      }
    };

    makerjs.model.addCaption(myFillet2AMakerW, TxtW, [xa, ya + markShiftThree], [xa + w, ya + markShiftThree]);//W文字标注
    makerjs.model.addCaption(myFillet2AMakerH, TxtH, [xa - markShiftFour, ya - h], [xa - markShiftFour, ya]);//H文字标注 
    makerjs.model.addCaption(myFillet2AMakerR, TxtR, [xa + aA + r + markShift, ya + b2A - markShift], [xa + aA + r + markShiftFour, ya + b2A - markShift]);//R文字标注
    makerjs.model.addCaption(myFillet2GMakerW, TxtW, [xg, yg - markShiftFour], [xg + w, yg - markShiftFour]);//W文字标注
    makerjs.model.addCaption(myFillet2GMakerH, TxtH, [xg - markShiftFour, yg - h], [xg - markShiftFour, yg]);//H文字标注 
    makerjs.model.addCaption(myFillet2GMakerR, TxtR, [xg + aG + r + markShift, yg - b2G + markShift], [xg + aG + r + markShiftFour, yg - b2G + markShift]);//R文字标注 
     var markers = {
      myFillet2AMakerW,
      myFillet2AMakerH,
      myFillet2AMakerR,
      myFillet2GMakerW,
      myFillet2GMakerH,
      myFillet2GMakerR,
    }
  } else if (area == "AI") {
    var myFillet2AMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xa, ya], [xa, ya + markShiftThree]),
        "WLine1": new makerjs.paths.Line([xa + w, ya], [xa + w, ya + markShiftFour]),
        "WLine2": new makerjs.paths.Line([xa, ya + markShift], [xa + w, ya + markShift]),
      }
    };
    var myFillet2AMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xa, ya], [xa - markShiftThree, ya]),//左上角
        "HLine1": new makerjs.paths.Line([xa, ya - h], [xa - markShiftThree, ya - h]),//左上角
        "HLine2": new makerjs.paths.Line([xa - markShiftTwo, ya - h], [xa - markShiftTwo, ya]),//左上角
      }
    };
    var rLineA = new makerjs.paths.Line([xa + aA + r, ya + b2A], [xa, ya - h]);
    var rAngleInDegreesA = makerjs.angle.ofPointInDegrees([xa, ya - h], [xa + w, ya]);
    var myFillet2AMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineA, -rAngleInDegreesA, [xa + aA + r, ya + b2A]),//左上角
      }
    };

    var myFillet2IMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xi, yi], [xi, yi - markShiftThree]),
        "WLine1": new makerjs.paths.Line([xi - w, yi], [xi - w, yi - markShiftFour]),
        "WLine2": new makerjs.paths.Line([xi, yi - markShiftTwo], [xi - w, yi - markShiftTwo]),
      }
    };
    var myFillet2IMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xi, yi], [xi + markShiftThree, yi]),//左上角
        "HLine1": new makerjs.paths.Line([xi, yi + h], [xi + markShiftThree, yi + h]),//左上角
        "HLine2": new makerjs.paths.Line([xi + markShiftTwo, yi + h], [xi + markShiftTwo, yi]),//左上角
      }
    };
    var rLineI = new makerjs.paths.Line([xi + aI, yi + bI], [xi, yi + h]);
    var rAngleInDegreesI = makerjs.angle.ofPointInDegrees([xi, yi + h], [xi - w, yi]);//角度
    var myFillet2IMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineI, 90 - rAngleInDegreesI / 2, [xi + aI, yi + bI]),//左上角
      }
    };
    makerjs.model.addCaption(myFillet2AMakerW, TxtW, [xa, ya + markShiftThree], [xa + w, ya + markShiftThree]);//W文字标注
    makerjs.model.addCaption(myFillet2AMakerH, TxtH, [xa - markShiftFour, ya - h], [xa - markShiftFour, ya]);//H文字标注 
    makerjs.model.addCaption(myFillet2AMakerR, TxtR, [xa + aA + r + markShift, ya + b2A - markShift], [xa + aA + r + markShiftFour, ya + b2A - markShift]);//R文字标注
    makerjs.model.addCaption(myFillet2IMakerW, TxtW, [xi - w, yi - markShiftFour], [xi, yi - markShiftFour]);//W文字标注
    makerjs.model.addCaption(myFillet2IMakerH, TxtH, [xi + markShiftFour, yi + h], [xi + markShiftFour, yi]);//H文字标注 
    makerjs.model.addCaption(myFillet2IMakerR, TxtR, [xi + aI + markShift, yi + bI + markShift], [xi + aI + markShiftFour, yi + bI + markShift]);//R文字标注 
    var markers = {
      myFillet2AMakerW,
      myFillet2AMakerH,
      myFillet2AMakerR,
      myFillet2IMakerW,
      myFillet2IMakerH,
      myFillet2IMakerR,
    }
  } else if (area == "CG") {
    
    var myFillet2CMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xc, yc], [xc, yc + markShiftThree]),
        "WLine1": new makerjs.paths.Line([xc - w, yc], [xc - w, yc + markShiftFour]),
        "WLine2": new makerjs.paths.Line([xc, yc + markShift], [xc - w, yc + markShift]),
      }
    };
    var myFillet2CMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftThree, yc]),//左上角
        "HLine1": new makerjs.paths.Line([xc, yc - h], [xc + markShiftThree, yc - h]),//左上角
        "HLine2": new makerjs.paths.Line([xc + markShiftTwo, yc - h], [xc + markShiftTwo, yc]),//左上角
      }
    };
    var rLineC = new makerjs.paths.Line([xc - (r + aC), yc + b2C], [xc - w, yc]);
    var rAngleInDegreesC = makerjs.angle.ofPointInDegrees([xc, yc - h], [xc - w, yc]);//角度
    var myFillet2CMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineC, -90 + rAngleInDegreesC / 2, [xc - (r + aC), yc + b2C]),//左上角
      }
    };
    var myFillet2GMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xg, yg], [xg, yg - markShiftThree]),
        "WLine1": new makerjs.paths.Line([xg + w, yg], [xg + w, yg - markShiftFour]),
        "WLine2": new makerjs.paths.Line([xg, yg - markShiftTwo], [xg + w, yg - markShiftTwo]),
      }
    };
    var myFillet2GMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftThree, yg]),//左上角
        "HLine1": new makerjs.paths.Line([xg, yg + h], [xg - markShiftThree, yg + h]),//左上角
        "HLine2": new makerjs.paths.Line([xg - markShiftTwo, yg + h], [xg - markShiftTwo, yg]),//左上角
      }
    };
    var rLineG = new makerjs.paths.Line([xg + aG + r, yg - b2G], [xg + w, yg]);
    var rAngleInDegreesG = makerjs.angle.ofPointInDegrees([xg + w, yg], [xg, yg + h]);//角度
    var myFillet2GMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineG, -90 + rAngleInDegreesG / 2, [xg + aG + r, yg - b2G]),//左上角
      }
    };

    makerjs.model.addCaption(myFillet2CMakerW, TxtW, [xc - w, yc + markShiftThree], [xc, yc + markShiftThree]);//W文字标注
    makerjs.model.addCaption(myFillet2CMakerH, TxtH, [xc + markShiftFour, yc - h], [xc + markShiftFour, yc]);//H文字标注 
    makerjs.model.addCaption(myFillet2CMakerR, TxtR, [xc + aC - r + markShift, yc + b2C - markShift], [xc + aC - r + markShiftFour, yc + b2C - markShift]);//R文字标注
    makerjs.model.addCaption(myFillet2GMakerW, TxtW, [xg, yg - markShiftFour], [xg + w, yg - markShiftFour]);//W文字标注
    makerjs.model.addCaption(myFillet2GMakerH, TxtH, [xg - markShiftFour, yg - h], [xg - markShiftFour, yg]);//H文字标注 
    makerjs.model.addCaption(myFillet2GMakerR, TxtR, [xg + aG + r + markShift, yg - b2G + markShift], [xg + aG + r + markShiftFour, yg - b2G + markShift]);//R文字标注 
   var markers = {
      myFillet2CMakerW,
      myFillet2CMakerH,
      myFillet2CMakerR,
      myFillet2GMakerW,
      myFillet2GMakerH,
      myFillet2GMakerR,
    }
  } else if (area == "CI") {
    
    var myFillet2CMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xc, yc], [xc, yc + markShiftThree]),
        "WLine1": new makerjs.paths.Line([xc - w, yc], [xc - w, yc + markShiftFour]),
        "WLine2": new makerjs.paths.Line([xc, yc + markShift], [xc - w, yc + markShift]),
      }
    };
    var myFillet2CMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftThree, yc]),//左上角
        "HLine1": new makerjs.paths.Line([xc, yc - h], [xc + markShiftThree, yc - h]),//左上角
        "HLine2": new makerjs.paths.Line([xc + markShiftTwo, yc - h], [xc + markShiftTwo, yc]),//左上角
      }
    };
    var rLineC = new makerjs.paths.Line([xc - (r + aC), yc + b2C], [xc - w, yc]);
    var rAngleInDegreesC = makerjs.angle.ofPointInDegrees([xc, yc - h], [xc - w, yc]);//角度
    var myFillet2CMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineC, -90 + rAngleInDegreesC / 2, [xc - (r + aC), yc + b2C]),//左上角
      }
    };
   
    var myFillet2IMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xi, yi], [xi, yi - markShiftThree]),
        "WLine1": new makerjs.paths.Line([xi - w, yi], [xi - w, yi - markShiftFour]),
        "WLine2": new makerjs.paths.Line([xi, yi - markShiftTwo], [xi - w, yi - markShiftTwo]),
      }
    };
    var myFillet2IMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xi, yi], [xi + markShiftThree, yi]),//左上角
        "HLine1": new makerjs.paths.Line([xi, yi + h], [xi + markShiftThree, yi + h]),//左上角
        "HLine2": new makerjs.paths.Line([xi + markShiftTwo, yi + h], [xi + markShiftTwo, yi]),//左上角
      }
    };
    var rLineI = new makerjs.paths.Line([xi + aI, yi + bI], [xi, yi + h]);
    var rAngleInDegreesI = makerjs.angle.ofPointInDegrees([xi, yi + h], [xi - w, yi]);//角度
    var myFillet2IMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineI, 90 - rAngleInDegreesI / 2, [xi + aI, yi + bI]),//左上角
      }
    };
    makerjs.model.addCaption(myFillet2CMakerW, TxtW, [xc - w, yc + markShiftThree], [xc, yc + markShiftThree]);//W文字标注
    makerjs.model.addCaption(myFillet2CMakerH, TxtH, [xc + markShiftFour, yc - h], [xc + markShiftFour, yc]);//H文字标注 
    makerjs.model.addCaption(myFillet2CMakerR, TxtR, [xc + aC - r + markShift, yc + b2C - markShift], [xc + aC - r + markShiftFour, yc + b2C - markShift]);//R文字标注
    makerjs.model.addCaption(myFillet2IMakerW, TxtW, [xi - w, yi - markShiftFour], [xi, yi - markShiftFour]);//W文字标注
    makerjs.model.addCaption(myFillet2IMakerH, TxtH, [xi + markShiftFour, yi + h], [xi + markShiftFour, yi]);//H文字标注 
    makerjs.model.addCaption(myFillet2IMakerR, TxtR, [xi + aI + markShift, yi + bI + markShift], [xi + aI + markShiftFour, yi + bI + markShift]);//R文字标注 
    var markers = {
      myFillet2CMakerW,
      myFillet2CMakerH,
      myFillet2CMakerR,
      myFillet2IMakerW,
      myFillet2IMakerH,
      myFillet2IMakerR,
    }
  } else if (area == "GI") {
   
    var myFillet2GMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xg, yg], [xg, yg - markShiftThree]),
        "WLine1": new makerjs.paths.Line([xg + w, yg], [xg + w, yg - markShiftFour]),
        "WLine2": new makerjs.paths.Line([xg, yg - markShiftTwo], [xg + w, yg - markShiftTwo]),
      }
    };
    var myFillet2GMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftThree, yg]),//左上角
        "HLine1": new makerjs.paths.Line([xg, yg + h], [xg - markShiftThree, yg + h]),//左上角
        "HLine2": new makerjs.paths.Line([xg - markShiftTwo, yg + h], [xg - markShiftTwo, yg]),//左上角
      }
    };
    var rLineG = new makerjs.paths.Line([xg + aG + r, yg - b2G], [xg + w, yg]);
    var rAngleInDegreesG = makerjs.angle.ofPointInDegrees([xg + w, yg], [xg, yg + h]);//角度
    var myFillet2GMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineG, -90 + rAngleInDegreesG / 2, [xg + aG + r, yg - b2G]),//左上角
      }
    };

    var myFillet2IMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xi, yi], [xi, yi - markShiftThree]),
        "WLine1": new makerjs.paths.Line([xi - w, yi], [xi - w, yi - markShiftFour]),
        "WLine2": new makerjs.paths.Line([xi, yi - markShiftTwo], [xi - w, yi - markShiftTwo]),
      }
    };
    var myFillet2IMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xi, yi], [xi + markShiftThree, yi]),//左上角
        "HLine1": new makerjs.paths.Line([xi, yi + h], [xi + markShiftThree, yi + h]),//左上角
        "HLine2": new makerjs.paths.Line([xi + markShiftTwo, yi + h], [xi + markShiftTwo, yi]),//左上角
      }
    };
    var rLineI = new makerjs.paths.Line([xi + aI, yi + bI], [xi, yi + h]);
    var rAngleInDegreesI = makerjs.angle.ofPointInDegrees([xi, yi + h], [xi - w, yi]);//角度
    var myFillet2IMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineI, 90 - rAngleInDegreesI / 2, [xi + aI, yi + bI]),//左上角
      }
    };
    makerjs.model.addCaption(myFillet2GMakerW, TxtW, [xg, yg - markShiftFour], [xg + w, yg - markShiftFour]);//W文字标注
    makerjs.model.addCaption(myFillet2GMakerH, TxtH, [xg - markShiftFour, yg - h], [xg - markShiftFour, yg]);//H文字标注 
    makerjs.model.addCaption(myFillet2GMakerR, TxtR, [xg + aG + r + markShift, yg - b2G + markShift], [xg + aG + r + markShiftFour, yg - b2G + markShift]);//R文字标注 
    makerjs.model.addCaption(myFillet2IMakerW, TxtW, [xi - w, yi - markShiftFour], [xi, yi - markShiftFour]);//W文字标注
    makerjs.model.addCaption(myFillet2IMakerH, TxtH, [xi + markShiftFour, yi + h], [xi + markShiftFour, yi]);//H文字标注 
    makerjs.model.addCaption(myFillet2IMakerR, TxtR, [xi + aI + markShift, yi + bI + markShift], [xi + aI + markShiftFour, yi + bI + markShift]);//R文字标注 
    var markers = {
      myFillet2GMakerW,
      myFillet2GMakerH,
      myFillet2GMakerR,
      myFillet2IMakerW,
      myFillet2IMakerH,
      myFillet2IMakerR,
    }
  } else if (area == "ACG") {
    var myFillet2AMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xa, ya], [xa, ya + markShiftThree]),
        "WLine1": new makerjs.paths.Line([xa + w, ya], [xa + w, ya + markShiftFour]),
        "WLine2": new makerjs.paths.Line([xa, ya + markShift], [xa + w, ya + markShift]),
      }
    };
    var myFillet2AMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xa, ya], [xa - markShiftThree, ya]),//左上角
        "HLine1": new makerjs.paths.Line([xa, ya - h], [xa - markShiftThree, ya - h]),//左上角
        "HLine2": new makerjs.paths.Line([xa - markShiftTwo, ya - h], [xa - markShiftTwo, ya]),//左上角
      }
    };
    var rLineA = new makerjs.paths.Line([xa + aA + r, ya + b2A], [xa, ya - h]);
    var rAngleInDegreesA = makerjs.angle.ofPointInDegrees([xa, ya - h], [xa + w, ya]);
    var myFillet2AMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineA, -rAngleInDegreesA, [xa + aA + r, ya + b2A]),//左上角
      }
    };

    var myFillet2CMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xc, yc], [xc, yc + markShiftThree]),
        "WLine1": new makerjs.paths.Line([xc - w, yc], [xc - w, yc + markShiftFour]),
        "WLine2": new makerjs.paths.Line([xc, yc + markShift], [xc - w, yc + markShift]),
      }
    };
    var myFillet2CMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftThree, yc]),//左上角
        "HLine1": new makerjs.paths.Line([xc, yc - h], [xc + markShiftThree, yc - h]),//左上角
        "HLine2": new makerjs.paths.Line([xc + markShiftTwo, yc - h], [xc + markShiftTwo, yc]),//左上角
      }
    };
    var rLineC = new makerjs.paths.Line([xc - (r + aC), yc + b2C], [xc - w, yc]);
    var rAngleInDegreesC = makerjs.angle.ofPointInDegrees([xc, yc - h], [xc - w, yc]);//角度
    var myFillet2CMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineC, -90 + rAngleInDegreesC / 2, [xc - (r + aC), yc + b2C]),//左上角
      }
    };
    var myFillet2GMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xg, yg], [xg, yg - markShiftThree]),
        "WLine1": new makerjs.paths.Line([xg + w, yg], [xg + w, yg - markShiftFour]),
        "WLine2": new makerjs.paths.Line([xg, yg - markShiftTwo], [xg + w, yg - markShiftTwo]),
      }
    };
    var myFillet2GMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftThree, yg]),//左上角
        "HLine1": new makerjs.paths.Line([xg, yg + h], [xg - markShiftThree, yg + h]),//左上角
        "HLine2": new makerjs.paths.Line([xg - markShiftTwo, yg + h], [xg - markShiftTwo, yg]),//左上角
      }
    };
    var rLineG = new makerjs.paths.Line([xg + aG + r, yg - b2G], [xg + w, yg]);
    var rAngleInDegreesG = makerjs.angle.ofPointInDegrees([xg + w, yg], [xg, yg + h]);//角度
    var myFillet2GMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineG, -90 + rAngleInDegreesG / 2, [xg + aG + r, yg - b2G]),//左上角
      }
    };

    makerjs.model.addCaption(myFillet2AMakerW, TxtW, [xa, ya + markShiftThree], [xa + w, ya + markShiftThree]);//W文字标注
    makerjs.model.addCaption(myFillet2AMakerH, TxtH, [xa - markShiftFour, ya - h], [xa - markShiftFour, ya]);//H文字标注 
    makerjs.model.addCaption(myFillet2AMakerR, TxtR, [xa + aA + r + markShift, ya + b2A - markShift], [xa + aA + r + markShiftFour, ya + b2A - markShift]);//R文字标注
    makerjs.model.addCaption(myFillet2CMakerW, TxtW, [xc - w, yc + markShiftThree], [xc, yc + markShiftThree]);//W文字标注
    makerjs.model.addCaption(myFillet2CMakerH, TxtH, [xc + markShiftFour, yc - h], [xc + markShiftFour, yc]);//H文字标注 
    makerjs.model.addCaption(myFillet2CMakerR, TxtR, [xc + aC - r + markShift, yc + b2C - markShift], [xc + aC - r + markShiftFour, yc + b2C - markShift]);//R文字标注
    makerjs.model.addCaption(myFillet2GMakerW, TxtW, [xg, yg - markShiftFour], [xg + w, yg - markShiftFour]);//W文字标注
    makerjs.model.addCaption(myFillet2GMakerH, TxtH, [xg - markShiftFour, yg - h], [xg - markShiftFour, yg]);//H文字标注 
    makerjs.model.addCaption(myFillet2GMakerR, TxtR, [xg + aG + r + markShift, yg - b2G + markShift], [xg + aG + r + markShiftFour, yg - b2G + markShift]);//R文字标注 
    var markers = {
      myFillet2AMakerW,
      myFillet2AMakerH,
      myFillet2AMakerR,
      myFillet2CMakerW,
      myFillet2CMakerH,
      myFillet2CMakerR,
      myFillet2GMakerW,
      myFillet2GMakerH,
      myFillet2GMakerR,
    }
  } else if (area == "ACI") {
    var myFillet2AMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xa, ya], [xa, ya + markShiftThree]),
        "WLine1": new makerjs.paths.Line([xa + w, ya], [xa + w, ya + markShiftFour]),
        "WLine2": new makerjs.paths.Line([xa, ya + markShift], [xa + w, ya + markShift]),
      }
    };
    var myFillet2AMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xa, ya], [xa - markShiftThree, ya]),//左上角
        "HLine1": new makerjs.paths.Line([xa, ya - h], [xa - markShiftThree, ya - h]),//左上角
        "HLine2": new makerjs.paths.Line([xa - markShiftTwo, ya - h], [xa - markShiftTwo, ya]),//左上角
      }
    };
    var rLineA = new makerjs.paths.Line([xa + aA + r, ya + b2A], [xa, ya - h]);
    var rAngleInDegreesA = makerjs.angle.ofPointInDegrees([xa, ya - h], [xa + w, ya]);
    var myFillet2AMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineA, -rAngleInDegreesA, [xa + aA + r, ya + b2A]),//左上角
      }
    };

    var myFillet2CMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xc, yc], [xc, yc + markShiftThree]),
        "WLine1": new makerjs.paths.Line([xc - w, yc], [xc - w, yc + markShiftFour]),
        "WLine2": new makerjs.paths.Line([xc, yc + markShift], [xc - w, yc + markShift]),
      }
    };
    var myFillet2CMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftThree, yc]),//左上角
        "HLine1": new makerjs.paths.Line([xc, yc - h], [xc + markShiftThree, yc - h]),//左上角
        "HLine2": new makerjs.paths.Line([xc + markShiftTwo, yc - h], [xc + markShiftTwo, yc]),//左上角
      }
    };
    var rLineC = new makerjs.paths.Line([xc - (r + aC), yc + b2C], [xc - w, yc]);
    var rAngleInDegreesC = makerjs.angle.ofPointInDegrees([xc, yc - h], [xc - w, yc]);//角度
    var myFillet2CMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineC, -90 + rAngleInDegreesC / 2, [xc - (r + aC), yc + b2C]),//左上角
      }
    };
    var myFillet2IMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xi, yi], [xi, yi - markShiftThree]),
        "WLine1": new makerjs.paths.Line([xi - w, yi], [xi - w, yi - markShiftFour]),
        "WLine2": new makerjs.paths.Line([xi, yi - markShiftTwo], [xi - w, yi - markShiftTwo]),
      }
    };
    var myFillet2IMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xi, yi], [xi + markShiftThree, yi]),//左上角
        "HLine1": new makerjs.paths.Line([xi, yi + h], [xi + markShiftThree, yi + h]),//左上角
        "HLine2": new makerjs.paths.Line([xi + markShiftTwo, yi + h], [xi + markShiftTwo, yi]),//左上角
      }
    };
    var rLineI = new makerjs.paths.Line([xi + aI, yi + bI], [xi, yi + h]);
    var rAngleInDegreesI = makerjs.angle.ofPointInDegrees([xi, yi + h], [xi - w, yi]);//角度
    var myFillet2IMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineI, 90 - rAngleInDegreesI / 2, [xi + aI, yi + bI]),//左上角
      }
    };
    makerjs.model.addCaption(myFillet2AMakerW, TxtW, [xa, ya + markShiftThree], [xa + w, ya + markShiftThree]);//W文字标注
    makerjs.model.addCaption(myFillet2AMakerH, TxtH, [xa - markShiftFour, ya - h], [xa - markShiftFour, ya]);//H文字标注 
    makerjs.model.addCaption(myFillet2AMakerR, TxtR, [xa + aA + r + markShift, ya + b2A - markShift], [xa + aA + r + markShiftFour, ya + b2A - markShift]);//R文字标注
    makerjs.model.addCaption(myFillet2CMakerW, TxtW, [xc - w, yc + markShiftThree], [xc, yc + markShiftThree]);//W文字标注
    makerjs.model.addCaption(myFillet2CMakerH, TxtH, [xc + markShiftFour, yc - h], [xc + markShiftFour, yc]);//H文字标注 
    makerjs.model.addCaption(myFillet2CMakerR, TxtR, [xc + aC - r + markShift, yc + b2C - markShift], [xc + aC - r + markShiftFour, yc + b2C - markShift]);//R文字标注
    makerjs.model.addCaption(myFillet2IMakerW, TxtW, [xi - w, yi - markShiftFour], [xi, yi - markShiftFour]);//W文字标注
    makerjs.model.addCaption(myFillet2IMakerH, TxtH, [xi + markShiftFour, yi + h], [xi + markShiftFour, yi]);//H文字标注 
    makerjs.model.addCaption(myFillet2IMakerR, TxtR, [xi + aI + markShift, yi + bI + markShift], [xi + aI + markShiftFour, yi + bI + markShift]);//R文字标注 
    var markers = {
      myFillet2AMakerW,
      myFillet2AMakerH,
      myFillet2AMakerR,
      myFillet2CMakerW,
      myFillet2CMakerH,
      myFillet2CMakerR,
      myFillet2IMakerW,
      myFillet2IMakerH,
      myFillet2IMakerR,
    }
  } else if (area == "AGI") {
    var myFillet2AMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xa, ya], [xa, ya + markShiftThree]),
        "WLine1": new makerjs.paths.Line([xa + w, ya], [xa + w, ya + markShiftFour]),
        "WLine2": new makerjs.paths.Line([xa, ya + markShift], [xa + w, ya + markShift]),
      }
    };
    var myFillet2AMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xa, ya], [xa - markShiftThree, ya]),//左上角
        "HLine1": new makerjs.paths.Line([xa, ya - h], [xa - markShiftThree, ya - h]),//左上角
        "HLine2": new makerjs.paths.Line([xa - markShiftTwo, ya - h], [xa - markShiftTwo, ya]),//左上角
      }
    };
    var rLineA = new makerjs.paths.Line([xa + aA + r, ya + b2A], [xa, ya - h]);
    var rAngleInDegreesA = makerjs.angle.ofPointInDegrees([xa, ya - h], [xa + w, ya]);
    var myFillet2AMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineA, -rAngleInDegreesA, [xa + aA + r, ya + b2A]),//左上角
      }
    };

    var myFillet2GMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xg, yg], [xg, yg - markShiftThree]),
        "WLine1": new makerjs.paths.Line([xg + w, yg], [xg + w, yg - markShiftFour]),
        "WLine2": new makerjs.paths.Line([xg, yg - markShiftTwo], [xg + w, yg - markShiftTwo]),
      }
    };
    var myFillet2GMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftThree, yg]),//左上角
        "HLine1": new makerjs.paths.Line([xg, yg + h], [xg - markShiftThree, yg + h]),//左上角
        "HLine2": new makerjs.paths.Line([xg - markShiftTwo, yg + h], [xg - markShiftTwo, yg]),//左上角
      }
    };
    var rLineG = new makerjs.paths.Line([xg + aG + r, yg - b2G], [xg + w, yg]);
    var rAngleInDegreesG = makerjs.angle.ofPointInDegrees([xg + w, yg], [xg, yg + h]);//角度
    var myFillet2GMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineG, -90 + rAngleInDegreesG / 2, [xg + aG + r, yg - b2G]),//左上角
      }
    };

    var myFillet2IMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xi, yi], [xi, yi - markShiftThree]),
        "WLine1": new makerjs.paths.Line([xi - w, yi], [xi - w, yi - markShiftFour]),
        "WLine2": new makerjs.paths.Line([xi, yi - markShiftTwo], [xi - w, yi - markShiftTwo]),
      }
    };
    var myFillet2IMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xi, yi], [xi + markShiftThree, yi]),//左上角
        "HLine1": new makerjs.paths.Line([xi, yi + h], [xi + markShiftThree, yi + h]),//左上角
        "HLine2": new makerjs.paths.Line([xi + markShiftTwo, yi + h], [xi + markShiftTwo, yi]),//左上角
      }
    };
    var rLineI = new makerjs.paths.Line([xi + aI, yi + bI], [xi, yi + h]);
    var rAngleInDegreesI = makerjs.angle.ofPointInDegrees([xi, yi + h], [xi - w, yi]);//角度
    var myFillet2IMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineI, 90 - rAngleInDegreesI / 2, [xi + aI, yi + bI]),//左上角
      }
    };
    makerjs.model.addCaption(myFillet2AMakerW, TxtW, [xa, ya + markShiftThree], [xa + w, ya + markShiftThree]);//W文字标注
    makerjs.model.addCaption(myFillet2AMakerH, TxtH, [xa - markShiftFour, ya - h], [xa - markShiftFour, ya]);//H文字标注 
    makerjs.model.addCaption(myFillet2AMakerR, TxtR, [xa + aA + r + markShift, ya + b2A - markShift], [xa + aA + r + markShiftFour, ya + b2A - markShift]);//R文字标注
    makerjs.model.addCaption(myFillet2GMakerW, TxtW, [xg, yg - markShiftFour], [xg + w, yg - markShiftFour]);//W文字标注
    makerjs.model.addCaption(myFillet2GMakerH, TxtH, [xg - markShiftFour, yg - h], [xg - markShiftFour, yg]);//H文字标注 
    makerjs.model.addCaption(myFillet2GMakerR, TxtR, [xg + aG + r + markShift, yg - b2G + markShift], [xg + aG + r + markShiftFour, yg - b2G + markShift]);//R文字标注 
    makerjs.model.addCaption(myFillet2IMakerW, TxtW, [xi - w, yi - markShiftFour], [xi, yi - markShiftFour]);//W文字标注
    makerjs.model.addCaption(myFillet2IMakerH, TxtH, [xi + markShiftFour, yi + h], [xi + markShiftFour, yi]);//H文字标注 
    makerjs.model.addCaption(myFillet2IMakerR, TxtR, [xi + aI + markShift, yi + bI + markShift], [xi + aI + markShiftFour, yi + bI + markShift]);//R文字标注 
    var markers = {
      myFillet2AMakerW,
      myFillet2AMakerH,
      myFillet2AMakerR,
      myFillet2GMakerW,
      myFillet2GMakerH,
      myFillet2GMakerR,
      myFillet2IMakerW,
      myFillet2IMakerH,
      myFillet2IMakerR,
    }
  } else if (area == "CGI") {
   
    var myFillet2CMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xc, yc], [xc, yc + markShiftThree]),
        "WLine1": new makerjs.paths.Line([xc - w, yc], [xc - w, yc + markShiftFour]),
        "WLine2": new makerjs.paths.Line([xc, yc + markShift], [xc - w, yc + markShift]),
      }
    };
    var myFillet2CMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftThree, yc]),//左上角
        "HLine1": new makerjs.paths.Line([xc, yc - h], [xc + markShiftThree, yc - h]),//左上角
        "HLine2": new makerjs.paths.Line([xc + markShiftTwo, yc - h], [xc + markShiftTwo, yc]),//左上角
      }
    };
    var rLineC = new makerjs.paths.Line([xc - (r + aC), yc + b2C], [xc - w, yc]);
    var rAngleInDegreesC = makerjs.angle.ofPointInDegrees([xc, yc - h], [xc - w, yc]);//角度
    var myFillet2CMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineC, -90 + rAngleInDegreesC / 2, [xc - (r + aC), yc + b2C]),//左上角
      }
    };
    var myFillet2GMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xg, yg], [xg, yg - markShiftThree]),
        "WLine1": new makerjs.paths.Line([xg + w, yg], [xg + w, yg - markShiftFour]),
        "WLine2": new makerjs.paths.Line([xg, yg - markShiftTwo], [xg + w, yg - markShiftTwo]),
      }
    };
    var myFillet2GMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftThree, yg]),//左上角
        "HLine1": new makerjs.paths.Line([xg, yg + h], [xg - markShiftThree, yg + h]),//左上角
        "HLine2": new makerjs.paths.Line([xg - markShiftTwo, yg + h], [xg - markShiftTwo, yg]),//左上角
      }
    };
    var rLineG = new makerjs.paths.Line([xg + aG + r, yg - b2G], [xg + w, yg]);
    var rAngleInDegreesG = makerjs.angle.ofPointInDegrees([xg + w, yg], [xg, yg + h]);//角度
    var myFillet2GMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineG, -90 + rAngleInDegreesG / 2, [xg + aG + r, yg - b2G]),//左上角
      }
    };

    var myFillet2IMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xi, yi], [xi, yi - markShiftThree]),
        "WLine1": new makerjs.paths.Line([xi - w, yi], [xi - w, yi - markShiftFour]),
        "WLine2": new makerjs.paths.Line([xi, yi - markShiftTwo], [xi - w, yi - markShiftTwo]),
      }
    };
    var myFillet2IMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xi, yi], [xi + markShiftThree, yi]),//左上角
        "HLine1": new makerjs.paths.Line([xi, yi + h], [xi + markShiftThree, yi + h]),//左上角
        "HLine2": new makerjs.paths.Line([xi + markShiftTwo, yi + h], [xi + markShiftTwo, yi]),//左上角
      }
    };
    var rLineI = new makerjs.paths.Line([xi + aI, yi + bI], [xi, yi + h]);
    var rAngleInDegreesI = makerjs.angle.ofPointInDegrees([xi, yi + h], [xi - w, yi]);//角度
    var myFillet2IMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineI, 90 - rAngleInDegreesI / 2, [xi + aI, yi + bI]),//左上角
      }
    };
    makerjs.model.addCaption(myFillet2CMakerW, TxtW, [xc - w, yc + markShiftThree], [xc, yc + markShiftThree]);//W文字标注
    makerjs.model.addCaption(myFillet2CMakerH, TxtH, [xc + markShiftFour, yc - h], [xc + markShiftFour, yc]);//H文字标注 
    makerjs.model.addCaption(myFillet2CMakerR, TxtR, [xc + aC - r + markShift, yc + b2C - markShift], [xc + aC - r + markShiftFour, yc + b2C - markShift]);//R文字标注
    makerjs.model.addCaption(myFillet2GMakerW, TxtW, [xg, yg - markShiftFour], [xg + w, yg - markShiftFour]);//W文字标注
    makerjs.model.addCaption(myFillet2GMakerH, TxtH, [xg - markShiftFour, yg - h], [xg - markShiftFour, yg]);//H文字标注 
    makerjs.model.addCaption(myFillet2GMakerR, TxtR, [xg + aG + r + markShift, yg - b2G + markShift], [xg + aG + r + markShiftFour, yg - b2G + markShift]);//R文字标注 
    makerjs.model.addCaption(myFillet2IMakerW, TxtW, [xi - w, yi - markShiftFour], [xi, yi - markShiftFour]);//W文字标注
    makerjs.model.addCaption(myFillet2IMakerH, TxtH, [xi + markShiftFour, yi + h], [xi + markShiftFour, yi]);//H文字标注 
    makerjs.model.addCaption(myFillet2IMakerR, TxtR, [xi + aI + markShift, yi + bI + markShift], [xi + aI + markShiftFour, yi + bI + markShift]);//R文字标注 
    var markers = {
      myFillet2CMakerW,
      myFillet2CMakerH,
      myFillet2CMakerR,
      myFillet2GMakerW,
      myFillet2GMakerH,
      myFillet2GMakerR,
      myFillet2IMakerW,
      myFillet2IMakerH,
      myFillet2IMakerR,
    }
  } else if (area == "ACGI") {
    var myFillet2AMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xa, ya], [xa, ya + markShiftThree]),
        "WLine1": new makerjs.paths.Line([xa + w, ya], [xa + w, ya + markShiftFour]),
        "WLine2": new makerjs.paths.Line([xa, ya + markShift], [xa + w, ya + markShift]),
      }
    };
    var myFillet2AMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xa, ya], [xa - markShiftThree, ya]),//左上角
        "HLine1": new makerjs.paths.Line([xa, ya - h], [xa - markShiftThree, ya - h]),//左上角
        "HLine2": new makerjs.paths.Line([xa - markShiftTwo, ya - h], [xa - markShiftTwo, ya]),//左上角
      }
    };
    var rLineA = new makerjs.paths.Line([xa + aA + r, ya + b2A], [xa, ya - h]);
    var rAngleInDegreesA = makerjs.angle.ofPointInDegrees([xa, ya - h], [xa + w, ya]);
    var myFillet2AMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineA, -rAngleInDegreesA, [xa + aA + r, ya + b2A]),//左上角
      }
    };

    var myFillet2CMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xc, yc], [xc, yc + markShiftThree]),
        "WLine1": new makerjs.paths.Line([xc - w, yc], [xc - w, yc + markShiftFour]),
        "WLine2": new makerjs.paths.Line([xc, yc + markShift], [xc - w, yc + markShift]),
      }
    };
    var myFillet2CMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftThree, yc]),//左上角
        "HLine1": new makerjs.paths.Line([xc, yc - h], [xc + markShiftThree, yc - h]),//左上角
        "HLine2": new makerjs.paths.Line([xc + markShiftTwo, yc - h], [xc + markShiftTwo, yc]),//左上角
      }
    };
    var rLineC = new makerjs.paths.Line([xc - (r + aC), yc + b2C], [xc - w, yc]);
    var rAngleInDegreesC = makerjs.angle.ofPointInDegrees([xc, yc - h], [xc - w, yc]);//角度
    var myFillet2CMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineC, -90 + rAngleInDegreesC / 2, [xc - (r + aC), yc + b2C]),//左上角
      }
    };
    var myFillet2GMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xg, yg], [xg, yg - markShiftThree]),
        "WLine1": new makerjs.paths.Line([xg + w, yg], [xg + w, yg - markShiftFour]),
        "WLine2": new makerjs.paths.Line([xg, yg - markShiftTwo], [xg + w, yg - markShiftTwo]),
      }
    };
    var myFillet2GMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftThree, yg]),//左上角
        "HLine1": new makerjs.paths.Line([xg, yg + h], [xg - markShiftThree, yg + h]),//左上角
        "HLine2": new makerjs.paths.Line([xg - markShiftTwo, yg + h], [xg - markShiftTwo, yg]),//左上角
      }
    };
    var rLineG = new makerjs.paths.Line([xg + aG + r, yg - b2G], [xg + w, yg]);
    var rAngleInDegreesG = makerjs.angle.ofPointInDegrees([xg + w, yg], [xg, yg + h]);//角度
    var myFillet2GMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineG, -90 + rAngleInDegreesG / 2, [xg + aG + r, yg - b2G]),//左上角
      }
    };

    var myFillet2IMakerW = {
      paths: {
        "WLine0": new makerjs.paths.Line([xi, yi], [xi, yi - markShiftThree]),
        "WLine1": new makerjs.paths.Line([xi - w, yi], [xi - w, yi - markShiftFour]),
        "WLine2": new makerjs.paths.Line([xi, yi - markShiftTwo], [xi - w, yi - markShiftTwo]),
      }
    };
    var myFillet2IMakerH = {
      paths: {
        "HLine0": new makerjs.paths.Line([xi, yi], [xi + markShiftThree, yi]),//左上角
        "HLine1": new makerjs.paths.Line([xi, yi + h], [xi + markShiftThree, yi + h]),//左上角
        "HLine2": new makerjs.paths.Line([xi + markShiftTwo, yi + h], [xi + markShiftTwo, yi]),//左上角
      }
    };
    var rLineI = new makerjs.paths.Line([xi + aI, yi + bI], [xi, yi + h]);
    var rAngleInDegreesI = makerjs.angle.ofPointInDegrees([xi, yi + h], [xi - w, yi]);//角度
    var myFillet2IMakerR = {
      paths: {
        "RLine": new makerjs.path.rotate(rLineI, 90 - rAngleInDegreesI / 2, [xi + aI, yi + bI]),//左上角
      }
    };
    makerjs.model.addCaption(myFillet2AMakerW, TxtW, [xa, ya + markShiftThree], [xa + w, ya + markShiftThree]);//W文字标注
    makerjs.model.addCaption(myFillet2AMakerH, TxtH, [xa - markShiftFour, ya - h], [xa - markShiftFour, ya]);//H文字标注 
    makerjs.model.addCaption(myFillet2AMakerR, TxtR, [xa + aA + r + markShift, ya + b2A - markShift], [xa + aA + r + markShiftFour, ya + b2A - markShift]);//R文字标注
    makerjs.model.addCaption(myFillet2CMakerW, TxtW, [xc - w, yc + markShiftThree], [xc, yc + markShiftThree]);//W文字标注
    makerjs.model.addCaption(myFillet2CMakerH, TxtH, [xc + markShiftFour, yc - h], [xc + markShiftFour, yc]);//H文字标注 
    makerjs.model.addCaption(myFillet2CMakerR, TxtR, [xc + aC - r + markShift, yc + b2C - markShift], [xc + aC - r + markShiftFour, yc + b2C - markShift]);//R文字标注
    makerjs.model.addCaption(myFillet2GMakerW, TxtW, [xg, yg - markShiftFour], [xg + w, yg - markShiftFour]);//W文字标注
    makerjs.model.addCaption(myFillet2GMakerH, TxtH, [xg - markShiftFour, yg - h], [xg - markShiftFour, yg]);//H文字标注 
    makerjs.model.addCaption(myFillet2GMakerR, TxtR, [xg + aG + r + markShift, yg - b2G + markShift], [xg + aG + r + markShiftFour, yg - b2G + markShift]);//R文字标注 
    makerjs.model.addCaption(myFillet2IMakerW, TxtW, [xi - w, yi - markShiftFour], [xi, yi - markShiftFour]);//W文字标注
    makerjs.model.addCaption(myFillet2IMakerH, TxtH, [xi + markShiftFour, yi + h], [xi + markShiftFour, yi]);//H文字标注 
    makerjs.model.addCaption(myFillet2IMakerR, TxtR, [xi + aI + markShift, yi + bI + markShift], [xi + aI + markShiftFour, yi + bI + markShift]);//R文字标注 
    var markers = {
      myFillet2AMakerW,
      myFillet2AMakerH,
      myFillet2AMakerR,
      myFillet2CMakerW,
      myFillet2CMakerH,
      myFillet2CMakerR,
      myFillet2GMakerW,
      myFillet2GMakerH,
      myFillet2GMakerR,
      myFillet2IMakerW,
      myFillet2IMakerH,
      myFillet2IMakerR,
    }

  }
  return { markers };
};
function Fillet2(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut) {
  var frame = outParam.shapeName;
  console.log("outParam==", outParam)
  console.log("model==", model)
  console.log("inputParam==", inputParam)
  console.log("outParam.shapeName==////////", outParam.shapeName)
  if (typeof (frame) == "undefined") {
    var R = inputParam.R, W = inputParam.W, H = inputParam.H, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
      plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = "Rectangle",
      glassW = outParam.glassW, glassH = outParam.glassH,
      markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
      markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
      w = W * 1 * myZoom,
      h = H * 1 * myZoom,
      r = R * 1 * myZoom,
      rd = r / Math.sqrt(2),
      a = 0,//圆心坐标[a,b]
      b = 0,//圆心坐标[a,b]
      b2 = 0,//圆心坐标[a,b]
      x0 = X * 1 * myZoom,
      y0 = Y * 1 * myZoom;
      if(plate.models===undefined||plate.models===""){
        console.log("1111111111111111");
        plate.models={};
                }        
                if(cutPlate.models===undefined||cutPlate.models===""){
                    console.log("22222222222");
                    //cutPlate.models={};
                    cutPlate.models={};
                }
                if(markers.models===undefined||markers.models===""){            
                    console.log("333333333333");
                    markers.models={};
                }
    if (r !== 0) {
      var TxtR = "R=" + R;
    }
    if (w !== 0) {
      var TxtW = W;
    }
    if (h !== 0) {
      var TxtH = H;
    }
    if (x0 !== 0) {
      var TxtX = X;
    }
    if (y0 !== 0) {
      var TxtY = Y;
    }
  } else {
    var R = inputParam.R, W = inputParam.W, H = inputParam.H, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
      plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = outParam.shapeName,
      glassW = outParam.glassW, glassH = outParam.glassH,
      markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
      markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
      w = W * 1 * myZoom,
      h = H * 1 * myZoom,
      r = R * 1 * myZoom,
      rd = r / Math.sqrt(2),
      a = 0,//圆心坐标[a,b]
      b = 0,//圆心坐标[a,b]
      b2 = 0,//圆心坐标[a,b]
      x0 = X * 1 * myZoom,
      y0 = Y * 1 * myZoom;
      if(plate.models===undefined||plate.models===""){
        console.log("1111111111111111");
        plate.models={};
                }        
                if(cutPlate.models===undefined||cutPlate.models===""){
                    console.log("22222222222");
                    //cutPlate.models={};
                    cutPlate.models={};
                }
                if(markers.models===undefined||markers.models===""){            
                    console.log("333333333333");
                    markers.models={};
                }
    if (r !== 0) {
      var TxtR = "R=" + R;
    }
    if (w !== 0) {
      var TxtW = W;
    }
    if (h !== 0) {
      var TxtH = H;
    }
    if (x0 !== 0) {
      var TxtX = X;
    }
    if (y0 !== 0) {
      var TxtY = Y;
    }
  }

  if (frame == "Rectangle") {
    //在A区域 计算孔的位置       
    var xa = x0, ya = glassH * 1 - y0,
      //C区域
      xc = glassW * 1 - x0, yc = glassH * 1 - y0,
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    var OffsetParam = {};
  } else if (frame == "IsoscelesTrapezoid") {
    //在A区域 计算孔的位置
    var xwd = (glassW * 1 - glassW1 * 1) / 2,
      xa = x0 + xwd, ya = glassH * 1 - y0,
      awd = xwd * h / (glassH * 1),
      cwd = awd,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标   
      //C区域
      xc = glassW * 1 - xwd - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc + awd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - awd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd, ahd: 0, cwd, cwdd: 0, chd: 0, iwd: awd, iwdd: 0, ihd: 0 };
  } else if (frame == "RightAngledTrapezoid") {
    //在A区域 计算孔的位置
    var yhd = glassH * 1 - glassH1 * 1, xwd = glassW * 1 - glassW1 * 1,
      ahd = yhd * w / (glassW1 * 1),//Y方向偏移
      awd = ((glassW1 * 1) - w) * yhd / (glassH * 1), //X方向偏移
      iwd = xwd * h / (glassH * 1), //X方向偏移
      xa = x0, ya = glassH * 1 - y0 - yhd,
      xawx = xa + w, xawy = ya + ahd,//A位置角W坐标
      xahx = xa, xahy = ya - h,//A位置角H坐标 
      //C区域
      xc = glassW * 1 - xwd - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc - ahd,//C位置角W坐标
      xchx = xc + iwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - iwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd, cwd: iwd, cwdd: 0, chd: 0, iwd, iwdd: 0, ihd: 0 };
  } else if (frame == "OtherTrapezoidsA") {
    //在A区域 计算孔的位置
    var yhd = glassH1 * 1 - glassH2 * 1,
      ahd = yhd * w / ((glassW * 1) - (glassW1 * 1) - (glassW2 * 1)),//Y方向偏移
      awd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移 A 
      iwd = (glassW2 * 1) * h / (glassH2 * 1), //X方向偏移
      gwd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移


      xa = x0 + glassW1 * 1, ya = glassH1 * 1 - y0,
      xawx = xa + w, xawy = ya - ahd,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标 
      //C区域
      xc = glassW * 1 - glassW2 * 1 - x0, yc = glassH2 * 1 - y0,
      xcwx = xc - w, xcwy = yc + ahd,//C位置角W坐标
      xchx = xc + iwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + gwd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - iwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd: 0, cwd: iwd, cwdd: 0, chd: ahd, iwd, iwdd: 0, ihd: 0 };
  } else if (frame == "OtherTrapezoidsB") {
    //在A区域 计算孔的位置
    var yhd = glassH1 * 1 - glassH2 * 1,
      ahd = yhd * w / ((glassW * 1) - (glassW1 * 1) - (glassW2 * 1)),//Y方向偏移
      awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A 
      cwd = (glassW2 * 1) * h / (glassH * 1), //X方向偏移 A 
      iwd = (glassW2 * 1) * h / (glassH2 * 1), //X方向偏移
      gwd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 - glassW2 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc + cwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - cwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd: 0, cwd, cwdd: 0, chd: 0, iwd: cwd, iwdd: 0, ihd: 0 };
  } else if (frame == "ParallelogramA") {
    //在A区域 计算孔的位置
    var awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 + glassW1 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc - awd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi + awd, xihy = yi + h;//I位置角H坐标

    var OffsetParam = { awd: 0, ahd: 0, cwd: 0, cwdd: awd, chd: 0, iwd: 0, iwdd: awd, ihd: 0 };

  } else if (frame == "ParallelogramB") {
    console.log("markers.models=", markers.models)
    //在A区域 计算孔的位置
    var awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A  G
      cwd = (glassW2 * 1) * h / (glassH * 1), //X方向偏移 C  I 
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 + glassW2 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc - cwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi + cwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd: 0, cwd: 0, cwdd: awd, chd: 0, iwd: 0, iwdd: cwd, ihd: 0 };

  }

  // 坐标参数
  var paramCoordinate = { w, h, r, x0, y0, rd, xa, ya, xc, yc, xg, yg, xi, yi };
  const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(model.plate.models)));
  var areaPoint = "";
  console.log("//画1、斜角 ------", allKeys)
  if (allKeys[0] == frame) {
    allKeys.shift();//去掉数组第一个元素
  }
  console.log("//画1、斜角 位置去掉数组第一个元素------", allKeys)
  if (allKeys.length == 0) {
    switch (area) {
      case "1": areaPoint = "A"; break;
      case "3": areaPoint = "C"; break;
      case "7": areaPoint = "G"; break;
      case "9": areaPoint = "I"; break;
    }
  } else {
    switch (area) {
      case "1": areaPoint = getStrs("A", allKeys); break;
      case "3": areaPoint = getStrs("C", allKeys); break;
      case "7": areaPoint = getStrs("G", allKeys); break;
      case "9": areaPoint = getStrs("I", allKeys); break;
    }
  }
  /**
  * 画图
  * 
  */
   var ObjName = "myFillet2" + areaPoint;
   var ObjNameM = "myFillet2" + areaPoint + "Mark";//外框标记
   var ObjNameMmyFillet2 = "myFillet2" + areaPoint + "MarkFillet2";//角标记
   var ObjNameO = "myFillet2" + areaPoint + "Obj";
   ObjName = creatmyFillet2(areaPoint, paramCoordinate);//图形
   console.log("ObjName===|||||||||", ObjName);
   ObjNameMmyFillet2 = creatmyFillet2Mark(outParam, areaPoint, paramCoordinate, inputParam, fixedParam, OffsetParam, zoom);//图形
   console.log("ObjNameMmyFillet2===|||||||||角标注", ObjNameMmyFillet2);
   ObjNameO = { ObjName };
   ObjNameM = RectangleMark(outParam, model, fixedParam, zoom, frame);//外框标记
   cutPlate.models = {};
   plate.models = {};
   markers.models = {};
   cutPlate.models["myFillet2" + areaPoint] = ObjName;
   plate.models["myFillet2" + areaPoint] = ObjName;
 
   console.log("ObjNameM.markers.models===|||||||||是否有所有标注", ObjNameM.markers.models);
   console.log("ObjNameMmyFillet2.markers===|||||||||是否有所有标注", ObjNameMmyFillet2.markers);
 
 
   markers.models = { ...ObjNameM.markers.models, ...ObjNameO, ...ObjNameMmyFillet2.markers };
 
   console.log("markers===|||||||||是否有所有标注", markers);



  myHolesNum++;
  return { plate, cutPlate, markers, myHolesNum }
};
export default Fillet2;