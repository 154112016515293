//外形：直角梯形
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
//(param,model,fixedParam,this.zoomM)
function RightAngledTrapezoid(inputParam, model, fixedParam, zoom) {
    console.log("RightAngledTrapezoid*inputParam=", inputParam);
    console.log("RightAngledTrapezoid*model=", model);
    console.log("RightAngledTrapezoid*fixedParam=", fixedParam);
    console.log("RightAngledTrapezoid*zoom=", zoom);
    var H = inputParam.H, H1 = inputParam.H1, W = inputParam.W, W1 = inputParam.W1, myZoom = zoom * 1,
        plate = model.plate, markers = model.markers,
        markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
        markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,

        h = H * 1 * myZoom,
        h1 = H1 * 1 * myZoom,
        w = W * 1 * myZoom,
        w1 = W1 * 1 * myZoom,
        // x0 = X*1*myZoom,
        // y0 = Y*1*myZoom;
        x0 = 0, x1 = 0, x2 = w1, x3 = w, y0 = 0, y1 = h1, y2 = h, y3 = 0;
    var glassH = h, glassW = w;

    if (h !== 0) {
        var TxtH = H;
    } if (h1 !== 0) {
        var TxtH1 = H1;
    }
    if (w !== 0) {
        var TxtW = W;
    }
    if (w1 !== 0) {
        var TxtW1 = W1;
    }


    console.log("inputParam=", inputParam);
    console.log("inputParam.H=", inputParam.H);

    var myRATrapezoid = {
        paths: {
            "LeftLine": new makerjs.paths.Line([x0, y0], [x1, y1]),
            "TopLine": new makerjs.paths.Line([x1, y1], [x2, y2]),
            "RightLine": new makerjs.paths.Line([x2, y2], [x3, y3]),
            "BottomLine": new makerjs.paths.Line([x3, y3], [x0, y0]),
        }
    };
    var RightAngledTrapezoidMarkH = {
        paths: {
            "RightLine": new makerjs.paths.Line([x3+ markShift, y3], [x3+ markShift, y2]),
            "RightShortUpline": new makerjs.paths.Line([x2, y2], [x3+ 2 * markShift, y2]),
            "RightShortDnline": new makerjs.paths.Line([x3, y3], [x3+ 2 * markShift, y3]),
        }
    };
    var RightAngledTrapezoidMarkH1 = {
        paths: {
            "LeftLine": new makerjs.paths.Line([x0 - markShift, y0], [x0 - markShift, y1]),
            "LeftShortUpline": new makerjs.paths.Line([x1, y1], [x0 - 2 * markShift, y1]),
            "LeftShortDnline": new makerjs.paths.Line([x0, y0], [x0 - 2 * markShift, y0]),
        }
    };
    var RightAngledTrapezoidMarkW = {
        paths: {
            "BottomLine": new makerjs.paths.Line([x0, y0 - markShift], [x3, y3 - markShift]),
            "BotmShortLfline": new makerjs.paths.Line([x0, y0], [x0, y0 - 2 * markShift]),
            "BotmShortRTline": new makerjs.paths.Line([x3, y3], [x3, y3 - 2 * markShift]),
        }
    };
    var RightAngledTrapezoidMarkW1 = {
        paths: {
            "UpLine": new makerjs.paths.Line([x1, y2 + markShift], [x2, y2 + markShift]),
            "UpShortLfline": new makerjs.paths.Line([x1, y1], [x1, y2 + 2 * markShift]),
            "UpShortRTline": new makerjs.paths.Line([x2, y2], [x2, y2 + 2 * markShift]),
        }
    };
    makerjs.model.addCaption(RightAngledTrapezoidMarkW, TxtW, [x0, y0 - 2 * markShift], [x3, y3 - 2 * markShift]);//长度文字  
    makerjs.model.addCaption(RightAngledTrapezoidMarkW1, TxtW1, [x1, y2 + 2 * markShift], [x2, y2 + 2 * markShift]);//长度文字
    makerjs.model.addCaption(RightAngledTrapezoidMarkH, TxtH, [x3 + 2 * markShift, y2], [x3 + 2 * markShift, y3]);//宽度文字
    makerjs.model.addCaption(RightAngledTrapezoidMarkH1, TxtH1, [x0 - 2 * markShift, y0], [x0 - 2 * markShift, y1]);//宽度文字
    // markers = {
    //     models: {
    //         RightAngledTrapezoidMarkH1,
    //         RightAngledTrapezoidMarkH,
    //         RightAngledTrapezoidMarkW,
    //         RightAngledTrapezoidMarkW1
    //     }
    // }
    plate.models['RightAngledTrapezoid'] = myRATrapezoid;
    markers.models['RightAngledTrapezoid'] = myRATrapezoid;
    markers.models['RightAngledTrapezoidMarkH1'] = RightAngledTrapezoidMarkH1;
    markers.models['RightAngledTrapezoidMarkH'] = RightAngledTrapezoidMarkH;
    markers.models['RightAngledTrapezoidMarkW'] = RightAngledTrapezoidMarkW;
    markers.models['RightAngledTrapezoidMarkW1'] = RightAngledTrapezoidMarkW1;
    /*****************************************************************************/
    return { plate, markers, glassW, glassH }
};
export default RightAngledTrapezoid;