//角5、地弹簧
import makerjs from "makerjs";
import RectangleMark from "./RectangleMark";
//获取角的字符串判断是哪个角
function getStrs(area, strs) {
  console.log("是否调用此函数getStrs（）")
  var arrA = [];
  var TA = strs[0].slice(8);
  TA = TA + area;
  arrA = Array.from(TA);
  console.log("是否调用此函数getStrs()arrA==", arrA)
  arrA = arrA.sort();
  arrA = arrA.join("");
  console.log("是否调用此函数getStrs()areaPoint arrA==", arrA)
  return arrA;
};
// 矩形等去角 连线函数
function creatFloorSpring(area, param) {
  console.log("是否调用此函数creatOneCorner（）");
  console.log("是否调用此函数creatOneCorner（）param=", param);
  var xa = param.xa, ya = param.ya, xawx1 = param.xawx1, xawy1 = param.xawy1,xawx2 = param.xawx2, xawy2 = param.xawy2,xawx3 = param.xawx3, xawy3 = param.xawy3,xawx4 = param.xawx4, xawy4 = param.xawy4,
    xc = param.xc, yc = param.yc, xcwx1 = param.xcwx1, xcwy1 = param.xcwy1,xcwx2 = param.xcwx2, xcwy2 = param.xcwy2,xcwx3 = param.xcwx3, xcwy3 = param.xcwy3,xcwx4 = param.xcwx4, xcwy4 = param.xcwy4,
    xg = param.xg, yg = param.yg, xgwx1 = param.xgwx1, xgwy1 = param.xgwy1, xgwx2 = param.xgwx2, xgwy2 = param.xgwy2,xgwx3 = param.xgwx3, xgwy3 = param.xgwy3,xgwx4 = param.xgwx4, xgwy4 = param.xgwy4,
    xi = param.xi, yi = param.yi, xiwx1 = param.xiwx1, xiwy1 = param.xiwy1, xiwx2 = param.xiwx2, xiwy2 = param.xiwy2,xiwx3 = param.xiwx3, xiwy3 = param.xiwy3,xiwx4 = param.xiwx4, xiwy4 = param.xiwy4,
    d = param.d,r = param.r,r1 = param.r1,r2 = param.r2,w = param.w,w1 = param.w1,w2 = param.w2,h = param.h,h1 = param.h1;
  if (area == "A") {
    var myFloorSpring = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2,false,true),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xc, yc]),
        "RithgLineA": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "BottomLineA": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1, ya-h1], r),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2,false,true),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xc, yc]),
        // "RithgLineA": new makerjs.paths.Line([xc, yc], [xi, yi]),
        // "BottomLineA": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1, ya-h1], r),
      }
    };

    //   var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xi, yi]]);
  } else if (area == "C") {
    var myFloorSpring = {
      paths: {
        "RightLineC": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx4, xcwy4]),   
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2,false,true ),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1,false,false),//是否大弧,是否顺时针
        "TopRithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        "BottomLineC": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesC": new makerjs.paths.Circle([xc-w1, yc-h1], r),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "RightLineC": new makerjs.paths.Line([xg, yg], [xa, ya]),
        // "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx4, xcwy4]),   
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2,false,true ),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1,false,false),//是否大弧,是否顺时针
        "TopRithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        // "BottomLineC": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesC": new makerjs.paths.Circle([xc-w1, yc-h1], r),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi]]);
  } else if (area == "G") {
    var myFloorSpring = {
      paths: {
        "LeftLineG": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        "TopLeftLineG": new makerjs.paths.Line([xa, ya],[xc, yc]),
        "RithgLineG": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx4, xgwy4]),   
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesG": new makerjs.paths.Circle([xg+w1, yg+h1], r),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "LeftLineG": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        // "TopLeftLineG": new makerjs.paths.Line([xa, ya],[xc, yc]),
        // "RithgLineG": new makerjs.paths.Line([xc, yc], [xi, yi]),
        // "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx4, xgwy4]),   
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesG": new makerjs.paths.Circle([xg+w1, yg+h1], r),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "I") {
    var myFloorSpring = {
      paths: {        
        "LeftLineI": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLeftLineI": new makerjs.paths.Line([xa, ya],[xc, yc]),
        "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1,false,true),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xg, xg]),
        "RightHolesI": new makerjs.paths.Circle([xi-w1, yi+h1], r),
      }
    };
    var myFloorSpringCut = {
      paths: {        
        // "LeftLineI": new makerjs.paths.Line([xg, yg], [xa, ya]),
        // "TopLeftLineI": new makerjs.paths.Line([xa, ya],[xc, yc]),
        // "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1,false,true),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xg, xg]),
        "RightHolesI": new makerjs.paths.Circle([xi-w1, yi+h1], r),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AC") {
    var myFloorSpring = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2,false,true),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xcwx4, xcwy4]),  
         "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1,false,false),//是否大弧,是否顺时针
        "TopRithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        "BottomLineA": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1, ya-h1], r),
        "RightHolesC": new makerjs.paths.Circle([xc-w1, yc-h1], r),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2,false,true),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xcwx4, xcwy4]),  
         "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1,false,false),//是否大弧,是否顺时针
        "TopRithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        // "BottomLineA": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1, ya-h1], r),
        "RightHolesC": new makerjs.paths.Circle([xc-w1, yc-h1], r),
      }
    };
    // var myFillet = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi]]);
  } else if (area == "AG") {
    var myFloorSpring = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2,false,true),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xc, yc]),
        "RithgLineA": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx4, xgwy4]),   
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1, ya-h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg+w1, yg+h1], r),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2,false,true),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xc, yc]),
        // "RithgLineA": new makerjs.paths.Line([xc, yc], [xi, yi]),
        // "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx4, xgwy4]),   
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1, ya-h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg+w1, yg+h1], r),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "AI") {
    var myFloorSpring = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2,false,true),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xc, yc]),
        "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1,false,true),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xg, xg]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1, ya-h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi-w1, yi+h1], r),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2,false,true),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xc, yc]),
        // "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1,false,true),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xg, xg]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1, ya-h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi-w1, yi+h1], r),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "CG") {
    var myFloorSpring = {
      paths: {
        "RightLineC": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx4, xcwy4]),   
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1,false,false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx4, xgwy4]),   
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesC": new makerjs.paths.Circle([xc-w1, yc-h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg+w1, yg+h1], r),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "RightLineC": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        // "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx4, xcwy4]),   
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1,false,false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        // "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx4, xgwy4]),   
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesC": new makerjs.paths.Circle([xc-w1, yc-h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg+w1, yg+h1], r),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "CI") {
    var myFloorSpring = {
      paths: {
        "RightLineC": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx4, xcwy4]),   
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1,false,false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1,false,true),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xg, xg]),
        "RightHolesC": new makerjs.paths.Circle([xc-w1, yc-h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi-w1, yi+h1], r),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "RightLineC": new makerjs.paths.Line([xg, yg], [xa, ya]),
        // "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx4, xcwy4]),   
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1,false,false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1,false,true),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xg, xg]),
        "RightHolesC": new makerjs.paths.Circle([xc-w1, yc-h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi-w1, yi+h1], r),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "GI") {
    var myFloorSpring = {
      paths: {
        "LeftLineG": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        "TopLeftLineG": new makerjs.paths.Line([xa, ya],[xc, yc]),
        "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1,false,true),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xgwx4, xgwy4]),
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesG": new makerjs.paths.Circle([xg+w1, yg+h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi-w1, yi+h1], r),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "LeftLineG": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        // "TopLeftLineG": new makerjs.paths.Line([xa, ya],[xc, yc]),
        // "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1,false,true),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xgwx4, xgwy4]),
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesG": new makerjs.paths.Circle([xg+w1, yg+h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi-w1, yi+h1], r),
      }
    };
    // var myFillet = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACG") {
    var myFloorSpring = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2,false,true),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xcwx4, xcwy4]),
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1,false,false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx4, xgwy4]),      
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1, ya-h1], r),
        "RightHolesC": new makerjs.paths.Circle([xc-w1, yc-h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg+w1, yg+h1], r),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2,false,true),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xcwx4, xcwy4]),
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1,false,false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        // "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx4, xgwy4]),      
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1, ya-h1], r),
        "RightHolesC": new makerjs.paths.Circle([xc-w1, yc-h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg+w1, yg+h1], r),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "ACI") {
    var myFloorSpring = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2,false,true),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xcwx4, xcwy4]),
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1,false,false),//是否大弧,是否顺时针
        "RithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "BottomLineI0": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]), 
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1,false,true),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xg, xg]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1, ya-h1], r),
        "RightHolesC": new makerjs.paths.Circle([xc-w1, yc-h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi-w1, yi+h1], r),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2,false,true),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xcwx4, xcwy4]),
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1,false,false),//是否大弧,是否顺时针
        "RithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "BottomLineI0": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]), 
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1,false,true),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xg, xg]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1, ya-h1], r),
        "RightHolesC": new makerjs.paths.Circle([xc-w1, yc-h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi-w1, yi+h1], r),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AGI") {
    var myFloorSpring = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2,false,true),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xc, yc]),
        "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1,false,true),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xgwx4, xgwy4]),  
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1, ya-h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg+w1, yg+h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi-w1, yi+h1], r),
      }
    };

    var myFloorSpringCut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2,false,true),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xc, yc]),
        // "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1,false,true),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xgwx4, xgwy4]),  
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1, ya-h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg+w1, yg+h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi-w1, yi+h1], r),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "CGI") {
    var myFloorSpring = {
      paths: {
        "RightLineC": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx4, xcwy4]),   
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1,false,false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1,false,true),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xgwx4, xgwy4]),
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesC": new makerjs.paths.Circle([xc-w1, yc-h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg+w1, yg+h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi-w1, yi+h1], r),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "RightLineC": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        // "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx4, xcwy4]),   
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1,false,false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1,false,true),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xgwx4, xgwy4]),
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesC": new makerjs.paths.Circle([xc-w1, yc-h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg+w1, yg+h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi-w1, yi+h1], r),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACGI") {
    var myFloorSpring = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2,false,true),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xcwx4, xcwy4]),
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1,false,false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1,false,true),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xgwx4, xgwy4]),   
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1, ya-h1], r),
        "RightHolesC": new makerjs.paths.Circle([xc-w1, yc-h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg+w1, yg+h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi-w1, yi+h1], r),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2,false,true),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xcwx4, xcwy4]),
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1,false,false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1,false,true),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xgwx4, xgwy4]),   
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1, ya-h1], r),
        "RightHolesC": new makerjs.paths.Circle([xc-w1, yc-h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg+w1, yg+h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi-w1, yi+h1], r),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  }
  return [myFloorSpring,myFloorSpringCut];
};
// 矩形等去角标记 连线函数
function creatFloorSpringMark(outParam, area, param, inputParam, fixedParam, OffsetParam, zoom) {
  console.log("是否调用此函数creatCornerMark()");
  console.log("是否调用此函数param", param);
  console.log("是否调用此函数outParam", outParam);
  console.log("是否调用此函数inputParam", inputParam);
  console.log("是否调用此函数OffsetParam", OffsetParam);
  var HH = outParam.H, WW = outParam.W, D = inputParam.D,H = inputParam.H, H1 = inputParam.H1, W = inputParam.W, W1 = inputParam.W1, W2 = inputParam.W2, W2 = inputParam.W2, R = inputParam.R, R1 = inputParam.R1, R2 = inputParam.R2,
    markShiftTwo = fixedParam.markShiftTwo * 1, myZoom = zoom * 1,
    // rd = OffsetParam.rMark * 1,
      Aa=OffsetParam.Aa*1,Aaa=OffsetParam.Aaa*1,Ab=OffsetParam.Ab*1,Ahd=OffsetParam.Ahd*1,
      Ca=OffsetParam.a*1,Caa=OffsetParam.Caa*1,Cb=OffsetParam.Cb*1,Chd=OffsetParam.Chd*1,
      Ga=OffsetParam.Ga*1,Gaa=OffsetParam.Gaa*1,Gb=OffsetParam.Gb*1,Ghd=OffsetParam.Ghd*1,
      Ia=OffsetParam.Ia*1,Iaa=OffsetParam.Iaa*1,Ib=OffsetParam.Ib*1,Ihd=OffsetParam.Ihd*1,
    //   ihd=OffsetParam.ihd*1,iwd=OffsetParam.iwd*1,iwdd=OffsetParam.iwdd*1,
    xa = param.xa, ya = param.ya, xawx1 = param.xawx1, xawy1 = param.xawy1,xawx2 = param.xawx2, xawy2 = param.xawy2,xawx3 = param.xawx3, xawy3 = param.xawy3,xawx4 = param.xawx4, xawy4 = param.xawy4,
    xc = param.xc, yc = param.yc, xcwx1 = param.xcwx1, xcwy1 = param.xcwy1,xcwx2 = param.xcwx2, xcwy2 = param.xcwy2,xcwx3 = param.xcwx3, xcwy3 = param.xcwy3,xcwx4 = param.xcwx4, xcwy4 = param.xcwy4,
    xg = param.xg, yg = param.yg, xgwx1 = param.xgwx1, xgwy1 = param.xgwy1, xgwx2 = param.xgwx2, xgwy2 = param.xgwy2,xgwx3 = param.xgwx3, xgwy3 = param.xgwy3,xgwx4 = param.xgwx4, xgwy4 = param.xgwy4,
    xi = param.xi, yi = param.yi, xiwx1 = param.xiwx1, xiwy1 = param.xiwy1, xiwx2 = param.xiwx2, xiwy2 = param.xiwy2,xiwx3 = param.xiwx3, xiwy3 = param.xiwy3,xiwx4 = param.xiwx4, xiwy4 = param.xiwy4,
    r = param.r,r1 = param.r1,r2 = param.r2,w = param.w,w1 = param.w1,w2 = param.w2,h = param.h,h1 = param.h1,    
    markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
    markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
    
    d = D * 1 * myZoom,
    h = H * 1 * myZoom,
    h1 = H1 * 1 * myZoom,
    w = W * 1 * myZoom,
    w1 = W1 * 1 * myZoom,
    w2 = W2 * 1 * myZoom,
    r = R * 1 * myZoom,
    r1 = R1 * 1 * myZoom,
    r2 = R2 * 1 * myZoom,

    hh = HH * 1 * myZoom,
    ww = WW * 1 * myZoom;
    if (r !== 0) {
      var TxtR = "R=" + R;
    }if (r1 !== 0) {
      var TxtR1 = "R1=" + R1;
    }if (r2 !== 0) {
      var TxtR2 = "R2=" + R2;
    }if (w !== 0) {
      var TxtW = "W=" + W;
    }if (w1 !== 0) {
      var TxtW1 = "W1=" + W1;
    }if (w2 !== 0) {
      var TxtW2 = "W2=" + W2;
    }if (h !== 0) {
      var TxtH = "H=" + H;
    }if (h1 !== 0) {
      var TxtH1 = "H1=" + H1;
    }if (d !== 0) {
      var TxtD = "D=" + D;
    }

  if (area == "A") {    
 var myFsAMakerWA = {
    paths: {
      "WALine0": new makerjs.paths.Line([xa,ya-h], [xa,ya-h-markShiftThree]),//左上角
      "WALine1": new makerjs.paths.Line([xa+w,ya-h], [xa+w,ya-h-markShiftThree]),//左上角
      "WALine2":new makerjs.paths.Line([xa,ya-h-markShift], [xa+w,ya-h-markShift]),
    }
  };
  var myFsAMakerW1A = {
    paths: {
     "W1ALine0":new makerjs.paths.Line([xa,ya-h-d-markShift], [xa+w1,ya-h-d-markShift]),
     "W1ALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1,ya-h1-d-markShiftThree]),//左上角
    }
  };
  var myFsAMakerW2A = {
    paths: {
     "W2ALine0":new makerjs.paths.Line([xa,ya-h-h-markShiftThree], [xa+w2,ya-h-h-markShiftThree]),
      "W2ALine1": new makerjs.paths.Line([xa+w2,ya], [xa+w2,ya-h-h-d-markShiftFour]),//左上角
    }
  };
  var myFsAMakerHA = {
    
    paths: {
     "HALine0":new makerjs.paths.Line([xa,ya], [xa-markShiftFour, ya]),
     "HALine1": new makerjs.paths.Line([xa,ya-h], [xa-markShiftFour, ya-h]),//左上角
     "HALine2": new makerjs.paths.Line([xa-markShiftTwo,ya], [xa-markShiftTwo,ya-h]),//左上角
    }
  };
  var myFsAMakerH1A = {    
    paths: {
     "H1ALine0":new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+d/2+markShiftFour, ya-h1]),
     "H1ALine1": new makerjs.paths.Line([xa+w2+markShift,ya], [xa+w2+markShift,ya-h1]),//左上角
    }
  };
  var myFsAMakerR2A = {    
    paths: {
     "R2ALine1": new makerjs.paths.Line([xa+w2,ya], [xa+w2, ya-r2-markShiftFour]),//左上角
     "R2ALine2": new makerjs.paths.Line([xa+Aa-markShiftTwo,ya], [xa+Aa,ya-Ahd]),//左上角
    }
  };
  var myFsAMakerR1A = {    
    paths: {
     "R1ALine1": new makerjs.paths.Line([xa+w,ya-Ahd], [xa+w+markShift,ya-Ahd-markShift]),//左上角
    }
  };
  var myFsAMakerDA = {    
    paths: {
     "DALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD]),//左上角
    }
  };
  makerjs.model.addCaption(myFsAMakerWA, TxtW, [xa,ya-h-markShiftThree], [xa+w,ya-h-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1A, TxtW1, [xa,ya-h-d-markShiftThree], [xa+w1,ya-h-d-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2A, TxtW2, [xa,ya-h-h-d-markShiftFour], [xa+w2,ya-h-h-d-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHA, TxtH, [xa-markShiftFour,ya-h], [xa-markShiftFour,ya]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1A, TxtH1, [xa+w2+markShiftThree,ya-h1], [xa+w2+markShiftThree,ya]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2A, TxtR2, [xa+Aa-markShiftThree,ya-Ahd+markShiftThree], [xa+Aa-markShift,ya-Ahd+markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1A, TxtR1, [xa+w,ya-Ahd], [xa+w+markShift,ya-Ahd-markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDA, TxtD, [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD],[xa+w1+markHolesOneD+markShiftFour,ya-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    var markers = {
      myFsAMakerWA,
      myFsAMakerW1A,
       myFsAMakerW2A,
       myFsAMakerHA,
      myFsAMakerH1A,
       myFsAMakerR2A,
      myFsAMakerR1A,
      myFsAMakerDA,
    }

  } else if (area == "C") {
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc,yc-h], [xc,yc-h-markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc-w,yc-h], [xc-w,yc-h-markShiftThree]),//左上角
        "WCLine2":new makerjs.paths.Line([xc,yc-h-markShift], [xc-w,yc-h-markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
       "W1CLine0":new makerjs.paths.Line([xc,yc-h-d-markShift], [xc-w1,yc-h-d-markShift]),
       "W1CLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1,yc-h1-d-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
       "W2CLine0":new makerjs.paths.Line([xc,yc-h-h-markShiftThree], [xc-w2,yc-h-h-markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2,yc-h-h-d-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {      
      paths: {
       "HCLine0":new makerjs.paths.Line([xc,yc], [xc+markShiftFour, yc]),
       "HCLine1": new makerjs.paths.Line([xc,yc-h], [xc+markShiftFour, yc-h]),//左上角
       "HCLine2": new makerjs.paths.Line([xc+markShiftTwo,yc], [xc+markShiftTwo,yc-h]),//左上角
      }
    };
    var myFsAMakerH1C = {    
      paths: {
       "H1CLine0":new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-d/2-markShiftFour, yc-h1]),
       "H1CLine1": new makerjs.paths.Line([xc-w2-markShift,yc], [xc-w2-markShift,yc-h1]),//左上角
      }
    };
    var myFsAMakerR2C = {    
      paths: {
      //  "R2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2, yc-r2-markShiftFour]),//左上角
       "R2CLine2": new makerjs.paths.Line([xc-Caa+markShiftTwo,yc], [xc-Caa,yc-Chd]),//左上角
      }
    };
    var myFsAMakerR1C = {    
      paths: {
       "R1CLine1": new makerjs.paths.Line([xc-w,yc-Chd], [xc-w-markShift,yc-Chd-markShift]),//左上角
      }
    };
    var myFsAMakerDC = {    
      paths: {
       "DCLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    makerjs.model.addCaption(myFsAMakerWC, TxtW, [xc-w,yc-h-markShiftThree], [xc,yc-h-markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1C, TxtW1, [xc-w1,yc-h-d-markShiftThree], [xc,yc-h-d-markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2C, TxtW2, [xc-w2,yc-h-h-d-markShiftFour], [xc,yc-h-h-d-markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHC, TxtH, [xc+markShiftFour,yc-h], [xc+markShiftFour,yc]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1C, TxtH1, [xc-w2-markShiftThree,yc-h1], [xc-w2-markShiftThree,yc]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR2C, TxtR2, [xc-Caa+markShift,yc-Chd+markShift], [xc-Caa+markShiftThree,yc-Chd+markShiftThree]);//R2文字标注
    makerjs.model.addCaption(myFsAMakerR1C, TxtR1, [xc-w-markShift,yc-Chd-markShift], [xc-w,yc-Chd]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDC, TxtD,[xc-w1-markHolesOneD-markShiftFour,yc-h1-d-markShiftFour-markHolesOneD], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
      var markers = {
        myFsAMakerWC,
        myFsAMakerW1C,
         myFsAMakerW2C,
         myFsAMakerHC,
        myFsAMakerH1C,
         myFsAMakerR2C,
        myFsAMakerR1C,
        myFsAMakerDC,
      }
  } else if (area == "G") {
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg,yg+h], [xg,yg+h+markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg+w,yg+h], [xg+w,yg+h+markShiftThree]),//左上角
        "WGLine2":new makerjs.paths.Line([xg,yg+h+markShift], [xg+w,yg+h+markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0":new makerjs.paths.Line([xg,yg+h+d+markShift], [xg+w1,yg+h+d+markShift]),
        "W1GLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1,yg+h1+d+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0":new makerjs.paths.Line([xg,yg+h+h+markShiftThree], [xg+w2,yg+h+h+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg+w2,yg], [xg+w2,yg+h+h+d+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {      
      paths: {
        "HGLine0":new makerjs.paths.Line([xg,yg], [xg-markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg,yg+h], [xg-markShiftFour, yg+h]),//左上角
        "HGLine2": new makerjs.paths.Line([xg-markShiftTwo,yg], [xg-markShiftTwo,yg+h]),//左上角
      }
    };
    var myFsAMakerH1G = {    
      paths: {
        "H1GLine0":new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+d/2+markShiftFour, yg+h1]),
        "H1GLine1": new makerjs.paths.Line([xg+w2+markShift,yg], [xg+w2+markShift,yg+h1]),//左上角
      }
    };
    var myFsAMakerR2G = {    
      paths: {
        "R2GLine1": new makerjs.paths.Line([xg+w2,yg], [xg+w2, yg+r2+markShiftFour]),//左上角
        "R2GLine2": new makerjs.paths.Line([xg+Ga-markShiftTwo,yg], [xg+Ga,yg+Ghd]),//左上角
      }
    };
    var myFsAMakerR1G = {    
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg+w,yg+Ghd], [xg+w+markShift,yg+Ghd+markShift]),//左上角
      }
    };
    var myFsAMakerDG = {    
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };
    makerjs.model.addCaption(myFsAMakerWG, TxtW, [xg,yg+h+markShiftThree], [xg+w,yg+h+markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1G, TxtW1, [xg,yg+h+d+markShiftThree], [xg+w1,yg+h+d+markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2G, TxtW2, [xg,yg+h+h+d+markShiftFour], [xg+w2,yg+h+h+d+markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHG, TxtH, [xg-markShiftFour,yg+h], [xg-markShiftFour,yg]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1G, TxtH1, [xg+w2+markShiftThree,yg+h1], [xg+w2+markShiftThree,yg]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR2G, TxtR2, [xg+Ga-markShiftThree,yg+Ghd-markShiftThree], [xg+Ga-markShift,yg+Ghd-markShift]);//R2文字标注
    makerjs.model.addCaption(myFsAMakerR1G, TxtR1, [xg+w,yg+Ghd], [xg+w+markShift,yg+Ghd+markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDG, TxtD, [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD],[xg+w1+markHolesOneD+markShiftFour,yg+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
      var markers = {
        myFsAMakerWG,
        myFsAMakerW1G,
         myFsAMakerW2G,
         myFsAMakerHG,
        myFsAMakerH1G,
         myFsAMakerR2G,
        myFsAMakerR1G,
        myFsAMakerDG,
      }
    
  } else if (area == "I") {
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi,yi+h], [xi,yi+h+markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi-w,yi+h], [xi-w,yi+h+markShiftThree]),//左上角
        "WILine2":new makerjs.paths.Line([xi,yi+h+markShift], [xi-w,yi+h+markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
       "W1ILine0":new makerjs.paths.Line([xi,yi+h+d+markShift], [xi-w1,yi+h+d+markShift]),
       "W1ILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1,yi+h1+d+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
       "W2ILine0":new makerjs.paths.Line([xi,yi+h+h+markShiftThree], [xi-w2,yi+h+h+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2,yi+h+h+d+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {      
      paths: {
       "HILine0":new makerjs.paths.Line([xi,yi], [xi+markShiftFour, yi]),
       "HILine1": new makerjs.paths.Line([xi,yi+h], [xi+markShiftFour, yi+h]),//左上角
       "HILine2": new makerjs.paths.Line([xi+markShiftTwo,yi], [xi+markShiftTwo,yi+h]),//左上角
      }
    };
    var myFsAMakerH1I = {    
      paths: {
       "H1ILine0":new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-d/2-markShiftFour, yi+h1]),
       "H1ILine1": new makerjs.paths.Line([xi-w2-markShift,yi], [xi-w2-markShift,yi+h1]),//左上角
      }
    };
    var myFsAMakerR2I = {    
      paths: {
      //  "R2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2, yi-r2-markShiftFour]),//左上角
       "R2ILine2": new makerjs.paths.Line([xi-Iaa+markShiftTwo,yi], [xi-Iaa,yi+Ihd]),//左上角
      }
    };
    var myFsAMakerR1I = {    
      paths: {
       "R1ILine1": new makerjs.paths.Line([xi-w,yi+Ihd], [xi-w-markShift,yi+Ihd+markShift]),//左上角
      }
    };
    var myFsAMakerDI = {    
      paths: {
       "DILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };
    makerjs.model.addCaption(myFsAMakerWI, TxtW, [xi-w,yi+h+markShiftThree], [xi,yi+h+markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1I, TxtW1, [xi-w1,yi+h+d+markShiftThree], [xi,yi+h+d+markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2I, TxtW2, [xi-w2,yi+h+h+d+markShiftFour], [xi,yi+h+h+d+markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHI, TxtH, [xi+markShiftFour,yi+h], [xi+markShiftFour,yi]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1I, TxtH1, [xi-w2-markShiftThree,yi+h1], [xi-w2-markShiftThree,yi]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR2I, TxtR2, [xi-Iaa+markShift,yi+Ihd-markShift], [xi-Iaa+markShiftThree,yi+Ihd-markShiftThree]);//R2文字标注
    makerjs.model.addCaption(myFsAMakerR1I, TxtR1, [xi-w-markShift,yi+Ihd+markShift], [xi-w,yi+Ihd]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDI, TxtD,[xi-w1-markHolesOneD-markShiftFour,yi+h1+d+markShiftFour+markHolesOneD], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
      var markers = {
        myFsAMakerWI,
        myFsAMakerW1I,
         myFsAMakerW2I,
         myFsAMakerHI,
        myFsAMakerH1I,
         myFsAMakerR2I,
        myFsAMakerR1I,
        myFsAMakerDI,
      }   
    
  } else if (area == "AC") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa,ya-h], [xa,ya-h-markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa+w,ya-h], [xa+w,ya-h-markShiftThree]),//左上角
        "WALine2":new makerjs.paths.Line([xa,ya-h-markShift], [xa+w,ya-h-markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
       "W1ALine0":new makerjs.paths.Line([xa,ya-h-d-markShift], [xa+w1,ya-h-d-markShift]),
       "W1ALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1,ya-h1-d-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
       "W2ALine0":new makerjs.paths.Line([xa,ya-h-h-markShiftThree], [xa+w2,ya-h-h-markShiftThree]),
        "W2ALine1": new makerjs.paths.Line([xa+w2,ya], [xa+w2,ya-h-h-d-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHA = {
      
      paths: {
       "HALine0":new makerjs.paths.Line([xa,ya], [xa-markShiftFour, ya]),
       "HALine1": new makerjs.paths.Line([xa,ya-h], [xa-markShiftFour, ya-h]),//左上角
       "HALine2": new makerjs.paths.Line([xa-markShiftTwo,ya], [xa-markShiftTwo,ya-h]),//左上角
      }
    };
    var myFsAMakerH1A = {    
      paths: {
       "H1ALine0":new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+d/2+markShiftFour, ya-h1]),
       "H1ALine1": new makerjs.paths.Line([xa+w2+markShift,ya], [xa+w2+markShift,ya-h1]),//左上角
      }
    };
    var myFsAMakerR2A = {    
      paths: {
       "R2ALine1": new makerjs.paths.Line([xa+w2,ya], [xa+w2, ya-r2-markShiftFour]),//左上角
       "R2ALine2": new makerjs.paths.Line([xa+Aa-markShiftTwo,ya], [xa+Aa,ya-Ahd]),//左上角
      }
    };
    var myFsAMakerR1A = {    
      paths: {
       "R1ALine1": new makerjs.paths.Line([xa+w,ya-Ahd], [xa+w+markShift,ya-Ahd-markShift]),//左上角
      }
    };
    var myFsAMakerDA = {    
      paths: {
       "DALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*****************  C  *********** */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc,yc-h], [xc,yc-h-markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc-w,yc-h], [xc-w,yc-h-markShiftThree]),//左上角
        "WCLine2":new makerjs.paths.Line([xc,yc-h-markShift], [xc-w,yc-h-markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
       "W1CLine0":new makerjs.paths.Line([xc,yc-h-d-markShift], [xc-w1,yc-h-d-markShift]),
       "W1CLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1,yc-h1-d-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
       "W2CLine0":new makerjs.paths.Line([xc,yc-h-h-markShiftThree], [xc-w2,yc-h-h-markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2,yc-h-h-d-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {      
      paths: {
       "HCLine0":new makerjs.paths.Line([xc,yc], [xc+markShiftFour, yc]),
       "HCLine1": new makerjs.paths.Line([xc,yc-h], [xc+markShiftFour, yc-h]),//左上角
       "HCLine2": new makerjs.paths.Line([xc+markShiftTwo,yc], [xc+markShiftTwo,yc-h]),//左上角
      }
    };
    var myFsAMakerH1C = {    
      paths: {
       "H1CLine0":new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-d/2-markShiftFour, yc-h1]),
       "H1CLine1": new makerjs.paths.Line([xc-w2-markShift,yc], [xc-w2-markShift,yc-h1]),//左上角
      }
    };
    var myFsAMakerR2C = {    
      paths: {
      //  "R2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2, yc-r2-markShiftFour]),//左上角
       "R2CLine2": new makerjs.paths.Line([xc-Caa+markShiftTwo,yc], [xc-Caa,yc-Chd]),//左上角
      }
    };
    var myFsAMakerR1C = {    
      paths: {
       "R1CLine1": new makerjs.paths.Line([xc-w,yc-Chd], [xc-w-markShift,yc-Chd-markShift]),//左上角
      }
    };
    var myFsAMakerDC = {    
      paths: {
       "DCLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /****************** G   ************** */
  

    /**************************************************** */
    
  makerjs.model.addCaption(myFsAMakerWA, TxtW, [xa,ya-h-markShiftThree], [xa+w,ya-h-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1A, TxtW1, [xa,ya-h-d-markShiftThree], [xa+w1,ya-h-d-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2A, TxtW2, [xa,ya-h-h-d-markShiftFour], [xa+w2,ya-h-h-d-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHA, TxtH, [xa-markShiftFour,ya-h], [xa-markShiftFour,ya]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1A, TxtH1, [xa+w2+markShiftThree,ya-h1], [xa+w2+markShiftThree,ya]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2A, TxtR2, [xa+Aa-markShiftThree,ya-Ahd+markShiftThree], [xa+Aa-markShift,ya-Ahd+markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1A, TxtR1, [xa+w,ya-Ahd], [xa+w+markShift,ya-Ahd-markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDA, TxtD, [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD],[xa+w1+markHolesOneD+markShiftFour,ya-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    
  /**************************************************** */

  
  makerjs.model.addCaption(myFsAMakerWC, TxtW, [xc-w,yc-h-markShiftThree], [xc,yc-h-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1C, TxtW1, [xc-w1,yc-h-d-markShiftThree], [xc,yc-h-d-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2C, TxtW2, [xc-w2,yc-h-h-d-markShiftFour], [xc,yc-h-h-d-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHC, TxtH, [xc+markShiftFour,yc-h], [xc+markShiftFour,yc]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1C, TxtH1, [xc-w2-markShiftThree,yc-h1], [xc-w2-markShiftThree,yc]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2C, TxtR2, [xc-Caa+markShift,yc-Chd+markShift], [xc-Caa+markShiftThree,yc-Chd+markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1C, TxtR1, [xc-w-markShift,yc-Chd-markShift], [xc-w,yc-Chd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDC, TxtD,[xc-w1-markHolesOneD-markShiftFour,yc-h1-d-markShiftFour-markHolesOneD], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    

  /******************************************************** */
  var markers = {
    /**A */
        myFsAMakerWA,
        myFsAMakerW1A,
         myFsAMakerW2A,
         myFsAMakerHA,
        myFsAMakerH1A,
         myFsAMakerR2A,
        myFsAMakerR1A,
        myFsAMakerDA,
        /**C */
        myFsAMakerWC,
        myFsAMakerW1C,
         myFsAMakerW2C,
         myFsAMakerHC,
        myFsAMakerH1C,
         myFsAMakerR2C,
        myFsAMakerR1C,
        myFsAMakerDC,
        /** G*/        
       
      }
  

    
  } else if (area == "AG") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa,ya-h], [xa,ya-h-markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa+w,ya-h], [xa+w,ya-h-markShiftThree]),//左上角
        "WALine2":new makerjs.paths.Line([xa,ya-h-markShift], [xa+w,ya-h-markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
       "W1ALine0":new makerjs.paths.Line([xa,ya-h-d-markShift], [xa+w1,ya-h-d-markShift]),
       "W1ALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1,ya-h1-d-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
       "W2ALine0":new makerjs.paths.Line([xa,ya-h-h-markShiftThree], [xa+w2,ya-h-h-markShiftThree]),
        "W2ALine1": new makerjs.paths.Line([xa+w2,ya], [xa+w2,ya-h-h-d-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHA = {
      
      paths: {
       "HALine0":new makerjs.paths.Line([xa,ya], [xa-markShiftFour, ya]),
       "HALine1": new makerjs.paths.Line([xa,ya-h], [xa-markShiftFour, ya-h]),//左上角
       "HALine2": new makerjs.paths.Line([xa-markShiftTwo,ya], [xa-markShiftTwo,ya-h]),//左上角
      }
    };
    var myFsAMakerH1A = {    
      paths: {
       "H1ALine0":new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+d/2+markShiftFour, ya-h1]),
       "H1ALine1": new makerjs.paths.Line([xa+w2+markShift,ya], [xa+w2+markShift,ya-h1]),//左上角
      }
    };
    var myFsAMakerR2A = {    
      paths: {
       "R2ALine1": new makerjs.paths.Line([xa+w2,ya], [xa+w2, ya-r2-markShiftFour]),//左上角
       "R2ALine2": new makerjs.paths.Line([xa+Aa-markShiftTwo,ya], [xa+Aa,ya-Ahd]),//左上角
      }
    };
    var myFsAMakerR1A = {    
      paths: {
       "R1ALine1": new makerjs.paths.Line([xa+w,ya-Ahd], [xa+w+markShift,ya-Ahd-markShift]),//左上角
      }
    };
    var myFsAMakerDA = {    
      paths: {
       "DALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*****************  C  *********** */
   
    /****************** G   ************** */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg,yg+h], [xg,yg+h+markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg+w,yg+h], [xg+w,yg+h+markShiftThree]),//左上角
        "WGLine2":new makerjs.paths.Line([xg,yg+h+markShift], [xg+w,yg+h+markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0":new makerjs.paths.Line([xg,yg+h+d+markShift], [xg+w1,yg+h+d+markShift]),
        "W1GLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1,yg+h1+d+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0":new makerjs.paths.Line([xg,yg+h+h+markShiftThree], [xg+w2,yg+h+h+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg+w2,yg], [xg+w2,yg+h+h+d+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {      
      paths: {
        "HGLine0":new makerjs.paths.Line([xg,yg], [xg-markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg,yg+h], [xg-markShiftFour, yg+h]),//左上角
        "HGLine2": new makerjs.paths.Line([xg-markShiftTwo,yg], [xg-markShiftTwo,yg+h]),//左上角
      }
    };
    var myFsAMakerH1G = {    
      paths: {
        "H1GLine0":new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+d/2+markShiftFour, yg+h1]),
        "H1GLine1": new makerjs.paths.Line([xg+w2+markShift,yg], [xg+w2+markShift,yg+h1]),//左上角
      }
    };
    var myFsAMakerR2G = {    
      paths: {
        "R2GLine1": new makerjs.paths.Line([xg+w2,yg], [xg+w2, yg+r2+markShiftFour]),//左上角
        "R2GLine2": new makerjs.paths.Line([xg+Ga-markShiftTwo,yg], [xg+Ga,yg+Ghd]),//左上角
      }
    };
    var myFsAMakerR1G = {    
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg+w,yg+Ghd], [xg+w+markShift,yg+Ghd+markShift]),//左上角
      }
    };
    var myFsAMakerDG = {    
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /************************   I  ******************* */

    /**************************************************** */
    
  makerjs.model.addCaption(myFsAMakerWA, TxtW, [xa,ya-h-markShiftThree], [xa+w,ya-h-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1A, TxtW1, [xa,ya-h-d-markShiftThree], [xa+w1,ya-h-d-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2A, TxtW2, [xa,ya-h-h-d-markShiftFour], [xa+w2,ya-h-h-d-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHA, TxtH, [xa-markShiftFour,ya-h], [xa-markShiftFour,ya]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1A, TxtH1, [xa+w2+markShiftThree,ya-h1], [xa+w2+markShiftThree,ya]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2A, TxtR2, [xa+Aa-markShiftThree,ya-Ahd+markShiftThree], [xa+Aa-markShift,ya-Ahd+markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1A, TxtR1, [xa+w,ya-Ahd], [xa+w+markShift,ya-Ahd-markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDA, TxtD, [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD],[xa+w1+markHolesOneD+markShiftFour,ya-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    
  /**************************************************** */

  /******************************************************** */
  makerjs.model.addCaption(myFsAMakerWG, TxtW, [xg,yg+h+markShiftThree], [xg+w,yg+h+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1G, TxtW1, [xg,yg+h+d+markShiftThree], [xg+w1,yg+h+d+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2G, TxtW2, [xg,yg+h+h+d+markShiftFour], [xg+w2,yg+h+h+d+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHG, TxtH, [xg-markShiftFour,yg+h], [xg-markShiftFour,yg]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1G, TxtH1, [xg+w2+markShiftThree,yg+h1], [xg+w2+markShiftThree,yg]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2G, TxtR2, [xg+Ga-markShiftThree,yg+Ghd-markShiftThree], [xg+Ga-markShift,yg+Ghd-markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1G, TxtR1, [xg+w,yg+Ghd], [xg+w+markShift,yg+Ghd+markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDG, TxtD, [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD],[xg+w1+markHolesOneD+markShiftFour,yg+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
    
  /******************************************************** */
  
  /******************************************************** */
  var markers = {
    /**A */
        myFsAMakerWA,
        myFsAMakerW1A,
         myFsAMakerW2A,
         myFsAMakerHA,
        myFsAMakerH1A,
         myFsAMakerR2A,
        myFsAMakerR1A,
        myFsAMakerDA,
        /**C */
        /** G*/        
        myFsAMakerWG,
        myFsAMakerW1G,
         myFsAMakerW2G,
         myFsAMakerHG,
        myFsAMakerH1G,
         myFsAMakerR2G,
        myFsAMakerR1G,
        myFsAMakerDG,
        /**I */
      }
  

    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "AI") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa,ya-h], [xa,ya-h-markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa+w,ya-h], [xa+w,ya-h-markShiftThree]),//左上角
        "WALine2":new makerjs.paths.Line([xa,ya-h-markShift], [xa+w,ya-h-markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
       "W1ALine0":new makerjs.paths.Line([xa,ya-h-d-markShift], [xa+w1,ya-h-d-markShift]),
       "W1ALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1,ya-h1-d-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
       "W2ALine0":new makerjs.paths.Line([xa,ya-h-h-markShiftThree], [xa+w2,ya-h-h-markShiftThree]),
        "W2ALine1": new makerjs.paths.Line([xa+w2,ya], [xa+w2,ya-h-h-d-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHA = {
      
      paths: {
       "HALine0":new makerjs.paths.Line([xa,ya], [xa-markShiftFour, ya]),
       "HALine1": new makerjs.paths.Line([xa,ya-h], [xa-markShiftFour, ya-h]),//左上角
       "HALine2": new makerjs.paths.Line([xa-markShiftTwo,ya], [xa-markShiftTwo,ya-h]),//左上角
      }
    };
    var myFsAMakerH1A = {    
      paths: {
       "H1ALine0":new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+d/2+markShiftFour, ya-h1]),
       "H1ALine1": new makerjs.paths.Line([xa+w2+markShift,ya], [xa+w2+markShift,ya-h1]),//左上角
      }
    };
    var myFsAMakerR2A = {    
      paths: {
       "R2ALine1": new makerjs.paths.Line([xa+w2,ya], [xa+w2, ya-r2-markShiftFour]),//左上角
       "R2ALine2": new makerjs.paths.Line([xa+Aa-markShiftTwo,ya], [xa+Aa,ya-Ahd]),//左上角
      }
    };
    var myFsAMakerR1A = {    
      paths: {
       "R1ALine1": new makerjs.paths.Line([xa+w,ya-Ahd], [xa+w+markShift,ya-Ahd-markShift]),//左上角
      }
    };
    var myFsAMakerDA = {    
      paths: {
       "DALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*****************  C  *********** */
    

    /************************   I  ******************* */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi,yi+h], [xi,yi+h+markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi-w,yi+h], [xi-w,yi+h+markShiftThree]),//左上角
        "WILine2":new makerjs.paths.Line([xi,yi+h+markShift], [xi-w,yi+h+markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
       "W1ILine0":new makerjs.paths.Line([xi,yi+h+d+markShift], [xi-w1,yi+h+d+markShift]),
       "W1ILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1,yi+h1+d+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
       "W2ILine0":new makerjs.paths.Line([xi,yi+h+h+markShiftThree], [xi-w2,yi+h+h+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2,yi+h+h+d+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {      
      paths: {
       "HILine0":new makerjs.paths.Line([xi,yi], [xi+markShiftFour, yi]),
       "HILine1": new makerjs.paths.Line([xi,yi+h], [xi+markShiftFour, yi+h]),//左上角
       "HILine2": new makerjs.paths.Line([xi+markShiftTwo,yi], [xi+markShiftTwo,yi+h]),//左上角
      }
    };
    var myFsAMakerH1I = {    
      paths: {
       "H1ILine0":new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-d/2-markShiftFour, yi+h1]),
       "H1ILine1": new makerjs.paths.Line([xi-w2-markShift,yi], [xi-w2-markShift,yi+h1]),//左上角
      }
    };
    var myFsAMakerR2I = {    
      paths: {
      //  "R2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2, yi-r2-markShiftFour]),//左上角
       "R2ILine2": new makerjs.paths.Line([xi-Iaa+markShiftTwo,yi], [xi-Iaa,yi+Ihd]),//左上角
      }
    };
    var myFsAMakerR1I = {    
      paths: {
       "R1ILine1": new makerjs.paths.Line([xi-w,yi+Ihd], [xi-w-markShift,yi+Ihd+markShift]),//左上角
      }
    };
    var myFsAMakerDI = {    
      paths: {
       "DILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /**************************************************** */
    
  makerjs.model.addCaption(myFsAMakerWA, TxtW, [xa,ya-h-markShiftThree], [xa+w,ya-h-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1A, TxtW1, [xa,ya-h-d-markShiftThree], [xa+w1,ya-h-d-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2A, TxtW2, [xa,ya-h-h-d-markShiftFour], [xa+w2,ya-h-h-d-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHA, TxtH, [xa-markShiftFour,ya-h], [xa-markShiftFour,ya]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1A, TxtH1, [xa+w2+markShiftThree,ya-h1], [xa+w2+markShiftThree,ya]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2A, TxtR2, [xa+Aa-markShiftThree,ya-Ahd+markShiftThree], [xa+Aa-markShift,ya-Ahd+markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1A, TxtR1, [xa+w,ya-Ahd], [xa+w+markShift,ya-Ahd-markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDA, TxtD, [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD],[xa+w1+markHolesOneD+markShiftFour,ya-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    
  /**************************************************** */
  
  /******************************************************** */
  
  makerjs.model.addCaption(myFsAMakerWI, TxtW, [xi-w,yi+h+markShiftThree], [xi,yi+h+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1I, TxtW1, [xi-w1,yi+h+d+markShiftThree], [xi,yi+h+d+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2I, TxtW2, [xi-w2,yi+h+h+d+markShiftFour], [xi,yi+h+h+d+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHI, TxtH, [xi+markShiftFour,yi+h], [xi+markShiftFour,yi]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1I, TxtH1, [xi-w2-markShiftThree,yi+h1], [xi-w2-markShiftThree,yi]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2I, TxtR2, [xi-Iaa+markShift,yi+Ihd-markShift], [xi-Iaa+markShiftThree,yi+Ihd-markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1I, TxtR1, [xi-w-markShift,yi+Ihd+markShift], [xi-w,yi+Ihd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDI, TxtD,[xi-w1-markHolesOneD-markShiftFour,yi+h1+d+markShiftFour+markHolesOneD], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   

  /******************************************************** */
  var markers = {
    /**A */
        myFsAMakerWA,
        myFsAMakerW1A,
         myFsAMakerW2A,
         myFsAMakerHA,
        myFsAMakerH1A,
         myFsAMakerR2A,
        myFsAMakerR1A,
        myFsAMakerDA,
        /**C */
        /**I */
        myFsAMakerWI,
        myFsAMakerW1I,
         myFsAMakerW2I,
         myFsAMakerHI,
        myFsAMakerH1I,
         myFsAMakerR2I,
        myFsAMakerR1I,
        myFsAMakerDI,
      }
  

    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "CG") {
   
    /*****************  C  *********** */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc,yc-h], [xc,yc-h-markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc-w,yc-h], [xc-w,yc-h-markShiftThree]),//左上角
        "WCLine2":new makerjs.paths.Line([xc,yc-h-markShift], [xc-w,yc-h-markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
       "W1CLine0":new makerjs.paths.Line([xc,yc-h-d-markShift], [xc-w1,yc-h-d-markShift]),
       "W1CLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1,yc-h1-d-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
       "W2CLine0":new makerjs.paths.Line([xc,yc-h-h-markShiftThree], [xc-w2,yc-h-h-markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2,yc-h-h-d-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {      
      paths: {
       "HCLine0":new makerjs.paths.Line([xc,yc], [xc+markShiftFour, yc]),
       "HCLine1": new makerjs.paths.Line([xc,yc-h], [xc+markShiftFour, yc-h]),//左上角
       "HCLine2": new makerjs.paths.Line([xc+markShiftTwo,yc], [xc+markShiftTwo,yc-h]),//左上角
      }
    };
    var myFsAMakerH1C = {    
      paths: {
       "H1CLine0":new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-d/2-markShiftFour, yc-h1]),
       "H1CLine1": new makerjs.paths.Line([xc-w2-markShift,yc], [xc-w2-markShift,yc-h1]),//左上角
      }
    };
    var myFsAMakerR2C = {    
      paths: {
      //  "R2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2, yc-r2-markShiftFour]),//左上角
       "R2CLine2": new makerjs.paths.Line([xc-Caa+markShiftTwo,yc], [xc-Caa,yc-Chd]),//左上角
      }
    };
    var myFsAMakerR1C = {    
      paths: {
       "R1CLine1": new makerjs.paths.Line([xc-w,yc-Chd], [xc-w-markShift,yc-Chd-markShift]),//左上角
      }
    };
    var myFsAMakerDC = {    
      paths: {
       "DCLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /****************** G   ************** */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg,yg+h], [xg,yg+h+markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg+w,yg+h], [xg+w,yg+h+markShiftThree]),//左上角
        "WGLine2":new makerjs.paths.Line([xg,yg+h+markShift], [xg+w,yg+h+markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0":new makerjs.paths.Line([xg,yg+h+d+markShift], [xg+w1,yg+h+d+markShift]),
        "W1GLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1,yg+h1+d+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0":new makerjs.paths.Line([xg,yg+h+h+markShiftThree], [xg+w2,yg+h+h+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg+w2,yg], [xg+w2,yg+h+h+d+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {      
      paths: {
        "HGLine0":new makerjs.paths.Line([xg,yg], [xg-markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg,yg+h], [xg-markShiftFour, yg+h]),//左上角
        "HGLine2": new makerjs.paths.Line([xg-markShiftTwo,yg], [xg-markShiftTwo,yg+h]),//左上角
      }
    };
    var myFsAMakerH1G = {    
      paths: {
        "H1GLine0":new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+d/2+markShiftFour, yg+h1]),
        "H1GLine1": new makerjs.paths.Line([xg+w2+markShift,yg], [xg+w2+markShift,yg+h1]),//左上角
      }
    };
    var myFsAMakerR2G = {    
      paths: {
        "R2GLine1": new makerjs.paths.Line([xg+w2,yg], [xg+w2, yg+r2+markShiftFour]),//左上角
        "R2GLine2": new makerjs.paths.Line([xg+Ga-markShiftTwo,yg], [xg+Ga,yg+Ghd]),//左上角
      }
    };
    var myFsAMakerR1G = {    
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg+w,yg+Ghd], [xg+w+markShift,yg+Ghd+markShift]),//左上角
      }
    };
    var myFsAMakerDG = {    
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /************************   I  ******************* */
    

    /**************************************************** */
  
  /**************************************************** */

  
  makerjs.model.addCaption(myFsAMakerWC, TxtW, [xc-w,yc-h-markShiftThree], [xc,yc-h-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1C, TxtW1, [xc-w1,yc-h-d-markShiftThree], [xc,yc-h-d-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2C, TxtW2, [xc-w2,yc-h-h-d-markShiftFour], [xc,yc-h-h-d-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHC, TxtH, [xc+markShiftFour,yc-h], [xc+markShiftFour,yc]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1C, TxtH1, [xc-w2-markShiftThree,yc-h1], [xc-w2-markShiftThree,yc]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2C, TxtR2, [xc-Caa+markShift,yc-Chd+markShift], [xc-Caa+markShiftThree,yc-Chd+markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1C, TxtR1, [xc-w-markShift,yc-Chd-markShift], [xc-w,yc-Chd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDC, TxtD,[xc-w1-markHolesOneD-markShiftFour,yc-h1-d-markShiftFour-markHolesOneD], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    

  /******************************************************** */
  makerjs.model.addCaption(myFsAMakerWG, TxtW, [xg,yg+h+markShiftThree], [xg+w,yg+h+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1G, TxtW1, [xg,yg+h+d+markShiftThree], [xg+w1,yg+h+d+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2G, TxtW2, [xg,yg+h+h+d+markShiftFour], [xg+w2,yg+h+h+d+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHG, TxtH, [xg-markShiftFour,yg+h], [xg-markShiftFour,yg]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1G, TxtH1, [xg+w2+markShiftThree,yg+h1], [xg+w2+markShiftThree,yg]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2G, TxtR2, [xg+Ga-markShiftThree,yg+Ghd-markShiftThree], [xg+Ga-markShift,yg+Ghd-markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1G, TxtR1, [xg+w,yg+Ghd], [xg+w+markShift,yg+Ghd+markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDG, TxtD, [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD],[xg+w1+markHolesOneD+markShiftFour,yg+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
    
  /******************************************************** */
  

  /******************************************************** */
  var markers = {
    /**A */
        /**C */
        myFsAMakerWC,
        myFsAMakerW1C,
         myFsAMakerW2C,
         myFsAMakerHC,
        myFsAMakerH1C,
         myFsAMakerR2C,
        myFsAMakerR1C,
        myFsAMakerDC,
        /** G*/        
        myFsAMakerWG,
        myFsAMakerW1G,
         myFsAMakerW2G,
         myFsAMakerHG,
        myFsAMakerH1G,
         myFsAMakerR2G,
        myFsAMakerR1G,
        myFsAMakerDG,
        /**I */
      }
  

    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "CI") {
   
    /*****************  C  *********** */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc,yc-h], [xc,yc-h-markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc-w,yc-h], [xc-w,yc-h-markShiftThree]),//左上角
        "WCLine2":new makerjs.paths.Line([xc,yc-h-markShift], [xc-w,yc-h-markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
       "W1CLine0":new makerjs.paths.Line([xc,yc-h-d-markShift], [xc-w1,yc-h-d-markShift]),
       "W1CLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1,yc-h1-d-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
       "W2CLine0":new makerjs.paths.Line([xc,yc-h-h-markShiftThree], [xc-w2,yc-h-h-markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2,yc-h-h-d-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {      
      paths: {
       "HCLine0":new makerjs.paths.Line([xc,yc], [xc+markShiftFour, yc]),
       "HCLine1": new makerjs.paths.Line([xc,yc-h], [xc+markShiftFour, yc-h]),//左上角
       "HCLine2": new makerjs.paths.Line([xc+markShiftTwo,yc], [xc+markShiftTwo,yc-h]),//左上角
      }
    };
    var myFsAMakerH1C = {    
      paths: {
       "H1CLine0":new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-d/2-markShiftFour, yc-h1]),
       "H1CLine1": new makerjs.paths.Line([xc-w2-markShift,yc], [xc-w2-markShift,yc-h1]),//左上角
      }
    };
    var myFsAMakerR2C = {    
      paths: {
      //  "R2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2, yc-r2-markShiftFour]),//左上角
       "R2CLine2": new makerjs.paths.Line([xc-Caa+markShiftTwo,yc], [xc-Caa,yc-Chd]),//左上角
      }
    };
    var myFsAMakerR1C = {    
      paths: {
       "R1CLine1": new makerjs.paths.Line([xc-w,yc-Chd], [xc-w-markShift,yc-Chd-markShift]),//左上角
      }
    };
    var myFsAMakerDC = {    
      paths: {
       "DCLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /****************** G   ************** */
    

    /************************   I  ******************* */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi,yi+h], [xi,yi+h+markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi-w,yi+h], [xi-w,yi+h+markShiftThree]),//左上角
        "WILine2":new makerjs.paths.Line([xi,yi+h+markShift], [xi-w,yi+h+markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
       "W1ILine0":new makerjs.paths.Line([xi,yi+h+d+markShift], [xi-w1,yi+h+d+markShift]),
       "W1ILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1,yi+h1+d+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
       "W2ILine0":new makerjs.paths.Line([xi,yi+h+h+markShiftThree], [xi-w2,yi+h+h+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2,yi+h+h+d+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {      
      paths: {
       "HILine0":new makerjs.paths.Line([xi,yi], [xi+markShiftFour, yi]),
       "HILine1": new makerjs.paths.Line([xi,yi+h], [xi+markShiftFour, yi+h]),//左上角
       "HILine2": new makerjs.paths.Line([xi+markShiftTwo,yi], [xi+markShiftTwo,yi+h]),//左上角
      }
    };
    var myFsAMakerH1I = {    
      paths: {
       "H1ILine0":new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-d/2-markShiftFour, yi+h1]),
       "H1ILine1": new makerjs.paths.Line([xi-w2-markShift,yi], [xi-w2-markShift,yi+h1]),//左上角
      }
    };
    var myFsAMakerR2I = {    
      paths: {
      //  "R2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2, yi-r2-markShiftFour]),//左上角
       "R2ILine2": new makerjs.paths.Line([xi-Iaa+markShiftTwo,yi], [xi-Iaa,yi+Ihd]),//左上角
      }
    };
    var myFsAMakerR1I = {    
      paths: {
       "R1ILine1": new makerjs.paths.Line([xi-w,yi+Ihd], [xi-w-markShift,yi+Ihd+markShift]),//左上角
      }
    };
    var myFsAMakerDI = {    
      paths: {
       "DILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /**************************************************** */
     
  /**************************************************** */

  
  makerjs.model.addCaption(myFsAMakerWC, TxtW, [xc-w,yc-h-markShiftThree], [xc,yc-h-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1C, TxtW1, [xc-w1,yc-h-d-markShiftThree], [xc,yc-h-d-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2C, TxtW2, [xc-w2,yc-h-h-d-markShiftFour], [xc,yc-h-h-d-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHC, TxtH, [xc+markShiftFour,yc-h], [xc+markShiftFour,yc]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1C, TxtH1, [xc-w2-markShiftThree,yc-h1], [xc-w2-markShiftThree,yc]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2C, TxtR2, [xc-Caa+markShift,yc-Chd+markShift], [xc-Caa+markShiftThree,yc-Chd+markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1C, TxtR1, [xc-w-markShift,yc-Chd-markShift], [xc-w,yc-Chd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDC, TxtD,[xc-w1-markHolesOneD-markShiftFour,yc-h1-d-markShiftFour-markHolesOneD], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    

  /******************************************************** */
   
  /******************************************************** */
  
  makerjs.model.addCaption(myFsAMakerWI, TxtW, [xi-w,yi+h+markShiftThree], [xi,yi+h+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1I, TxtW1, [xi-w1,yi+h+d+markShiftThree], [xi,yi+h+d+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2I, TxtW2, [xi-w2,yi+h+h+d+markShiftFour], [xi,yi+h+h+d+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHI, TxtH, [xi+markShiftFour,yi+h], [xi+markShiftFour,yi]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1I, TxtH1, [xi-w2-markShiftThree,yi+h1], [xi-w2-markShiftThree,yi]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2I, TxtR2, [xi-Iaa+markShift,yi+Ihd-markShift], [xi-Iaa+markShiftThree,yi+Ihd-markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1I, TxtR1, [xi-w-markShift,yi+Ihd+markShift], [xi-w,yi+Ihd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDI, TxtD,[xi-w1-markHolesOneD-markShiftFour,yi+h1+d+markShiftFour+markHolesOneD], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   

  /******************************************************** */
  var markers = {
    /**A */
        /**C */
        myFsAMakerWC,
        myFsAMakerW1C,
         myFsAMakerW2C,
         myFsAMakerHC,
        myFsAMakerH1C,
         myFsAMakerR2C,
        myFsAMakerR1C,
        myFsAMakerDC,
        /** G*/        
        /**I */
        myFsAMakerWI,
        myFsAMakerW1I,
         myFsAMakerW2I,
         myFsAMakerHI,
        myFsAMakerH1I,
         myFsAMakerR2I,
        myFsAMakerR1I,
        myFsAMakerDI,
      }
  

    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "GI") {
    
    /****************** G   ************** */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg,yg+h], [xg,yg+h+markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg+w,yg+h], [xg+w,yg+h+markShiftThree]),//左上角
        "WGLine2":new makerjs.paths.Line([xg,yg+h+markShift], [xg+w,yg+h+markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0":new makerjs.paths.Line([xg,yg+h+d+markShift], [xg+w1,yg+h+d+markShift]),
        "W1GLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1,yg+h1+d+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0":new makerjs.paths.Line([xg,yg+h+h+markShiftThree], [xg+w2,yg+h+h+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg+w2,yg], [xg+w2,yg+h+h+d+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {      
      paths: {
        "HGLine0":new makerjs.paths.Line([xg,yg], [xg-markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg,yg+h], [xg-markShiftFour, yg+h]),//左上角
        "HGLine2": new makerjs.paths.Line([xg-markShiftTwo,yg], [xg-markShiftTwo,yg+h]),//左上角
      }
    };
    var myFsAMakerH1G = {    
      paths: {
        "H1GLine0":new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+d/2+markShiftFour, yg+h1]),
        "H1GLine1": new makerjs.paths.Line([xg+w2+markShift,yg], [xg+w2+markShift,yg+h1]),//左上角
      }
    };
    var myFsAMakerR2G = {    
      paths: {
        "R2GLine1": new makerjs.paths.Line([xg+w2,yg], [xg+w2, yg+r2+markShiftFour]),//左上角
        "R2GLine2": new makerjs.paths.Line([xg+Ga-markShiftTwo,yg], [xg+Ga,yg+Ghd]),//左上角
      }
    };
    var myFsAMakerR1G = {    
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg+w,yg+Ghd], [xg+w+markShift,yg+Ghd+markShift]),//左上角
      }
    };
    var myFsAMakerDG = {    
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /************************   I  ******************* */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi,yi+h], [xi,yi+h+markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi-w,yi+h], [xi-w,yi+h+markShiftThree]),//左上角
        "WILine2":new makerjs.paths.Line([xi,yi+h+markShift], [xi-w,yi+h+markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
       "W1ILine0":new makerjs.paths.Line([xi,yi+h+d+markShift], [xi-w1,yi+h+d+markShift]),
       "W1ILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1,yi+h1+d+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
       "W2ILine0":new makerjs.paths.Line([xi,yi+h+h+markShiftThree], [xi-w2,yi+h+h+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2,yi+h+h+d+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {      
      paths: {
       "HILine0":new makerjs.paths.Line([xi,yi], [xi+markShiftFour, yi]),
       "HILine1": new makerjs.paths.Line([xi,yi+h], [xi+markShiftFour, yi+h]),//左上角
       "HILine2": new makerjs.paths.Line([xi+markShiftTwo,yi], [xi+markShiftTwo,yi+h]),//左上角
      }
    };
    var myFsAMakerH1I = {    
      paths: {
       "H1ILine0":new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-d/2-markShiftFour, yi+h1]),
       "H1ILine1": new makerjs.paths.Line([xi-w2-markShift,yi], [xi-w2-markShift,yi+h1]),//左上角
      }
    };
    var myFsAMakerR2I = {    
      paths: {
      //  "R2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2, yi-r2-markShiftFour]),//左上角
       "R2ILine2": new makerjs.paths.Line([xi-Iaa+markShiftTwo,yi], [xi-Iaa,yi+Ihd]),//左上角
      }
    };
    var myFsAMakerR1I = {    
      paths: {
       "R1ILine1": new makerjs.paths.Line([xi-w,yi+Ihd], [xi-w-markShift,yi+Ihd+markShift]),//左上角
      }
    };
    var myFsAMakerDI = {    
      paths: {
       "DILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /**************************************************** */
   
  /******************************************************** */
  makerjs.model.addCaption(myFsAMakerWG, TxtW, [xg,yg+h+markShiftThree], [xg+w,yg+h+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1G, TxtW1, [xg,yg+h+d+markShiftThree], [xg+w1,yg+h+d+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2G, TxtW2, [xg,yg+h+h+d+markShiftFour], [xg+w2,yg+h+h+d+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHG, TxtH, [xg-markShiftFour,yg+h], [xg-markShiftFour,yg]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1G, TxtH1, [xg+w2+markShiftThree,yg+h1], [xg+w2+markShiftThree,yg]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2G, TxtR2, [xg+Ga-markShiftThree,yg+Ghd-markShiftThree], [xg+Ga-markShift,yg+Ghd-markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1G, TxtR1, [xg+w,yg+Ghd], [xg+w+markShift,yg+Ghd+markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDG, TxtD, [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD],[xg+w1+markHolesOneD+markShiftFour,yg+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
    
  /******************************************************** */
  
  makerjs.model.addCaption(myFsAMakerWI, TxtW, [xi-w,yi+h+markShiftThree], [xi,yi+h+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1I, TxtW1, [xi-w1,yi+h+d+markShiftThree], [xi,yi+h+d+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2I, TxtW2, [xi-w2,yi+h+h+d+markShiftFour], [xi,yi+h+h+d+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHI, TxtH, [xi+markShiftFour,yi+h], [xi+markShiftFour,yi]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1I, TxtH1, [xi-w2-markShiftThree,yi+h1], [xi-w2-markShiftThree,yi]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2I, TxtR2, [xi-Iaa+markShift,yi+Ihd-markShift], [xi-Iaa+markShiftThree,yi+Ihd-markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1I, TxtR1, [xi-w-markShift,yi+Ihd+markShift], [xi-w,yi+Ihd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDI, TxtD,[xi-w1-markHolesOneD-markShiftFour,yi+h1+d+markShiftFour+markHolesOneD], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   

  /******************************************************** */
  var markers = {
    /**A */
        /** G*/        
        myFsAMakerWG,
        myFsAMakerW1G,
         myFsAMakerW2G,
         myFsAMakerHG,
        myFsAMakerH1G,
         myFsAMakerR2G,
        myFsAMakerR1G,
        myFsAMakerDG,
        /**I */
        myFsAMakerWI,
        myFsAMakerW1I,
         myFsAMakerW2I,
         myFsAMakerHI,
        myFsAMakerH1I,
         myFsAMakerR2I,
        myFsAMakerR1I,
        myFsAMakerDI,
      }
  

   
    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACG") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa,ya-h], [xa,ya-h-markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa+w,ya-h], [xa+w,ya-h-markShiftThree]),//左上角
        "WALine2":new makerjs.paths.Line([xa,ya-h-markShift], [xa+w,ya-h-markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
       "W1ALine0":new makerjs.paths.Line([xa,ya-h-d-markShift], [xa+w1,ya-h-d-markShift]),
       "W1ALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1,ya-h1-d-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
       "W2ALine0":new makerjs.paths.Line([xa,ya-h-h-markShiftThree], [xa+w2,ya-h-h-markShiftThree]),
        "W2ALine1": new makerjs.paths.Line([xa+w2,ya], [xa+w2,ya-h-h-d-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHA = {
      
      paths: {
       "HALine0":new makerjs.paths.Line([xa,ya], [xa-markShiftFour, ya]),
       "HALine1": new makerjs.paths.Line([xa,ya-h], [xa-markShiftFour, ya-h]),//左上角
       "HALine2": new makerjs.paths.Line([xa-markShiftTwo,ya], [xa-markShiftTwo,ya-h]),//左上角
      }
    };
    var myFsAMakerH1A = {    
      paths: {
       "H1ALine0":new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+d/2+markShiftFour, ya-h1]),
       "H1ALine1": new makerjs.paths.Line([xa+w2+markShift,ya], [xa+w2+markShift,ya-h1]),//左上角
      }
    };
    var myFsAMakerR2A = {    
      paths: {
       "R2ALine1": new makerjs.paths.Line([xa+w2,ya], [xa+w2, ya-r2-markShiftFour]),//左上角
       "R2ALine2": new makerjs.paths.Line([xa+Aa-markShiftTwo,ya], [xa+Aa,ya-Ahd]),//左上角
      }
    };
    var myFsAMakerR1A = {    
      paths: {
       "R1ALine1": new makerjs.paths.Line([xa+w,ya-Ahd], [xa+w+markShift,ya-Ahd-markShift]),//左上角
      }
    };
    var myFsAMakerDA = {    
      paths: {
       "DALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*****************  C  *********** */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc,yc-h], [xc,yc-h-markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc-w,yc-h], [xc-w,yc-h-markShiftThree]),//左上角
        "WCLine2":new makerjs.paths.Line([xc,yc-h-markShift], [xc-w,yc-h-markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
       "W1CLine0":new makerjs.paths.Line([xc,yc-h-d-markShift], [xc-w1,yc-h-d-markShift]),
       "W1CLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1,yc-h1-d-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
       "W2CLine0":new makerjs.paths.Line([xc,yc-h-h-markShiftThree], [xc-w2,yc-h-h-markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2,yc-h-h-d-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {      
      paths: {
       "HCLine0":new makerjs.paths.Line([xc,yc], [xc+markShiftFour, yc]),
       "HCLine1": new makerjs.paths.Line([xc,yc-h], [xc+markShiftFour, yc-h]),//左上角
       "HCLine2": new makerjs.paths.Line([xc+markShiftTwo,yc], [xc+markShiftTwo,yc-h]),//左上角
      }
    };
    var myFsAMakerH1C = {    
      paths: {
       "H1CLine0":new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-d/2-markShiftFour, yc-h1]),
       "H1CLine1": new makerjs.paths.Line([xc-w2-markShift,yc], [xc-w2-markShift,yc-h1]),//左上角
      }
    };
    var myFsAMakerR2C = {    
      paths: {
      //  "R2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2, yc-r2-markShiftFour]),//左上角
       "R2CLine2": new makerjs.paths.Line([xc-Caa+markShiftTwo,yc], [xc-Caa,yc-Chd]),//左上角
      }
    };
    var myFsAMakerR1C = {    
      paths: {
       "R1CLine1": new makerjs.paths.Line([xc-w,yc-Chd], [xc-w-markShift,yc-Chd-markShift]),//左上角
      }
    };
    var myFsAMakerDC = {    
      paths: {
       "DCLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /****************** G   ************** */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg,yg+h], [xg,yg+h+markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg+w,yg+h], [xg+w,yg+h+markShiftThree]),//左上角
        "WGLine2":new makerjs.paths.Line([xg,yg+h+markShift], [xg+w,yg+h+markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0":new makerjs.paths.Line([xg,yg+h+d+markShift], [xg+w1,yg+h+d+markShift]),
        "W1GLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1,yg+h1+d+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0":new makerjs.paths.Line([xg,yg+h+h+markShiftThree], [xg+w2,yg+h+h+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg+w2,yg], [xg+w2,yg+h+h+d+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {      
      paths: {
        "HGLine0":new makerjs.paths.Line([xg,yg], [xg-markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg,yg+h], [xg-markShiftFour, yg+h]),//左上角
        "HGLine2": new makerjs.paths.Line([xg-markShiftTwo,yg], [xg-markShiftTwo,yg+h]),//左上角
      }
    };
    var myFsAMakerH1G = {    
      paths: {
        "H1GLine0":new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+d/2+markShiftFour, yg+h1]),
        "H1GLine1": new makerjs.paths.Line([xg+w2+markShift,yg], [xg+w2+markShift,yg+h1]),//左上角
      }
    };
    var myFsAMakerR2G = {    
      paths: {
        "R2GLine1": new makerjs.paths.Line([xg+w2,yg], [xg+w2, yg+r2+markShiftFour]),//左上角
        "R2GLine2": new makerjs.paths.Line([xg+Ga-markShiftTwo,yg], [xg+Ga,yg+Ghd]),//左上角
      }
    };
    var myFsAMakerR1G = {    
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg+w,yg+Ghd], [xg+w+markShift,yg+Ghd+markShift]),//左上角
      }
    };
    var myFsAMakerDG = {    
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /************************   I  ******************* */

    /**************************************************** */
    
  makerjs.model.addCaption(myFsAMakerWA, TxtW, [xa,ya-h-markShiftThree], [xa+w,ya-h-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1A, TxtW1, [xa,ya-h-d-markShiftThree], [xa+w1,ya-h-d-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2A, TxtW2, [xa,ya-h-h-d-markShiftFour], [xa+w2,ya-h-h-d-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHA, TxtH, [xa-markShiftFour,ya-h], [xa-markShiftFour,ya]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1A, TxtH1, [xa+w2+markShiftThree,ya-h1], [xa+w2+markShiftThree,ya]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2A, TxtR2, [xa+Aa-markShiftThree,ya-Ahd+markShiftThree], [xa+Aa-markShift,ya-Ahd+markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1A, TxtR1, [xa+w,ya-Ahd], [xa+w+markShift,ya-Ahd-markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDA, TxtD, [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD],[xa+w1+markHolesOneD+markShiftFour,ya-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    
  /**************************************************** */

  
  makerjs.model.addCaption(myFsAMakerWC, TxtW, [xc-w,yc-h-markShiftThree], [xc,yc-h-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1C, TxtW1, [xc-w1,yc-h-d-markShiftThree], [xc,yc-h-d-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2C, TxtW2, [xc-w2,yc-h-h-d-markShiftFour], [xc,yc-h-h-d-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHC, TxtH, [xc+markShiftFour,yc-h], [xc+markShiftFour,yc]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1C, TxtH1, [xc-w2-markShiftThree,yc-h1], [xc-w2-markShiftThree,yc]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2C, TxtR2, [xc-Caa+markShift,yc-Chd+markShift], [xc-Caa+markShiftThree,yc-Chd+markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1C, TxtR1, [xc-w-markShift,yc-Chd-markShift], [xc-w,yc-Chd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDC, TxtD,[xc-w1-markHolesOneD-markShiftFour,yc-h1-d-markShiftFour-markHolesOneD], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    

  /******************************************************** */
  makerjs.model.addCaption(myFsAMakerWG, TxtW, [xg,yg+h+markShiftThree], [xg+w,yg+h+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1G, TxtW1, [xg,yg+h+d+markShiftThree], [xg+w1,yg+h+d+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2G, TxtW2, [xg,yg+h+h+d+markShiftFour], [xg+w2,yg+h+h+d+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHG, TxtH, [xg-markShiftFour,yg+h], [xg-markShiftFour,yg]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1G, TxtH1, [xg+w2+markShiftThree,yg+h1], [xg+w2+markShiftThree,yg]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2G, TxtR2, [xg+Ga-markShiftThree,yg+Ghd-markShiftThree], [xg+Ga-markShift,yg+Ghd-markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1G, TxtR1, [xg+w,yg+Ghd], [xg+w+markShift,yg+Ghd+markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDG, TxtD, [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD],[xg+w1+markHolesOneD+markShiftFour,yg+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
    
  /******************************************************** */
  /******************************************************** */
  var markers = {
    /**A */
        myFsAMakerWA,
        myFsAMakerW1A,
         myFsAMakerW2A,
         myFsAMakerHA,
        myFsAMakerH1A,
         myFsAMakerR2A,
        myFsAMakerR1A,
        myFsAMakerDA,
        /**C */
        myFsAMakerWC,
        myFsAMakerW1C,
         myFsAMakerW2C,
         myFsAMakerHC,
        myFsAMakerH1C,
         myFsAMakerR2C,
        myFsAMakerR1C,
        myFsAMakerDC,
        /** G*/        
        myFsAMakerWG,
        myFsAMakerW1G,
         myFsAMakerW2G,
         myFsAMakerHG,
        myFsAMakerH1G,
         myFsAMakerR2G,
        myFsAMakerR1G,
        myFsAMakerDG,
        /**I */
      }
  

    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "ACI") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa,ya-h], [xa,ya-h-markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa+w,ya-h], [xa+w,ya-h-markShiftThree]),//左上角
        "WALine2":new makerjs.paths.Line([xa,ya-h-markShift], [xa+w,ya-h-markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
       "W1ALine0":new makerjs.paths.Line([xa,ya-h-d-markShift], [xa+w1,ya-h-d-markShift]),
       "W1ALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1,ya-h1-d-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
       "W2ALine0":new makerjs.paths.Line([xa,ya-h-h-markShiftThree], [xa+w2,ya-h-h-markShiftThree]),
        "W2ALine1": new makerjs.paths.Line([xa+w2,ya], [xa+w2,ya-h-h-d-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHA = {
      
      paths: {
       "HALine0":new makerjs.paths.Line([xa,ya], [xa-markShiftFour, ya]),
       "HALine1": new makerjs.paths.Line([xa,ya-h], [xa-markShiftFour, ya-h]),//左上角
       "HALine2": new makerjs.paths.Line([xa-markShiftTwo,ya], [xa-markShiftTwo,ya-h]),//左上角
      }
    };
    var myFsAMakerH1A = {    
      paths: {
       "H1ALine0":new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+d/2+markShiftFour, ya-h1]),
       "H1ALine1": new makerjs.paths.Line([xa+w2+markShift,ya], [xa+w2+markShift,ya-h1]),//左上角
      }
    };
    var myFsAMakerR2A = {    
      paths: {
       "R2ALine1": new makerjs.paths.Line([xa+w2,ya], [xa+w2, ya-r2-markShiftFour]),//左上角
       "R2ALine2": new makerjs.paths.Line([xa+Aa-markShiftTwo,ya], [xa+Aa,ya-Ahd]),//左上角
      }
    };
    var myFsAMakerR1A = {    
      paths: {
       "R1ALine1": new makerjs.paths.Line([xa+w,ya-Ahd], [xa+w+markShift,ya-Ahd-markShift]),//左上角
      }
    };
    var myFsAMakerDA = {    
      paths: {
       "DALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*****************  C  *********** */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc,yc-h], [xc,yc-h-markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc-w,yc-h], [xc-w,yc-h-markShiftThree]),//左上角
        "WCLine2":new makerjs.paths.Line([xc,yc-h-markShift], [xc-w,yc-h-markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
       "W1CLine0":new makerjs.paths.Line([xc,yc-h-d-markShift], [xc-w1,yc-h-d-markShift]),
       "W1CLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1,yc-h1-d-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
       "W2CLine0":new makerjs.paths.Line([xc,yc-h-h-markShiftThree], [xc-w2,yc-h-h-markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2,yc-h-h-d-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {      
      paths: {
       "HCLine0":new makerjs.paths.Line([xc,yc], [xc+markShiftFour, yc]),
       "HCLine1": new makerjs.paths.Line([xc,yc-h], [xc+markShiftFour, yc-h]),//左上角
       "HCLine2": new makerjs.paths.Line([xc+markShiftTwo,yc], [xc+markShiftTwo,yc-h]),//左上角
      }
    };
    var myFsAMakerH1C = {    
      paths: {
       "H1CLine0":new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-d/2-markShiftFour, yc-h1]),
       "H1CLine1": new makerjs.paths.Line([xc-w2-markShift,yc], [xc-w2-markShift,yc-h1]),//左上角
      }
    };
    var myFsAMakerR2C = {    
      paths: {
      //  "R2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2, yc-r2-markShiftFour]),//左上角
       "R2CLine2": new makerjs.paths.Line([xc-Caa+markShiftTwo,yc], [xc-Caa,yc-Chd]),//左上角
      }
    };
    var myFsAMakerR1C = {    
      paths: {
       "R1CLine1": new makerjs.paths.Line([xc-w,yc-Chd], [xc-w-markShift,yc-Chd-markShift]),//左上角
      }
    };
    var myFsAMakerDC = {    
      paths: {
       "DCLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /****************** G   ************** */

    /************************   I  ******************* */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi,yi+h], [xi,yi+h+markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi-w,yi+h], [xi-w,yi+h+markShiftThree]),//左上角
        "WILine2":new makerjs.paths.Line([xi,yi+h+markShift], [xi-w,yi+h+markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
       "W1ILine0":new makerjs.paths.Line([xi,yi+h+d+markShift], [xi-w1,yi+h+d+markShift]),
       "W1ILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1,yi+h1+d+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
       "W2ILine0":new makerjs.paths.Line([xi,yi+h+h+markShiftThree], [xi-w2,yi+h+h+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2,yi+h+h+d+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {      
      paths: {
       "HILine0":new makerjs.paths.Line([xi,yi], [xi+markShiftFour, yi]),
       "HILine1": new makerjs.paths.Line([xi,yi+h], [xi+markShiftFour, yi+h]),//左上角
       "HILine2": new makerjs.paths.Line([xi+markShiftTwo,yi], [xi+markShiftTwo,yi+h]),//左上角
      }
    };
    var myFsAMakerH1I = {    
      paths: {
       "H1ILine0":new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-d/2-markShiftFour, yi+h1]),
       "H1ILine1": new makerjs.paths.Line([xi-w2-markShift,yi], [xi-w2-markShift,yi+h1]),//左上角
      }
    };
    var myFsAMakerR2I = {    
      paths: {
      //  "R2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2, yi-r2-markShiftFour]),//左上角
       "R2ILine2": new makerjs.paths.Line([xi-Iaa+markShiftTwo,yi], [xi-Iaa,yi+Ihd]),//左上角
      }
    };
    var myFsAMakerR1I = {    
      paths: {
       "R1ILine1": new makerjs.paths.Line([xi-w,yi+Ihd], [xi-w-markShift,yi+Ihd+markShift]),//左上角
      }
    };
    var myFsAMakerDI = {    
      paths: {
       "DILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /**************************************************** */
    
  makerjs.model.addCaption(myFsAMakerWA, TxtW, [xa,ya-h-markShiftThree], [xa+w,ya-h-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1A, TxtW1, [xa,ya-h-d-markShiftThree], [xa+w1,ya-h-d-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2A, TxtW2, [xa,ya-h-h-d-markShiftFour], [xa+w2,ya-h-h-d-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHA, TxtH, [xa-markShiftFour,ya-h], [xa-markShiftFour,ya]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1A, TxtH1, [xa+w2+markShiftThree,ya-h1], [xa+w2+markShiftThree,ya]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2A, TxtR2, [xa+Aa-markShiftThree,ya-Ahd+markShiftThree], [xa+Aa-markShift,ya-Ahd+markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1A, TxtR1, [xa+w,ya-Ahd], [xa+w+markShift,ya-Ahd-markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDA, TxtD, [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD],[xa+w1+markHolesOneD+markShiftFour,ya-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    
  /**************************************************** */

  
  makerjs.model.addCaption(myFsAMakerWC, TxtW, [xc-w,yc-h-markShiftThree], [xc,yc-h-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1C, TxtW1, [xc-w1,yc-h-d-markShiftThree], [xc,yc-h-d-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2C, TxtW2, [xc-w2,yc-h-h-d-markShiftFour], [xc,yc-h-h-d-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHC, TxtH, [xc+markShiftFour,yc-h], [xc+markShiftFour,yc]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1C, TxtH1, [xc-w2-markShiftThree,yc-h1], [xc-w2-markShiftThree,yc]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2C, TxtR2, [xc-Caa+markShift,yc-Chd+markShift], [xc-Caa+markShiftThree,yc-Chd+markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1C, TxtR1, [xc-w-markShift,yc-Chd-markShift], [xc-w,yc-Chd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDC, TxtD,[xc-w1-markHolesOneD-markShiftFour,yc-h1-d-markShiftFour-markHolesOneD], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    

  /******************************************************** */
  /******************************************************** */
  
  makerjs.model.addCaption(myFsAMakerWI, TxtW, [xi-w,yi+h+markShiftThree], [xi,yi+h+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1I, TxtW1, [xi-w1,yi+h+d+markShiftThree], [xi,yi+h+d+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2I, TxtW2, [xi-w2,yi+h+h+d+markShiftFour], [xi,yi+h+h+d+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHI, TxtH, [xi+markShiftFour,yi+h], [xi+markShiftFour,yi]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1I, TxtH1, [xi-w2-markShiftThree,yi+h1], [xi-w2-markShiftThree,yi]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2I, TxtR2, [xi-Iaa+markShift,yi+Ihd-markShift], [xi-Iaa+markShiftThree,yi+Ihd-markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1I, TxtR1, [xi-w-markShift,yi+Ihd+markShift], [xi-w,yi+Ihd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDI, TxtD,[xi-w1-markHolesOneD-markShiftFour,yi+h1+d+markShiftFour+markHolesOneD], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   

  /******************************************************** */
  var markers = {
    /**A */
        myFsAMakerWA,
        myFsAMakerW1A,
         myFsAMakerW2A,
         myFsAMakerHA,
        myFsAMakerH1A,
         myFsAMakerR2A,
        myFsAMakerR1A,
        myFsAMakerDA,
        /**C */
        myFsAMakerWC,
        myFsAMakerW1C,
         myFsAMakerW2C,
         myFsAMakerHC,
        myFsAMakerH1C,
         myFsAMakerR2C,
        myFsAMakerR1C,
        myFsAMakerDC,
        /** G*/   
        /**I */
        myFsAMakerWI,
        myFsAMakerW1I,
         myFsAMakerW2I,
         myFsAMakerHI,
        myFsAMakerH1I,
         myFsAMakerR2I,
        myFsAMakerR1I,
        myFsAMakerDI,
      }
  

    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AGI") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa,ya-h], [xa,ya-h-markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa+w,ya-h], [xa+w,ya-h-markShiftThree]),//左上角
        "WALine2":new makerjs.paths.Line([xa,ya-h-markShift], [xa+w,ya-h-markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
       "W1ALine0":new makerjs.paths.Line([xa,ya-h-d-markShift], [xa+w1,ya-h-d-markShift]),
       "W1ALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1,ya-h1-d-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
       "W2ALine0":new makerjs.paths.Line([xa,ya-h-h-markShiftThree], [xa+w2,ya-h-h-markShiftThree]),
        "W2ALine1": new makerjs.paths.Line([xa+w2,ya], [xa+w2,ya-h-h-d-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHA = {
      
      paths: {
       "HALine0":new makerjs.paths.Line([xa,ya], [xa-markShiftFour, ya]),
       "HALine1": new makerjs.paths.Line([xa,ya-h], [xa-markShiftFour, ya-h]),//左上角
       "HALine2": new makerjs.paths.Line([xa-markShiftTwo,ya], [xa-markShiftTwo,ya-h]),//左上角
      }
    };
    var myFsAMakerH1A = {    
      paths: {
       "H1ALine0":new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+d/2+markShiftFour, ya-h1]),
       "H1ALine1": new makerjs.paths.Line([xa+w2+markShift,ya], [xa+w2+markShift,ya-h1]),//左上角
      }
    };
    var myFsAMakerR2A = {    
      paths: {
       "R2ALine1": new makerjs.paths.Line([xa+w2,ya], [xa+w2, ya-r2-markShiftFour]),//左上角
       "R2ALine2": new makerjs.paths.Line([xa+Aa-markShiftTwo,ya], [xa+Aa,ya-Ahd]),//左上角
      }
    };
    var myFsAMakerR1A = {    
      paths: {
       "R1ALine1": new makerjs.paths.Line([xa+w,ya-Ahd], [xa+w+markShift,ya-Ahd-markShift]),//左上角
      }
    };
    var myFsAMakerDA = {    
      paths: {
       "DALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*****************  C  *********** */
    /****************** G   ************** */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg,yg+h], [xg,yg+h+markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg+w,yg+h], [xg+w,yg+h+markShiftThree]),//左上角
        "WGLine2":new makerjs.paths.Line([xg,yg+h+markShift], [xg+w,yg+h+markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0":new makerjs.paths.Line([xg,yg+h+d+markShift], [xg+w1,yg+h+d+markShift]),
        "W1GLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1,yg+h1+d+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0":new makerjs.paths.Line([xg,yg+h+h+markShiftThree], [xg+w2,yg+h+h+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg+w2,yg], [xg+w2,yg+h+h+d+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {      
      paths: {
        "HGLine0":new makerjs.paths.Line([xg,yg], [xg-markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg,yg+h], [xg-markShiftFour, yg+h]),//左上角
        "HGLine2": new makerjs.paths.Line([xg-markShiftTwo,yg], [xg-markShiftTwo,yg+h]),//左上角
      }
    };
    var myFsAMakerH1G = {    
      paths: {
        "H1GLine0":new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+d/2+markShiftFour, yg+h1]),
        "H1GLine1": new makerjs.paths.Line([xg+w2+markShift,yg], [xg+w2+markShift,yg+h1]),//左上角
      }
    };
    var myFsAMakerR2G = {    
      paths: {
        "R2GLine1": new makerjs.paths.Line([xg+w2,yg], [xg+w2, yg+r2+markShiftFour]),//左上角
        "R2GLine2": new makerjs.paths.Line([xg+Ga-markShiftTwo,yg], [xg+Ga,yg+Ghd]),//左上角
      }
    };
    var myFsAMakerR1G = {    
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg+w,yg+Ghd], [xg+w+markShift,yg+Ghd+markShift]),//左上角
      }
    };
    var myFsAMakerDG = {    
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /************************   I  ******************* */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi,yi+h], [xi,yi+h+markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi-w,yi+h], [xi-w,yi+h+markShiftThree]),//左上角
        "WILine2":new makerjs.paths.Line([xi,yi+h+markShift], [xi-w,yi+h+markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
       "W1ILine0":new makerjs.paths.Line([xi,yi+h+d+markShift], [xi-w1,yi+h+d+markShift]),
       "W1ILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1,yi+h1+d+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
       "W2ILine0":new makerjs.paths.Line([xi,yi+h+h+markShiftThree], [xi-w2,yi+h+h+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2,yi+h+h+d+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {      
      paths: {
       "HILine0":new makerjs.paths.Line([xi,yi], [xi+markShiftFour, yi]),
       "HILine1": new makerjs.paths.Line([xi,yi+h], [xi+markShiftFour, yi+h]),//左上角
       "HILine2": new makerjs.paths.Line([xi+markShiftTwo,yi], [xi+markShiftTwo,yi+h]),//左上角
      }
    };
    var myFsAMakerH1I = {    
      paths: {
       "H1ILine0":new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-d/2-markShiftFour, yi+h1]),
       "H1ILine1": new makerjs.paths.Line([xi-w2-markShift,yi], [xi-w2-markShift,yi+h1]),//左上角
      }
    };
    var myFsAMakerR2I = {    
      paths: {
      //  "R2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2, yi-r2-markShiftFour]),//左上角
       "R2ILine2": new makerjs.paths.Line([xi-Iaa+markShiftTwo,yi], [xi-Iaa,yi+Ihd]),//左上角
      }
    };
    var myFsAMakerR1I = {    
      paths: {
       "R1ILine1": new makerjs.paths.Line([xi-w,yi+Ihd], [xi-w-markShift,yi+Ihd+markShift]),//左上角
      }
    };
    var myFsAMakerDI = {    
      paths: {
       "DILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /**************************************************** */
    
  makerjs.model.addCaption(myFsAMakerWA, TxtW, [xa,ya-h-markShiftThree], [xa+w,ya-h-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1A, TxtW1, [xa,ya-h-d-markShiftThree], [xa+w1,ya-h-d-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2A, TxtW2, [xa,ya-h-h-d-markShiftFour], [xa+w2,ya-h-h-d-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHA, TxtH, [xa-markShiftFour,ya-h], [xa-markShiftFour,ya]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1A, TxtH1, [xa+w2+markShiftThree,ya-h1], [xa+w2+markShiftThree,ya]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2A, TxtR2, [xa+Aa-markShiftThree,ya-Ahd+markShiftThree], [xa+Aa-markShift,ya-Ahd+markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1A, TxtR1, [xa+w,ya-Ahd], [xa+w+markShift,ya-Ahd-markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDA, TxtD, [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD],[xa+w1+markHolesOneD+markShiftFour,ya-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    
  /**************************************************** */
  /******************************************************** */
  makerjs.model.addCaption(myFsAMakerWG, TxtW, [xg,yg+h+markShiftThree], [xg+w,yg+h+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1G, TxtW1, [xg,yg+h+d+markShiftThree], [xg+w1,yg+h+d+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2G, TxtW2, [xg,yg+h+h+d+markShiftFour], [xg+w2,yg+h+h+d+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHG, TxtH, [xg-markShiftFour,yg+h], [xg-markShiftFour,yg]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1G, TxtH1, [xg+w2+markShiftThree,yg+h1], [xg+w2+markShiftThree,yg]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2G, TxtR2, [xg+Ga-markShiftThree,yg+Ghd-markShiftThree], [xg+Ga-markShift,yg+Ghd-markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1G, TxtR1, [xg+w,yg+Ghd], [xg+w+markShift,yg+Ghd+markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDG, TxtD, [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD],[xg+w1+markHolesOneD+markShiftFour,yg+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
    
  /******************************************************** */
  
  makerjs.model.addCaption(myFsAMakerWI, TxtW, [xi-w,yi+h+markShiftThree], [xi,yi+h+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1I, TxtW1, [xi-w1,yi+h+d+markShiftThree], [xi,yi+h+d+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2I, TxtW2, [xi-w2,yi+h+h+d+markShiftFour], [xi,yi+h+h+d+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHI, TxtH, [xi+markShiftFour,yi+h], [xi+markShiftFour,yi]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1I, TxtH1, [xi-w2-markShiftThree,yi+h1], [xi-w2-markShiftThree,yi]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2I, TxtR2, [xi-Iaa+markShift,yi+Ihd-markShift], [xi-Iaa+markShiftThree,yi+Ihd-markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1I, TxtR1, [xi-w-markShift,yi+Ihd+markShift], [xi-w,yi+Ihd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDI, TxtD,[xi-w1-markHolesOneD-markShiftFour,yi+h1+d+markShiftFour+markHolesOneD], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   

  /******************************************************** */
  var markers = {
    /**A */
        myFsAMakerWA,
        myFsAMakerW1A,
         myFsAMakerW2A,
         myFsAMakerHA,
        myFsAMakerH1A,
         myFsAMakerR2A,
        myFsAMakerR1A,
        myFsAMakerDA,
        /**C */
        /** G*/        
        myFsAMakerWG,
        myFsAMakerW1G,
         myFsAMakerW2G,
         myFsAMakerHG,
        myFsAMakerH1G,
         myFsAMakerR2G,
        myFsAMakerR1G,
        myFsAMakerDG,
        /**I */
        myFsAMakerWI,
        myFsAMakerW1I,
         myFsAMakerW2I,
         myFsAMakerHI,
        myFsAMakerH1I,
         myFsAMakerR2I,
        myFsAMakerR1I,
        myFsAMakerDI,
      }
  

    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "CGI") {
   
    /*****************  C  *********** */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc,yc-h], [xc,yc-h-markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc-w,yc-h], [xc-w,yc-h-markShiftThree]),//左上角
        "WCLine2":new makerjs.paths.Line([xc,yc-h-markShift], [xc-w,yc-h-markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
       "W1CLine0":new makerjs.paths.Line([xc,yc-h-d-markShift], [xc-w1,yc-h-d-markShift]),
       "W1CLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1,yc-h1-d-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
       "W2CLine0":new makerjs.paths.Line([xc,yc-h-h-markShiftThree], [xc-w2,yc-h-h-markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2,yc-h-h-d-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {      
      paths: {
       "HCLine0":new makerjs.paths.Line([xc,yc], [xc+markShiftFour, yc]),
       "HCLine1": new makerjs.paths.Line([xc,yc-h], [xc+markShiftFour, yc-h]),//左上角
       "HCLine2": new makerjs.paths.Line([xc+markShiftTwo,yc], [xc+markShiftTwo,yc-h]),//左上角
      }
    };
    var myFsAMakerH1C = {    
      paths: {
       "H1CLine0":new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-d/2-markShiftFour, yc-h1]),
       "H1CLine1": new makerjs.paths.Line([xc-w2-markShift,yc], [xc-w2-markShift,yc-h1]),//左上角
      }
    };
    var myFsAMakerR2C = {    
      paths: {
      //  "R2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2, yc-r2-markShiftFour]),//左上角
       "R2CLine2": new makerjs.paths.Line([xc-Caa+markShiftTwo,yc], [xc-Caa,yc-Chd]),//左上角
      }
    };
    var myFsAMakerR1C = {    
      paths: {
       "R1CLine1": new makerjs.paths.Line([xc-w,yc-Chd], [xc-w-markShift,yc-Chd-markShift]),//左上角
      }
    };
    var myFsAMakerDC = {    
      paths: {
       "DCLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /****************** G   ************** */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg,yg+h], [xg,yg+h+markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg+w,yg+h], [xg+w,yg+h+markShiftThree]),//左上角
        "WGLine2":new makerjs.paths.Line([xg,yg+h+markShift], [xg+w,yg+h+markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0":new makerjs.paths.Line([xg,yg+h+d+markShift], [xg+w1,yg+h+d+markShift]),
        "W1GLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1,yg+h1+d+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0":new makerjs.paths.Line([xg,yg+h+h+markShiftThree], [xg+w2,yg+h+h+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg+w2,yg], [xg+w2,yg+h+h+d+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {      
      paths: {
        "HGLine0":new makerjs.paths.Line([xg,yg], [xg-markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg,yg+h], [xg-markShiftFour, yg+h]),//左上角
        "HGLine2": new makerjs.paths.Line([xg-markShiftTwo,yg], [xg-markShiftTwo,yg+h]),//左上角
      }
    };
    var myFsAMakerH1G = {    
      paths: {
        "H1GLine0":new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+d/2+markShiftFour, yg+h1]),
        "H1GLine1": new makerjs.paths.Line([xg+w2+markShift,yg], [xg+w2+markShift,yg+h1]),//左上角
      }
    };
    var myFsAMakerR2G = {    
      paths: {
        "R2GLine1": new makerjs.paths.Line([xg+w2,yg], [xg+w2, yg+r2+markShiftFour]),//左上角
        "R2GLine2": new makerjs.paths.Line([xg+Ga-markShiftTwo,yg], [xg+Ga,yg+Ghd]),//左上角
      }
    };
    var myFsAMakerR1G = {    
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg+w,yg+Ghd], [xg+w+markShift,yg+Ghd+markShift]),//左上角
      }
    };
    var myFsAMakerDG = {    
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /************************   I  ******************* */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi,yi+h], [xi,yi+h+markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi-w,yi+h], [xi-w,yi+h+markShiftThree]),//左上角
        "WILine2":new makerjs.paths.Line([xi,yi+h+markShift], [xi-w,yi+h+markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
       "W1ILine0":new makerjs.paths.Line([xi,yi+h+d+markShift], [xi-w1,yi+h+d+markShift]),
       "W1ILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1,yi+h1+d+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
       "W2ILine0":new makerjs.paths.Line([xi,yi+h+h+markShiftThree], [xi-w2,yi+h+h+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2,yi+h+h+d+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {      
      paths: {
       "HILine0":new makerjs.paths.Line([xi,yi], [xi+markShiftFour, yi]),
       "HILine1": new makerjs.paths.Line([xi,yi+h], [xi+markShiftFour, yi+h]),//左上角
       "HILine2": new makerjs.paths.Line([xi+markShiftTwo,yi], [xi+markShiftTwo,yi+h]),//左上角
      }
    };
    var myFsAMakerH1I = {    
      paths: {
       "H1ILine0":new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-d/2-markShiftFour, yi+h1]),
       "H1ILine1": new makerjs.paths.Line([xi-w2-markShift,yi], [xi-w2-markShift,yi+h1]),//左上角
      }
    };
    var myFsAMakerR2I = {    
      paths: {
      //  "R2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2, yi-r2-markShiftFour]),//左上角
       "R2ILine2": new makerjs.paths.Line([xi-Iaa+markShiftTwo,yi], [xi-Iaa,yi+Ihd]),//左上角
      }
    };
    var myFsAMakerR1I = {    
      paths: {
       "R1ILine1": new makerjs.paths.Line([xi-w,yi+Ihd], [xi-w-markShift,yi+Ihd+markShift]),//左上角
      }
    };
    var myFsAMakerDI = {    
      paths: {
       "DILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /**************************************************** */
  
  makerjs.model.addCaption(myFsAMakerWC, TxtW, [xc-w,yc-h-markShiftThree], [xc,yc-h-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1C, TxtW1, [xc-w1,yc-h-d-markShiftThree], [xc,yc-h-d-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2C, TxtW2, [xc-w2,yc-h-h-d-markShiftFour], [xc,yc-h-h-d-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHC, TxtH, [xc+markShiftFour,yc-h], [xc+markShiftFour,yc]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1C, TxtH1, [xc-w2-markShiftThree,yc-h1], [xc-w2-markShiftThree,yc]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2C, TxtR2, [xc-Caa+markShift,yc-Chd+markShift], [xc-Caa+markShiftThree,yc-Chd+markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1C, TxtR1, [xc-w-markShift,yc-Chd-markShift], [xc-w,yc-Chd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDC, TxtD,[xc-w1-markHolesOneD-markShiftFour,yc-h1-d-markShiftFour-markHolesOneD], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    

  /******************************************************** */
  makerjs.model.addCaption(myFsAMakerWG, TxtW, [xg,yg+h+markShiftThree], [xg+w,yg+h+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1G, TxtW1, [xg,yg+h+d+markShiftThree], [xg+w1,yg+h+d+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2G, TxtW2, [xg,yg+h+h+d+markShiftFour], [xg+w2,yg+h+h+d+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHG, TxtH, [xg-markShiftFour,yg+h], [xg-markShiftFour,yg]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1G, TxtH1, [xg+w2+markShiftThree,yg+h1], [xg+w2+markShiftThree,yg]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2G, TxtR2, [xg+Ga-markShiftThree,yg+Ghd-markShiftThree], [xg+Ga-markShift,yg+Ghd-markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1G, TxtR1, [xg+w,yg+Ghd], [xg+w+markShift,yg+Ghd+markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDG, TxtD, [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD],[xg+w1+markHolesOneD+markShiftFour,yg+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
    
  /******************************************************** */
  
  makerjs.model.addCaption(myFsAMakerWI, TxtW, [xi-w,yi+h+markShiftThree], [xi,yi+h+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1I, TxtW1, [xi-w1,yi+h+d+markShiftThree], [xi,yi+h+d+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2I, TxtW2, [xi-w2,yi+h+h+d+markShiftFour], [xi,yi+h+h+d+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHI, TxtH, [xi+markShiftFour,yi+h], [xi+markShiftFour,yi]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1I, TxtH1, [xi-w2-markShiftThree,yi+h1], [xi-w2-markShiftThree,yi]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2I, TxtR2, [xi-Iaa+markShift,yi+Ihd-markShift], [xi-Iaa+markShiftThree,yi+Ihd-markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1I, TxtR1, [xi-w-markShift,yi+Ihd+markShift], [xi-w,yi+Ihd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDI, TxtD,[xi-w1-markHolesOneD-markShiftFour,yi+h1+d+markShiftFour+markHolesOneD], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   

  /******************************************************** */
  var markers = {
        /**C */
        myFsAMakerWC,
        myFsAMakerW1C,
         myFsAMakerW2C,
         myFsAMakerHC,
        myFsAMakerH1C,
         myFsAMakerR2C,
        myFsAMakerR1C,
        myFsAMakerDC,
        /** G*/        
        myFsAMakerWG,
        myFsAMakerW1G,
         myFsAMakerW2G,
         myFsAMakerHG,
        myFsAMakerH1G,
         myFsAMakerR2G,
        myFsAMakerR1G,
        myFsAMakerDG,
        /**I */
        myFsAMakerWI,
        myFsAMakerW1I,
         myFsAMakerW2I,
         myFsAMakerHI,
        myFsAMakerH1I,
         myFsAMakerR2I,
        myFsAMakerR1I,
        myFsAMakerDI,
      }
  

    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACGI") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa,ya-h], [xa,ya-h-markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa+w,ya-h], [xa+w,ya-h-markShiftThree]),//左上角
        "WALine2":new makerjs.paths.Line([xa,ya-h-markShift], [xa+w,ya-h-markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
       "W1ALine0":new makerjs.paths.Line([xa,ya-h-d-markShift], [xa+w1,ya-h-d-markShift]),
       "W1ALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1,ya-h1-d-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
       "W2ALine0":new makerjs.paths.Line([xa,ya-h-h-markShiftThree], [xa+w2,ya-h-h-markShiftThree]),
        "W2ALine1": new makerjs.paths.Line([xa+w2,ya], [xa+w2,ya-h-h-d-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHA = {
      
      paths: {
       "HALine0":new makerjs.paths.Line([xa,ya], [xa-markShiftFour, ya]),
       "HALine1": new makerjs.paths.Line([xa,ya-h], [xa-markShiftFour, ya-h]),//左上角
       "HALine2": new makerjs.paths.Line([xa-markShiftTwo,ya], [xa-markShiftTwo,ya-h]),//左上角
      }
    };
    var myFsAMakerH1A = {    
      paths: {
       "H1ALine0":new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+d/2+markShiftFour, ya-h1]),
       "H1ALine1": new makerjs.paths.Line([xa+w2+markShift,ya], [xa+w2+markShift,ya-h1]),//左上角
      }
    };
    var myFsAMakerR2A = {    
      paths: {
       "R2ALine1": new makerjs.paths.Line([xa+w2,ya], [xa+w2, ya-r2-markShiftFour]),//左上角
       "R2ALine2": new makerjs.paths.Line([xa+Aa-markShiftTwo,ya], [xa+Aa,ya-Ahd]),//左上角
      }
    };
    var myFsAMakerR1A = {    
      paths: {
       "R1ALine1": new makerjs.paths.Line([xa+w,ya-Ahd], [xa+w+markShift,ya-Ahd-markShift]),//左上角
      }
    };
    var myFsAMakerDA = {    
      paths: {
       "DALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*****************  C  *********** */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc,yc-h], [xc,yc-h-markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc-w,yc-h], [xc-w,yc-h-markShiftThree]),//左上角
        "WCLine2":new makerjs.paths.Line([xc,yc-h-markShift], [xc-w,yc-h-markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
       "W1CLine0":new makerjs.paths.Line([xc,yc-h-d-markShift], [xc-w1,yc-h-d-markShift]),
       "W1CLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1,yc-h1-d-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
       "W2CLine0":new makerjs.paths.Line([xc,yc-h-h-markShiftThree], [xc-w2,yc-h-h-markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2,yc-h-h-d-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {      
      paths: {
       "HCLine0":new makerjs.paths.Line([xc,yc], [xc+markShiftFour, yc]),
       "HCLine1": new makerjs.paths.Line([xc,yc-h], [xc+markShiftFour, yc-h]),//左上角
       "HCLine2": new makerjs.paths.Line([xc+markShiftTwo,yc], [xc+markShiftTwo,yc-h]),//左上角
      }
    };
    var myFsAMakerH1C = {    
      paths: {
       "H1CLine0":new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-d/2-markShiftFour, yc-h1]),
       "H1CLine1": new makerjs.paths.Line([xc-w2-markShift,yc], [xc-w2-markShift,yc-h1]),//左上角
      }
    };
    var myFsAMakerR2C = {    
      paths: {
      //  "R2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2, yc-r2-markShiftFour]),//左上角
       "R2CLine2": new makerjs.paths.Line([xc-Caa+markShiftTwo,yc], [xc-Caa,yc-Chd]),//左上角
      }
    };
    var myFsAMakerR1C = {    
      paths: {
       "R1CLine1": new makerjs.paths.Line([xc-w,yc-Chd], [xc-w-markShift,yc-Chd-markShift]),//左上角
      }
    };
    var myFsAMakerDC = {    
      paths: {
       "DCLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /****************** G   ************** */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg,yg+h], [xg,yg+h+markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg+w,yg+h], [xg+w,yg+h+markShiftThree]),//左上角
        "WGLine2":new makerjs.paths.Line([xg,yg+h+markShift], [xg+w,yg+h+markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0":new makerjs.paths.Line([xg,yg+h+d+markShift], [xg+w1,yg+h+d+markShift]),
        "W1GLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1,yg+h1+d+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0":new makerjs.paths.Line([xg,yg+h+h+markShiftThree], [xg+w2,yg+h+h+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg+w2,yg], [xg+w2,yg+h+h+d+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {      
      paths: {
        "HGLine0":new makerjs.paths.Line([xg,yg], [xg-markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg,yg+h], [xg-markShiftFour, yg+h]),//左上角
        "HGLine2": new makerjs.paths.Line([xg-markShiftTwo,yg], [xg-markShiftTwo,yg+h]),//左上角
      }
    };
    var myFsAMakerH1G = {    
      paths: {
        "H1GLine0":new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+d/2+markShiftFour, yg+h1]),
        "H1GLine1": new makerjs.paths.Line([xg+w2+markShift,yg], [xg+w2+markShift,yg+h1]),//左上角
      }
    };
    var myFsAMakerR2G = {    
      paths: {
        "R2GLine1": new makerjs.paths.Line([xg+w2,yg], [xg+w2, yg+r2+markShiftFour]),//左上角
        "R2GLine2": new makerjs.paths.Line([xg+Ga-markShiftTwo,yg], [xg+Ga,yg+Ghd]),//左上角
      }
    };
    var myFsAMakerR1G = {    
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg+w,yg+Ghd], [xg+w+markShift,yg+Ghd+markShift]),//左上角
      }
    };
    var myFsAMakerDG = {    
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /************************   I  ******************* */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi,yi+h], [xi,yi+h+markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi-w,yi+h], [xi-w,yi+h+markShiftThree]),//左上角
        "WILine2":new makerjs.paths.Line([xi,yi+h+markShift], [xi-w,yi+h+markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
       "W1ILine0":new makerjs.paths.Line([xi,yi+h+d+markShift], [xi-w1,yi+h+d+markShift]),
       "W1ILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1,yi+h1+d+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
       "W2ILine0":new makerjs.paths.Line([xi,yi+h+h+markShiftThree], [xi-w2,yi+h+h+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2,yi+h+h+d+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {      
      paths: {
       "HILine0":new makerjs.paths.Line([xi,yi], [xi+markShiftFour, yi]),
       "HILine1": new makerjs.paths.Line([xi,yi+h], [xi+markShiftFour, yi+h]),//左上角
       "HILine2": new makerjs.paths.Line([xi+markShiftTwo,yi], [xi+markShiftTwo,yi+h]),//左上角
      }
    };
    var myFsAMakerH1I = {    
      paths: {
       "H1ILine0":new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-d/2-markShiftFour, yi+h1]),
       "H1ILine1": new makerjs.paths.Line([xi-w2-markShift,yi], [xi-w2-markShift,yi+h1]),//左上角
      }
    };
    var myFsAMakerR2I = {    
      paths: {
      //  "R2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2, yi-r2-markShiftFour]),//左上角
       "R2ILine2": new makerjs.paths.Line([xi-Iaa+markShiftTwo,yi], [xi-Iaa,yi+Ihd]),//左上角
      }
    };
    var myFsAMakerR1I = {    
      paths: {
       "R1ILine1": new makerjs.paths.Line([xi-w,yi+Ihd], [xi-w-markShift,yi+Ihd+markShift]),//左上角
      }
    };
    var myFsAMakerDI = {    
      paths: {
       "DILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /**************************************************** */
    
  makerjs.model.addCaption(myFsAMakerWA, TxtW, [xa,ya-h-markShiftThree], [xa+w,ya-h-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1A, TxtW1, [xa,ya-h-d-markShiftThree], [xa+w1,ya-h-d-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2A, TxtW2, [xa,ya-h-h-d-markShiftFour], [xa+w2,ya-h-h-d-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHA, TxtH, [xa-markShiftFour,ya-h], [xa-markShiftFour,ya]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1A, TxtH1, [xa+w2+markShiftThree,ya-h1], [xa+w2+markShiftThree,ya]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2A, TxtR2, [xa+Aa-markShiftThree,ya-Ahd+markShiftThree], [xa+Aa-markShift,ya-Ahd+markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1A, TxtR1, [xa+w,ya-Ahd], [xa+w+markShift,ya-Ahd-markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDA, TxtD, [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD],[xa+w1+markHolesOneD+markShiftFour,ya-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    
  /**************************************************** */

  
  makerjs.model.addCaption(myFsAMakerWC, TxtW, [xc-w,yc-h-markShiftThree], [xc,yc-h-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1C, TxtW1, [xc-w1,yc-h-d-markShiftThree], [xc,yc-h-d-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2C, TxtW2, [xc-w2,yc-h-h-d-markShiftFour], [xc,yc-h-h-d-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHC, TxtH, [xc+markShiftFour,yc-h], [xc+markShiftFour,yc]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1C, TxtH1, [xc-w2-markShiftThree,yc-h1], [xc-w2-markShiftThree,yc]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2C, TxtR2, [xc-Caa+markShift,yc-Chd+markShift], [xc-Caa+markShiftThree,yc-Chd+markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1C, TxtR1, [xc-w-markShift,yc-Chd-markShift], [xc-w,yc-Chd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDC, TxtD,[xc-w1-markHolesOneD-markShiftFour,yc-h1-d-markShiftFour-markHolesOneD], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    

  /******************************************************** */
  makerjs.model.addCaption(myFsAMakerWG, TxtW, [xg,yg+h+markShiftThree], [xg+w,yg+h+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1G, TxtW1, [xg,yg+h+d+markShiftThree], [xg+w1,yg+h+d+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2G, TxtW2, [xg,yg+h+h+d+markShiftFour], [xg+w2,yg+h+h+d+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHG, TxtH, [xg-markShiftFour,yg+h], [xg-markShiftFour,yg]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1G, TxtH1, [xg+w2+markShiftThree,yg+h1], [xg+w2+markShiftThree,yg]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2G, TxtR2, [xg+Ga-markShiftThree,yg+Ghd-markShiftThree], [xg+Ga-markShift,yg+Ghd-markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1G, TxtR1, [xg+w,yg+Ghd], [xg+w+markShift,yg+Ghd+markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDG, TxtD, [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD],[xg+w1+markHolesOneD+markShiftFour,yg+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
    
  /******************************************************** */
  
  makerjs.model.addCaption(myFsAMakerWI, TxtW, [xi-w,yi+h+markShiftThree], [xi,yi+h+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1I, TxtW1, [xi-w1,yi+h+d+markShiftThree], [xi,yi+h+d+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2I, TxtW2, [xi-w2,yi+h+h+d+markShiftFour], [xi,yi+h+h+d+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHI, TxtH, [xi+markShiftFour,yi+h], [xi+markShiftFour,yi]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1I, TxtH1, [xi-w2-markShiftThree,yi+h1], [xi-w2-markShiftThree,yi]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2I, TxtR2, [xi-Iaa+markShift,yi+Ihd-markShift], [xi-Iaa+markShiftThree,yi+Ihd-markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1I, TxtR1, [xi-w-markShift,yi+Ihd+markShift], [xi-w,yi+Ihd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDI, TxtD,[xi-w1-markHolesOneD-markShiftFour,yi+h1+d+markShiftFour+markHolesOneD], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   

  /******************************************************** */
  var markers = {
    /**A */
        myFsAMakerWA,
        myFsAMakerW1A,
         myFsAMakerW2A,
         myFsAMakerHA,
        myFsAMakerH1A,
         myFsAMakerR2A,
        myFsAMakerR1A,
        myFsAMakerDA,
        /**C */
        myFsAMakerWC,
        myFsAMakerW1C,
         myFsAMakerW2C,
         myFsAMakerHC,
        myFsAMakerH1C,
         myFsAMakerR2C,
        myFsAMakerR1C,
        myFsAMakerDC,
        /** G*/        
        myFsAMakerWG,
        myFsAMakerW1G,
         myFsAMakerW2G,
         myFsAMakerHG,
        myFsAMakerH1G,
         myFsAMakerR2G,
        myFsAMakerR1G,
        myFsAMakerDG,
        /**I */
        myFsAMakerWI,
        myFsAMakerW1I,
         myFsAMakerW2I,
         myFsAMakerHI,
        myFsAMakerH1I,
         myFsAMakerR2I,
        myFsAMakerR1I,
        myFsAMakerDI,
      }
  

    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  }
  return { markers };
};
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
 //已知相切两圆的圆心坐标及半径，求切点坐标
 function CircleTwo(x1,y1,x2,y2,r1,r2){
    var x=(r1*x2+x1*r2)/(r2+r1);    
     var d=Math.sqrt((r1*r1)-(x-x1)*(x-x1));             
   var Arr = [x,d]; 
   return Arr;
 };
function FloorSpring(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){

  var frame = outParam.shapeName;
  console.log("outParam==", outParam)
  console.log("model==", model)
  console.log("inputParam==", inputParam)
  console.log("outParam.shapeName==////////", outParam.shapeName)
  if (typeof (frame) == "undefined") {
    var D=inputParam.D,R1=inputParam.R1,R2=inputParam.R2,W=inputParam.W,W1=inputParam.W1,W2=inputParam.W2,H=inputParam.H,H1=inputParam.H1,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
    plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = "Rectangle",
      glassW = outParam.glassW, glassH = outParam.glassH,
      markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
      markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,


      w = W*1*myZoom,
      w1 = W1*1*myZoom,
      w2 = W2*1*myZoom,
      h = H*1*myZoom,
      h1 = H1*1*myZoom,
      r1 = R1*1*myZoom,
      r2 = R2*1*myZoom,
      d = D*1*myZoom,
      r = d/2,
      a=0,//圆心坐标[a,b]
      b=0,//圆心坐标[a,b]  
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;  
        if(plate.models===undefined||plate.models===""){
          console.log("1111111111111111");
          plate.models={};
                  }        
                  if(cutPlate.models===undefined||cutPlate.models===""){
                      console.log("22222222222");
                      //cutPlate.models={};
                      cutPlate.models={};
                  }
                  if(markers.models===undefined||markers.models===""){            
                      console.log("333333333333");
                      markers.models={};
                  }

      if(d!==0){
        var TxtD="∮"+D;
       }
       if(r1!==0){
        var TxtR1="R1="+R1;
       }
       if(r2!==0){
        var TxtR2="R2="+R2;
       }
       if(w!==0){
        var TxtW=W;
       }
       if(w1!==0){
        var TxtW1=W1;
       }
       if(w2!==0){
        var TxtW2=W2;
       }
       if(h!==0){
        var TxtH=H;
       }
       if(h1!==0){
        var TxtH1=H1;
       }
       if(x0!==0){          
       var TxtX=X;
       }
       if(y0!==0){          
        var TxtY=Y;
       }
  } else {
    var D=inputParam.D,R1=inputParam.R1,R2=inputParam.R2,W=inputParam.W,W1=inputParam.W1,W2=inputParam.W2,H=inputParam.H,H1=inputParam.H1,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
    plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = outParam.shapeName,
      glassW = outParam.glassW, glassH = outParam.glassH,
      markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
      markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,

      w = W*1*myZoom,
   w1 = W1*1*myZoom,
   w2 = W2*1*myZoom,
   h = H*1*myZoom,
   h1 = H1*1*myZoom,
   r1 = R1*1*myZoom,
   r2 = R2*1*myZoom,
   d = D*1*myZoom,
   r = d/2,
   a=0,//圆心坐标[a,b]
   b=0,//圆心坐标[a,b]  
     x0 = X*1*myZoom,
     y0 = Y*1*myZoom;  
     if(plate.models===undefined||plate.models===""){
      console.log("1111111111111111");
      plate.models={};
              }        
              if(cutPlate.models===undefined||cutPlate.models===""){
                  console.log("22222222222");
                  //cutPlate.models={};
                  cutPlate.models={};
              }
              if(markers.models===undefined||markers.models===""){            
                  console.log("333333333333");
                  markers.models={};
              }
    /* 
   w = W*1,
   w1 = W1*1,
   w2 = W2*1,
   h = H*1,
   h1 = H1*1,
   r1 = R1*1,
   r2 = R2*1,
   d = D*1,
   r = (D*1)/2,
   a=0,//圆心坐标[a,b]
   b=0,//圆心坐标[a,b]  
     x0 = X*1,
     y0 = Y*1;
     */   
     if(d!==0){
      var TxtD="∮"+D;
     }
     if(r1!==0){
      var TxtR1="R1="+R1;
     }
     if(r2!==0){
      var TxtR2="R2="+R2;
     }
     if(w!==0){
      var TxtW=W;
     }
     if(w1!==0){
      var TxtW1=W1;
     }
     if(w2!==0){
      var TxtW2=W2;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(h1!==0){
      var TxtH1=H1;
     }
     if(x0!==0){          
     var TxtX=X;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
    }
  if (frame == "Rectangle") {
    console.log("frame==undefined||||*****") 

    //在A区域 计算孔的位置       
    var xa = x0, ya = glassH * 1 - y0,
    //C区域
    xc = glassW * 1 - x0, yc = glassH * 1 - y0,
    //在G区域 计算孔的位置
    xg = x0, yg = y0,
    //在I区域 计算孔的位置
    xi = glassW * 1 - x0, yi = y0,
    arrA=[],arrC=[],arrG=[],arrI=[];

  arrA=CircleTwo(xa+w,ya+r1,xa+w2,ya-r2,r1,r2);
  var Aa=arrA[0]*1,  Ab=arrA[1]*1,  Ahd=h-(r1-Ab),Aaa=glassW * 1-Aa;
  arrC=CircleTwo(xc-w,yc+r1,xc-w2,yc-r2,r1,r2);
  var Ca=arrC[0]*1,  Cb=arrC[1]*1,  Chd=h-(r1-Cb),Caa=glassW * 1-Ca;
  arrG=CircleTwo(xg+w,yg-r1,xg+w2,yg+r2,r1,r2);
  var Ga=arrG[0]*1,  Gb=arrG[1]*1,  Ghd=h-(r1-Gb),Gaa=glassW * 1-Ga;
  arrI=CircleTwo(xi-w,yi-r1,xi-w2,yi+r2,r1,r2); 
  var Ia=arrI[0]*1,  Ib=arrI[1]*1,  Ihd=h-(r1-Ib),Iaa=glassW * 1-Ia,
// var a=arr[0]*1,b=arr[1]*1,aa=glassW * 1-a,hd=h-(r1-b),
      xawx1 = xa, xawy1 =ya-h,//A位置角1坐标
      xawx2 = xa+w,xawy2 = ya-h,//A位置角2坐标
      xawx3 = xa+Aa,xawy3 = ya-Ahd,//A位置角3坐标
      xawx4 = xa+w2,xawy4 = ya,//A位置角4坐标
      // "TopLine": new makerjs.paths.Line([xc-w2, yc+5],[xc+5, yc+5]),
      //   "RightLine": new makerjs.paths.Line([xc+5, yc+5], [xc+5, yc-h]),
      //   "BottomLine": new makerjs.paths.Line( [xc+5, yc-h],[xc-w, yc-h]),
      //   "BottomRightArc": new makerjs.paths.Arc([xc-w, yc-h], [xc-aa, yc-hd],r1,false,true),//是否大弧,是否顺时针  
      //   "BottomLeftArc": new makerjs.paths.Arc([xc-aa, yc-hd], [xc-w2, yc],r2,false,false),//是否大弧,是否顺时针
      //   "LeftLine": new makerjs.paths.Line([xc-w2, yc], [xc-w2, yc+5]),
      //   "RightHoles": new makerjs.paths.Circle([xc-w1, yc-h1], r),
      xcwx1 = xc, xcwy1 =yc-h,//C位置角1坐标
      xcwx2 = xc-w,xcwy2 = yc-h,//C位置角2坐标
      xcwx3 = xc-Caa,xcwy3 = yc-Chd,//C位置角3坐标
      xcwx4 = xc-w2,xcwy4 = yc,//C位置角4坐标
      // var myFloorSpringG = {
      //   paths: {  
      //     "LeftLine": new makerjs.paths.Line([xg-5, yg-5], [xg-5, yg+h]),
      //     "TopLine": new makerjs.paths.Line([xg-5, yg+h], [xg+w, yg+h]),
      //     "TopLeftArc": new makerjs.paths.Arc([xg+w, yg+h], [xg+a, yg+hd],r1,false,true),//是否大弧,是否顺时针  
      //     "TopRightArc": new makerjs.paths.Arc([xg+a, yg+hd], [xg+w2, yg],r2,false,false),//是否大弧,是否顺时针
      //     "RightLine": new makerjs.paths.Line([xg+w2, yg], [xg+w2, yg-5]),
      //     "BottomLine": new makerjs.paths.Line([xg+w2, yg-5], [xg-5, yg-5]),
      //     "RightHoles": new makerjs.paths.Circle([xg+w1, yg+h1], r),
      //   }
      //   };

      xgwx1 = xg, xgwy1 =yg+h,//G位置角1坐标
      xgwx2 = xg+w,xgwy2 = yg+h,//G位置角2坐标
      xgwx3 = xg+Ga,xgwy3 = yg+Ghd,//G位置角3坐标
      xgwx4 = xg+w2,xgwy4 = yg,//G位置角4坐标
      
      // var myFloorSpringI = {
      //   paths: {
      //     "BottomLine": new makerjs.paths.Line([xi+5, yi-5], [xi-w2, yi-5]),
      //     "LeftLine": new makerjs.paths.Line([xi-w2, yi-5], [xi-w2, yi]), 
      //     "TopLeftArc": new makerjs.paths.Arc([xi-w2, yi], [xi-aa, yi+hd],r2,false,false),//是否大弧,是否顺时针  
      //     "TopRightArc": new makerjs.paths.Arc([xi-aa, yi+hd], [xi-w, yi+h],r1,false,true),//是否大弧,是否顺时针
      //     "TopLine": new makerjs.paths.Line([xi-w, yi+h], [xi+5, yi+h]),
      //     "RightLine": new makerjs.paths.Line([xi+5, yi+h], [xi+5, yi-5]),
      //     "RightHoles": new makerjs.paths.Circle([xi-w1, yi+h1], r),
      //   }
      //   };

      xiwx1 = xi, xiwy1 =yi+h,//I位置角1坐标
      xiwx2 = xi-w,xiwy2 = yi+h,//I位置角2坐标
      xiwx3 = xi-Iaa,xiwy3 = yi+Ihd,//I位置角3坐标
      xiwx4 = xi-w2,xiwy4 = yi;//I位置角4坐标      
var OffsetParam={Aa,Aaa,Ahd,Ca,Caa,Chd,Ga,Gaa,Ghd,Ia,Iaa,Ihd};
  } else if (frame == "IsoscelesTrapezoid") {
    //在A区域 计算孔的位置
    var xwd = (glassW * 1 - glassW1 * 1) / 2,
      xa = x0 + xwd, ya = glassH * 1 - y0,
      awd = xwd * h / (glassH * 1),
      cwd = awd,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标   
      //C区域
      xc = glassW * 1 - xwd - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc + awd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - awd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd, ahd: 0, cwd, cwdd: 0, chd: 0, iwd: awd, iwdd: 0, ihd: 0 };
  } else if (frame == "RightAngledTrapezoid") {
    //在A区域 计算孔的位置
    var yhd = glassH * 1 - glassH1 * 1, xwd = glassW * 1 - glassW1 * 1,
      ahd = yhd * w / (glassW1 * 1),//Y方向偏移
      awd = ((glassW1 * 1) - w) * yhd / (glassH * 1), //X方向偏移
      iwd = xwd * h / (glassH * 1), //X方向偏移
      xa = x0, ya = glassH * 1 - y0 - yhd,
      xawx = xa + w, xawy = ya + ahd,//A位置角W坐标
      xahx = xa, xahy = ya - h,//A位置角H坐标 
      //C区域
      xc = glassW * 1 - xwd - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc - ahd,//C位置角W坐标
      xchx = xc + iwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - iwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd, cwd: iwd, cwdd: 0, chd: 0, iwd, iwdd: 0, ihd: 0 };
  } else if (frame == "OtherTrapezoidsA") {
    //在A区域 计算孔的位置
    var yhd = glassH1 * 1 - glassH2 * 1,
      ahd = yhd * w / ((glassW * 1) - (glassW1 * 1) - (glassW2 * 1)),//Y方向偏移
      awd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移 A 
      iwd = (glassW2 * 1) * h / (glassH2 * 1), //X方向偏移
      gwd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移


      xa = x0 + glassW1 * 1, ya = glassH1 * 1 - y0,
      xawx = xa + w, xawy = ya - ahd,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标 
      //C区域
      xc = glassW * 1 - glassW2 * 1 - x0, yc = glassH2 * 1 - y0,
      xcwx = xc - w, xcwy = yc + ahd,//C位置角W坐标
      xchx = xc + iwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + gwd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - iwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd: 0, cwd: iwd, cwdd: 0, chd: ahd, iwd, iwdd: 0, ihd: 0 };
  } else if (frame == "OtherTrapezoidsB") {
    //在A区域 计算孔的位置
    var yhd = glassH1 * 1 - glassH2 * 1,
      ahd = yhd * w / ((glassW * 1) - (glassW1 * 1) - (glassW2 * 1)),//Y方向偏移
      awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A 
      cwd = (glassW2 * 1) * h / (glassH * 1), //X方向偏移 A 
      iwd = (glassW2 * 1) * h / (glassH2 * 1), //X方向偏移
      gwd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 - glassW2 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc + cwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - cwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd: 0, cwd, cwdd: 0, chd: 0, iwd: cwd, iwdd: 0, ihd: 0 };
  } else if (frame == "ParallelogramA") {
    //在A区域 计算孔的位置
    var awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 + glassW1 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc - awd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi + awd, xihy = yi + h;//I位置角H坐标

    var OffsetParam = { awd: 0, ahd: 0, cwd: 0, cwdd: awd, chd: 0, iwd: 0, iwdd: awd, ihd: 0 };

  } else if (frame == "ParallelogramB") {
    console.log("markers.models=", markers.models)
    //在A区域 计算孔的位置
    var awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A  G
      cwd = (glassW2 * 1) * h / (glassH * 1), //X方向偏移 C  I 
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 + glassW2 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc - cwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi + cwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd: 0, cwd: 0, cwdd: awd, chd: 0, iwd: 0, iwdd: cwd, ihd: 0 };

  }

  // 坐标参数
  var paramCoordinate = {d,w,w1,w2,h,h1,r,r1,r2, xa, ya, xawx1, xawy1,xawx2, xawy2,xawx3, xawy3,xawx4, xawy4, xc, yc, xcwx1, xcwy1, xcwx2,xcwy2,xcwx3,xcwy3,xcwx4,xcwy4, xg, yg, xgwx1, xgwy1, xgwx2, xgwy2,xgwx3, xgwy3,xgwx4, xgwy4, xi, yi, xiwx1, xiwy1, xiwx2, xiwy2, xiwx3, xiwy3, xiwx4, xiwy4 };
  const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(model.plate.models)));
  var areaPoint = "";
  console.log("//画1、斜角 ------", allKeys)
  if (allKeys[0] == frame) {
    allKeys.shift();//去掉数组第一个元素
  }
  console.log("//画1、斜角 位置去掉数组第一个元素------", allKeys)
  if (allKeys.length == 0) {
    switch (area) {
      case "1": areaPoint = "A"; break;
      case "3": areaPoint = "C"; break;
      case "7": areaPoint = "G"; break;
      case "9": areaPoint = "I"; break;
    }
  } else {
    switch (area) {
      case "1": areaPoint = getStrs("A", allKeys); break;
      case "3": areaPoint = getStrs("C", allKeys); break;
      case "7": areaPoint = getStrs("G", allKeys); break;
      case "9": areaPoint = getStrs("I", allKeys); break;
    }
  }
  /**
  * 画图
  * 
  */
  var ObjName = "myCorner" + areaPoint;
  var ObjNameM = "myCorner" + areaPoint + "Mark";//外框标记
  var ObjNameMCorner = "myCorner" + areaPoint + "MarkCorner";//角标记
  var ObjNameO = "myCorner" + areaPoint + "Obj";
  ObjName = creatFloorSpring(areaPoint, paramCoordinate);//图形
  console.log("ObjName===|||||||||", ObjName);
  ObjNameMCorner = creatFloorSpringMark(outParam, areaPoint, paramCoordinate, inputParam, fixedParam, OffsetParam, zoom);//图形
  console.log("ObjNameMCorner===|||||||||角标注", ObjNameMCorner);
  ObjNameO = { ObjName:ObjName[0] };
  ObjNameM = RectangleMark(outParam, model, fixedParam, zoom, frame);//外框标记
  cutPlate.models = {};
  plate.models = {};
  markers.models = {};
  cutPlate.models["myCorner" + areaPoint] = ObjName[1];
  plate.models["myCorner" + areaPoint] = ObjName[0];
  console.log("ObjNameM.markers.models===|||||||||是否有所有标注", ObjNameM.markers.models);
  console.log("ObjNameMCorner.markers===|||||||||是否有所有标注", ObjNameMCorner.markers);
  markers.models = { ...ObjNameM.markers.models, ...ObjNameO, ...ObjNameMCorner.markers };
  console.log("markers===|||||||||是否有所有标注", markers);

myHolesNum++;
return{plate,cutPlate,markers, myHolesNum}
};
export default FloorSpring;