//角1、斜角
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ObliqueAngle(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut,frame){
  console.log("outParam==",outParam)
 
  if (typeof (frame) == "undefined") {
    var H = inputParam.H, W = inputParam.W, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
      plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = "Rectangle",
      glassW = outParam.glassW, glassH = outParam.glassH,
      markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
      markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
      clickArea=[],
      h = H * 1 * myZoom,
      w = W * 1 * myZoom,
      x0 = X * 1 * myZoom,
      y0 = Y * 1 * myZoom;
      if(plate.models===undefined||plate.models===""){
        console.log("undefinedundefinedundefined1111111111111111");
        plate.models={};
                }        
      if(cutPlate.models===undefined||cutPlate.models===""){
        console.log("22222222222");
        cutPlate.models={};
                }
      if(markers.models===undefined||markers.models===""){            
        console.log("333333333333");
        markers.models={};
                }
    /*
    h = H*1,
    w= W*1,
      x0 = X*1,
      y0 = Y*1;
      */
    if (h !== 0) {
      var TxtH = H;
    }
    if (w !== 0) {
      var TxtW = W;
    }
    if (x0 !== 0) {
      var TxtX = X;
    }
    if (y0 !== 0) {
      var TxtY = Y;
    }
  } else {
    var H = inputParam.H, W = inputParam.W, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
      plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = outParam.shapeName,
      glassW = outParam.W, glassH = outParam.H, glassW1 = outParam.W1, glassW2 = outParam.W2, glassH1 = outParam.H1, glassH2 = outParam.H2,
      markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
      markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
      clickArea=[],
      h = H * 1 * myZoom,
      w = W * 1 * myZoom,
      x0 = X * 1 * myZoom,
      y0 = Y * 1 * myZoom;
      if(plate.models===undefined||plate.models===""){
        console.log("1111111111111111");
        plate.models={};
                }        
                if(cutPlate.models===undefined||cutPlate.models===""){
                    console.log("22222222222");
                    //cutPlate.models={};
                    cutPlate.models={};
                }
                if(markers.models===undefined||markers.models===""){            
                    console.log("333333333333");
                    markers.models={};
                }
    /*
    h = H*1,
    w= W*1,
      x0 = X*1,
      y0 = Y*1;
      */
    if (h !== 0) {
      var TxtH = H;
    }
    if (w !== 0) {
      var TxtW = W;
    }
    if (x0 !== 0) {
      var TxtX = X;
    }
    if (y0 !== 0) {
      var TxtY = Y;
    }
  }


 if(frame=="Rectangle"){ 
  console.log('frame=dddddddddddddddddd="Rectangle"');
      //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  console.log("xaAAAAAAAAAAAAAAA==",xa,ya,xb,yb,xc,yc,xd,yd,xe,ye,xf,yf,xg,yg,xh,yh,xi,yi,w,h);
  if(area==1){
    //画1、斜角 A位置
    var myOaAngleA=new makerjs.models.ConnectTheDots(true,[[xa-5,ya+5],[xa-5,ya-h],[xa,ya-h],[xa+w,ya],[xa+w,ya+5]]);
    
console.log("myOaAngleCCCCCCCCCCCCCCCCC==",myOaAngleA);
////标记1、斜角 A位置
var myOaAngleAMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerW.origin = [xa,ya];
var myOaAngleAMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerH.origin = [xa,ya];
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0-h], [0-markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,markShift], [0+w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleAMakerW, TxtW, [0,markShiftThree], [0+w, markShiftThree]);//W文字标注
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleAMakerH, new makerjs.paths.Line([0-markShiftTwo,0-h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleAMakerH, TxtH, [0-markShiftFour,0-h], [0-markShiftFour, 0]);//H文字标注
/*****************************************************************************/
plate.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleAMakerW"+myHolesNum]=myOaAngleAMakerW;
markers.models["myOaAngleAMakerH"+myHolesNum]=myOaAngleAMakerH;
/*****************************************************************************/
console.log("myOaAngleAAAAAAAAAAAAAAAA==",myOaAngleA);
console.log("markers.modelsAAAAAAAAAAAAAAA==",markers.models);
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myOaAngleA"+myHolesNum]);
console.log("markersAAAAAAAAAAAAAAA==",markers);
/*****************************************************************************/ 
/*****************************************************************************/ 
  
}else if(area==3){
      
     //画1、斜角 C位置
     var myOaAngleC=new makerjs.models.ConnectTheDots(true,[[xc-w,yc],[xc-w,yc+5],[xc+5,yc+5],[xc+5,yc-h],[xc,yc-h]]);
////标记1、斜角 C位置
var myOaAngleCMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerW.origin = [xc,yc];
var myOaAngleCMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerH.origin = [xc,yc];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0-h], [0+markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,markShift], [0-w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleCMakerW, TxtW, [0-w,markShiftThree], [0, markShiftThree]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerH, new makerjs.paths.Line([0+markShift,0-h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleCMakerH, TxtH, [0+markShiftFour,0-h], [0+markShiftFour, 0]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleCMakerW"+myHolesNum]=myOaAngleCMakerW;
markers.models["myOaAngleCMakerH"+myHolesNum]=myOaAngleCMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myOaAngleC"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 
 
    }else if(area==7){

      //画1、斜角 G位置
     var myOaAngleG=new makerjs.models.ConnectTheDots(true,[[xg,yg+h],[xg-5,yg+h],[xg-5,yg-5],[xg+w,yg-5],[xg+w,yg]]);
////标记1、斜角 G位置
var myOaAngleGMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerW.origin = [xg,yg];
var myOaAngleGMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerH.origin = [xg,yg];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0+w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleGMakerW, TxtW, [0,-markShiftFour], [0+w, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerH, new makerjs.paths.Line([0-markShiftTwo,0+h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleGMakerH, TxtH, [0-markShiftFour,0], [0-markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleGMakerW"+myHolesNum]=myOaAngleGMakerW;
markers.models["myOaAngleGMakerH"+myHolesNum]=myOaAngleGMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myOaAngleG"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

      
    }else if(area==9){
     
       //画1、斜角 I位置
     var myOaAngleI=new makerjs.models.ConnectTheDots(true,[[xi-w,yi-5],[xi+5,yi-5],[xi+5,yi+h],[xi,yi+h],[xi-w,yi]]);
////标记1、斜角 I位置
var myOaAngleIMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerW.origin = [xi,yi];
var myOaAngleIMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerH.origin = [xi,yi];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0+h], [0+markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0-w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleIMakerW, TxtW, [0-w,-markShiftFour], [0, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerH, new makerjs.paths.Line([0+markShift,0+h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleIMakerH, TxtH, [0+markShiftFour,0], [0+markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleIMakerW"+myHolesNum]=myOaAngleIMakerW;
markers.models["myOaAngleIMakerH"+myHolesNum]=myOaAngleIMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myOaAngleI"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

    }
}else if(frame=="IsoscelesTrapezoid"){ 
      //在A区域 计算孔的位置
      var xwd=(glassW * 1-glassW1 * 1)/2, 
           
 xa=x0+xwd,ya=glassH * 1-y0,
 xa1=xwd*h/(glassH * 1),
 
  //B区域//只有Y标注没有X标注 
  //xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-xwd-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  //xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  //xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  //xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  //xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画1、斜角 A位置
    var myOaAngleA=new makerjs.models.ConnectTheDots(true,[[xa-5,ya+5],[(xa-xa1)-5,ya-h],[(xa-xa1),ya-h],[xa+w,ya],[xa+w,ya+5]]);
////标记1、斜角 A位置
var myOaAngleAMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerW.origin = [xa,ya];
var myOaAngleAMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerH.origin = [xa,ya];
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0-h], [0-markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,markShift], [0+w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleAMakerW, TxtW, [0,markShiftThree], [0+w, markShiftThree]);//W文字标注
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleAMakerH, new makerjs.paths.Line([0-markShiftTwo,0-h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleAMakerH, TxtH, [0-markShiftFour,0-h], [0-markShiftFour, 0]);//H文字标注
/*****************************************************************************/
plate.models[area]=myOaAngleA;
markers.models[area]=myOaAngleA;
markers.models["myOaAngleAMakerW"]=myOaAngleAMakerW;
markers.models["myOaAngleAMakerH"]=myOaAngleAMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.IsoscelesTrapezoid, markers.models[area]);
console.log("markers==",markers);
/*****************************************************************************/ 
/*****************************************************************************/ 
  
    }else if(area==3){
      
     //画1、斜角 C位置
     var myOaAngleC=new makerjs.models.ConnectTheDots(true,[[xc-w,yc],[xc-w,yc+5],[xc+5,yc+5],[xc+xa1+5,yc-h],[xc+xa1,yc-h]]);
////标记1、斜角 C位置
var myOaAngleCMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerW.origin = [xc,yc];
var myOaAngleCMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerH.origin = [xc,yc];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0-h], [0+markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,markShift], [0-w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleCMakerW, TxtW, [0-w,markShiftThree], [0, markShiftThree]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerH, new makerjs.paths.Line([0+markShift,0-h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleCMakerH, TxtH, [0+markShiftFour,0-h], [0+markShiftFour, 0]);//H文字标注
/*****************************************************************************/ 
plate.models[area]=myOaAngleC;
markers.models[area]=myOaAngleC;
markers.models["myOaAngleCMakerW"]=myOaAngleCMakerW;
markers.models["myOaAngleCMakerH"]=myOaAngleCMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.IsoscelesTrapezoid, markers.models[area])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 
 
    }else if(area==7){

      //画1、斜角 G位置
     var myOaAngleG=new makerjs.models.ConnectTheDots(true,[[xa1,yg+h],[xg-5,yg+h],[xg-5,yg-5],[xg+w,yg-5],[xg+w,yg]]);
////标记1、斜角 G位置
var myOaAngleGMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerW.origin = [xg,yg];
var myOaAngleGMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerH.origin = [xg,yg];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0+w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleGMakerW, TxtW, [0,-markShiftFour], [0+w, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerH, new makerjs.paths.Line([0-markShiftTwo,0+h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleGMakerH, TxtH, [0-markShiftFour,0], [0-markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleG"]=myOaAngleG;
markers.models["myOaAngleG"]=myOaAngleG;
markers.models["myOaAngleGMakerW"]=myOaAngleGMakerW;
markers.models["myOaAngleGMakerH"]=myOaAngleGMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.IsoscelesTrapezoid, markers.models["myOaAngleG"])

console.log("markers==",markers);
/*****************************************************************************/ 

      
    }else if(area==9){
     
       //画1、斜角 I位置
     var myOaAngleI=new makerjs.models.ConnectTheDots(true,[[xi-w,yi-5],[xi+5,yi-5],[xi+5,yi+h],[(xi-xa1),yi+h],[xi-w,yi]]);
////标记1、斜角 I位置
var myOaAngleIMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerW.origin = [xi,yi];
var myOaAngleIMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerH.origin = [xi,yi];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0+h], [0+markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0-w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleIMakerW, TxtW, [0-w,-markShiftFour], [0, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerH, new makerjs.paths.Line([0+markShift,0+h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleIMakerH, TxtH, [0+markShiftFour,0], [0+markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleI"]=myOaAngleI;
markers.models["myOaAngleI"]=myOaAngleI;
markers.models["myOaAngleIMakerW"]=myOaAngleIMakerW;
markers.models["myOaAngleIMakerH"]=myOaAngleIMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.IsoscelesTrapezoid, markers.models["myOaAngleI"])

console.log("markers==",markers);
/*****************************************************************************/ 

    }
}else if(frame=="RightAngledTrapezoid"){  
    //在A区域 计算孔的位置
    var  yhd=glassH * 1-glassH1 * 1,xwd=glassW * 1-glassW1 * 1,
    ahd=yhd*w/(glassW1*1),//Y方向偏移
    awd=((glassW1*1)-w)*yhd/(glassH*1), //X方向偏移
    iwd=xwd*h/(glassH*1), //X方向偏移
         
//xa=x0+xwd,ya=glassH * 1-y0,
//xa1=xwd*h/(glassH * 1),
xa=x0,ya=glassH * 1-y0-yhd,
//B区域//只有Y标注没有X标注 
//xb=glassW * 1/2,yb=glassH * 1-y0,
//C区域
xc=glassW * 1-xwd-x0,yc=glassH * 1-y0,
//D区域
//只有X标注没有Y标注 
//xd=x0,yd=glassH * 1/2,
//E区域
//只有X标注没有Y标注 
//xe=glassW * 1/2,ye=glassH * 1/2,
//F区域
//只有X标注没有Y标注 
//xf=glassW * 1-x0,yf=glassH * 1/2,
//在G区域 计算孔的位置
xg=x0,yg=y0,
//在H区域 计算孔的位置
//只标注Y
//xh=glassW*1/2,yh=y0,
//在I区域 计算孔的位置
xi=glassW*1-x0,yi=y0;
if(area==1){
  //画1、斜角 A位置
  var myOaAngleA=new makerjs.models.ConnectTheDots(true,[[xa-5,ya+5],[(xa-5),ya-h],[xa,ya-h],[xa+w,ya+ahd],[xa+w,ya+ahd+5]]);
////标记1、斜角 A位置
var myOaAngleAMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerW.origin = [xa,ya];
var myOaAngleAMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerH.origin = [xa,ya];
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0-h], [0-markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,markShift], [0+w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleAMakerW, TxtW, [0,markShiftThree], [0+w, markShiftThree]);//W文字标注
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleAMakerH, new makerjs.paths.Line([0-markShiftTwo,0-h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleAMakerH, TxtH, [0-markShiftFour,0-h], [0-markShiftFour, 0]);//H文字标注
/*****************************************************************************/
plate.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleAMakerW"+myHolesNum]=myOaAngleAMakerW;
markers.models["myOaAngleAMakerH"+myHolesNum]=myOaAngleAMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRATrapezoid, markers.models["myOaAngleA"+myHolesNum]);
console.log("markers==",markers);
/*****************************************************************************/ 
/*****************************************************************************/ 

  }else if(area==3){
    
   //画1、斜角 C位置
   var myOaAngleC=new makerjs.models.ConnectTheDots(true,[[xc-w,yc-ahd],[xc-w,yc+5],[xc+5,yc+5],[xc+iwd+5,yc-h],[xc+iwd,yc-h]]);
////标记1、斜角 C位置
var myOaAngleCMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerW.origin = [xc,yc];
var myOaAngleCMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerH.origin = [xc,yc];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0-h], [0+markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,markShift], [0-w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleCMakerW, TxtW, [0-w,markShiftThree], [0, markShiftThree]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerH, new makerjs.paths.Line([0+markShift,0-h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleCMakerH, TxtH, [0+markShiftFour,0-h], [0+markShiftFour, 0]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleCMakerW"+myHolesNum]=myOaAngleCMakerW;
markers.models["myOaAngleCMakerH"+myHolesNum]=myOaAngleCMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRATrapezoid, markers.models["myOaAngleC"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 

  }else if(area==7){

    //画1、斜角 G位置
   var myOaAngleG=new makerjs.models.ConnectTheDots(true,[[xg,yg+h],[xg-5,yg+h],[xg-5,yg-5],[xg+w,yg-5],[xg+w,yg]]);
////标记1、斜角 G位置
var myOaAngleGMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerW.origin = [xg,yg];
var myOaAngleGMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerH.origin = [xg,yg];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0+w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleGMakerW, TxtW, [0,-markShiftFour], [0+w, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerH, new makerjs.paths.Line([0-markShiftTwo,0+h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleGMakerH, TxtH, [0-markShiftFour,0], [0-markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleGMakerW"+myHolesNum]=myOaAngleGMakerW;
markers.models["myOaAngleGMakerH"+myHolesNum]=myOaAngleGMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRATrapezoid, markers.models["myOaAngleG"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

    
  }else if(area==9){
   
     //画1、斜角 I位置
   var myOaAngleI=new makerjs.models.ConnectTheDots(true,[[xi-w,yi-5],[xi+5,yi-5],[xi+5,yi+h],[(xi-iwd),yi+h],[xi-w,yi]]);
////标记1、斜角 I位置
var myOaAngleIMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerW.origin = [xi,yi];
var myOaAngleIMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerH.origin = [xi,yi];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0+h], [0+markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0-w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleIMakerW, TxtW, [0-w,-markShiftFour], [0, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerH, new makerjs.paths.Line([0+markShift,0+h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleIMakerH, TxtH, [0+markShiftFour,0], [0+markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleIMakerW"+myHolesNum]=myOaAngleIMakerW;
markers.models["myOaAngleIMakerH"+myHolesNum]=myOaAngleIMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRATrapezoid, markers.models["myOaAngleI"+myHolesNum])
console.log("markers==",markers);
/*****************************************************************************/ 

  }
}else if(frame=="OtherTrapezoidsA"){  
  //在A区域 计算孔的位置
  var  yhd=glassH1 * 1-glassH2 * 1,
  ahd=yhd*w/((glassW*1)-(glassW1*1)-(glassW2*1)),//Y方向偏移
  awd=(glassW1*1)*h/(glassH1*1), //X方向偏移 A 
  iwd=(glassW2*1)*h/(glassH2*1), //X方向偏移
  gwd=(glassW1*1)*h/(glassH1*1), //X方向偏移
//xa=x0+xwd,ya=glassH * 1-y0,
//xa1=xwd*h/(glassH * 1),
xa=x0+glassW1 * 1,ya=glassH1 * 1-y0,
//B区域//只有Y标注没有X标注 
//xb=glassW * 1/2,yb=glassH * 1-y0,
//C区域
xc=glassW * 1-glassW2 * 1-x0,yc=glassH2 * 1-y0,
//D区域
//只有X标注没有Y标注 
//xd=x0,yd=glassH * 1/2,
//E区域
//只有X标注没有Y标注 
//xe=glassW * 1/2,ye=glassH * 1/2,
//F区域
//只有X标注没有Y标注 
//xf=glassW * 1-x0,yf=glassH * 1/2,
//在G区域 计算孔的位置
xg=x0,yg=y0,
//在H区域 计算孔的位置
//只标注Y
//xh=glassW*1/2,yh=y0,
//在I区域 计算孔的位置
xi=glassW*1-x0,yi=y0;
if(area==1){
//画1、斜角 A位置
var myOaAngleA=new makerjs.models.ConnectTheDots(true,[[xa-awd-5,ya+5],[xa-awd-5,ya-h],[xa-awd,ya-h],[xa+w,ya-ahd],[xa+w,ya+5]]);
////标记1、斜角 A位置
var myOaAngleAMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerW.origin = [xa,ya];
var myOaAngleAMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerH.origin = [xa,ya];
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0-h], [0-markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,markShift], [0+w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleAMakerW, TxtW, [0,markShiftThree], [0+w, markShiftThree]);//W文字标注
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleAMakerH, new makerjs.paths.Line([0-markShiftTwo,0-h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleAMakerH, TxtH, [0-markShiftFour,0-h], [0-markShiftFour, 0]);//H文字标注
/*****************************************************************************/
plate.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleAMakerW"+myHolesNum]=myOaAngleAMakerW;
markers.models["myOaAngleAMakerH"+myHolesNum]=myOaAngleAMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myOTrapezoidA, markers.models["myOaAngleA"+myHolesNum]);
console.log("markers==",markers);
/*****************************************************************************/ 
/*****************************************************************************/ 

}else if(area==3){
  
 //画1、斜角 C位置
 var myOaAngleC=new makerjs.models.ConnectTheDots(true,[[xc-w,yc+ahd],[xc-w,yc+ahd+5],[xc+5,yc+ahd+5],[xc+iwd+5,yc-h],[xc+iwd,yc-h]]);
////标记1、斜角 C位置
var myOaAngleCMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerW.origin = [xc,yc];
var myOaAngleCMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerH.origin = [xc,yc];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0-h], [0+markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,markShift], [0-w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleCMakerW, TxtW, [0-w,markShiftThree], [0, markShiftThree]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerH, new makerjs.paths.Line([0+markShift,0-h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleCMakerH, TxtH, [0+markShiftFour,0-h], [0+markShiftFour, 0]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleCMakerW"+myHolesNum]=myOaAngleCMakerW;
markers.models["myOaAngleCMakerH"+myHolesNum]=myOaAngleCMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myOTrapezoidA, markers.models["myOaAngleC"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 

}else if(area==7){

  //画1、斜角 G位置
 var myOaAngleG=new makerjs.models.ConnectTheDots(true,[[xg+gwd,yg+h],[xg-5,yg+h],[xg-5,yg-5],[xg+w,yg-5],[xg+w,yg]]);
////标记1、斜角 G位置
var myOaAngleGMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerW.origin = [xg,yg];
var myOaAngleGMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerH.origin = [xg,yg];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0+w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleGMakerW, TxtW, [0,-markShiftFour], [0+w, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerH, new makerjs.paths.Line([0-markShiftTwo,0+h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleGMakerH, TxtH, [0-markShiftFour,0], [0-markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleGMakerW"+myHolesNum]=myOaAngleGMakerW;
markers.models["myOaAngleGMakerH"+myHolesNum]=myOaAngleGMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myOTrapezoidA, markers.models["myOaAngleG"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

  
}else if(area==9){
 
   //画1、斜角 I位置
 var myOaAngleI=new makerjs.models.ConnectTheDots(true,[[xi-w,yi-5],[xi+5,yi-5],[xi+5,yi+h],[(xi-iwd),yi+h],[xi-w,yi]]);
////标记1、斜角 I位置
var myOaAngleIMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerW.origin = [xi,yi];
var myOaAngleIMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerH.origin = [xi,yi];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0+h], [0+markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0-w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleIMakerW, TxtW, [0-w,-markShiftFour], [0, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerH, new makerjs.paths.Line([0+markShift,0+h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleIMakerH, TxtH, [0+markShiftFour,0], [0+markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleIMakerW"+myHolesNum]=myOaAngleIMakerW;
markers.models["myOaAngleIMakerH"+myHolesNum]=myOaAngleIMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myOTrapezoidA, markers.models["myOaAngleI"+myHolesNum])
console.log("markers==",markers);
/*****************************************************************************/ 

}
}else if(frame=="OtherTrapezoidsB"){
  //在A区域 计算孔的位置
  var  yhd=glassH1 * 1-glassH2 * 1,
  ahd=yhd*w/((glassW*1)-(glassW1*1)-(glassW2*1)),//Y方向偏移
  awd=(glassW1*1)*h/(glassH*1), //X方向偏移 A 
  cwd=(glassW2*1)*h/(glassH*1), //X方向偏移 A 
  iwd=(glassW2*1)*h/(glassH2*1), //X方向偏移
  gwd=(glassW1*1)*h/(glassH1*1), //X方向偏移
//xa=x0+xwd,ya=glassH * 1-y0,
//xa1=xwd*h/(glassH * 1),
xa=x0+glassW1 * 1,ya=glassH * 1-y0,
//B区域//只有Y标注没有X标注 
//xb=glassW * 1/2,yb=glassH * 1-y0,
//C区域
xc=glassW * 1-glassW2 * 1-x0,yc=glassH * 1-y0,
//D区域
//只有X标注没有Y标注 
//xd=x0,yd=glassH * 1/2,
//E区域
//只有X标注没有Y标注 
//xe=glassW * 1/2,ye=glassH * 1/2,
//F区域
//只有X标注没有Y标注 
//xf=glassW * 1-x0,yf=glassH * 1/2,
//在G区域 计算孔的位置
xg=x0,yg=y0,
//在H区域 计算孔的位置
//只标注Y
//xh=glassW*1/2,yh=y0,
//在I区域 计算孔的位置
xi=glassW*1-x0,yi=y0;
if(area==1){
//画1、斜角 A位置
var myOaAngleA=new makerjs.models.ConnectTheDots(true,[[xa-awd-5,ya+5],[xa-awd-5,ya-h],[xa-awd,ya-h],[xa+w,ya],[xa+w,ya+5]]);
////标记1、斜角 A位置
var myOaAngleAMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerW.origin = [xa,ya];
var myOaAngleAMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerH.origin = [xa,ya];
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0-h], [0-markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,markShift], [0+w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleAMakerW, TxtW, [0,markShiftThree], [0+w, markShiftThree]);//W文字标注
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleAMakerH, new makerjs.paths.Line([0-markShiftTwo,0-h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleAMakerH, TxtH, [0-markShiftFour,0-h], [0-markShiftFour, 0]);//H文字标注
/*****************************************************************************/
plate.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleAMakerW"+myHolesNum]=myOaAngleAMakerW;
markers.models["myOaAngleAMakerH"+myHolesNum]=myOaAngleAMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myOTrapezoidB, markers.models["myOaAngleA"+myHolesNum]);
console.log("markers==",markers);
/*****************************************************************************/ 
/*****************************************************************************/ 

}else if(area==3){
  
 //画1、斜角 C位置
 var myOaAngleC=new makerjs.models.ConnectTheDots(true,[[xc-w,yc],[xc-w,yc+5],[xc+cwd+5,yc+5],[xc+cwd+5,yc-h],[xc+cwd,yc-h]]);
////标记1、斜角 C位置
var myOaAngleCMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerW.origin = [xc,yc];
var myOaAngleCMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerH.origin = [xc,yc];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0-h], [0+markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,markShift], [0-w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleCMakerW, TxtW, [0-w,markShiftThree], [0, markShiftThree]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerH, new makerjs.paths.Line([0+markShift,0-h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleCMakerH, TxtH, [0+markShiftFour,0-h], [0+markShiftFour, 0]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleCMakerW"+myHolesNum]=myOaAngleCMakerW;
markers.models["myOaAngleCMakerH"+myHolesNum]=myOaAngleCMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myOTrapezoidB, markers.models["myOaAngleC"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 

}else if(area==7){

  //画1、斜角 G位置
 var myOaAngleG=new makerjs.models.ConnectTheDots(true,[[xg+awd,yg+h],[xg-5,yg+h],[xg-5,yg-5],[xg+w,yg-5],[xg+w,yg]]);
////标记1、斜角 G位置
var myOaAngleGMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerW.origin = [xg,yg];
var myOaAngleGMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerH.origin = [xg,yg];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0+w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleGMakerW, TxtW, [0,-markShiftFour], [0+w, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerH, new makerjs.paths.Line([0-markShiftTwo,0+h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleGMakerH, TxtH, [0-markShiftFour,0], [0-markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleGMakerW"+myHolesNum]=myOaAngleGMakerW;
markers.models["myOaAngleGMakerH"+myHolesNum]=myOaAngleGMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myOTrapezoidB, markers.models["myOaAngleG"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

  
}else if(area==9){
 
   //画1、斜角 I位置
 var myOaAngleI=new makerjs.models.ConnectTheDots(true,[[xi-w,yi-5],[xi+5,yi-5],[xi+5,yi+h],[(xi-cwd),yi+h],[xi-w,yi]]);
////标记1、斜角 I位置
var myOaAngleIMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerW.origin = [xi,yi];
var myOaAngleIMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerH.origin = [xi,yi];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0+h], [0+markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0-w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleIMakerW, TxtW, [0-w,-markShiftFour], [0, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerH, new makerjs.paths.Line([0+markShift,0+h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleIMakerH, TxtH, [0+markShiftFour,0], [0+markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleIMakerW"+myHolesNum]=myOaAngleIMakerW;
markers.models["myOaAngleIMakerH"+myHolesNum]=myOaAngleIMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myOTrapezoidB, markers.models["myOaAngleI"+myHolesNum])
console.log("markers==",markers);
/*****************************************************************************/ 

}
}else if(frame=="ParallelogramA"){
  //在A区域 计算孔的位置
  var  awd=(glassW1*1)*h/(glassH*1), //X方向偏移 A
xa=x0+glassW1 * 1,ya=glassH * 1-y0,
//B区域//只有Y标注没有X标注 
//xb=glassW * 1/2,yb=glassH * 1-y0,
//C区域
xc=glassW * 1+glassW1 * 1-x0,yc=glassH * 1-y0,
//D区域
//只有X标注没有Y标注 
//xd=x0,yd=glassH * 1/2,
//E区域
//只有X标注没有Y标注 
//xe=glassW * 1/2,ye=glassH * 1/2,
//F区域
//只有X标注没有Y标注 
//xf=glassW * 1-x0,yf=glassH * 1/2,
//在G区域 计算孔的位置
xg=x0,yg=y0,
//在H区域 计算孔的位置
//只标注Y
//xh=glassW*1/2,yh=y0,
//在I区域 计算孔的位置
xi=glassW*1-x0,yi=y0;
if(area==1){
//画1、斜角 A位置
var myOaAngleA=new makerjs.models.ConnectTheDots(true,[[xa-awd-5,ya+5],[xa-awd-5,ya-h],[xa-awd,ya-h],[xa+w,ya],[xa+w,ya+5]]);
////标记1、斜角 A位置
var myOaAngleAMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerW.origin = [xa,ya];
var myOaAngleAMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerH.origin = [xa,ya];
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0-h], [0-markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,markShift], [0+w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleAMakerW, TxtW, [0,markShiftThree], [0+w, markShiftThree]);//W文字标注
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleAMakerH, new makerjs.paths.Line([0-markShiftTwo,0-h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleAMakerH, TxtH, [0-markShiftFour,0-h], [0-markShiftFour, 0]);//H文字标注
/*****************************************************************************/
plate.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleAMakerW"+myHolesNum]=myOaAngleAMakerW;
markers.models["myOaAngleAMakerH"+myHolesNum]=myOaAngleAMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myParallelogramA, markers.models["myOaAngleA"+myHolesNum]);
console.log("markers==",markers);
/*****************************************************************************/ 
/*****************************************************************************/ 

}else if(area==3){
  
 //画1、斜角 C位置
 var myOaAngleC=new makerjs.models.ConnectTheDots(true,[[xc-w,yc],[xc-w,yc+5],[xc+5,yc+5],[xc+5,yc-h],[xc-awd,yc-h]]);
////标记1、斜角 C位置
var myOaAngleCMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerW.origin = [xc,yc];
var myOaAngleCMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerH.origin = [xc,yc];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0-h], [0+markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,markShift], [0-w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleCMakerW, TxtW, [0-w,markShiftThree], [0, markShiftThree]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerH, new makerjs.paths.Line([0+markShift,0-h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleCMakerH, TxtH, [0+markShiftFour,0-h], [0+markShiftFour, 0]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleCMakerW"+myHolesNum]=myOaAngleCMakerW;
markers.models["myOaAngleCMakerH"+myHolesNum]=myOaAngleCMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myParallelogramA, markers.models["myOaAngleC"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 

}else if(area==7){

  //画1、斜角 G位置
 var myOaAngleG=new makerjs.models.ConnectTheDots(true,[[xg+awd,yg+h],[xg-5,yg+h],[xg-5,yg-5],[xg+w,yg-5],[xg+w,yg]]);
////标记1、斜角 G位置
var myOaAngleGMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerW.origin = [xg,yg];
var myOaAngleGMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerH.origin = [xg,yg];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0+w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleGMakerW, TxtW, [0,-markShiftFour], [0+w, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerH, new makerjs.paths.Line([0-markShiftTwo,0+h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleGMakerH, TxtH, [0-markShiftFour,0], [0-markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleGMakerW"+myHolesNum]=myOaAngleGMakerW;
markers.models["myOaAngleGMakerH"+myHolesNum]=myOaAngleGMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myParallelogramA, markers.models["myOaAngleG"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

  
}else if(area==9){
 
   //画1、斜角 I位置
 var myOaAngleI=new makerjs.models.ConnectTheDots(true,[[xi-w,yi-5],[xi+awd+5,yi-5],[xi+awd+5,yi+h],[xi+awd,yi+h],[xi-w,yi]]);
////标记1、斜角 I位置
var myOaAngleIMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerW.origin = [xi,yi];
var myOaAngleIMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerH.origin = [xi,yi];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0+h], [0+markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0-w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleIMakerW, TxtW, [0-w,-markShiftFour], [0, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerH, new makerjs.paths.Line([0+markShift,0+h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleIMakerH, TxtH, [0+markShiftFour,0], [0+markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleIMakerW"+myHolesNum]=myOaAngleIMakerW;
markers.models["myOaAngleIMakerH"+myHolesNum]=myOaAngleIMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myParallelogramA, markers.models["myOaAngleI"+myHolesNum])
console.log("markers==",markers);
/*****************************************************************************/ 

}
}else if(frame=="ParallelogramB"){
  console.log("markers.models=",markers.models)  
  //在A区域 计算孔的位置
  var  awd=(glassW1*1)*h/(glassH*1), //X方向偏移 A  G
       cwd=(glassW2*1)*h/(glassH*1), //X方向偏移 C  I  
//xa=x0+xwd,ya=glassH * 1-y0,
//xa1=xwd*h/(glassH * 1),
xa=x0+glassW1 * 1,ya=glassH * 1-y0,
//B区域//只有Y标注没有X标注 
//xb=glassW * 1/2,yb=glassH * 1-y0,
//C区域
xc=glassW * 1+glassW2 * 1-x0,yc=glassH * 1-y0,
//D区域
//只有X标注没有Y标注 
//xd=x0,yd=glassH * 1/2,
//E区域
//只有X标注没有Y标注 
//xe=glassW * 1/2,ye=glassH * 1/2,
//F区域
//只有X标注没有Y标注 
//xf=glassW * 1-x0,yf=glassH * 1/2,
//在G区域 计算孔的位置
xg=x0,yg=y0,
//在H区域 计算孔的位置
//只标注Y
//xh=glassW*1/2,yh=y0,
//在I区域 计算孔的位置
xi=glassW*1-x0,yi=y0;
if(area==1){
//画1、斜角 A位置
var myOaAngleA=new makerjs.models.ConnectTheDots(true,[[xa-awd-5,ya+5],[xa-awd-5,ya-h],[xa-awd,ya-h],[xa+w,ya],[xa+w,ya+5]]);
////标记1、斜角 A位置
var myOaAngleAMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerW.origin = [xa,ya];
var myOaAngleAMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerH.origin = [xa,ya];
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0-h], [0-markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,markShift], [0+w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleAMakerW, TxtW, [0,markShiftThree], [0+w, markShiftThree]);//W文字标注
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleAMakerH, new makerjs.paths.Line([0-markShiftTwo,0-h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleAMakerH, TxtH, [0-markShiftFour,0-h], [0-markShiftFour, 0]);//H文字标注
/*****************************************************************************/
plate.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleAMakerW"+myHolesNum]=myOaAngleAMakerW;
markers.models["myOaAngleAMakerH"+myHolesNum]=myOaAngleAMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myParallelogramB, markers.models["myOaAngleA"+myHolesNum]);
console.log("markers==",markers);
/*****************************************************************************/ 
/*****************************************************************************/ 

}else if(area==3){
  
 //画1、斜角 C位置
 var myOaAngleC=new makerjs.models.ConnectTheDots(true,[[xc-w,yc],[xc-w,yc+5],[xc+5,yc+5],[xc+5,yc-h],[xc-cwd,yc-h]]);
////标记1、斜角 C位置
var myOaAngleCMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerW.origin = [xc,yc];
var myOaAngleCMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerH.origin = [xc,yc];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0-h], [0+markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,markShift], [0-w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleCMakerW, TxtW, [0-w,markShiftThree], [0, markShiftThree]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerH, new makerjs.paths.Line([0+markShift,0-h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleCMakerH, TxtH, [0+markShiftFour,0-h], [0+markShiftFour, 0]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleCMakerW"+myHolesNum]=myOaAngleCMakerW;
markers.models["myOaAngleCMakerH"+myHolesNum]=myOaAngleCMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myParallelogramB, markers.models["myOaAngleC"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 

}else if(area==7){

  //画1、斜角 G位置
 var myOaAngleG=new makerjs.models.ConnectTheDots(true,[[xg+awd,yg+h],[xg-5,yg+h],[xg-5,yg-5],[xg+w,yg-5],[xg+w,yg]]);
////标记1、斜角 G位置
var myOaAngleGMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerW.origin = [xg,yg];
var myOaAngleGMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerH.origin = [xg,yg];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0+w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleGMakerW, TxtW, [0,-markShiftFour], [0+w, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerH, new makerjs.paths.Line([0-markShiftTwo,0+h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleGMakerH, TxtH, [0-markShiftFour,0], [0-markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleGMakerW"+myHolesNum]=myOaAngleGMakerW;
markers.models["myOaAngleGMakerH"+myHolesNum]=myOaAngleGMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myParallelogramB, markers.models["myOaAngleG"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

  
}else if(area==9){
 
   //画1、斜角 I位置
 var myOaAngleI=new makerjs.models.ConnectTheDots(true,[[xi-w,yi-5],[xi+cwd+5,yi-5],[xi+cwd+5,yi+h],[xi+cwd,yi+h],[xi-w,yi]]);
////标记1、斜角 I位置
var myOaAngleIMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerW.origin = [xi,yi];
var myOaAngleIMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerH.origin = [xi,yi];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0+h], [0+markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0-w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleIMakerW, TxtW, [0-w,-markShiftFour], [0, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerH, new makerjs.paths.Line([0+markShift,0+h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleIMakerH, TxtH, [0+markShiftFour,0], [0+markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleIMakerW"+myHolesNum]=myOaAngleIMakerW;
markers.models["myOaAngleIMakerH"+myHolesNum]=myOaAngleIMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myParallelogramB, markers.models["myOaAngleI"+myHolesNum])
console.log("markers==",markers);
/*****************************************************************************/ 

}
}
clickArea.push(area)
console.log("ObliqueAngle out==|||||*********",plate,markers,myHolesNum)
console.log("plate.models==|||||*********",plate.models)
const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(plate.models)));
console.log("allKeys==",allKeys.length);
myHolesNum++;
return{plate,cutPlate, markers,myHolesNum} 
};


export default ObliqueAngle;